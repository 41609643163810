<app-coding-standard-search *ngIf="showCodingStandardCheck" #codingStandardSearchComponent
                            [label]="'Check Failed Standards'"
                            [required]="false"
                            [productionStageCode]="productionStageCode"
>
</app-coding-standard-search>

<form [formGroup]="form" id="form">
    <div class="form-group col-sm-12">
        <label for="reason" class="required">{{ reasonLabel }}</label>
        <textarea
                id="reason"
                class="form-control"
                [maxLength]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                [minLength]="FormHelper.NOTE_FIELD_MIN_LENGTH"
                formControlName="reason"
                placeholder="Give a reason"
                [rows]="8"
        ></textarea>
        <error-message
                [controlName]="'reason'"
                [singularError]="true"
                [form]="form"
        ></error-message>
    </div>
</form>

