import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent {
  @Input()
  text  = '';
  showLessText = true;
  @Input()
  characterLimit = 300;
  displayedText = '';
  moreOrLess = 'more';
  ngOnInit() {
    this.getLimitedText(this.characterLimit);
  }

  showText(): string {
    if(this.showLessText){
      this.showLessText = false
      this.moreOrLess = 'less'
      return this.getLimitedText(this.text.length);
    } else {
      this.showLessText = true
      this.moreOrLess = 'more'
      return this.getLimitedText(this.characterLimit)
    }
  }

  getLimitedText(length: number): string {
    this.displayedText = this.text.slice(0, length);
    return this.displayedText
  }
}
