import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {WigPojo} from "../../../../../../../sdk/customer-fulfillment-api-sdk";

@Component({
    selector: 'app-execution-menu',
    templateUrl: './execution-menu.component.html',
    styleUrls: ['./execution-menu.component.scss']
})
export class ExecutionMenuComponent {

    constructor(private router: Router) {
    }


    createCommitment(): void {
        window.scroll(0, 0);
        this.router.navigate(['dashboard', 'execution', 'commitment', 'create'])
    }

    viewScoreboard() {
        window.scroll(0, 0);
        const itemJson = localStorage.getItem('lastWigItem');
        const item = itemJson ? JSON.parse(itemJson) : null;


        if(item!=null && item !== '' && item != undefined){
            this.router.navigate(['/dashboard/execution/scoreboard'], {
                state: {wigItem: item}
            });
        } else {
            this.router.navigate(['/dashboard/execution/wig/list']);
        }

    }
}
