/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CustomerDto } from '../model/models';
import { NameIdPojo } from '../model/models';
import { QueryResultsTicketPojo } from '../model/models';
import { Ticket } from '../model/models';
import { TicketAssignmentDto } from '../model/models';
import { TicketClassificationDto } from '../model/models';
import { TicketClassificationPojo } from '../model/models';
import { TicketDetailPojo } from '../model/models';
import { TicketDto } from '../model/models';
import { TicketLifecyclePojo } from '../model/models';
import { TicketUpdateDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ClassifyTicketRequestParams {
    ticketClassificationDto: TicketClassificationDto;
}

export interface CreateTicketRequestParams {
    ticketDto: TicketDto;
}

export interface GetCustomerRequestParams {
    id: number;
}

export interface GetTicketStatusRequestParams {
    identifier: string;
}

export interface SearchAllTicketsRequestParams {
    closedBy?: string;
    issueCategory?: string;
    lifecycleStatus?: 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    subject?: string;
    assignedAt?: string;
    resolvedBy?: string;
    description?: string;
    cancelledBy?: string;
    platform?: string;
    assignedTo?: string;
    portalAccount?: string;
    createdAt?: string;
    fulfilledAt?: string;
    qrCode?: string;
    id?: number;
    closedAt?: string;
    email?: string;
    identifier?: string;
    subService?: string;
    ticketSubCategory?: string;
    assignedBy?: string;
    resolvedAt?: string;
    resolutionStartedAt?: string;
    issuePriority?: 'CRITICAL' | 'URGENT' | 'HIGH' | 'NORMAL' | 'LOW';
    cancelledAt?: string;
    ticketType?: string;
    statusUpdateTokenSalt?: string;
    ticketCategory?: string;
    createdBy?: string;
    service?: string;
    fulfilledBy?: string;
    customer?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    lifecycleStatuses?: Array<object>;
    availableForCommitment?: boolean;
    platformId?: number;
    priority?: 'CRITICAL' | 'URGENT' | 'HIGH' | 'NORMAL' | 'LOW';
    createdAfter?: string;
    priorityItems?: Array<object>;
    issueCategoryIds?: Array<object>;
    issueCategoryId?: number;
    name?: string;
    limit?: number;
    assignee?: string;
    createdBefore?: string;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchTicketsRequestParams {
    closedBy?: string;
    issueCategory?: string;
    lifecycleStatus?: 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    subject?: string;
    assignedAt?: string;
    resolvedBy?: string;
    description?: string;
    cancelledBy?: string;
    platform?: string;
    assignedTo?: string;
    portalAccount?: string;
    createdAt?: string;
    fulfilledAt?: string;
    qrCode?: string;
    id?: number;
    closedAt?: string;
    email?: string;
    identifier?: string;
    subService?: string;
    ticketSubCategory?: string;
    assignedBy?: string;
    resolvedAt?: string;
    resolutionStartedAt?: string;
    issuePriority?: 'CRITICAL' | 'URGENT' | 'HIGH' | 'NORMAL' | 'LOW';
    cancelledAt?: string;
    ticketType?: string;
    statusUpdateTokenSalt?: string;
    ticketCategory?: string;
    createdBy?: string;
    service?: string;
    fulfilledBy?: string;
    customer?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    lifecycleStatuses?: Array<object>;
    availableForCommitment?: boolean;
    platformId?: number;
    priority?: 'CRITICAL' | 'URGENT' | 'HIGH' | 'NORMAL' | 'LOW';
    createdAfter?: string;
    priorityItems?: Array<object>;
    issueCategoryIds?: Array<object>;
    issueCategoryId?: number;
    name?: string;
    limit?: number;
    assignee?: string;
    createdBefore?: string;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface TicketAssignmentRequestParams {
    ticketAssignmentDto: TicketAssignmentDto;
}

export interface TicketDetailsRequestParams {
    id: number;
}

export interface UpdateStatusByTokenRequestParams {
    identifier: string;
    ticketUpdateDto: TicketUpdateDto;
}


@Injectable({
  providedIn: 'root'
})
export class TicketControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public classifyTicket(requestParameters: ClassifyTicketRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TicketClassificationDto>;
    public classifyTicket(requestParameters: ClassifyTicketRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TicketClassificationDto>>;
    public classifyTicket(requestParameters: ClassifyTicketRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TicketClassificationDto>>;
    public classifyTicket(requestParameters: ClassifyTicketRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const ticketClassificationDto = requestParameters.ticketClassificationDto;
        if (ticketClassificationDto === null || ticketClassificationDto === undefined) {
            throw new Error('Required parameter ticketClassificationDto was null or undefined when calling classifyTicket.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<TicketClassificationDto>(`${this.configuration.basePath}/tickets/classify-ticket`,
            ticketClassificationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTicket(requestParameters: CreateTicketRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Ticket>;
    public createTicket(requestParameters: CreateTicketRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Ticket>>;
    public createTicket(requestParameters: CreateTicketRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Ticket>>;
    public createTicket(requestParameters: CreateTicketRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const ticketDto = requestParameters.ticketDto;
        if (ticketDto === null || ticketDto === undefined) {
            throw new Error('Required parameter ticketDto was null or undefined when calling createTicket.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Ticket>(`${this.configuration.basePath}/tickets`,
            ticketDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClassificationDetails(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TicketClassificationPojo>;
    public getClassificationDetails(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TicketClassificationPojo>>;
    public getClassificationDetails(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TicketClassificationPojo>>;
    public getClassificationDetails(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TicketClassificationPojo>(`${this.configuration.basePath}/tickets/classification-properties`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomer(requestParameters: GetCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<CustomerDto>;
    public getCustomer(requestParameters: GetCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<CustomerDto>>;
    public getCustomer(requestParameters: GetCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<CustomerDto>>;
    public getCustomer(requestParameters: GetCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCustomer.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CustomerDto>(`${this.configuration.basePath}/tickets/customer-details`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRepresentatives(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameIdPojo>>;
    public getRepresentatives(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameIdPojo>>>;
    public getRepresentatives(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameIdPojo>>>;
    public getRepresentatives(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NameIdPojo>>(`${this.configuration.basePath}/tickets/representatives`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTicketStatus(requestParameters: GetTicketStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<TicketLifecyclePojo>>;
    public getTicketStatus(requestParameters: GetTicketStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<TicketLifecyclePojo>>>;
    public getTicketStatus(requestParameters: GetTicketStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<TicketLifecyclePojo>>>;
    public getTicketStatus(requestParameters: GetTicketStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getTicketStatus.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TicketLifecyclePojo>>(`${this.configuration.basePath}/tickets/${encodeURIComponent(String(identifier))}/ticket/status`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAllTickets(requestParameters: SearchAllTicketsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsTicketPojo>;
    public searchAllTickets(requestParameters: SearchAllTicketsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsTicketPojo>>;
    public searchAllTickets(requestParameters: SearchAllTicketsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsTicketPojo>>;
    public searchAllTickets(requestParameters: SearchAllTicketsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const closedBy = requestParameters.closedBy;
        const issueCategory = requestParameters.issueCategory;
        const lifecycleStatus = requestParameters.lifecycleStatus;
        const subject = requestParameters.subject;
        const assignedAt = requestParameters.assignedAt;
        const resolvedBy = requestParameters.resolvedBy;
        const description = requestParameters.description;
        const cancelledBy = requestParameters.cancelledBy;
        const platform = requestParameters.platform;
        const assignedTo = requestParameters.assignedTo;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const fulfilledAt = requestParameters.fulfilledAt;
        const qrCode = requestParameters.qrCode;
        const id = requestParameters.id;
        const closedAt = requestParameters.closedAt;
        const email = requestParameters.email;
        const identifier = requestParameters.identifier;
        const subService = requestParameters.subService;
        const ticketSubCategory = requestParameters.ticketSubCategory;
        const assignedBy = requestParameters.assignedBy;
        const resolvedAt = requestParameters.resolvedAt;
        const resolutionStartedAt = requestParameters.resolutionStartedAt;
        const issuePriority = requestParameters.issuePriority;
        const cancelledAt = requestParameters.cancelledAt;
        const ticketType = requestParameters.ticketType;
        const statusUpdateTokenSalt = requestParameters.statusUpdateTokenSalt;
        const ticketCategory = requestParameters.ticketCategory;
        const createdBy = requestParameters.createdBy;
        const service = requestParameters.service;
        const fulfilledBy = requestParameters.fulfilledBy;
        const customer = requestParameters.customer;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const lifecycleStatuses = requestParameters.lifecycleStatuses;
        const availableForCommitment = requestParameters.availableForCommitment;
        const platformId = requestParameters.platformId;
        const priority = requestParameters.priority;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const issueCategoryIds = requestParameters.issueCategoryIds;
        const issueCategoryId = requestParameters.issueCategoryId;
        const name = requestParameters.name;
        const limit = requestParameters.limit;
        const assignee = requestParameters.assignee;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (closedBy !== undefined && closedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closedBy, 'closedBy');
        }
        if (issueCategory !== undefined && issueCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>issueCategory, 'issueCategory');
        }
        if (lifecycleStatus !== undefined && lifecycleStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lifecycleStatus, 'lifecycleStatus');
        }
        if (subject !== undefined && subject !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subject, 'subject');
        }
        if (assignedAt !== undefined && assignedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedAt, 'assignedAt');
        }
        if (resolvedBy !== undefined && resolvedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolvedBy, 'resolvedBy');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (cancelledBy !== undefined && cancelledBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledBy, 'cancelledBy');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (assignedTo !== undefined && assignedTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedTo, 'assignedTo');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (fulfilledAt !== undefined && fulfilledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fulfilledAt, 'fulfilledAt');
        }
        if (qrCode !== undefined && qrCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>qrCode, 'qrCode');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (closedAt !== undefined && closedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closedAt, 'closedAt');
        }
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (subService !== undefined && subService !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subService, 'subService');
        }
        if (ticketSubCategory !== undefined && ticketSubCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketSubCategory, 'ticketSubCategory');
        }
        if (assignedBy !== undefined && assignedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedBy, 'assignedBy');
        }
        if (resolvedAt !== undefined && resolvedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolvedAt, 'resolvedAt');
        }
        if (resolutionStartedAt !== undefined && resolutionStartedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolutionStartedAt, 'resolutionStartedAt');
        }
        if (issuePriority !== undefined && issuePriority !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>issuePriority, 'issuePriority');
        }
        if (cancelledAt !== undefined && cancelledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAt, 'cancelledAt');
        }
        if (ticketType !== undefined && ticketType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketType, 'ticketType');
        }
        if (statusUpdateTokenSalt !== undefined && statusUpdateTokenSalt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>statusUpdateTokenSalt, 'statusUpdateTokenSalt');
        }
        if (ticketCategory !== undefined && ticketCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketCategory, 'ticketCategory');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (service !== undefined && service !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>service, 'service');
        }
        if (fulfilledBy !== undefined && fulfilledBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fulfilledBy, 'fulfilledBy');
        }
        if (customer !== undefined && customer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customer, 'customer');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (lifecycleStatuses) {
            lifecycleStatuses.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'lifecycleStatuses');
            })
        }
        if (availableForCommitment !== undefined && availableForCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>availableForCommitment, 'availableForCommitment');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (priority !== undefined && priority !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priority, 'priority');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (issueCategoryIds) {
            issueCategoryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'issueCategoryIds');
            })
        }
        if (issueCategoryId !== undefined && issueCategoryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>issueCategoryId, 'issueCategoryId');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (assignee !== undefined && assignee !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignee, 'assignee');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsTicketPojo>(`${this.configuration.basePath}/tickets/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ticket
     * Filter Tickets
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTickets(requestParameters: SearchTicketsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsTicketPojo>;
    public searchTickets(requestParameters: SearchTicketsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsTicketPojo>>;
    public searchTickets(requestParameters: SearchTicketsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsTicketPojo>>;
    public searchTickets(requestParameters: SearchTicketsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const closedBy = requestParameters.closedBy;
        const issueCategory = requestParameters.issueCategory;
        const lifecycleStatus = requestParameters.lifecycleStatus;
        const subject = requestParameters.subject;
        const assignedAt = requestParameters.assignedAt;
        const resolvedBy = requestParameters.resolvedBy;
        const description = requestParameters.description;
        const cancelledBy = requestParameters.cancelledBy;
        const platform = requestParameters.platform;
        const assignedTo = requestParameters.assignedTo;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const fulfilledAt = requestParameters.fulfilledAt;
        const qrCode = requestParameters.qrCode;
        const id = requestParameters.id;
        const closedAt = requestParameters.closedAt;
        const email = requestParameters.email;
        const identifier = requestParameters.identifier;
        const subService = requestParameters.subService;
        const ticketSubCategory = requestParameters.ticketSubCategory;
        const assignedBy = requestParameters.assignedBy;
        const resolvedAt = requestParameters.resolvedAt;
        const resolutionStartedAt = requestParameters.resolutionStartedAt;
        const issuePriority = requestParameters.issuePriority;
        const cancelledAt = requestParameters.cancelledAt;
        const ticketType = requestParameters.ticketType;
        const statusUpdateTokenSalt = requestParameters.statusUpdateTokenSalt;
        const ticketCategory = requestParameters.ticketCategory;
        const createdBy = requestParameters.createdBy;
        const service = requestParameters.service;
        const fulfilledBy = requestParameters.fulfilledBy;
        const customer = requestParameters.customer;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const lifecycleStatuses = requestParameters.lifecycleStatuses;
        const availableForCommitment = requestParameters.availableForCommitment;
        const platformId = requestParameters.platformId;
        const priority = requestParameters.priority;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const issueCategoryIds = requestParameters.issueCategoryIds;
        const issueCategoryId = requestParameters.issueCategoryId;
        const name = requestParameters.name;
        const limit = requestParameters.limit;
        const assignee = requestParameters.assignee;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (closedBy !== undefined && closedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closedBy, 'closedBy');
        }
        if (issueCategory !== undefined && issueCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>issueCategory, 'issueCategory');
        }
        if (lifecycleStatus !== undefined && lifecycleStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lifecycleStatus, 'lifecycleStatus');
        }
        if (subject !== undefined && subject !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subject, 'subject');
        }
        if (assignedAt !== undefined && assignedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedAt, 'assignedAt');
        }
        if (resolvedBy !== undefined && resolvedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolvedBy, 'resolvedBy');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (cancelledBy !== undefined && cancelledBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledBy, 'cancelledBy');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (assignedTo !== undefined && assignedTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedTo, 'assignedTo');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (fulfilledAt !== undefined && fulfilledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fulfilledAt, 'fulfilledAt');
        }
        if (qrCode !== undefined && qrCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>qrCode, 'qrCode');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (closedAt !== undefined && closedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>closedAt, 'closedAt');
        }
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (subService !== undefined && subService !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subService, 'subService');
        }
        if (ticketSubCategory !== undefined && ticketSubCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketSubCategory, 'ticketSubCategory');
        }
        if (assignedBy !== undefined && assignedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignedBy, 'assignedBy');
        }
        if (resolvedAt !== undefined && resolvedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolvedAt, 'resolvedAt');
        }
        if (resolutionStartedAt !== undefined && resolutionStartedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolutionStartedAt, 'resolutionStartedAt');
        }
        if (issuePriority !== undefined && issuePriority !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>issuePriority, 'issuePriority');
        }
        if (cancelledAt !== undefined && cancelledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAt, 'cancelledAt');
        }
        if (ticketType !== undefined && ticketType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketType, 'ticketType');
        }
        if (statusUpdateTokenSalt !== undefined && statusUpdateTokenSalt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>statusUpdateTokenSalt, 'statusUpdateTokenSalt');
        }
        if (ticketCategory !== undefined && ticketCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ticketCategory, 'ticketCategory');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (service !== undefined && service !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>service, 'service');
        }
        if (fulfilledBy !== undefined && fulfilledBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fulfilledBy, 'fulfilledBy');
        }
        if (customer !== undefined && customer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customer, 'customer');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (lifecycleStatuses) {
            lifecycleStatuses.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'lifecycleStatuses');
            })
        }
        if (availableForCommitment !== undefined && availableForCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>availableForCommitment, 'availableForCommitment');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (priority !== undefined && priority !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priority, 'priority');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (issueCategoryIds) {
            issueCategoryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'issueCategoryIds');
            })
        }
        if (issueCategoryId !== undefined && issueCategoryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>issueCategoryId, 'issueCategoryId');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (assignee !== undefined && assignee !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignee, 'assignee');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsTicketPojo>(`${this.configuration.basePath}/tickets`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ticketAssignment(requestParameters: TicketAssignmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public ticketAssignment(requestParameters: TicketAssignmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public ticketAssignment(requestParameters: TicketAssignmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public ticketAssignment(requestParameters: TicketAssignmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const ticketAssignmentDto = requestParameters.ticketAssignmentDto;
        if (ticketAssignmentDto === null || ticketAssignmentDto === undefined) {
            throw new Error('Required parameter ticketAssignmentDto was null or undefined when calling ticketAssignment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/tickets/assign`,
            ticketAssignmentDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ticketDetails(requestParameters: TicketDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TicketDetailPojo>;
    public ticketDetails(requestParameters: TicketDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TicketDetailPojo>>;
    public ticketDetails(requestParameters: TicketDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TicketDetailPojo>>;
    public ticketDetails(requestParameters: TicketDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling ticketDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TicketDetailPojo>(`${this.configuration.basePath}/tickets/ticket-details`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatusByToken(requestParameters: UpdateStatusByTokenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public updateStatusByToken(requestParameters: UpdateStatusByTokenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public updateStatusByToken(requestParameters: UpdateStatusByTokenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public updateStatusByToken(requestParameters: UpdateStatusByTokenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling updateStatusByToken.');
        }
        const ticketUpdateDto = requestParameters.ticketUpdateDto;
        if (ticketUpdateDto === null || ticketUpdateDto === undefined) {
            throw new Error('Required parameter ticketUpdateDto was null or undefined when calling updateStatusByToken.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/tickets/${encodeURIComponent(String(identifier))}/ticket/status`,
            ticketUpdateDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
