/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocalTime } from './local-time';
import { NameIdPojo } from './name-id-pojo';
import { CadenceEventPojo } from './cadence-event-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface CadencePojo { 
    id?: number;
    name?: string;
    frequency?: CadencePojo.FrequencyEnum;
    wigId?: number;
    wigName?: string;
    wigDescription?: string;
    players?: Array<PortalUserPojo>;
    inSession?: boolean;
    lastCadenceEvent?: CadenceEventPojo;
    createdAt?: string;
    createdBy?: NameIdPojo;
    startTime?: LocalTime;
    joinedCadence?: boolean;
}
export namespace CadencePojo {
    export type FrequencyEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY';
    export const FrequencyEnum = {
        Daily: 'DAILY' as FrequencyEnum,
        Weekly: 'WEEKLY' as FrequencyEnum,
        Monthly: 'MONTHLY' as FrequencyEnum,
        Quarterly: 'QUARTERLY' as FrequencyEnum
    };
}


