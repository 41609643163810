<!--<div-->
<!--  *ngIf="showAlertMessageTrigger"-->
<!--  [ngClass]="{ anim: showAlertMessageTrigger }"-->
<!--  [class.alert-success]="getMessageType() === AlertType.success"-->
<!--  [class.alert-danger]="getMessageType() === AlertType.error"-->
<!--  [class.alert-primary]="getMessageType() === AlertType.primary"-->
<!--  [class.alert-info]="getMessageType() === AlertType.info"-->
<!--  class="alert mb-4 fs-14 text-center"-->
<!--&gt;-->
<!--  {{ getErrorMessage() }}-->
<!--</div>-->
<!--<div class="dash-content">-->
<!--  <div class="container mt-4">-->
<!--    <div class="title text-center my-5">-->
<!--      <h1>Report an Issue</h1>-->
<!--    </div>-->

<!--    <div class="card border-0 app-card">-->
<!--      <div class="card-body">-->
<!--        <div class="description">-->
<!--          <p class="font-500">{{ line1 }}</p>-->
<!--          <p class="last" [innerHTML]="line2"></p>-->
<!--        </div>-->

<!--        <div class="body">-->
<!--          <form [formGroup]="form" (ngSubmit)="create()">-->
<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <div class="form">-->
<!--                  <label class="required" for="name">Name</label>-->
<!--                  <div class="form-wrapper">-->
<!--                    <input-->
<!--                      type="text"-->
<!--                      placeholder="Enter Your Full Name"-->
<!--                      id="name"-->
<!--                      formControlName="name"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'name'"-->
<!--                    [singularError]="true"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-6">-->
<!--                <div class="form">-->
<!--                  <label for="email" class="required">Email Address</label>-->
<!--                  <div class="form-wrapper">-->
<!--                    <input-->
<!--                      type="text"-->
<!--                      placeholder="Enter Your Email Address"-->
<!--                      id="email"-->
<!--                      formControlName="email"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'email'"-->
<!--                    [singularError]="true"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-6">-->
<!--                <div class="form">-->
<!--                  <label for="phoneNumber">Phone Number</label>-->

<!--                  <div class="form-wrapper">-->
<!--                    <ngx-intl-tel-input-->
<!--                      [cssClass]="'custom w-100'"-->
<!--                      style="width: 100%"-->
<!--                      [enableAutoCountrySelect]="false"-->
<!--                      [enableAutoCountrySelect]="true"-->
<!--                      [enablePlaceholder]="true"-->
<!--                      [searchCountryFlag]="true"-->
<!--                      [searchCountryField]="[-->
<!--                        SearchCountryField.Iso2,-->
<!--                        SearchCountryField.Name-->
<!--                      ]"-->
<!--                      [selectFirstCountry]="false"-->
<!--                      [selectedCountryISO]="selectedCountryISO"-->
<!--                      [separateDialCode]="separateDialCode"-->
<!--                      [numberFormat]="PhoneNumberFormat.National"-->
<!--                      [maxLength]="15"-->
<!--                      [phoneValidation]="true"-->
<!--                      [formControlName]="'phoneNumber'"-->
<!--                    ></ngx-intl-tel-input>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                  <error-message [controlName]="'phoneNumber'" [form]="form"></error-message>&ndash;&gt;-->
<!--                  <ng-container-->
<!--                    *ngIf="-->
<!--                      form.controls.phoneNumber.touched &&-->
<!--                      form.controls.phoneNumber.invalid-->
<!--                    "-->
<!--                  >-->
<!--                    <small-->
<!--                      [style.font-size.px]="12"-->
<!--                      [style.color]="'red ' + '!important'"-->
<!--                      class="text-danger"-->
<!--                    >-->
<!--                      This field is invalid <br-->
<!--                    /></small>-->
<!--                  </ng-container>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-6">-->
<!--                <div class="form">-->
<!--                  <label for="" class="required">Issue Category</label>-->
<!--                  <div class="form-wrapper">-->
<!--                    <select formControlName="issueCategory" >-->
<!--                      <option *ngFor="let ic of issueCategories" [value]="ic.id ">-->
<!--                        {{ ic.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'issueCategory'"-->
<!--                    [singularError]="true"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-6">-->
<!--                <div class="form">-->
<!--                  <label for="" class="required">Product</label>-->
<!--                  <div class="form-wrapper">-->
<!--                    <select formControlName="product" >-->
<!--                      <option *ngFor="let p of products" [value]="p.id ">-->
<!--                        {{ p.name | titlecase }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'product'"-->
<!--                    [singularError]="true"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-6">-->
<!--                <div class="form">-->
<!--                  <label for="" class="required">Issue priority</label>-->
<!--                  <div class="form-wrapper">-->
<!--                    <select formControlName="priority">-->
<!--                      <option readonly [value]="null">Select priority</option>-->
<!--                      <option *ngFor="let p of issuePriority" [value]="p">-->
<!--                        {{ p }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'priority'"-->
<!--                    [singularError]="true"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-12">-->
<!--                <div class="form">-->
<!--                  <label for="" class="required">Description</label>-->
<!--                  <div class="form-wrapper">-->
<!--                    <textarea formControlName="description" placeholder="Enter Description"></textarea>-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'description'"-->
<!--                    [singularError]="true"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <span-->
<!--                    class="{{ position }}"-->
<!--                    style="-->
<!--                      font-weight: 400;-->
<!--                      font-size: 0.8rem;-->
<!--                      margin-top: 0.2rem;-->
<!--                      margin-bottom: 0.3rem;-->
<!--                    "-->
<!--                  >-->
<!--                    <span *ngIf="showWarning" class="text-danger">{{-->
<!--                      "Character limit has been reached"-->
<!--                    }}</span>-->
<!--                    {{ initialCount }}/{{ limit }}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-12">-->
<!--                <div class="form">-->
<!--                  <label class="form-label"> Attach Documents (optional)</label> <br />-->
<!--                  <div class="upload-box mt-3">-->
<!--                    <ngx-dropzone-->
<!--                      [multiple]="true"-->
<!--                      (change)="onSelect($event)"-->
<!--                      class="upload"-->
<!--                      accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"-->
<!--                    >-->
<!--                      <ngx-dropzone-label>-->
<!--                        <div-->
<!--                          class="d-flex align-items-center flex-wrap flex-sm-nowrap gap-2 gap-sm-2 gap-md-5"-->
<!--                        >-->
<!--                          <label class="file-select-card">-->
<!--                            <span-->
<!--                              class="iconify icon"-->
<!--                              data-icon="basil:upload-outline"-->
<!--                            ></span>-->
<!--                            <div-->
<!--                              class="text-black"-->
<!--                              [innerHTML]="-->
<!--                                'Choose file <span class=fw-normal>or</span> drag here'-->
<!--                              "-->
<!--                            ></div>-->
<!--                            <div class="instructions">-->
<!--                              File format must only be PNG, JPG or PDF. Max 5MB in-->
<!--                              size-->
<!--                            </div>-->
<!--                          </label>-->
<!--                        </div>-->
<!--                      </ngx-dropzone-label>-->
<!--                      &lt;!&ndash;                      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">&ndash;&gt;-->
<!--                      &lt;!&ndash;                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>&ndash;&gt;-->
<!--                      &lt;!&ndash;                      </ngx-dropzone-preview>&ndash;&gt;-->
<!--                    </ngx-dropzone>-->
<!--                  </div>-->
<!--                  <error-message-->
<!--                    [controlName]="'document'"-->
<!--                    [form]="form"-->
<!--                  ></error-message>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-md-12">-->
<!--                <div class="upload-dbox mb-4" *ngIf="files && files.length > 0">-->
<!--                  <div class="row">-->
<!--                    <div class="col-sm-4 col-md-2 col-lg-2" *ngFor="let f of files; let i = index" style="position: relative;">-->
<!--                      <figure class="mb-0 position-relative">-->
<!--                        <img-->
<!--                          width="50px"-->
<!--                          [src]="f.type === 'pdf' ? 'assets/img/pdf-image.png' : 'assets/img/image.jpg'"-->
<!--                          class="mb-1 img-fluid text-center"-->
<!--                        />-->
<!--                        <figcaption style="font-size: 10px">-->
<!--                          {{ f.fileName }}-->
<!--                        </figcaption>-->
<!--                      </figure>-->
<!--                      <a style="cursor: pointer" class="fs-10 text-danger pointer" (click)="delete(f)">-->
<!--                        delete-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->

<!--              <div class="row text-center column-gap-2">-->
<!--                <div class="col-12">-->
<!--                  <re-captcha (resolved)="resolved($event)" siteKey="6LcTwMEoAAAAAEjtYDnHQuZD-a0n1tJrJsh9FlAC"></re-captcha>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                  <div class="form">-->
<!--                    <button-->
<!--                      [disabled]="submitting"-->
<!--                      (click)="cancel()"-->
<!--                      type="button"-->
<!--                      class="js-btn js-btn-primary-outline fs-14"-->
<!--                    >-->
<!--                      <span *ngIf="!submitting">Cancel</span>-->
<!--                    </button>-->

<!--                                        <button-->
<!--                      [disabled]="submitting"-->
<!--                      type="submit"-->
<!--                      class="js-btn js-btn-primary ms-3 font-200"-->
<!--                    >-->
<!--                      <span>Submit</span>-->
<!--                      <app-loading *ngIf="submitting"></app-loading>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div style="max-width: 1000px; margin: auto" class="my-5 pt-md-5">
  <div
          *ngIf="showAlertMessageTrigger"
          [ngClass]="{ anim: showAlertMessageTrigger }"
          [class.alert-success]="getMessageType() === AlertType.success"
          [class.alert-danger]="getMessageType() === AlertType.error"
          [class.alert-primary]="getMessageType() === AlertType.primary"
          [class.alert-info]="getMessageType() === AlertType.info"
          class="alert alert-danger my-5 text-center" role="alert"
  >
    {{ getErrorMessage() }}
  </div>
  <div class="mb-5">
    <h1 class="fs-26 mb-4 text-center">Report an Issue</h1>
    <div class="fs-18 text-center" style="max-width: 666px; line-height: 27px; margin: auto; opacity: 0.7;" >{{ line1 }}</div>
  </div>
  <div class="card">
    <div class="card-body p-4">
      <form [formGroup]="form" (ngSubmit)="create()">
        <div class="row align-items-center gx-3">
          <div class="col-md-12 mb-2">
            <div class="form">
              <div class="mb-2">
                <label class="form-label required filter">Name</label>
                <input
                        [formControlName]="'name'"
                        type="text"
                        class="form-control" placeholder="Enter Full Name">
              </div>
              <error-message
                      [controlName]="'name'"
                      [singularError]="true"
                      [form]="form"
              ></error-message>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="">
              <label for="email" class="required form-label">Email Address</label>
              <input
                      type="text"
                      placeholder="Enter Your Email Address"
                      id="email" class="form-control"
                      formControlName="email"
              />
              <error-message
                      [controlName]="'email'"
                      [singularError]="true"
                      [form]="form"
              ></error-message>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="">
              <label for="phoneNumber" class="form-label">Phone Number</label>
              <div class="form-wrapper">
                <ngx-intl-tel-input
                        [cssClass]="'custom w-100 form-control'"
                        style="width: 100%; border: 0px;"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="selectedCountryISO"
                        [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [formControlName]="'phoneNumber'"
                ></ngx-intl-tel-input>
              </div>
              <!--                  <error-message [controlName]="'phoneNumber'" [form]="form"></error-message>-->
              <ng-container
                      *ngIf="
                      form.controls.phoneNumber.touched &&
                      form.controls.phoneNumber.invalid
                    "
              >
                <small
                        [style.font-size.px]="12"
                        [style.color]="'red ' + '!important'"
                        class="text-danger"
                >
                  This field is invalid <br
                /></small>
              </ng-container>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="form">
              <label for="" class="required form-label">Issue Category</label>
              <select formControlName="issueCategory" class="form-select" >
                <option *ngFor="let ic of issueCategories" [value]="ic.id ">
                  {{ ic.name }}
                </option>
              </select>
              <error-message
                      [controlName]="'issueCategory'"
                      [singularError]="true"
                      [form]="form"
              ></error-message>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="form">
              <label for="" class="required form-label">Product/Platform</label>
              <select formControlName="product" class="form-select">
                <option *ngFor="let p of products" [value]="p.id ">
                  {{ p.name | uppercase }}
                </option>
              </select>
              <error-message
                      [controlName]="'product'"
                      [singularError]="true"
                      [form]="form"
              ></error-message>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="form">
              <label class="required form-label">Issue priority </label>
              <select formControlName="priority" class="form-select">
                <option readonly [value]="null">Select priority</option>
                <option *ngFor="let p of issuePriority" [value]="p">
                  {{ p }}
                </option>
              </select>
              <error-message
                      [controlName]="'priority'"
                      [singularError]="true"
                      [form]="form"
              ></error-message>
            </div>
          </div>

          <div class="col-md-12 mb-2">
            <div class="form">
              <label class="required form-label">Issue Description </label>
              <textarea formControlName="description" class="form-control" placeholder="Enter Description"></textarea>
              <error-message
                      [controlName]="'description'"
                      [singularError]="true"
                      [form]="form"
              ></error-message>
            </div>
            <div>
                  <span
                          class="{{ position }}"
                          style="
                      font-weight: 400;
                      font-size: 0.8rem;
                      margin-top: 0.2rem;
                      margin-bottom: 0.3rem;
                    "
                  >
                    <span *ngIf="showWarning" class="text-danger">{{
                      "Character limit has been reached"
                      }}</span>
                    {{ initialCount }}/{{ limit }}
                  </span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form">
              <label class="form-label"> Attach Documents</label>
              <div class="py-lg-4 px-lg-3 p-3 card rounded-2">
                <label class="file-select-card-2">
                  <ngx-dropzone
                          [multiple]="true"
                          (change)="onSelect($event)"
                          accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                  >
                    <ngx-dropzone-label>

                    </ngx-dropzone-label>
                  </ngx-dropzone>
                  <div class="my-4">
                    <svg class="mb-4" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M39.0371 28.3558V23.1538C39.0371 21.38 38.3324 19.6788 37.0781 18.4245C35.8238 17.1702 34.1226 16.4655 32.3488 16.4655H29.3762C28.7849 16.4655 28.2179 16.2306 27.7998 15.8125C27.3816 15.3944 27.1468 14.8274 27.1468 14.2361V11.2635C27.1468 9.48965 26.4421 7.78846 25.1878 6.53416C23.9335 5.27985 22.2323 4.5752 20.4585 4.5752H16.7427M30.1193 28.3558H18.229M21.2016 4.5752H11.5407C10.3101 4.5752 9.31128 5.57398 9.31128 6.80463V40.9893C9.31128 42.22 10.3101 43.2187 11.5407 43.2187H36.8077C38.0383 43.2187 39.0371 42.22 39.0371 40.9893V22.4107C39.0371 17.6804 37.158 13.1439 33.8132 9.79909C30.4684 6.45429 25.9319 4.5752 21.2016 4.5752Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>-->
                      <ellipse cx="36.923" cy="37.1598" rx="12.6838" ry="12.683" fill="#121C52"/>
                      <path d="M29.8772 40.6351V41.5124C29.8772 42.2131 30.1556 42.8852 30.6511 43.3808C31.1466 43.8763 31.8187 44.1547 32.5195 44.1547H41.3271C42.0279 44.1547 42.7 43.8763 43.1955 43.3808C43.691 42.8852 43.9694 42.2131 43.9694 41.5124V40.6316M36.9233 40.1912V30.5028M36.9233 30.5028L40.006 33.5855M36.9233 30.5028L33.8406 33.5855" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>-->
                    </svg>
                    <div class="fw-medium">Drag and drop file here or <span style="color: #121C52;" class="fw-semibold"><u>Choose file</u></span></div>
                  </div>
                </label>
                <div class="d-flex justify-content-between align-items-center my-2">
                  <div>Supported Format: <span class="fw-medium">PDF, PNG or JPG</span></div>
                  <div>Maximum Size: <span class="fw-medium">5MB</span></div>
                </div>
              </div>
              <error-message
                      [controlName]="'document'"
                      [form]="form"
              ></error-message>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-4 p-2 mb-md-5 card rounded-2" *ngIf="files && files.length > 0" style="background: #f5f8fa;">
              <div class="">
                <div class="justify-content-between d-flex" *ngFor="let f of files; let i = index" style="position: relative;">
                  <div>
                    <figure class="mb-0 position-relative">
                      <img
                              width="25px"
                              [src]="f.type === 'pdf' ? 'assets/img/pdf-image.png' : 'assets/img/image.jpg'"
                              class="mb-1 img-fluid text-center"
                      />
                      <figcaption style="font-size: 10px">
                        {{ f.fileName }}
                      </figcaption>
                    </figure>
                  </div>
                  <div class="">
                    <button (click)="delete(f)" class="btn btn-light text-danger p-2 rounded-2 ms-2" style="box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.20); background-color: #fff;" >Remove</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <re-captcha (resolved)="resolved($event)" siteKey="6LcTwMEoAAAAAEjtYDnHQuZD-a0n1tJrJsh9FlAC"></re-captcha>
        </div>
        <div class="justify-content-end text-end">
          <button
                  [disabled]="submitting"
                  (click)="cancel()"
                  type="button"
                  class="btn btn-outline-secondary px-3"
          >
            <span *ngIf="!submitting">Cancel</span>
          </button>
          <button
                  [disabled]="submitting"
                  type="submit"
                  class="btn btn-primary ms-3 px-3"
          >
            <span>Submit</span>
            <app-loading *ngIf="submitting"></app-loading>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
