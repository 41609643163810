export class NameValuePair {
  public name: any;
  public value: any;
  public isSelected: any;

  constructor(value: any, name: any) {
    this.name = name;
    this.value = value;
  }
}
