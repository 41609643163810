<div class="panel">
    <div class="d-flex flex-wrap column-gap-5 align-items-center">
        <!-- Show on staging only. And in prod after the icons has been placed continuously at the buttom of the screen
        <div class="pos disable">
            <img src="/assets/img/add-blocker.svg" width="42" height="41" class="img-fluid" alt="">
            <p>Add <br/>Blocker</p>
        </div>

        <div class="pos">
            <img (click)="createCommitment()" src="/assets/img/create-commitment.svg" width="42" height="41" class="img-fluid" alt="">
            <p (click)="createCommitment()">Create <br/>Commitment</p>
        </div>

        <div class="pos pointer" (click)="viewScoreboard()">
            <img src="/assets/img/view-scoreboard.svg" width="42" height="41" class="img-fluid" alt="">
            <p>View <br/>Scoreboard</p>
        </div>
        -->
    </div>
</div>
