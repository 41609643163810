/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { ProcessDefinition } from './process-definition';
import { PortalUser } from './portal-user';


export interface ProductionProcessStage { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: ProductionProcessStage.StatusEnum;
    stageNumber?: number;
    identifier?: string;
    name?: string;
    code?: string;
    shortDisplayName?: string;
    supportingDocRequiredForUpdate?: boolean;
    mandatoryStage?: boolean;
    decompositionRequiredForUpdate?: boolean;
    entryCanBeDeleted?: boolean;
    generatesOutputFile?: boolean;
    autoRequestNextStageOnUpdate?: boolean;
    directUpdateSupported?: boolean;
    attestationRequiredForCICompliance?: boolean;
    attestationRequiredForReleaseCompliance?: boolean;
    portalAccount?: PortalAccount;
    nextStage?: ProductionProcessStage;
    previousStage?: ProductionProcessStage;
    process?: ProcessDefinition;
}
export namespace ProductionProcessStage {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


