/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WigPojo } from './wig-pojo';
import { CustomerValuePojo } from './customer-value-pojo';
import { QueryResultsLeadMeasureCommitmentGroupingPojo } from './query-results-lead-measure-commitment-grouping-pojo';
import { PortalUserDetailsPojo } from './portal-user-details-pojo';


export interface ScoreboardPojo { 
    wigPojo?: WigPojo;
    customerValuePojoList?: Array<CustomerValuePojo>;
    totalNumberOfOpenBugs?: number;
    totalNumberOfPendingTickets?: number;
    totalNumberOfSignedCustomerSignOffs?: number;
    totalNumberOfExpectedCustomerSignOffs?: number;
    totalNumberOfPacsIssued?: number;
    totalNumberOfPacs?: number;
    playerWithoutCommitmentList?: Array<PortalUserDetailsPojo>;
    leadMeasureCommitmentGroupingPojoList?: QueryResultsLeadMeasureCommitmentGroupingPojo;
}

