/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentPojo } from './attachment-pojo';


export interface PlatformPojo { 
    id?: number;
    name?: string;
    code?: string;
    description?: string;
    url?: string;
    logo?: AttachmentPojo;
    createdAt?: string;
    createdBy?: string;
    bugCount?: number;
    pendingTickets?: number;
}

