<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title>
        {{ 'User Story' }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                class="text-danger pointer"
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>

</div>


<mat-dialog-content class="p-4">
    <div *ngIf="showAlertMessageTrigger"
         [ngClass]="{ anim: showAlertMessageTrigger }"
         [class.alert-success]="getMessageType() === AlertType.success"
         [class.alert-danger]="getMessageType() === AlertType.error"
         [class.alert-primary]="getMessageType() === AlertType.primary"
         [class.alert-info]="getMessageType() === AlertType.info"
         class="alert mb-4 fs-14 text-center"
    >
        {{ getErrorMessage() }}
    </div>
    <div class="row">
        <div class="col-12">
            <div class="content">
                <ng-container class="mt-5" *ngFor="let us of userStories; let i = index">
                    <div class="card bg-light-subtle">
                        <div class="card-body ">
                            <div class=" mb-2">
                                <div class="me-3">
                                    <div class="fs-14 fw-bold">As A (Type Of User)</div>
                                    <div class="fs-12">{{ us?.actor }}</div>
                                </div>
                                <div class="me-3">
                                    <div class="fs-14 fw-bold">I Want To (Action)</div>
                                    <div class="fs-12">{{ us?.action }}</div>
                                </div>
                                <div class="me-3">
                                    <div class="fs-14 fw-bold">So That (Value)</div>
                                    <div class="fs-12">{{ us?.value }}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr class="divider mb-4">
                </ng-container>

            </div>
        </div>
    </div>
    <app-acceptance-criteria-selector *ngIf="shouldShowCriteriaSearch"
            (alert)="showOutputMessages($event)"
            [userStoryId]="this.userStories[0].id"></app-acceptance-criteria-selector>

    <div class="text-end mt-4">
        <button (click)="onDone(); (false)"
                class="btn btn-dark mt-1"
                type="button">
         Done
        </button>
    </div>
</mat-dialog-content>
