<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<form [formGroup]="form">
    <div class="mt-3 mb-5 p-3 mx-3 search bg-white row g-3 d-sm-flex align-items-center justify-content-between">
        <div class="col-md-5 mt-0">
            <div class="material-form">
                <input type="email" class="form-control" [formControlName]="'keyword'"
                       placeholder="Search by Module Name, Module ID or any keyword">
            </div>
        </div>
        <div class="col-md-7">
            <div class="d-flex align-items-center float-end" [formGroup]="form">
                <div class="dropdown">
                    <button class="btn btn-outline-secondary px-3 d-flex align-items-center" role="button"
                            id="dropdownMenuLink1"
                            data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify icon me-2"
                                                                                  data-icon="solar:sort-line-duotone"></span>Filter
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end mt-3 p-3"
                        style="width: 270px; border-radius: 10px; box-shadow: 2px 4px 13px 0px rgba(0, 0, 0, 0.10);"
                        aria-labelledby="dropdownMenuLink1">
                        <li style="cursor: pointer">
                            <div class="mb-3">
                                <label class="form-label filter">Module Name</label>
                                <input type="text" formControlName="moduleName" class="form-control"
                                       placeholder="Enter Module Name">
                            </div>
                            <div class="mb-3">
                                <label class="form-label filter">Module ID</label>
                                <input type="text" formControlName="moduleId" class="form-control"
                                       placeholder="Enter Module ID">
                            </div>
                            <div class="mb-3">
                                <label class="form-label filter">Deployment Environment</label>
                                <select class="form-control form-select" formControlName="deploymentEnvCode">
                                    <option readonly [value]="''">Select Deployment Environment</option>
                                    <option *ngFor="let e of environments$ | async" [value]="e.code">{{ e.name }}
                                    </option>
                                </select>
                                <error-message [form]="form" [controlName]="'deploymentEnvCode'"></error-message>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Start Date</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="createdAfter"
                                        placeholder="DD-MM-YYYY"
                                        #dp1="bsDatepicker"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' }"
                                        bsDatepicker
                                />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">End Date</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="createdBefore"
                                        placeholder="DD-MM-YYYY"
                                        #dp2="bsDatepicker"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' }"
                                        [maxDate]="now()"
                                        [minDate]="getMinDate()"
                                        bsDatepicker
                                />
                                <error-message [form]="form" [controlName]="'createdBefore'"></error-message>
                            </div>
                            <div class="mb-3">
                                <button class="btn btn-dark w-100" (click)="submit()"><span class="iconify icon"
                                                                                            data-icon="iconamoon:search-thin"></span>Search
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <button [disabled]="searchManager.working"
                        class="btn btn-dark ms-3 px-3 d-flex align-items-center" (click)="submit()">
                    <i *ngIf="!searchManager.working">
                        <i class="iconify icon me-2"
                           data-icon="iconamoon:search-thin"></i>
                    </i>
                    <app-loading *ngIf="this.searchManager.working"></app-loading>
                    Search
                </button>
            </div>
        </div>
    </div>
</form>




<ng-container *ngIf="!emptyListShowOnce">
    <page-size
            *ngIf="searchManager.totalCount > 10"
            [searchManager]="searchManager"
    ></page-size>
    <div class="p-d3 px-4 bg-white background mdx-3">

        <div class="d-flex justify-content-between flex-wrap p-1 bg-white ms-3"
             style="border-radius: 5px;">
            <div class="pos">
                <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
                <span class="fw-medium primary-color"> {{ this.selection.selected.length }} Selected</span>
                <u class="fw-medium pointer primary-color" (click)="clearSelection()">Clear</u>
            </div>

            <button class="btn btn-dark px-4 btn-lg" (click)="mapTo()">
                {{ callToActionText }}
            </button>
        </div>
        <hr>

        <div class="card" style="border: none;">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">SN</th>
                            <th scope="col">Module ID</th>
                            <th scope="col">Module Name</th>
                            <th scope="col">Module Description</th>
                        </tr>
                        </thead>
                        <ng-container>
                            <ng-container *ngIf="true">
                                <tbody>
                                <tr
                                        class="justify-content-center"
                                        *ngFor="let module of searchManager.list; let i = index">
                                    <td>
                                        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                      (change)="$event ? selection.toggle(module.id) : null"
                                                      [checked]="selection.isSelected(module.id)">
                                        </mat-checkbox>
                                    </td>
                                    <td>{{ i + searchManager.offset + 1 }}</td>
                                    <td>{{ module?.identifierDisplayName | uppercase }}</td>
                                    <td>{{ module?.name }}</td>
                                    <td>
                                        {{ helperService.cutText(module?.description || '-', 40) }}
                                        <span *ngIf="module?.description?.length >= 41" [placement]="'right'"
                                              [tooltip]="module?.description">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                    </td>
                                </tr>
                                </tbody>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div
            *ngIf="searchManager.totalCount > 10"
            class="row py-2"
            [ngClass]="{ 'disabled-table': searchManager.working }"
    >
        <div class="col-12 d-flex justify-content-end">
            <pagination
                    [(ngModel)]="searchManager.page"
                    [boundaryLinks]="true"
                    [itemsPerPage]="searchManager.itemsPerPage"
                    [maxSize]="maxPageSize"
                    [rotate]="true"
                    [totalItems]="searchManager.totalCount"
                    firstText="&laquo;"
                    lastText="&raquo;"
                    nextText="&rsaquo;"
                    previousText="&lsaquo;"
            ></pagination>
        </div>
    </div>
</ng-container>


<empty-list
        *ngIf="searchManager.list.length == 0 && !searchManager.working && emptyListShowOnce"
        [description]="'No Business Requirement has been created or searched for'"
        [@fadeInOut]></empty-list>

