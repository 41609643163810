<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<div class="p-2 mt-5" style="border: 0.5px solid rgba(0, 0, 0, 0.50);  border-radius: 7px;">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="p-2  mb-2 searchx row align-items-end row-gap-3">

            <div class="col-md-9">
                <label class="form-label fw-medium" *ngIf="label">
                    {{ label }}
                </label>
            </div>
            <div class="col-md-3 text-end">
                <input type="text" formControlName="keyword" id="keyword"
                       class="form-control"
                       placeholder="Search Request Keyword">
            </div>
        </div>
    </form>

    <div *ngIf="searchManager.totalCount > 0 " class="row"
         [ngClass]="{'disabled-table': searchManager.working}">
        <div class="d-md-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <app-total-records [searchManager]="searchManager"></app-total-records>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <page-size
                        *ngIf="searchManager.totalCount > 0"
                        [searchManager]="searchManager"
                ></page-size>
            </div>
        </div>
    </div>
    <div class="card search" style="border: 0.2px solid #ececec;">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 5%">S/N</th>
                        <th scope="col" style="width: 20%">User Type</th>
                        <th scope="col" style="width: 30%">I Want To (Action)</th>
                        <th scope="col" style="width: 30%">So That I Can (Value)</th>
                        <th scope="col" style="width: 10%">Criteria Count</th>
                    </tr>
                    </thead>

                    <ng-container *ngIf="!searchManager._working; else stillLoading">
                        <ng-container *ngIf="searchManager.list.length > 0; else NoDataToDisplay">
                            <tbody>
                            <tr class="justify-content-center"
                                *ngFor="let story of searchManager.list; let i = index">
                                <td class="fw-bold">{{ i + searchManager.offset + 1 }}</td>
                                <td>{{ story?.actor }}</td>
                                <td>
                                    {{ helperService.cutText(story.action, 40) }}
                                    <span *ngIf="story.action?.length >= 41" [placement]="'right'"
                                          [tooltip]="story.action">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                </td>
                                <td>
                                    {{ helperService.cutText(story.value, 40) }}
                                    <span *ngIf="story.value.length >= 41" [placement]="'right'"
                                          [tooltip]="story.value">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                </td>
                                <td>
                                    <a class="text-primary text-decoration-underline"
                                       [ngClass]="{'text-decoration-none': story?.criteriaCount == 0}"
                                       (click)="viewCriteriaList(story?.id)">{{ story?.criteriaCount || 0 }}</a>
                                </td>
                            </tr>
                            </tbody>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>


    <div
            *ngIf="searchManager.totalCount > 10"
            class="row py-2"
            [ngClass]="{ 'disabled-table': searchManager.working }"
    >
        <div class="col-12 d-flex justify-content-end">
            <pagination
                    [(ngModel)]="searchManager.page"
                    [boundaryLinks]="true"
                    [itemsPerPage]="searchManager.itemsPerPage"
                    [maxSize]="maxPageSize"
                    [rotate]="true"
                    [totalItems]="searchManager.totalCount"
                    firstText="&laquo;"
                    lastText="&raquo;"
                    nextText="&rsaquo;"
                    previousText="&lsaquo;"
            ></pagination>
        </div>
    </div>

</div>


<ng-template #stillLoading>
    <tr>
        <td colspan="100%">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #NoDataToDisplay>
    <tr>
        <td class="text-center" colspan="100%">NO DATA</td>
    </tr>
</ng-template>
