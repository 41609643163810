/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { Img } from './img.type';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss']
})
export class ImgComponent {

  @Input() loader = 'assets/img/Loader.gif';

  @Input() config : Img = {
    image: '',
    alt: "Image",
    width: 0,
    height: 0,
    cover: false,
    contain: false,
    circle: false,
    padding: 0,
    background: '#fff'
  };

  @Input()
  showHtml = false;

  @Input()
  htmlText = '+'


  isLoading:boolean;
  showSuccessImage = false
  showErrorImage = false
  errorImage = 'assets/img/broken-image.png'

  constructor() {
    this.isLoading=true;
  }

  hideLoader(){
    this.isLoading=false;
  }

  showImage(){
    this.isLoading=false;
    this.showSuccessImage = true
    this.showErrorImage = false
  }

  showError(){
    this.isLoading = false
    this.showSuccessImage = false
    this.showErrorImage = true
  }

}
