import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchHandler} from "../../search/search-handler";
import {
    QueryResultsUserStoryPojo,
    SearchUserStoryRequestParams,
    UserStoryControllerService,
    UserStoryPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../search/search-filter-source";
import {SearchManager} from "../../search/search-manager";
import {HelperService} from "../../../services/helper.service";
import {FormBuilder, Validators} from "@angular/forms";
import {PlatformSelectorService} from "../../../services/platform-selector.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {distinctUntilChanged, Observable} from "rxjs";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../search/paginated-search";
import {CriteriaCountModalComponent} from "../criteria-count-modal/criteria-count-modal.component";
import {
    CreateAcceptanceCriteriaDialogComponent
} from "../../../pages/intranet/process/components/create-acceptance-criteria-dialog/create-acceptance-criteria-dialog.component";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';

@Component({
    selector: 'app-user-story-search',
    templateUrl: './user-story-search.component.html',
    styleUrls: ['./user-story-search.component.css']
})
export class UserStorySearchComponent implements OnInit,
    SearchHandler<UserStoryPojo, SearchUserStoryRequestParams>,
    SearchFilterSource<SearchUserStoryRequestParams> {

    form = this.fb.group({
        action: [''],
        actor: [''],
        value: [''],
        customerValueId: [''],
        createdBefore: [''],
        createdAfter: [''],
        useCaseId: [undefined as number, Validators.required],
        keyword: ['']
    });

    @Input()
    label = ''

    @Input()
    useCaseId: number;

    searchManager!: SearchManager<UserStoryPojo, SearchUserStoryRequestParams>;

    maxPageSize = 5;

    platformId: number;

    @Input()
    module: number;

    @Input()
    exclude: number[];

    emptyListShowOnce = true;

    initialSelection = [];

    @Output()
    selectedIds: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    constructor(public helperService: HelperService,
                private fb: FormBuilder,
                private userStoryControllerService: UserStoryControllerService,
                private platformSelectorService: PlatformSelectorService,
                private modalService: BsModalService) {

        this.searchManager = new SearchManager<UserStoryPojo, SearchUserStoryRequestParams>(this, this);
        this.emptyListShowOnce = false;
    }

    getFilter(): SearchUserStoryRequestParams {
        const form = this.form.getRawValue();
        return Object.assign(form);
    }

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    ngOnInit() {
        this.updateFormValue();
        this.initListeners();
    }

    updateFormValue(): void {
        this.form.controls.useCaseId.setValue(this.useCaseId);
    }

    initListeners(): void {

        this.platformSelectorService.currentPlatform.pipe(distinctUntilChanged()).subscribe(value => {
            if (value) this.submit();
        });

    }

    getPersistentKey(): string {
        return 'UserStorySearchComponent';
    }

    getSearchDescriptor(e: SearchUserStoryRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchUserStoryRequestParams): Observable<QueryResultsUserStoryPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        // filter.name = this.form.get('name').value;

        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.userStoryControllerService.searchUserStory(filter);
    }

    viewCriteriaList(id: number) {
        this.helperService.loadModal(CriteriaCountModalComponent, 'Criteria', '',
            {
                userStoryId: id,
                showActionColumn: false
            },
            '1200px')
            .afterClosed()
            .subscribe((value) => {
                // this.searchManager.reloadAndShowFirstPage();
            });
    }

    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    createCriteria(userStory: UserStoryPojo) {
        const bsModal = this.modalService.show(CreateAcceptanceCriteriaDialogComponent, {
            initialState: {
                userStory
            },
            backdrop: 'static',
            class: 'modal-lg modal-dialog-centered',
            keyboard: false
        });
        bsModal.content.addedCriteriaEvent.subscribe(() => {
            this.searchManager.reloadAndShowFirstPage();
        });

    }

}
