/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentPojo } from './attachment-pojo';


export interface ProcessStageTrainingPojo { 
    id?: number;
    position?: number;
    type?: ProcessStageTrainingPojo.TypeEnum;
    imageContent?: AttachmentPojo;
    textContent?: string;
    processStageId?: number;
    processStageCode?: string;
}
export namespace ProcessStageTrainingPojo {
    export type TypeEnum = 'IMAGE' | 'TEXT';
    export const TypeEnum = {
        Image: 'IMAGE' as TypeEnum,
        Text: 'TEXT' as TypeEnum
    };
}


