/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotifyRequestApproverDto { 
    emailAddress: string;
    accessDetails?: string;
    notes?: string;
    complianceId: number;
    processStage?: NotifyRequestApproverDto.ProcessStageEnum;
}
export namespace NotifyRequestApproverDto {
    export type ProcessStageEnum = 'RDA' | 'MRP';
    export const ProcessStageEnum = {
        Rda: 'RDA' as ProcessStageEnum,
        Mrp: 'MRP' as ProcessStageEnum
    };
}


