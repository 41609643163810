<div class="d-flex justify-content-between mx-2">
    <div mat-dialog-title>

    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>
</div>


<mat-dialog-content>
  <div mat-dialog-title class="d-flex justify-content-center align-items-center p-2">
    <img src="assets/img/bg_warning.svg" alt="" />
  </div>
  <div class="body">
    <h5>{{ data.title }}</h5>
    <p class="mt-3 text-center">{{ data.description }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex justify-content-evenly w-100 pb-5">
    <button
      *ngIf="showCancel"
      style="min-width: 120px"
      class="btn btn-light fs-14"
      (click)="no()"
    >
      {{cancelText}}
    </button>
    <button
      *ngIf="showConfirm"
      style="min-width: 120px"
      class="btn btn-dark ms-3 font-200"
      (click)="yes()"
      cdkFocusInitial
    >
      {{confirmText}}
    </button>
  </div>
</mat-dialog-actions>
