import {Component, Input, OnInit} from '@angular/core';
import {PortalAccountTypeRole, PortalUserPojo} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {User} from "../../../models/user/user.model";
import {AuthenticationService} from "../../../services/authentication.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-view-player',
    templateUrl: './view-player.component.html',
    styleUrls: ['./view-player.component.css']
})
export class ViewPlayerComponent implements OnInit {

    @Input()
    players: Array<PortalUserPojo>

    user: User | undefined;

    constructor(private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        this.fetchUser();
    }

    fetchUser(): void {
        this.authenticationService.getUser().subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
    }

    getRoles(roles: Array<string> | undefined) {
        return roles?.join(", ");
    }

    getRoleFromUserPojo(player: PortalUserPojo): Array<string> {
        return player?.memberships?.find(x => x.accountCode == this.user?.accounts[0].accountCode)?.roles

    }
}
