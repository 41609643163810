<form action="" [formGroup]="config?.form">
    <div class="d-flex column-gap-3">

        <div class="d-flex flex-column align-items-center justify-content-center">
            <input type="number" [formControlName]="config?.inputOneFormControlName"
                   [style.width]="config?.inputWidth"
                   class="form-control">
            <div class="label fs-10">{{ config?.inputOneLabel }}</div>
        </div>
        <div class="slash"></div>
        <div class="d-flex flex-column align-items-center justify-content-center">
            <input type="number" [formControlName]="config?.inputTwoFormControlName"
                   [style.width]="config?.inputWidth"
                   class="form-control">
            <div class="label fs-10">{{ config?.inputTwoLabel }}</div>
        </div>

    </div>
</form>
