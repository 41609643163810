/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CICreationRequestDtoV2 } from '../model/models';
import { CIRequestChecklistDto } from '../model/models';
import { CIRequestStatusUpdateDto } from '../model/models';
import { CIRequestUpdateDto } from '../model/models';
import { NameIdPojo } from '../model/models';
import { QueryResultsCIRequestPojo } from '../model/models';
import { RequirementSpecificationDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ActivateRequestRequestParams {
    requirementSpecificationDto: RequirementSpecificationDto;
}

export interface CreateCIRequestRequestParams {
    cICreationRequestDtoV2: CICreationRequestDtoV2;
}

export interface SearchCIRequestsRequestParams {
    identifier?: string;
    createdAt?: string;
    createdBy?: string;
    identifierDisplayName?: string;
    id?: number;
    requestStatus?: 'LOGGED' | 'ACTIVATED' | 'ASSIGNED' | 'RESOLVED' | 'ACCEPTED';
    platform?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    createdByName?: string;
    offset?: number;
    platformId?: number;
    createdAfter?: string;
    priorityItems?: Array<object>;
    productionUnitId?: number;
    searchAcrossPortalAccounts?: boolean;
    limit?: number;
    assignee?: string;
    createdBefore?: string;
    moduleId?: number;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface UpdateCIRequestRequestParams {
    cIRequestUpdateDto: CIRequestUpdateDto;
}

export interface UpdateCIRequestChecklistRequestParams {
    cIRequestChecklistDto: CIRequestChecklistDto;
}

export interface UpdateCIRequestStatusRequestParams {
    cIRequestStatusUpdateDto: CIRequestStatusUpdateDto;
}

export interface UpdateRequirementSpecificationRequestParams {
    requirementSpecificationDto: RequirementSpecificationDto;
}


@Injectable({
  providedIn: 'root'
})
export class CiRequestControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateRequest(requestParameters: ActivateRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public activateRequest(requestParameters: ActivateRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public activateRequest(requestParameters: ActivateRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public activateRequest(requestParameters: ActivateRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const requirementSpecificationDto = requestParameters.requirementSpecificationDto;
        if (requirementSpecificationDto === null || requirementSpecificationDto === undefined) {
            throw new Error('Required parameter requirementSpecificationDto was null or undefined when calling activateRequest.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/ci-requests/activate-request`,
            requirementSpecificationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCIRequest(requestParameters: CreateCIRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<NameIdPojo>;
    public createCIRequest(requestParameters: CreateCIRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<NameIdPojo>>;
    public createCIRequest(requestParameters: CreateCIRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<NameIdPojo>>;
    public createCIRequest(requestParameters: CreateCIRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const cICreationRequestDtoV2 = requestParameters.cICreationRequestDtoV2;
        if (cICreationRequestDtoV2 === null || cICreationRequestDtoV2 === undefined) {
            throw new Error('Required parameter cICreationRequestDtoV2 was null or undefined when calling createCIRequest.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<NameIdPojo>(`${this.configuration.basePath}/ci-requests/create`,
            cICreationRequestDtoV2,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * C&amp;I Request
     * Filter C&amp;I Requests
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCIRequests(requestParameters: SearchCIRequestsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsCIRequestPojo>;
    public searchCIRequests(requestParameters: SearchCIRequestsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsCIRequestPojo>>;
    public searchCIRequests(requestParameters: SearchCIRequestsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsCIRequestPojo>>;
    public searchCIRequests(requestParameters: SearchCIRequestsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const createdAt = requestParameters.createdAt;
        const createdBy = requestParameters.createdBy;
        const identifierDisplayName = requestParameters.identifierDisplayName;
        const id = requestParameters.id;
        const requestStatus = requestParameters.requestStatus;
        const platform = requestParameters.platform;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const createdByName = requestParameters.createdByName;
        const offset = requestParameters.offset;
        const platformId = requestParameters.platformId;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const productionUnitId = requestParameters.productionUnitId;
        const searchAcrossPortalAccounts = requestParameters.searchAcrossPortalAccounts;
        const limit = requestParameters.limit;
        const assignee = requestParameters.assignee;
        const createdBefore = requestParameters.createdBefore;
        const moduleId = requestParameters.moduleId;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (identifierDisplayName !== undefined && identifierDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifierDisplayName, 'identifierDisplayName');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (requestStatus !== undefined && requestStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestStatus, 'requestStatus');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (createdByName !== undefined && createdByName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdByName, 'createdByName');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (productionUnitId !== undefined && productionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productionUnitId, 'productionUnitId');
        }
        if (searchAcrossPortalAccounts !== undefined && searchAcrossPortalAccounts !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchAcrossPortalAccounts, 'searchAcrossPortalAccounts');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (assignee !== undefined && assignee !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>assignee, 'assignee');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (moduleId !== undefined && moduleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>moduleId, 'moduleId');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsCIRequestPojo>(`${this.configuration.basePath}/ci-requests`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCIRequest(requestParameters: UpdateCIRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<NameIdPojo>;
    public updateCIRequest(requestParameters: UpdateCIRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<NameIdPojo>>;
    public updateCIRequest(requestParameters: UpdateCIRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<NameIdPojo>>;
    public updateCIRequest(requestParameters: UpdateCIRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const cIRequestUpdateDto = requestParameters.cIRequestUpdateDto;
        if (cIRequestUpdateDto === null || cIRequestUpdateDto === undefined) {
            throw new Error('Required parameter cIRequestUpdateDto was null or undefined when calling updateCIRequest.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<NameIdPojo>(`${this.configuration.basePath}/ci-requests/update`,
            cIRequestUpdateDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCIRequestChecklist(requestParameters: UpdateCIRequestChecklistRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateCIRequestChecklist(requestParameters: UpdateCIRequestChecklistRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateCIRequestChecklist(requestParameters: UpdateCIRequestChecklistRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateCIRequestChecklist(requestParameters: UpdateCIRequestChecklistRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const cIRequestChecklistDto = requestParameters.cIRequestChecklistDto;
        if (cIRequestChecklistDto === null || cIRequestChecklistDto === undefined) {
            throw new Error('Required parameter cIRequestChecklistDto was null or undefined when calling updateCIRequestChecklist.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/ci-requests/update-checklist`,
            cIRequestChecklistDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCIRequestStatus(requestParameters: UpdateCIRequestStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateCIRequestStatus(requestParameters: UpdateCIRequestStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateCIRequestStatus(requestParameters: UpdateCIRequestStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateCIRequestStatus(requestParameters: UpdateCIRequestStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const cIRequestStatusUpdateDto = requestParameters.cIRequestStatusUpdateDto;
        if (cIRequestStatusUpdateDto === null || cIRequestStatusUpdateDto === undefined) {
            throw new Error('Required parameter cIRequestStatusUpdateDto was null or undefined when calling updateCIRequestStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/ci-requests/update-status`,
            cIRequestStatusUpdateDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRequirementSpecification(requestParameters: UpdateRequirementSpecificationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateRequirementSpecification(requestParameters: UpdateRequirementSpecificationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateRequirementSpecification(requestParameters: UpdateRequirementSpecificationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateRequirementSpecification(requestParameters: UpdateRequirementSpecificationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const requirementSpecificationDto = requestParameters.requirementSpecificationDto;
        if (requirementSpecificationDto === null || requirementSpecificationDto === undefined) {
            throw new Error('Required parameter requirementSpecificationDto was null or undefined when calling updateRequirementSpecification.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/ci-requests/update-requirement-specification`,
            requirementSpecificationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
