/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CIRequestChecklistPojo } from './ci-request-checklist-pojo';
import { PlatformPojo } from './platform-pojo';
import { RequirementSpecificationPojo } from './requirement-specification-pojo';
import { AttachmentPojo } from './attachment-pojo';
import { BusinessRequirementPojo } from './business-requirement-pojo';
import { SoftwareProductionUnitPojo } from './software-production-unit-pojo';


export interface CIRequestPojo { 
    id?: number;
    identifier?: string;
    identifierDisplayName?: string;
    requestStatus?: CIRequestPojo.RequestStatusEnum;
    createdAt?: string;
    softwareProductionUnits?: Array<SoftwareProductionUnitPojo>;
    platform?: PlatformPojo;
    attachments?: Array<AttachmentPojo>;
    requester?: string;
    requirementSpecifications?: Array<RequirementSpecificationPojo>;
    businessRequirements?: Array<BusinessRequirementPojo>;
    ciRequestChecklists?: Array<CIRequestChecklistPojo>;
}
export namespace CIRequestPojo {
    export type RequestStatusEnum = 'LOGGED' | 'ACTIVATED' | 'ASSIGNED' | 'RESOLVED' | 'ACCEPTED';
    export const RequestStatusEnum = {
        Logged: 'LOGGED' as RequestStatusEnum,
        Activated: 'ACTIVATED' as RequestStatusEnum,
        Assigned: 'ASSIGNED' as RequestStatusEnum,
        Resolved: 'RESOLVED' as RequestStatusEnum,
        Accepted: 'ACCEPTED' as RequestStatusEnum
    };
}


