/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SoftwareDeploymentEnvironmentPojo { 
    id?: number;
    name?: SoftwareDeploymentEnvironmentPojo.NameEnum;
    code?: string;
}
export namespace SoftwareDeploymentEnvironmentPojo {
    export type NameEnum = 'WEB' | 'MOBILE' | 'DESKTOP' | 'EMBEDDED_SYSTEM';
    export const NameEnum = {
        Web: 'WEB' as NameEnum,
        Mobile: 'MOBILE' as NameEnum,
        Desktop: 'DESKTOP' as NameEnum,
        EmbeddedSystem: 'EMBEDDED_SYSTEM' as NameEnum
    };
}


