/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OTPValidationDto { 
    type: OTPValidationDto.TypeEnum;
    channel: OTPValidationDto.ChannelEnum;
    recipient: string;
    firstName?: string;
    pin: string;
}
export namespace OTPValidationDto {
    export type TypeEnum = 'USER_REGISTRATION' | 'PHONE_NUMBER_UPDATE' | 'GENERAL' | 'ONBOARDING_PHONE' | 'ONBOARDING_EMAIL';
    export const TypeEnum = {
        UserRegistration: 'USER_REGISTRATION' as TypeEnum,
        PhoneNumberUpdate: 'PHONE_NUMBER_UPDATE' as TypeEnum,
        General: 'GENERAL' as TypeEnum,
        OnboardingPhone: 'ONBOARDING_PHONE' as TypeEnum,
        OnboardingEmail: 'ONBOARDING_EMAIL' as TypeEnum
    };
    export type ChannelEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const ChannelEnum = {
        Sms: 'SMS' as ChannelEnum,
        Email: 'EMAIL' as ChannelEnum,
        PhoneCall: 'PHONE_CALL' as ChannelEnum
    };
}


