import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.css']
})
export class EmptyListComponent {
  private _title: string;
  private _description: string;
  private _innerHtmlText: SafeHtml;

  @Input() lottiePath = 'assets/lottie/no_data.json';

  @Input()
  fullPage = false;

  get title(): string {
    return this._title;
  }

  @Input()
  set title(value: string) {
    this._title = value;
  }

  get description(): string {
    return this._description;
  }

  @Input()
  set description(value: string) {
    this._description = value;
  }

  getInnerHtmlText(): SafeHtml | undefined {
    return this._innerHtmlText || undefined;
  }

  @Input()
  set innerHtmlText(value: string) {
    this._innerHtmlText = this.sanitizer.bypassSecurityTrustHtml(value);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
