import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchHandler} from "../../../../../shared/search/search-handler";
import {
    CreateComplianceEntryLogDto,
    ProductionProcessStageControllerService, ProductionProcessStagePojo,
    QueryResultsProductionProcessStagePojo,
    SearchProcessStageByProcessRequestParams,
} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../../../../shared/search/search-filter-source";
import {FormBuilder, Validators} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {Observable} from "rxjs";
import {SearchManager} from "../../../../../shared/search/search-manager";
import {Utils} from "../../../../../shared/utils/utils";
import {PageManager} from "../../../../../services/page-manager";
import {HelperService} from "../../../../../services/helper.service";
import {NameValuePair} from "../../../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../../../shared/search/paginated-search";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-coding-standard-search',
    templateUrl: './coding-standard-search.component.html',
    styleUrls: ['./coding-standard-search.component.scss']
})
export class CodingStandardSearchComponent implements OnInit,
    SearchHandler<ProductionProcessStagePojo, SearchProcessStageByProcessRequestParams>,
    SearchFilterSource<SearchProcessStageByProcessRequestParams> {

    @Input()
    selectedPUTypeCodes: Array<object> = []

    @Input()
    label = 'Production Standard'

    @Input()
    required = true;

    @Input()
    productionStageCode = environment.productionStandardProcessCode;

    form = this.fb.group({
        processCode: [this.productionStageCode],
        productionUnitTypeCodes: [this.selectedPUTypeCodes],
        keyword: [''],
    })

    initialSelection = [];

    allowMultiSelect = true;

    selection = new SelectionModel<number>(this.allowMultiSelect, this.initialSelection);

    @Input()
    callToActionText = 'Mark as Released';

    @Input()
    formFilter$: Observable<any>

    @Input()
    searchDescription = 'This table displays production units that have been approved by <br/> QA Team.'

    @Output()
    selectedIds: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();


    searchManager!: SearchManager<ProductionProcessStagePojo, SearchProcessStageByProcessRequestParams>;

    maxPageSize = 5;

    statuses = Utils.enumValues(CreateComplianceEntryLogDto.OutputStatusConstantEnum)

    constructor(private fb: FormBuilder,
                private pageManager: PageManager,
                public helperService: HelperService,
                private productionProcessStageController: ProductionProcessStageControllerService) {
        this.searchManager = new SearchManager<ProductionProcessStagePojo, SearchProcessStageByProcessRequestParams>(this, this)
    }

    ngOnInit(): void {
        this.updateFormValue();
        this.loadSearchData();
        this.submit();
    }

    updateFormValue(): void {
        this.form.controls.processCode.setValue(this.productionStageCode)
    }

    getFilter(): SearchProcessStageByProcessRequestParams {
        return Object.assign({}, {...this.form.getRawValue()});
    }

    getPersistentKey(): string {
        return 'CodingStandardSearchComponent';
    }

    getSearchDescriptor(e: SearchProcessStageByProcessRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    updateSearchQuery(): void {
        this.pageManager.storeData(this.getPersistentKey(), 'search', this.form.value);
    }

    submit(): void {
        if (!this.form?.valid) {
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    private loadSearchData(): void {
        const data = this.pageManager.getData(this.getPersistentKey(), 'search');

        const cleanData = PaginatedSearch.removeNullFields(data);

        if (cleanData) {
            // this.form.patchValue(cleanData);
        }
    }

    search(page: number | undefined, filter?: SearchProcessStageByProcessRequestParams): Observable<QueryResultsProductionProcessStagePojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.productionProcessStageController.searchProcessStageByProcess({
            ...paginatedSearch.getSearchParams()
        });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        // const numRows = this.searchManager.list.length;
        const numRows = this.searchManager.totalCount;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.searchManager.list.forEach(row => this.selection.select(row.id));
    }

    clearSelection() {
        this.selection.clear();
    }

    get selected(): number[] {
        return this.selection.selected;
    }

    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////

    protected readonly environment = environment;
}