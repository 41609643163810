import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button-with-loader',
  templateUrl: './button-with-loader.component.html',
  styleUrls: ['./button-with-loader.component.css']
})
export class ButtonWithLoaderComponent {

  @Input()
  isProcessing = false;

  @Input()
  classText = "btn btn-primary w-100 py-2 my-3";

  @Input()
  idleStateText = "Proceed";

  @Input()
  workingStateText = "Processing";

  @Output()
  buttonClick: EventEmitter<any> =  new EventEmitter<any>();

  emitOnClickEvent() {
    this.buttonClick.emit();
  }
}
