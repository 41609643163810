import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PlatformPojo} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../../../../services/helper.service";

@Component({
    selector: 'app-platform-card',
    templateUrl: './platform-card.component.html',
    styleUrls: ['./platform-card.component.scss']
})
export class PlatformCardComponent {

    @Input()
    platformPojo: PlatformPojo

    selectedPlatformId: number;

    constructor(private helperService: HelperService) {
    }

    @Input()
    set platformModel(id: number) {

        if (id) {
            this.selectedPlatformId = id;
        }
    }

    @Output()
    platformModelChange: EventEmitter<number> = new EventEmitter<number>()

    @Output()
    deletePlatform: EventEmitter<number> = new EventEmitter<number>();

    selectPlatform(id: number): void{
        if(id){
            this.platformModelChange.emit(id);
        }
    }

    get isSelected(): boolean {
        return this.platformPojo?.id == this.selectedPlatformId;
    }

    get logo(): any {
        if(this.platformPojo?.logo){
            return this.helperService.getFileUrl(this.platformPojo?.logo?.id, true);
        } else {
            return 'assets/img/logo.png';
        }
    }

    delete(id: number): void {
        this.deletePlatform.emit(id);
    }
}
