/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';
import { RequirementSpecificationParameterDto } from './requirement-specification-parameter-dto';


export interface RequirementSpecificationDto { 
    specification: string;
    requirementSpecificationParameterDtoList: Array<RequirementSpecificationParameterDto>;
    dataDictionaryDto?: AttachmentDto;
    ciRequestId: number;
    requirementSpecificationId?: number;
    position?: number;
    notes?: Array<string>;
}

