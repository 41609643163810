/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';
import { UserAcceptanceTest } from './user-acceptance-test';
import { SoftwareModule } from './software-module';
import { PortalUser } from './portal-user';


export interface UseCase { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: UseCase.StatusEnum;
    identifier?: string;
    identifierDisplayName?: string;
    name?: string;
    platform?: Platform;
    userAcceptanceTest?: UserAcceptanceTest;
    softwareModule?: SoftwareModule;
}
export namespace UseCase {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


