<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title>
        {{ title }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>
</div>

<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<mat-dialog-content>
    <p class="fs-14">{{ subtitle }}</p>
    <div class="p-2">
        <app-compliance-disapproval
                [productionStageCode]="productionStageCode"
                #disapprovalComponent
        ></app-compliance-disapproval>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex gap-3 justify-content-end w-100 m-3">
        <button class="btn btn-lg btn-dark" (click)="disapprove()">
            <i class="iconify icon" data-icon="fluent:flash-24-regular"></i>
            {{ 'Disapprove' }}
        </button>
    </div>
</mat-dialog-actions>
