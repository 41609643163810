import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Img} from "../img/img.type";
import {Observable, Subject} from "rxjs";
import {log10} from "chart.js/helpers";

@Component({
    selector: 'app-previous-next-button',
    templateUrl: './previous-next-button.component.html',
    styleUrls: ['./previous-next-button.component.css']
})
export class PreviousNextButtonComponent implements OnInit {

    @Output()
    previousAction: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    nextAction: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    previousButtonDisabled$: Observable<boolean>

    @Input()
    nextButtonDisabled$: Observable<boolean>

    @Input()
    showNextPreviousText = true;

    _disablePrevious = true;

    _disableNext: boolean;

    is = true

    @Input() config: Img = {
        image: '',
        alt: "circle",
        width: 30,
        height: 30,
        cover: false,
        contain: false,
        circle: true,
        padding: 0,
        background: '#DCE6F5'
    };

    ngOnInit() {
        this.previousButtonDisabled$.subscribe(x => this._disablePrevious = x)
        this.nextButtonDisabled$.subscribe(x => this._disableNext = x)
    }

    previous(): void {
        this.previousAction.emit();
    }

    next(): void {
        this.nextAction.emit();
    }
}

export type DirectionType = 'previous' | 'next';

export const DirectionType = {
    Previous: 'previous' as DirectionType,
    Next: 'next' as DirectionType
}
