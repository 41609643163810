import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-input-over-input',
    templateUrl: './input-over-input.component.html',
    styleUrls: ['./input-over-input.component.css']
})
export class InputOverInputComponent {

    config: InputOverInputConfig;

    readonly defaultConfig: InputOverInputConfig = {
        inputOneLabel: "Score",
        inputTwoLabel: "Day's Benchmark",
        inputWidth: "100px"
    }

    @Input()
    set configuration(config: InputOverInputConfig) {

        if (config) {
            this.config =
                {...this.defaultConfig, ...config}
        }

    }
}

export interface InputOverInputConfig {
    inputWidth?: string,
    inputOneLabel?: string,
    inputOneFormControlName?: string
    inputTwoLabel?: string,
    inputTwoFormControlName?: string
    form?: FormGroup
}
