<div class="modal-body">
  <div class="text-center pt-3 pb-2">
    <span
      class="iconify"
      data-icon="carbon:information"
      style="font-size: 65px;"
    ></span>
    <div class="mt-3 mb-4">{{ body }}</div>
    <div>
      <button
        *ngIf="showCancel"
        (click)="close()"
        type="button"
        class="btn btn-danger"
        data-bs-dismiss="modal"
      >
        {{ negativeButtonText }}
      </button>
      <button type="button" class="btn btn-primary" (click)="confirm()">
        <i *ngIf="loading" class="fa" [ngClass]="{ 'fa-spinner fa-spin': loading }"></i>
        {{ positiveButtonText }}
      </button>
    </div>
  </div>
</div>
