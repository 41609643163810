import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {
    BusinessRequirementControllerService,
    BusinessRequirementPojo, BusinessRequirementSectionControllerService,
    BusinessRequirementSectionPojo,
    PlatformControllerService,
    PlatformPojo,
    QueryResultsBusinessRequirementPojo,
    SearchBusinessRequirementRequestParams, UserStoryPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {FormBuilder, Validators} from "@angular/forms";
import {SearchManager} from "../../search/search-manager";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {distinctUntilChanged, Observable, of} from "rxjs";
import {HelperService} from "../../../services/helper.service";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {SearchHandler} from "../../search/search-handler";
import {SearchFilterSource} from "../../search/search-filter-source";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {catchError, map} from "rxjs/operators";
import {SelectionModel} from "@angular/cdk/collections";
import {animate, style, transition, trigger} from "@angular/animations";
import {PlatformSelectorService} from "../../../services/platform-selector.service";
import {
    UserStoryDialogComponent
} from "../../../pages/intranet/master-records/customer-value/user-story-dialog/user-story-dialog.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-requirement-selector',
    templateUrl: './requirement-selector.component.html',
    styleUrls: ['./requirement-selector.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0}),
                animate('300ms ease-out', style({opacity: 1})),
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({opacity: 0})),
            ]),
        ])
    ]
})
export class RequirementSelectorComponent implements OnInit, OnChanges,
    SearchHandler<BusinessRequirementPojo, SearchBusinessRequirementRequestParams>,
    SearchFilterSource <SearchBusinessRequirementRequestParams> {

    form = this.fb.group({
        platformId: ['', Validators.required],
        moduleId: [undefined],
        exclude: [undefined],
        sectionId: [''],
        keyword: [''],
        complianceEntryId: []
    });

    dataSource: MatTableDataSource<BusinessRequirementPojo>;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    searchManager!
        :
        SearchManager<BusinessRequirementPojo, SearchBusinessRequirementRequestParams>;

    maxPageSize = 5;

    platforms$: Observable<Array<PlatformPojo>>

    sections$: Observable<Array<BusinessRequirementSectionPojo>>

    platformId: number;

    @Input()
    module: number;

    @Input()
    exclude: number[];

    emptyListShowOnce = true;

    initialSelection = [];

    allowMultiSelect = true;

    selection = new SelectionModel<number>(this.allowMultiSelect, this.initialSelection);

    @Input()
    callToActionText = 'Map Requirement';

    @Input()
    showPlatformControl = false;

    @Input()
    showSectionControl = true;

    isTemplateChange = false;
    @Output()
    selectedIds: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
    @Input()
    isRequirementDocumentRequest: boolean;

    @Input()
    isRequirementDocumentRequestApproval!: boolean;
    @Input() complianceEntryId!: number;

    @Input() showAuthor= true;
    @Input() showCreationDate = true;

    @Output()
    allHaveAcceptanceCriteriaEmitted: EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor(public helperService: HelperService,
                private fb: FormBuilder,
                private businessReqService: BusinessRequirementControllerService,
                private productService: PlatformControllerService,
                private sectionService: BusinessRequirementSectionControllerService,
                private platformSelectorService: PlatformSelectorService,
                private router: Router) {

        this.searchManager = new SearchManager<BusinessRequirementPojo, SearchBusinessRequirementRequestParams>(this, this);

        this.searchManager.queryResult
            .subscribe(() => {
                this.setDataSource();
                this.emptyListShowOnce = false;
            })

        this.initListeners();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['complianceEntryId'] && changes['complianceEntryId'].currentValue) {
            this.complianceEntryId = changes['complianceEntryId'].currentValue;
            this.form.get('complianceEntryId').patchValue(this.complianceEntryId);
            this.submit();
        }

        if (changes['isRequirementDocumentRequestApproval'] && changes['isRequirementDocumentRequestApproval'].currentValue) {
            this.isRequirementDocumentRequestApproval = changes['isRequirementDocumentRequestApproval'].currentValue;
        }
    }


    @Input()
    set platform(platformId: number) {

        if (this.module) this.form.controls.moduleId.setValue(this.module);
        if (this.exclude?.length > 0) this.form.controls.exclude.setValue(this.exclude);

        if (this.isTemplateChange && platformId && platformId !== 0) {
            this.platformId = platformId;
            this.form.controls.platformId.setValue(platformId.toString());
            this.fetchSections();
        }
    }

    @Output()
    platformChange: EventEmitter<number> = new EventEmitter<number>();


    @Input()
    set selectionModel(ids: Array<number>) {

        if (ids && ids.length > 0) {
            ids.forEach(id => this.selection.select(id))
        }
    }

    public updateAndSearch(filter: SearchBusinessRequirementRequestParams): void {
        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                this.form.controls[key].setValue(filter[key]);
            }
        });
        this.selection.clear();
        if (!this.isRequirementDocumentRequestApproval)
            this.submit();
    }

    @Output()
    selectionModelChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    ngOnInit() {
        this.fetchProducts();
        if (!this.isRequirementDocumentRequestApproval)
        this.submit();

    }

    initListeners(): void {

        if (this.module) this.form.controls.moduleId.setValue(this.module);
        if (this.exclude?.length > 0) this.form.controls.exclude.setValue(this.exclude);

        this.form.controls.platformId.valueChanges
            .subscribe(value => {
                if (value) {
                    this.isTemplateChange = true;
                    this.platformChange.emit(Number(value));
                }
            })

        this.platformSelectorService.currentPlatform.pipe(distinctUntilChanged()).subscribe(value => {
            if (value) {
                this.form.patchValue({
                    platformId: value.toString()
                })
            }
        })
    }

    fetchProducts() {
        this.platforms$ = this.productService.searchPlatforms({limit: 1000})
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }


    fetchSections() {
        this.sections$ = this.sectionService.searchBusinessRequirementSection({
            platformId: this.platformId,
            limit: 1000
        })
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource(this.searchManager.list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    mapTo(): void {
        this.selectedIds.emit(this.selection.selected);
        this.selectionModelChange.emit(this.selection.selected);
    }

    getFilter(): SearchBusinessRequirementRequestParams {
        const form = this.form.getRawValue();
        const data = Object.assign(form);
        data.platformId = Number(form.platformId)
        return data;

    }

    getPersistentKey(): string {
        return RequirementSelectorComponent.name;
    }

    getSearchDescriptor(e: SearchBusinessRequirementRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchBusinessRequirementRequestParams): Observable<QueryResultsBusinessRequirementPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;

        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage;


        if (this.isRequirementDocumentRequest) {
            filter.reviewStatus = "PENDING";
        }
        if (this.isRequirementDocumentRequestApproval) {
            filter.complianceEntryId = this.complianceEntryId;
        }

        const queryValue = this.businessReqService.searchBusinessRequirement(filter);

        queryValue.subscribe(x => {
            const allHaveAcceptanceCriteria = x.results.every(y => y.allReqHasAcceptanceCriteria);

            // Checking that all Userstory associated with BR has atleast one acceptance criteria
            // API checks
            if (!allHaveAcceptanceCriteria) {
                this.allHaveAcceptanceCriteriaEmitted.emit(false);
                return;
            }

            // Frontend checks for all edge cases
            const allHasCriteriaCount = x.results.every(y =>
                y.userStories.every(z => z.criteriaCount > 0)
            );

            this.allHaveAcceptanceCriteriaEmitted.emit(allHasCriteriaCount);

            x.results.forEach(y => {
                // this.selection.select(y.id);
                y.allReqHasAcceptanceCriteria
            });
        })
        return queryValue;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row.id));
    }


    clearSelection() {
        this.selection.clear();
    }

    viewUserStories(userStories: Array<UserStoryPojo>): void {
        this.helperService.loadModal(UserStoryDialogComponent, 'User Story', '',
            {
                userStories: userStories
            },
            '1100px')
            .afterClosed()
            .subscribe((x) => {
            });
    }

    editRequirement(requirementPojo: BusinessRequirementPojo): void {
        this.router.navigate(['dashboard', 'master-records', 'business-req', requirementPojo.platformId, requirementPojo.id, 'update'], {
            queryParams: {shouldShowCriteria: true},
            state: requirementPojo
        })
    }

    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////
}
