<div class="my-page">
    <div class="my-container">
        <div class="d-flex justify-content-end">
            <button (click)="showHide()" class="mb-2 unset btn">
                <img src="assets/img/eye.svg" alt="">
            </button>
        </div>
        <div *ngIf="show && headers" class="nav box">

            <div class="step">
                <ng-container *ngFor="let tab of headers; index as i">
                    <div [id]="'tracker-line-'+i" class="mark-line cursor-pointer">
                        <div (click)="clickProcessStage(i)"
                             [ngClass]="{
                                    active: logged(i),
                                    last_track: i == lastLogged,
                                    pulse: clickedProcessStage == i && pulsate,
                                    color_1: updateCount(i) < 6,
                                    color_2: updateCount(i) > 5 && updateCount(i) < 11,
                                    color_3: updateCount(i) > 10
                                    }" class="status-mark">

                                <span>
                                    <i *ngIf="logged(i) && (i != lastLogged)"><i
                                            class="iconify icon" style="color: white"
                                            [ngClass]="{ active: logged(i) }"
                                            [attr.data-icon]="tabIcons[0]">
                                    </i></i>
                                    <span class="count" *ngIf="!logged(i) || i == lastLogged">
                                        {{ i + 1 }}
                                    </span>
                                </span>

                        </div>
                        <div class="">
                            <h6 (click)="select(i)" [ngClass]="{ active: logged(i), pointer: logged(i) }">
                                {{ tab }}
                                <span class="arrow">
                                    <i *ngIf="selectedIndex != i"><i class="iconify icon"
                                                                     data-icon="material-symbols-light:arrow-forward-ios"></i></i>
                                    <i *ngIf="selectedIndex == i"><i class="iconify icon"
                                                                     data-icon="oui:arrow-down"></i></i>
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div class="right">
                        <div
                                [ngClass]="{
                        active: logged(i),
                        line: i < tabHeaders.length - 1
                      }"
                        ></div>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="selected" class="detail" [style.margin-left.px]="marginLeft">

                <div class="d-flex align-items-center" *ngIf=" selected?.numberOfUpdates < 6"><div class="legend-1 me-1"></div>:1 to 5 Updates</div>
                <div class="d-flex align-items-center" *ngIf=" selected?.numberOfUpdates > 5 && selected?.numberOfUpdates < 11"><div class="legend-2 me-1"></div>:6 to 10 Updates</div>
                <div class="d-flex align-items-center" *ngIf=" selected?.numberOfUpdates > 10"><div class="legend-3 me-1"></div>:Above 10 Updates</div>
                <div class="card border-0 mt-2">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-md-6 menu">
                                Updates Count:
                            </div>
                            <div class="col-md-6 item">
                                {{ selected?.numberOfUpdates }}
                            </div>
                        </div>
                        <div class="row mb-2 ">
                            <div class="col-md-6 menu">
                                Last Updated By:
                            </div>
                            <div class="col-md-6 item">
                                {{ helperService.cutText(selected?.lastUpdatedBy?.name || '-', 20) }}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-6 menu">
                                Last Updated At:
                            </div>
                            <div class="col-md-6 item">
                                {{ selected?.processComplianceLastLoggedAt | datetime }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 menu">
                                Version Number:
                            </div>
                            <div class="col-md-6 item">
                                {{ selected?.versionNumber || '-' }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>


    </div>
</div>
