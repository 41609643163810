import {Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {catchError, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {PlatformControllerService, PlatformPojo} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {PlatformSelectorService} from "../../../../services/platform-selector.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-platform-switcher',
    templateUrl: './platform-switcher.component.html',
    styleUrls: ['./platform-switcher.component.css']
})
export class PlatformSwitcherComponent implements OnInit {

    title: string;

    platforms$: Observable<Array<PlatformPojo>>

    selectedPlatformId: number;

    constructor(public dialogRef: MatDialogRef<PlatformSwitcherComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private productService: PlatformControllerService,
                private platformSelectorService: PlatformSelectorService,
                private router: Router) {

        this.title = this.data?.modal?.title
    }

    ngOnInit(): void {
        this.fetchProducts();
        this.initListeners();
    }

    set platform(platformId: number) {

        if (platformId) {
            this.selectedPlatformId = platformId;
            this.platformSelectorService.currentPlatform = platformId;

            this.dialogRef.close();
        }
    }

    get platform(): number {
        return this.selectedPlatformId;
    }


    fetchProducts() {
        this.platforms$ = this.productService.searchPlatforms({limit: 1000})
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }

    addPlatform(): void {
        this.dialogRef.close();
        this.router.navigate(['dashboard','master-records','platforms','create'])
    }

    deletePlatform(platformId: number): void {
        this.dialogRef.close();
        // this.platformComponent.onDeletePlatform(platformId, {reason :'Deletion'})
    }

    initListeners(): void {
        this.platformSelectorService.currentPlatform.subscribe(id => {
            if (id) {
                this.selectedPlatformId = id;
            }
        })
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////


}
