import { Component, Input } from '@angular/core';
import { AlertType } from 'src/app/pages/extranet/report-issue/report-issue.component';

@Component({
  selector: 'app-base-alert',
  template: `
    <div *ngIf="showAlertMessageTrigger"
         [ngClass]="{ anim: showAlertMessageTrigger }"
         [class.alert-success]="alertMessage.type === AlertType.success"
         [class.alert-danger]="alertMessage.type === AlertType.error"
         [class.alert-primary]="alertMessage.type === AlertType.primary"
         [class.alert-info]="alertMessage.type === AlertType.info"
         class="alert mb-4 fs-14 text-center">
        {{ alertMessage.msg }}
    </div>
  `,
  styleUrls: ['./base-alert.component.css']
})
export class BaseAlertComponent {
  @Input() showAlertMessageTrigger: boolean;
  @Input() alertMessage: { msg: string; type: AlertType };

  AlertType = AlertType;

  showAlertMessage(msg: string, type: AlertType): void {
    this.alertMessage = { msg, type };
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }
}