<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title>
        {{ title }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>

</div>

<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>


<mat-dialog-content class="p-4">
    <app-module-selector
            [platform]="this.platformId"
            (selectedIds)="setSelected($event)"
    ></app-module-selector>
</mat-dialog-content>
