import {Pipe, PipeTransform} from '@angular/core';
import {Observable, timer, BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
    name: 'countdown'
})
export class CountdownPipe implements PipeTransform {

    private getMsDiff = (futureDate: string): number => (+(new Date(futureDate)) - Date.now());

    private msToTime(msRemaining: number, unit: string): string {
        const isNegative = msRemaining < 0;

        const days: string | number = Math.floor(Math.abs(msRemaining) / (1000 * 60 * 60 * 24));
        let hours: string | number = Math.floor((Math.abs(msRemaining) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes: string | number = Math.floor((Math.abs(msRemaining) % (1000 * 60 * 60)) / (1000 * 60));
        let seconds: string | number = Math.floor((Math.abs(msRemaining) % (1000 * 60)) / 1000);

        hours = (hours < 10) ? '0' + hours : hours;
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        switch (unit) {
            case 'seconds':
                return `${seconds}`;
            case 'minutes':
                return `${minutes}`;
            case 'hours':
                return `${hours}`;
            case 'days':
                return isNegative ? `-${days}` : `${days}`;
            case 'isNegative':
                return `${isNegative}`;
            default:
                return `${days} Days: ${hours} Hours: ${minutes} Minutes: ${seconds} Seconds`;
        }
    }

    public transform(futureDate: string, unit = '', isPaused = false): Observable<string> | null {
        if (!futureDate) {
            return null;
        }
        let msRemaining = this.getMsDiff(futureDate);
        const countdownSubject = new BehaviorSubject<string>(this.msToTime(msRemaining, unit));

        if (!isPaused) {
            timer(0, 1000)
                .pipe(
                    map(() => {
                        msRemaining -= 1000;
                        const countdown = this.msToTime(msRemaining, unit);
                        countdownSubject.next(countdown);
                    })
                )
                .subscribe();
        }

        return countdownSubject.asObservable();
    }
}
