/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUserPojo } from './portal-user-pojo';


export interface LagMeasurePojo { 
    id?: number;
    name?: string;
    type?: LagMeasurePojo.TypeEnum;
    description?: string;
    createdAt?: string;
    createdBy?: PortalUserPojo;
    unitOfMeasure?: string;
    desiredDirection?: LagMeasurePojo.DesiredDirectionEnum;
    code?: string;
    score?: number;
    currentValue?: number;
    maxValue?: number;
    overallBenchmarkValue?: number;
    isPrimary?: boolean;
    totalActivitiesDone?: number;
    totalActivities?: number;
}
export namespace LagMeasurePojo {
    export type TypeEnum = 'PERFORMANCE' | 'PRODUCTIVITY';
    export const TypeEnum = {
        Performance: 'PERFORMANCE' as TypeEnum,
        Productivity: 'PRODUCTIVITY' as TypeEnum
    };
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        Up: 'UP' as DesiredDirectionEnum,
        Down: 'DOWN' as DesiredDirectionEnum
    };
}


