export enum Constant {
  nairaSign = '₦',
  ONBOARD_DATA = 'ONBOARD_DATA',
  SELECTED_ORG_LOGO_ID = 'SELECTED_ORG_LOGO_ID',
  EXISTING_REQUISITION = 'EXISTING_REQUISITION',
  EXISTING_INVOICE = 'EXISTING_INVOICE',
  EXISTING_INVOICE_SIG = 'EXISTING_INVOICE_SIG',
  INVOICE_DISCOUNT_MANAGER = 'INVOICE_DISCOUNT_MANAGER',
  CURRENT_CUSTOMER_EMAIL = 'CURRENT_CUSTOMER_EMAIL',
  CURRENT_CUSTOMER_NAME = 'CURRENT_CUSTOMER_NAME',
  CURRENT_CUSTOMER_PHONE_NUMBER = 'CURRENT_CUSTOMER_PHONE_NUMBER'
}
