/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotePojo } from './note-pojo';
import { RequirementSpecificationEntryPojo } from './requirement-specification-entry-pojo';
import { AttachmentPojo } from './attachment-pojo';


export interface RequirementSpecificationPojo { 
    id?: number;
    position?: number;
    specification?: string;
    requirementSpecificationEntries?: Array<RequirementSpecificationEntryPojo>;
    notes?: Array<NotePojo>;
    attachments?: Array<AttachmentPojo>;
}

