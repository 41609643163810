<div class="my-2">
  <span
    class="{{ position }}"
    style="font-weight: 400; font-size: 0.8rem; margin-top: 0.2rem; margin-bottom: 0.3rem"
  >
    <span *ngIf="showWarning" class="text-danger">{{
      'Character limit has been reached'
    }}</span>
    {{ initialCount }}/{{ limit }}
  </span>
</div>
