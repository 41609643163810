<div class="card text-center mb-3 p-5 pt-2" [ngClass]="{'vh-100': fullPage}" style="border: none; background: inherit" >
    <div class="card-body p-5">
        <ng-lottie
                containerClass="mx-auto"
                [options]="{ path: lottiePath, autoplay: true, loop: true }"
                width="20%"
                height="50%"
        ></ng-lottie>
        <div class="fs-20 fw-600 my-3"> {{title}}
        </div>
        <div class="fs-18">
            <ng-container *ngIf="getInnerHtmlText(); else normal">
                <p class="fs-18 fw-400 mb-2" [innerHTML]="getInnerHtmlText()"></p>
            </ng-container>
            <ng-template #normal>
                <p class="fs-18 fw-400 mb-2">{{description}} </p>
            </ng-template>
        </div>
    </div>
</div>