/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LeadMeasureCommitmentDto { 
    c5Id?: number;
    moduleId?: number;
    productionUnitId?: number;
    leadMeasureId: number;
    comment?: string;
    delegationLogId?: number;
    ticketId?: number;
    startTime: string;
    endTime: string;
    foresightScore: number;
    foresightBenchmark: number;
    effortType?: LeadMeasureCommitmentDto.EffortTypeEnum;
    cadenceEventId?: number;
    originalLeadMeasureCommitmentId?: number;
    platformId?: number;
    executedDomainEntityType: LeadMeasureCommitmentDto.ExecutedDomainEntityTypeEnum;
    leadMeasureCommitmentCategory: LeadMeasureCommitmentDto.LeadMeasureCommitmentCategoryEnum;
}
export namespace LeadMeasureCommitmentDto {
    export type EffortTypeEnum = 'NEW' | 'UPDATE';
    export const EffortTypeEnum = {
        New: 'NEW' as EffortTypeEnum,
        Update: 'UPDATE' as EffortTypeEnum
    };
    export type ExecutedDomainEntityTypeEnum = 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'LEAD_MEASURE_COMMITMENT' | 'TICKET' | 'PROCESS_STAGE';
    export const ExecutedDomainEntityTypeEnum = {
        Module: 'MODULE' as ExecutedDomainEntityTypeEnum,
        CustomerValue: 'CUSTOMER_VALUE' as ExecutedDomainEntityTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as ExecutedDomainEntityTypeEnum,
        Delegation: 'DELEGATION' as ExecutedDomainEntityTypeEnum,
        LeadMeasureCommitment: 'LEAD_MEASURE_COMMITMENT' as ExecutedDomainEntityTypeEnum,
        Ticket: 'TICKET' as ExecutedDomainEntityTypeEnum,
        ProcessStage: 'PROCESS_STAGE' as ExecutedDomainEntityTypeEnum
    };
    export type LeadMeasureCommitmentCategoryEnum = 'WIG' | 'WHIRLWIND';
    export const LeadMeasureCommitmentCategoryEnum = {
        Wig: 'WIG' as LeadMeasureCommitmentCategoryEnum,
        Whirlwind: 'WHIRLWIND' as LeadMeasureCommitmentCategoryEnum
    };
}


