import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    BusinessRequirementControllerService,
    BusinessRequirementCustomerValueMapperDto, BusinessRequirementSoftwareModuleMapperDto
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {UnsubscribeOnDestroyAdapter} from "../../../../services/unsubscribe-on-destroy-adapter";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {HelperService} from "../../../../services/helper.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-module-selector-dialog',
    templateUrl: './module-selector-dialog.component.html',
    styleUrls: ['./module-selector-dialog.component.css']
})
export class ModuleSelectorDialogComponent extends UnsubscribeOnDestroyAdapter {

    platformId: number;

    businessReqId: number;

    title: string;

    constructor(public dialogRef: MatDialogRef<ModuleSelectorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private businessRequirementService: BusinessRequirementControllerService,
                public helperService: HelperService,
                private router: Router) {

        super();

        this.platformId = this.data?.platformId;
        this.businessReqId = this.data?.businessReqId;

        if (!this.platformId || !this.businessReqId) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }

        this.title = this.data?.modal?.title
    }

    setSelected($event: Array<number>) {
        if ($event && $event.length > 0) {
            this.mapTo($event);
        } else {
            this.showAlertMessage('Select at least one module to map', AlertType.error);
        }
    }


    mapTo(selectedIds: Array<number>): void {
        const payload: Array<BusinessRequirementSoftwareModuleMapperDto> =
            selectedIds.map((id: number): BusinessRequirementSoftwareModuleMapperDto => {
                return {
                    businessRequirmentId: this.businessReqId,
                    softwareModuleId: id
                }
            })

        this.subscriptions.sink = this.businessRequirementService.mapBusinessRequirementToSoftwareModule({
            businessRequirementSoftwareModuleMapperDto: payload,
            platformId: this.platformId
        })
            .subscribe({
                next: (data) => {
                },
                complete: () => {
                    this.dialogRef.close(true);
                },
                error: (error: unknown) => {
                    this.showAlertMessage(this.helperService.getError(error), AlertType.error);
                }
            })
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////
}
