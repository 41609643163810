/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CriterionChecker } from './criterion-checker';


export interface AcceptanceCriteriaPojo { 
    id?: number;
    given?: CriterionChecker;
    when?: CriterionChecker;
    then?: CriterionChecker;
    checkStatus?: AcceptanceCriteriaPojo.CheckStatusEnum;
    comment?: string;
    identifier?: string;
    identifierDisplayName?: string;
    userStoryId?: number;
    lastUpdatedById?: number;
    createdAt?: string;
    createdById?: number;
}
export namespace AcceptanceCriteriaPojo {
    export type CheckStatusEnum = 'OK' | 'NOT_OK' | 'NOT_CHECKED';
    export const CheckStatusEnum = {
        Ok: 'OK' as CheckStatusEnum,
        NotOk: 'NOT_OK' as CheckStatusEnum,
        NotChecked: 'NOT_CHECKED' as CheckStatusEnum
    };
}


