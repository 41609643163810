import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datetime'
})
export class DatetimePipe implements PipeTransform {
    transform(value: any, format?: string, args?: any): any {
        if (!value) {
            return null;
        }
        let m: moment.Moment;

        if (format) {
            m = moment(value, format);

        } else {
            m = moment(value);
        }
        return m.isValid() ? m.format('DD MMM YYYY, hh:mmA') : null; // 01 Mar 2019, 10:48AM
    }
}
