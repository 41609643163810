import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";

import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {SearchManager} from "../../search/search-manager";
import {SelectionModel} from "@angular/cdk/collections";
import {FormBuilder} from "@angular/forms";
import {HelperService} from "../../../services/helper.service";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {Observable} from "rxjs";
import {SearchHandler} from "../../search/search-handler";
import {SearchFilterSource} from "../../search/search-filter-source";
import {
    AcceptanceCriteriaControllerService,
    AcceptanceCriteriaPojo, MarkAcceptanceCriteriaDto, QueryResultsAcceptanceCriteriaPojo,
    SearchAcceptanceCriteriaRequestParams
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {AlertType} from "../../../pages/extranet/report-issue/report-issue.component";
import CheckStatusEnum = AcceptanceCriteriaPojo.CheckStatusEnum;

@Component({
    selector: 'app-acceptance-criteria-selector',
    templateUrl: './acceptance-criteria-selector.component.html',
    styleUrls: ['./acceptance-criteria-selector.component.css']
})
export class AcceptanceCriteriaSelectorComponent implements OnInit, OnChanges, SearchHandler<AcceptanceCriteriaPojo, SearchAcceptanceCriteriaRequestParams>, SearchFilterSource<SearchAcceptanceCriteriaRequestParams> {

    @Output() alert = new EventEmitter<{ msg: string; type: AlertType }>();
    form = this.fb.group({
        keyword: [''],
        userStoryIds: []
    });

    dataSource: MatTableDataSource<AcceptanceCriteriaPojo>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    searchManager!: SearchManager<AcceptanceCriteriaPojo, SearchAcceptanceCriteriaRequestParams>;
    maxPageSize = 5;
    @Input()
    exclude: number[];
    emptyListShowOnce = true;
    initialSelection = [];
    allowMultiSelect = true;
    selection = new SelectionModel<number>(this.allowMultiSelect, this.initialSelection);
    @Input() userStoryId!: number;
    isMarking = false;
    checkStatusEnum = CheckStatusEnum;

    constructor(private fb: FormBuilder,
                public helperService: HelperService,
                private acceptanceCriteriaControllerService: AcceptanceCriteriaControllerService) {

        this.searchManager = new SearchManager<AcceptanceCriteriaPojo, SearchAcceptanceCriteriaRequestParams>(this, this);

        this.searchManager.queryResult
            .subscribe((result) => {
                this.setDataSource();
                this.emptyListShowOnce = false;
            })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['userStoryId'] && changes['userStoryId'].currentValue) {
            this.form.get('userStoryIds').patchValue(changes['userStoryId'].currentValue);
            this.submit();
        }
    }

    ngOnInit(): void {
        //this.submit();
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource(this.searchManager.list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    getFilter(): SearchAcceptanceCriteriaRequestParams {
        const form = this.form.getRawValue();
        return Object.assign(form);

    }

    getPersistentKey(): string {
        return AcceptanceCriteriaSelectorComponent.name;
    }

    getSearchDescriptor(e: SearchAcceptanceCriteriaRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchAcceptanceCriteriaRequestParams): Observable<QueryResultsAcceptanceCriteriaPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;

        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage;

        return this.acceptanceCriteriaControllerService.searchAcceptanceCriteria(filter);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row.id));
    }

    clearSelection() {
        this.selection.clear();
    }

    toggle(acceptanceCriteriaPojo: AcceptanceCriteriaPojo) {
        this.selection.toggle(acceptanceCriteriaPojo.id);
    }

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    markAcceptanceCriteria(checkStatus: MarkAcceptanceCriteriaDto.CheckStatusConstantEnum, id?: number) {
        if(this.isMarking){
            return;
        }

        if(this.selection.isEmpty() && !id){
            this.alert.emit({
                msg: 'Kindly select a success criteria.',
                type: AlertType.error
            });
        }
        this.isMarking = true;
        const markAcceptanceCriteriaDto: MarkAcceptanceCriteriaDto = {
            ids: id ?  [id] :  this.selection.selected,
           checkStatusConstant: checkStatus
        }

        this.acceptanceCriteriaControllerService.markAcceptanceCriteria({markAcceptanceCriteriaDto: markAcceptanceCriteriaDto}).subscribe({
            next: (v) => {
                this.isMarking = false;
                this.alert.emit({
                    msg: 'Acceptance criteria has been updated successfully.',
                    type: AlertType.success
                });
                this.selection.clear();
                this.searchManager.reloadAndShowFirstPage();
            }, error: (err: unknown) => {
                this.isMarking = false;
                this.alert.emit({
                    msg: this.helperService.getError(err),
                    type: AlertType.success
                });
            }
        })
    }

}
