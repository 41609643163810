<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title">
            <h4>Bulk Upload Result</h4>
        </div>
        <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                (click)="close()"
                aria-label="Close"
        ></button>
    </div>
    <div class="modal-body">
        <div *ngIf="showErrorMessageTrigger" class="alert alert-danger mt-4 container">
            <div class="d-flex mb-2">
                <span class="iconify me-2" data-icon="clarity:warning-alt-filled"></span>
                {{ getErrorMessage() }}
            </div>
        </div>
        <p class="mt-3">Here's a summary of the file imported below</p>
        <div class="wrapper">
            <div class="pos">
                <div class="left">
                    <i class="iconify" data-icon="ci:file-upload"></i>
                </div>
                <div class="right">
                    <p>
                        {{ result?.errors?.length + result?.successes?.length }}
                    </p>
                    <ng-container>
                        <p class="fs-11">Total Records</p>
                    </ng-container>

                </div>
            </div>
            <div class="pos">
                <div class="left">
                    <i class="iconify green" data-icon="uil:file-check"></i>
                </div>
                <div class="right">
                    <p>
                        {{ result?.successes?.length }}
                    </p>
                    <p class="fs-11">Total Successful</p>
                </div>
            </div>
            <div class="pos">
                <div class="left">
                    <i class="iconify red" data-icon="gala:file-error"></i>
                </div>
                <div class="right">
                    <p>
                        {{ result?.errors?.length }}
                    </p>
                    <p class="fs-11">Records with Errors</p>
                </div>
            </div>
        </div>
        <hr/>

        <ng-container *ngIf="result?.errors?.length > 0">
            <div class="error-box alert alert-danger">
                <p>
                    Some errors were found in the file you uploaded. Follow the steps below to resolve them.
                </p>
                <ol>
                    <li>
                        Download the error
                        <a href="javascript:void(0)" (click)="downloadErrorSheet.emit(true)">files here</a>
                    </li>
                    <li>Correct the records and ensure all the information is accurate</li>
                    <li>Clear out the error column</li>
                    <li>Re-upload the updated file below and continue</li>

                    <button class="btn btn-outline-primary mt-3" (click)="continue.emit(false)">
                        Re-upload sheet
                    </button>
                </ol>
            </div>
        </ng-container>

        <div
                *ngIf="result?.successes?.length > 0"
                class="btn-wrapper mt-5"
                style="display: flex; align-items: center"
        >
            <div style="display: flex; align-items: center">
                <button
                        *ngIf="result?.successes?.length > 0 && result?.errors?.length === 0"
                        class="btn btn-outline-primary"
                        (click)="continue.emit(true)"
                        [disabled]="submitting"
                        style="margin-right: 5px"
                >
                    <span *ngIf="result?.errors?.length > 0"> Ignore errors and </span>
                    <app-loading color="#000" *ngIf="submitting"></app-loading>
                    Proceed
                </button>
            </div>
        </div>
    </div>
</div>
