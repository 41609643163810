/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCodePojo } from './name-code-pojo';
import { NameIdPojo } from './name-id-pojo';


export interface BreachPojo { 
    id?: number;
    category?: NameIdPojo;
    stages?: Array<NameCodePojo>;
    responsible?: NameIdPojo;
    loggedBy?: NameIdPojo;
    pendingTraining?: boolean;
    timestamp?: string;
    trainingTakenAt?: string;
    note?: string;
    learning?: string;
    canTakeTraining?: boolean;
}

