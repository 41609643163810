import {Component, Input} from '@angular/core';
import {PortalUserPojo} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {Img} from "../img/img.type";
import {Utils} from "../../utils/utils";

@Component({
    selector: 'app-player-photo-gallery',
    templateUrl: './player-photo-gallery.component.html',
    styleUrls: ['./player-photo-gallery.component.scss']
})
export class PlayerPhotoGalleryComponent {

    /**
     * List of players
     */
    @Input()
    players: Array<PortalUserPojo> = new Array<PortalUserPojo>();

    /**
     * Count of photos to show
     */
    @Input()
    displaySize = 3

    /**
     * sets whether to show remaining count.
     * Remaining count + {@link displaySize} =  the length of {@link players}
     */
    @Input()
    showRemainingCount = true;

    avatar = ['assets/img/player-one.svg', 'assets/img/player-two.svg', 'assets/img/player-three.svg']

    profilePhotoConfig: Img = {
        image: '',
        alt: 'Profile Image',
        width: 30,
        height: 30,
        cover: true,
        contain: false,
        circle: true,
        padding: 2,
        background: 'white',
    };

    getPhotoConfig(player: PortalUserPojo, index: number): Img {
        const initials = this.getPlayerInitials(player);
        const bgColor = Utils.generateColorFromString(initials);

        return {
            ...this.profilePhotoConfig,
            image: null,
            initials: initials,
            initialsBgColor: bgColor
        };
    }


    getPlayerInitials(player: PortalUserPojo): string {
        let initials = '#';
        if (player.firstName && player.lastName) {
            initials = player.firstName.charAt(0).toUpperCase() + player.lastName.charAt(0).toUpperCase();
        }
        return initials;
    }


}
