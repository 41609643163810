import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
    CustomerDto,
    NameCodePojo,
    NameIdPojo,
    TicketAssignmentDto,
    TicketClassificationDto,
    TicketControllerService,
    TicketDetailPojo,
    TicketLifecyclePojo,
    TicketPojo,
} from '../../../../../../sdk/customer-fulfillment-api-sdk';
import {AuthenticationService} from '../../../../services/authentication.service';
import {HelperService} from '../../../../services/helper.service';
import {Utils} from '../../../../shared/utils/utils';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';

@Component({
    selector: 'app-manage-tickets',
    templateUrl: './manage-tickets.component.html',
    styleUrls: ['./manage-tickets.component.css'],
})
export class ManageTicketsComponent implements OnInit {
    form: FormGroup;
    editForm: FormGroup;
    assignForm: FormGroup;

    successMessage: string;
    showSuccessMessageTrigger = false;
    errorMessage: string;
    showErrorMessageTrigger = false;
    submitting: boolean;
    ticketId: number;
    ticket: TicketPojo;
    data!: Array<TicketLifecyclePojo>;
    customer: CustomerDto;
    edit: boolean;
    // ticketType: Utils.enumValues()
    ticketStatus = Utils.enumValues(TicketLifecyclePojo.LifecycleStatusEnum);
    ticketCategories: NameCodePojo[] = [];
    subTicketCategories: NameCodePojo[] = [];
    ticketTypes = Utils.enumValues(TicketClassificationDto.TypeConstantEnum);
    service: NameCodePojo[] = [];
    subServices: NameCodePojo[] = [];
    representatives: NameIdPojo[] = [];
    details: TicketDetailPojo;
    isUpdate = false;
    isAssignment = false;
    enable: boolean;
    @ViewChild('closeTicketEdit') closeTicketEdit!: ElementRef;
    downloadingAttachment: any;
    starArray: number[] = [1, 2, 3, 4, 5];
    selectedStar = 0;

    constructor(
        private fb: FormBuilder,
        private ticketControllerService: TicketControllerService,
        public helperService: HelperService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.assignForm = this.fb.group({
            assign: ['']
        });
        this.activatedRoute.params.subscribe({
            next: (param) => {
                this.ticketId = param['id'];
                this.getTicket(this.ticketId);
            }
        })
        this.initializeEditForm();
        this.getTicket(this.ticketId);
        this.ticketClassificationProperties();
        this.getRepresentatives();

        this.selectedStar = this.details?.rating;
    }

    private getTicket(ticketId: any): void {
        this.ticketControllerService.searchTickets({id: ticketId}).subscribe({
            next: (data: any) => {
                this.ticket = data?.results[0];
                this.getTicketDetails(ticketId);
                this.getCustomerDetails(data?.results[0]?.customer?.id);
                this.getLifecycleStatus();
            },
            error: (error: unknown) => {
                this.showAlertMessage(this.helperService.getError(error), AlertType.error);
            },
        });
    }

    private getLifecycleStatus(): void {
        const identifier = this.ticket.identifier || '';
        this.ticketControllerService
            .getTicketStatus({identifier: identifier})
            .subscribe({
                next: (data) => {
                    this.data = data;
                },
                error: (error) => {
                    this.showAlertMessage(
                        this.helperService.getError(error.error.message), AlertType.error
                    );
                }
            });
    }

    getCustomerDetails(id: number): void {
        this.ticketControllerService.getCustomer({id: id}).subscribe((res) => {
            this.customer = res;
        });
    }


    getTicketDetails(id: number) {
        this.ticketControllerService.ticketDetails({id: id}).subscribe((res) => {
            this.details = res;
            this.editForm.patchValue({
                status: this.details?.lifecycleStatus || '',
                category: this.details?.ticketCategory?.code || '',
                type: this.details?.type || '',
                subject: this.details?.subject || '',
                service: this.details?.service?.code || '',
                subService: this.details?.subService?.code || '',
                subCategory: [''],
            })

            this.assignForm.patchValue({
                assign: this.details?.assignedTo?.id || ''
            })

        })
    }

    get status(): string {
        if (this.data && this.data.length > 0) {
            return ('TICKET ' + this.ticket.lifecycleStatus || '').toUpperCase();
        }
        return '';
    }

    initializeEditForm() {
        this.editForm = this.fb.group({
            status: [''],
            category: [''],
            type: [''],
            subject: [''],
            service: [''],
            subService: [''],
            subCategory: [''],
        });
    }

    ticketClassificationProperties() {
        this.ticketControllerService.getClassificationDetails().subscribe((res) => {
            this.ticketCategories = res.ticketCategories;
            this.service = res.services;
            this.subServices = res.subServices;
        });
    }

    getRepresentatives() {
        this.ticketControllerService.getRepresentatives().subscribe((res) => {
            this.representatives = res;
        });
    }

    update() {
        this.isUpdate = true;
        if (!this.editForm.valid) {
            this.editForm.markAllAsTouched();
            return;
        }

        const params: TicketClassificationDto = {
            ticketId: +this.ticketId,
            subject: this.editForm.get('subject').value,
            typeConstant: this.editForm.get('type').value ? this.editForm.get('type').value : null,
            serviceCode: this.editForm.get('service').value,
            ticketCategoryCode: this.editForm.get('category').value,
            subServiceCode: this.editForm.get('subService').value,
            status: this.editForm.get('status').value,
        };

        this.ticketControllerService
            .classifyTicket({ticketClassificationDto: params})
            .subscribe({
                next: (res) => {
                    this.isUpdate = false;
                    this.showAlertMessage('Ticket updated successfully', AlertType.success);
                    this.getTicket(this.ticketId);
                    this.closeTicketEdit.nativeElement.click();
                },
                error: (error) => {
                    this.isUpdate = false;
                    this.showAlertMessage(
                        this.helperService.getError(error.error.message), AlertType.error
                    );
                }
            });
    }


    assign() {
        this.isAssignment = true;
        if (!this.assignForm.valid) {
            return;
        }

        const params: TicketAssignmentDto = {
            assignedTo: this.assignForm.get('assign').value,
            ticketId: this.ticketId
        }
        this.ticketControllerService
            .ticketAssignment({ticketAssignmentDto: params})
            .subscribe(
                {
                    next: () => {
                        this.isAssignment = false;
                        this.getTicket(this.ticketId);
                        this.showAlertMessage('Ticket assigned successfully', AlertType.success);
                    },
                    error: (error) => {
                        this.isAssignment = false;
                        this.showAlertMessage(
                            this.helperService.getError(error.error.message), AlertType.error
                        );
                    }
                }
            );


    }


    goToTicketDetail(ticketId: number): void {
        this.router.navigate([`view-issue/${ticketId}`]);
    }


    downloadImages() {
        this.ticket.attachments.forEach(x => {
            this.downloadAttachment(x)
        })
    }

    downloadAttachment(id): void {
        if (this.downloadingAttachment)
            return
        if (this.ticket.attachments?.length == 0)
            return
        this.downloadingAttachment = true;

        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = (this.getFileUrl(id, true));
        downloadLink.setAttribute('download', 'Attachment');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
        this.downloadingAttachment = false;
    }

    getFileUrl(fileId: number, download = true): string {
        return download
            ? `${environment.apiBaseUrl}/files/${fileId}`
            : `${environment.apiBaseUrl}/files/${fileId}/open`;
    }


    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    };
    showAlertMessageTrigger = false;

    getAlertMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    protected readonly AlertType = AlertType;


}
