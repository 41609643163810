import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    AcceptanceCriteriaControllerService,
    AcceptanceCriteriaPojo, QueryResultsAcceptanceCriteriaPojo,
    SearchAcceptanceCriteriaRequestParams,
    SearchUserStoryRequestParams,
    UserStoryPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {HelperService} from "../../../services/helper.service";
import {SearchManager} from "../../search/search-manager";
import {SearchHandler} from "../../search/search-handler";
import {SearchFilterSource} from "../../search/search-filter-source";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {Observable} from "rxjs";
import {FormBuilder} from "@angular/forms";

@Component({
    selector: 'app-criteria-count-modal',
    templateUrl: './criteria-count-modal.component.html',
    styleUrls: ['./criteria-count-modal.component.css']
})
export class CriteriaCountModalComponent implements SearchHandler<UserStoryPojo, SearchUserStoryRequestParams>,
    SearchFilterSource<SearchUserStoryRequestParams> {

    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    };
    showAlertMessageTrigger = false;
    criteriaPojos: Array<AcceptanceCriteriaPojo> = [];
    userStoryId: number;
    searchManager!: SearchManager<AcceptanceCriteriaPojo, SearchAcceptanceCriteriaRequestParams>;
    form = this.fb.group({
        userStoryId: []
    });
    shouldShowDeleteCriteria = true;

    showActionColumn = true;

    constructor(public dialogRef: MatDialogRef<CriteriaCountModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private acceptanceCriteriaControllerService: AcceptanceCriteriaControllerService,
                private helperService: HelperService,
                private fb: FormBuilder) {
        this.searchManager = new SearchManager<AcceptanceCriteriaPojo, SearchAcceptanceCriteriaRequestParams>(this, this);
        if (this.data?.showActionColumn != undefined){
            this.showActionColumn = this.data.showActionColumn;
        }
        this.userStoryId = this.data?.userStoryId;
        if (this.data?.shouldShowDeleteCriteria != undefined) {
            this.shouldShowDeleteCriteria = this.data?.shouldShowDeleteCriteria;
        } else {
            this.shouldShowDeleteCriteria = false;
        }

        this.form.get('userStoryId').patchValue(this.userStoryId);
        if (this.userStoryId) {
            this.submit();
        }

        if (!this.userStoryId) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }
    }

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    getFilter(): SearchAcceptanceCriteriaRequestParams {
        const form = this.form.getRawValue();
        return Object.assign(form);

    }

    getPersistentKey(): string {
        return CriteriaCountModalComponent.name;
    }

    getSearchDescriptor(e: SearchAcceptanceCriteriaRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchAcceptanceCriteriaRequestParams): Observable<QueryResultsAcceptanceCriteriaPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;

        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage
        filter.userStoryIds = this.form.get('userStoryId').value;
        console.log(this.form.get('userStoryId').value)
        return this.acceptanceCriteriaControllerService.searchAcceptanceCriteria(filter);
    }

    deleteAcceptanceCriteria(id: number) {

        if (this.searchManager.list.length <= 1) {
            this.showAlertMessage("At least one acceptance criteria must remain. Deleting the last criteria is not allowed", AlertType.error);
            return;
        }

        this.acceptanceCriteriaControllerService.deleteAcceptanceCriteria({id: id}).subscribe({
            next: () => {
                this.showAlertMessage("Criteria Deleted Successfully.", AlertType.success);
                setTimeout(() => {
                        this.dialogRef.close()
                    },
                    2000);
            }, error: (err: unknown) => {
                this.showAlertMessage(this.helperService.getError(err), AlertType.error);
            }
        })
    }


    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

}
