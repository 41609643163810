/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';
import { NameIdPojo } from './name-id-pojo';
import { NameCodeIdPojo } from './name-code-id-pojo';
import { DateTimeIdPojo } from './date-time-id-pojo';


export interface DelegationDto { 
    id?: number;
    topic?: string;
    description: string;
    purpose: string;
    blockerStrategy: string;
    affectedCustomerName: string;
    affectedCustomerOrganisationId?: number;
    dueAt: string;
    remindAt?: string;
    valueStatus?: DelegationDto.ValueStatusEnum;
    delegate: NameCodeIdPojo;
    expectedOutcomes?: Array<NameIdPojo>;
    feedbackTimes?: Array<DateTimeIdPojo>;
    attachments?: Array<AttachmentDto>;
    nonCompletionConsequenceIds?: Array<number>;
    customerValueId?: number;
}
export namespace DelegationDto {
    export type ValueStatusEnum = 'VALUE_CAPTURED' | 'VALUE_NOT_CAPTURED' | 'PENDING' | 'RE_DELEGATED';
    export const ValueStatusEnum = {
        ValueCaptured: 'VALUE_CAPTURED' as ValueStatusEnum,
        ValueNotCaptured: 'VALUE_NOT_CAPTURED' as ValueStatusEnum,
        Pending: 'PENDING' as ValueStatusEnum,
        ReDelegated: 'RE_DELEGATED' as ValueStatusEnum
    };
}


