import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ProductionProcessComplianceControllerService,
    ProductionProcessComplianceEntryPojo,
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../services/helper.service";
import {of, switchMap} from "rxjs";
import {PlatformSelectorService} from "../../../services/platform-selector.service";
import {catchError, map, tap} from "rxjs/operators";

@Component({
    selector: 'app-compliance-tracker',
    templateUrl: './compliance-tracker.component.html',
    styleUrls: ['./compliance-tracker.component.scss']
})
export class ComplianceTrackerComponent implements OnInit {
    tabHeaders: string[] = []//['BRD Approval', 'Design', 'Module Release Planning', 'Coding Implementation', 'Testing UAT', 'Release', 'Monitoring', 'Production Certificate'];

    selectedLifecycle: ProductionProcessComplianceEntryPojo;

    @Input()
    pulsate = false;

    @Output()
    selectedProcessStageEvent: EventEmitter<ProductionProcessComplianceEntryPojo> = new EventEmitter<ProductionProcessComplianceEntryPojo>();

    selectedIndex: number;

    clickedProcessStage: number;

    tabIcons: string[] = [
        'el:ok',
        'carbon:checkmark',
    ];

    data: Array<ProductionProcessComplianceEntryPojo>;

    loading = false;

    show = true;

    constructor(public helperService: HelperService,
                private complianceService: ProductionProcessComplianceControllerService,
                private platformSelector: PlatformSelectorService
    ) {
    }

    ngOnInit(): void {
        this.fetchComplianceLifecycle();
    }

    get headers(): Array<string> {
        return this.tabHeaders
    }

    get selected(): ProductionProcessComplianceEntryPojo {
        return this.selectedLifecycle;
    }

    fetchComplianceLifecycle(): void {
        this.platformSelector.currentPlatform.pipe(
            switchMap(id => {
                    if (id) {
                        return this.complianceService.fetchProcessComplianceLifecycle({platformId: id})
                            .pipe(
                                catchError(error => {
                                    console.error('Error occurred:', error);
                                    return of([])
                                })
                            )
                    } else {
                        this.platformSelector.switchPlatform();
                        return of([]);
                    }
                }
            ),
            tap((result: ProductionProcessComplianceEntryPojo[]) => {
                this.selectedLifecycle = undefined;
                this.selectedIndex = undefined;
                this.data = result;
            }),
            // map((result: ProductionProcessComplianceLifecyclePojo[]) => result.sort((a, b)=>a.))
            map((result: ProductionProcessComplianceEntryPojo[]) => result.map(x => x.productionProcessStageName)),
            tap(headers => {
                this.tabHeaders = headers;
            })
        )
            .subscribe();

    }

    detailLeft = 0;

    select(index: number): void {
        const cycle = this.data[index];
        if (cycle?.isProcessLogged) {
            if (this.selectedIndex == index) {
                this.selectedLifecycle = undefined
                this.selectedIndex = undefined;
            } else {
                this.selectedLifecycle = this.data[index]
                this.selectedIndex = index;
            }

        }
    }

    get marginLeft(): number {

        if (this.selectedIndex) {
            const selectedItem = document.getElementById(`tracker-line-${this.selectedIndex}`);
            const rect = selectedItem?.getBoundingClientRect();

            const slider = document.querySelector('.detail');
            const sliderRect = slider?.getBoundingClientRect();

            const parentContainer = document.querySelector('.box');
            const parentRect = parentContainer?.getBoundingClientRect();

            let selectedItemLeft = rect.left - parentRect.left + parentContainer.scrollLeft - 20;

            if (((parentRect.width + parentContainer.scrollLeft) - (selectedItemLeft + sliderRect.width)) < 0) {
                selectedItemLeft = parentRect.width + parentContainer.scrollLeft - sliderRect.width - 40;
            }
            this.detailLeft = selectedItemLeft;

            return selectedItemLeft;
        }
        return 0;
    }

    logged(index: number): boolean {
        return this.data[index]?.isProcessLogged
    }

    updateCount(index: number): number {
        return this.data[index]?.numberOfUpdates
    }

    get lastLogged(): number {
        const index = this.data.slice().reverse().findIndex(x => x.isProcessLogged)
        if (index != -1) {
            return this.data.length - index - 1;
        }
        return -1;
    }

    showHide() {
        if (this.show) {
            this.show = false;
            this.selectedIndex = undefined;
            this.selectedLifecycle = undefined
        } else {
            this.show = !this.show;
        }
    }

    clickProcessStage(i) {
        this.clickedProcessStage = i;
        this.selectedProcessStageEvent.emit(this.data[i]);
    }
}
