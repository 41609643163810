import {Component, Inject} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
  SearchTicketsRequestParams,
  Ticket,
  TicketControllerService, TicketLifecyclePojo
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HelperService} from "../../../../services/helper.service";

@Component({
  selector: 'app-search-ticket-modal',
  templateUrl: './search-ticket-modal.component.html',
  styleUrls: ['./search-ticket-modal.component.scss']
})
export class SearchTicketModalComponent {

  emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  constructor(
    public dialogRef: MatDialogRef<SearchTicketModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ticketService: TicketControllerService,
    private router: Router,
    private helperService: HelperService) {
  }
  form = this.fb.group({
    email: ['',
      Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2)
      ])
    ]
  })
  submitting = false;
  submit() {
    if(this.form.invalid){
      this.helperService.getInvalidFormFields(this.form);
      return;
    }

    if (
      !this.emailRegex.test(<string>this.form.getRawValue().email?.trim()) &&
      isNaN(Number(this.form.getRawValue().email?.trim() || 's'))
    ) {
      console.log("Not an email or number")
      return;
    }


    this.submitting = true;
    this.ticketService
      .searchTickets({...this.searchTicketPojo})
      .subscribe({
        next: (value) =>{
          if (value.results && value.results.length == 0) {}
          else {
            const tickets = value.results;
            this.navigateToTicketView(tickets);
            this.dialogRef.close();
          }
        },
        complete: ()=>{
          this.submitting = false;
        },
        error:(error: unknown) =>{
          this.submitting = false;
        },
      })

  }

  navigateToTicketView(tickets: Array<Ticket> | undefined): void {
    // if(this.router.url === '/view-issue'){}
    this.router.navigate(['/view-issue',],{state: {tickets: tickets}});
  }

  get searchTicketPojo(): SearchTicketsRequestParams | undefined{
    const form = this.form.getRawValue();
    const param : SearchTicketsRequestParams =   {}
    if(this.emailRegex.test(<string>form.email?.trim())){
      param.email = form.email?.trim()
    } else {
      param.id =  7307//Number("form.email?")
    }
    return param;
  }
}
