import {Injectable, OnDestroy,} from '@angular/core';
import {NotificationControllerService, NotificationPojo} from "../../../sdk/customer-fulfillment-api-sdk";
import {BehaviorSubject, interval, Subject, takeUntil, timer} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy {

    private notificationsSubject = new BehaviorSubject<NotificationPojo[]>([]);

    private unsubscribeAll: Subject<any> = new Subject<any>();

    //subscribe to get notifications
    notifications$ = this.notificationsSubject.asObservable();

    constructor(private notificationControllerService: NotificationControllerService) {

        timer(1000, 30000).pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.getPendingNotifications();
            })
    }

    putNotifications(notifications: NotificationPojo[]) {
        this.notificationsSubject.next(notifications);
    }

    addNotifications(notification: NotificationPojo[]) {
        const notifications = this.notificationsSubject.value;
        notifications.push(...notification);
        this.notificationsSubject.next(notifications);
    }

    markNotificationAsRead(notificationId: number) {
        this.notificationControllerService.markNotificationAsRead({
            trackerId: notificationId,
        })
            .subscribe(() => {
                this.getPendingNotifications();
            });
    }

    getPendingNotifications(): void {
        this.notificationControllerService
            .searchMyNotifications({limit: 20, deliveryStatuses: ['SENT', 'PENDING'] as any[]})
            .subscribe((res) => {
                this.putNotifications(res.results);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

}
