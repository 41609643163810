import {Component, Inject, Input, ViewChild} from '@angular/core';
import {
    AcceptanceCriteriaControllerService,
    AcceptanceCriteriaPojo, QueryResultsAcceptanceCriteriaPojo,
    SearchAcceptanceCriteriaRequestParams,
    UserStoryPojo
} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {SearchManager} from "../../../../../shared/search/search-manager";
import {SelectionModel} from "@angular/cdk/collections";
import {NameValuePair} from "../../../../../models/etc/name-value-pair.model";
import {Observable} from "rxjs";
import {HelperService} from "../../../../../services/helper.service";
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";

@Component({
    selector: 'app-user-story-dialog',
    templateUrl: './user-story-dialog.component.html',
    styleUrls: ['./user-story-dialog.component.css']
})
export class UserStoryDialogComponent {
    userStories: Array<UserStoryPojo> = [];
    shouldShowCriteriaSearch = false;
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    };
    showAlertMessageTrigger = false;

    constructor(public dialogRef: MatDialogRef<UserStoryDialogComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public helperService: HelperService) {

        this.userStories = this.data?.userStories;
        this.shouldShowCriteriaSearch = this.data?.shouldShowCriteriaSearch;

        if (!this.userStories || this.userStories.length == 0) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }
    }


    showOutputMessages($event: { msg: string; type: AlertType }) {
        this.showAlertMessage($event.msg, $event.type);
    }

    onDone(){
        this.dialogRef.close();
    }

    protected readonly AlertType = AlertType;

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }
}
