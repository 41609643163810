<div class="modal-body">
  <div class="object-fit-contain">
    <div class="text-center pt-1 pb-2">
      <div class="d-flex justify-content-between align-items-center">
        <h6 class="m-0" translate=''> {{"partner.form.field.captureFace" | translate}} </h6>
      </div>
      <div class="mb-4 mt-3">
        <div class="alert alert-danger d-flex align-items-start" *ngIf="errorMessage">
          <div class="me-2">
            <span
              class="iconify"
              data-icon="carbon:warning-alt-filled"
              style="font-size: 20px"
            ></span>
          </div>
          {{ errorMessage }}
        </div>
        <webcam
          [width]="425"
          [trigger]="invokeObservable"
          (imageCapture)="captureImg($event)"
        ></webcam>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    (click)="closeModal()"
    class="btn btn-danger rounded-2"
    data-bs-dismiss="modal"
    translate=''
  >
    partner.form.field.cancel
  </button>
  <button type="button" class="btn btn-dark rounded-2" (click)="getSnapshot()" translate=''>
    <span class="iconify" data-icon="carbon:airline-rapid-board"></span> partner.form.field.captureFace
  </button>
</div>
