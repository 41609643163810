import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment/moment";

@Pipe({
  name: 'dateWithoutYear'
})
export class DateWithoutYearPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return moment(value).format('DD MMM ');
  }
  
  

}
