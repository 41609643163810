import {Component, EventEmitter, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-icon-selection',
    templateUrl: './icon-selection.component.html',
    styleUrls: ['./icon-selection.component.scss']
})
export class IconSelectionComponent {

    icons = [
        'mingcute:process-line',
        'gridicons:add-outline',
        'uim:process',
        'carbon:document',
        'lucide:edit',
        'clarity:process-on-vm-line',
        'pepicons-pencil:eye-circle',
        'simple-icons:thunderbird',
        'ant-design:thunderbolt-twotone',
        'material-symbols:code',
        'cuida:monitor-outline',
        'fluent-mdl2:not-executed',
        'fluent-mdl2:documentation',
        'fluent-mdl2:release-gate-check',
        'material-symbols:settings',
        'ix:reset'

    ];

    @Output() iconSelected = new EventEmitter<string>();
    selectedIcon: any = null;

    constructor(protected modalRef: BsModalRef) {
    }

    selectIcon(icon: any) {
        this.selectedIcon = icon;
        this.iconSelected.emit(icon);
        this.modalRef.hide();
    }

}
