<!-- In the component HTML file -->
<div class="m-0 rounded rounded-3">
    <div class="d-flex justify-content-between p-3 align-items-center">
        <div class="d-flex justify-content-start align-items-center">
            <h6 class="modal-title mb-0 fs-5" id="modal-title">User Story</h6>
        </div>
        <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
    </div>

    <div class="p-4 modal-body">
        <div class="row">
            <div class="col-12">
                <div class="content">
                    <ng-container class="mt-5" *ngFor="let us of getPaginatedUserStories(userStories); let i = index">
                        <div class="card bg-light-subtle">
                            <div class="card-body ">
                                <div class=" mb-2">
                                    <div class="me-3">
                                        <div class="fs-14 fw-bold">As A (Type Of User)</div>
                                        <div class="fs-12">{{ us?.actor }}</div>
                                    </div>
                                    <div class="me-3">
                                        <div class="fs-14 fw-bold">I Want To (Action)</div>
                                        <div class="fs-12">{{ us?.action }}</div>
                                    </div>
                                    <div class="me-3">
                                        <div class="fs-14 fw-bold">So That (Value)</div>
                                        <div class="fs-12">{{ us?.value }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="divider mb-4">
                    </ng-container>

                    <nav aria-label="User stories pagination" *ngIf="userStories?.length > itemsPerPage">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="currentPage === 1">
                                <a class="page-link" (click)="changePage(currentPage - 1)" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active]="currentPage === i + 1">
                                <a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
                            </li>
                            <li class="page-item" [class.disabled]="currentPage === totalPages">
                                <a class="page-link" (click)="changePage(currentPage + 1)" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
