/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OperatingSystemDto } from './operating-system-dto';
import { OperatingSystemVersionDto } from './operating-system-version-dto';


export interface HardwareDeviceDto { 
    id?: number;
    hardwareDeviceName?: string;
    hardwareDeviceIp?: string;
    hardwareDeviceSerialNumber?: string;
    hardwareDeviceDNSAddressName?: string;
    operatingSystem?: OperatingSystemDto;
    operatingSystemVersion?: OperatingSystemVersionDto;
}

