<div>
    <form [formGroup]="form">
        <div class="row">

            <div class="col-12 mb-4">
                <label for="result" class="form-label filter required">Key Results</label>
                <textarea type="text" rows="4" formControlName="result" id="result" class="form-control"
                          placeholder="Enter KeyResult"></textarea>
                <character-limit
                        [controlName]="'result'"
                        [form]="form"
                        [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                ></character-limit>
                <error-message
                        [controlName]="'result'"
                        [singularError]="true"
                        [form]="form">
                </error-message>
            </div>

            <div class="col-12 mb-4 text-end">
                <button class="btn btn-outline-dark" (click)="addEntryToList()">
                    <i class="iconify icon" data-icon="basil:plus-solid"></i>
                    Add
                </button>
            </div>

            <ng-container *ngFor="let item of keyResults; index as i;">
                <div class="col-12 mb-2">
                    <div class="text-end">
                        <span (click)="deleteKeyResult(i)" class="fs-10 text-danger pointer">Remove</span>
                    </div>
                    <div class="bg">
                        {{ item?.result }}
                    </div>
                </div>
            </ng-container>

        </div>
    </form>
</div>
