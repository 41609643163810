import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  BusinessRequirementControllerService, BusinessRequirementCustomerValueMapperDto,
  BusinessRequirementSoftwareModuleMapperDto
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../../services/helper.service";
import {Router} from "@angular/router";
import { AlertType } from 'src/app/pages/extranet/report-issue/report-issue.component';
import {UnsubscribeOnDestroyAdapter} from "../../../../services/unsubscribe-on-destroy-adapter";

@Component({
  selector: 'app-customer-value-selector-dialog',
  templateUrl: './customer-value-selector-dialog.component.html',
  styleUrls: ['./customer-value-selector-dialog.component.css']
})
export class CustomerValueSelectorDialogComponent extends UnsubscribeOnDestroyAdapter {


  platformId: number;

  businessReqId: number;

  title: string;

  constructor(public dialogRef: MatDialogRef<CustomerValueSelectorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private businessRequirementService: BusinessRequirementControllerService,
              public helperService: HelperService,
              private router: Router) {

    super();

    this.platformId = this.data?.platformId;
    this.businessReqId = this.data?.businessReqId;

    if (!this.platformId || !this.businessReqId) {
      setTimeout(() => {
            this.dialogRef.close()
          },
          1000)
    }

    this.title = this.data?.modal?.title
  }

  setSelected($event: Array<number>) {
    if ($event && $event.length > 0) {
      this.mapTo($event);
    } else {
      this.showAlertMessage('Select at least one module to map', AlertType.error);
    }
  }


  mapTo(selectedIds: Array<number>): void {
    const payload: Array<BusinessRequirementCustomerValueMapperDto> =
        selectedIds.map((id: number): BusinessRequirementCustomerValueMapperDto => {
          return {
            businessRequirmentId: this.businessReqId,
            customerValueId: id
          }
        })

    this.subscriptions.sink = this.businessRequirementService.mapBusinessRequirementToCustomerValue({
      businessRequirementCustomerValueMapperDto: payload,
      platformId: this.platformId
    })
        .subscribe({
          next: (data) => {
          },
          complete: () => {
            this.dialogRef.close(true);
          },
          error: (error: unknown) => {
            this.showAlertMessage(this.helperService.getError(error), AlertType.error);
          }
        })
  }


  /////////////////////
  //////ALERT
  /////////////////////
  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  getErrorMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly AlertType = AlertType;

  /////////////////////
  //////END ALERT
  /////////////////////

}
