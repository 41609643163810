import {Pipe, PipeTransform} from '@angular/core';
import {BehaviorSubject, Observable, timer} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
    name: 'stopWatch'
})
export class StopWatchPipe implements PipeTransform {

    private getMsDiff = (startDate: string, endDate: any): number => ((endDate && endDate !== '') ? (+new Date(endDate)) : Date.now()) - (+(new Date(startDate)));

    private msToTime(msElapsed: number, unit: string): string {
        const isNegative = msElapsed < 0;

        const days: string | number = Math.floor(Math.abs(msElapsed) / (1000 * 60 * 60 * 24));
        let hours: string | number = Math.floor((Math.abs(msElapsed) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes: string | number = Math.floor((Math.abs(msElapsed) % (1000 * 60 * 60)) / (1000 * 60));
        let seconds: string | number = Math.floor((Math.abs(msElapsed) % (1000 * 60)) / 1000);

        hours = (hours < 10) ? '0' + hours : hours;
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        switch (unit) {
            case 'seconds':
                return `${seconds}`;
            case 'minutes':
                return `${minutes}`;
            case 'hours':
                return `${hours}`;
            case 'days':
                return `${days}`;
            case 'isNegative':
                return `${isNegative}`;
            default:
                return `${days} Days: ${hours} Hours: ${minutes} Minutes: ${seconds} Seconds`;
        }
    }

    public transform(startDate: string, unit = '', endDate = '', isPaused = false): Observable<string> | null {
        if (!startDate) {
            return null;
        }
        let msElapsed = this.getMsDiff(startDate, endDate);
        const countdownSubject = new BehaviorSubject<string>(this.msToTime(msElapsed, unit));

        if (!isPaused && !endDate) {
            timer(0, 1000)
                .pipe(
                    map(() => {
                        msElapsed += 1000;
                        const countdown = this.msToTime(msElapsed, unit);
                        countdownSubject.next(countdown);
                    })
                )
                .subscribe();
        }

        if (endDate) {
            const countdown = this.msToTime(msElapsed, unit);
            countdownSubject.next(countdown);
        }


        return countdownSubject.asObservable();
    }
}

