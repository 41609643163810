<div class=" card mb-5 search">
    <div class="card-body">
        <form [formGroup]="form" (submit)="submit()">
            <div class="row g-3 mb-4">
                <div class="d-flex justify-content-end">
                    <div class="col-md-6">
                        <label for="keyword" class="form-label">Keyword</label>
                        <input type="text"
                               placeholder="Enter any Keyword"
                               id="keyword"
                               class="form-control"
                               [formControlName]="'keyword'"
                               name="keyword"
                        />
                    </div>
                </div>
            </div>
        </form>

        <div class="row">
            <ng-container *ngIf="!emptyListShowOnce">
                <div *ngIf="searchManager.totalCount > 0 " class="row"
                     [ngClass]="{'disabled-table': searchManager.working}">
                    <div class="d-md-flex align-acceptances-center justify-content-between">
                        <div class="col-md-6">
                            <app-total-records [searchManager]="searchManager"></app-total-records>
                        </div>
                        <div class="col-md-6 d-flex justify-content-end">
                            <page-size
                                    *ngIf="searchManager.totalCount > 0"
                                    [searchManager]="searchManager"
                            ></page-size>
                        </div>
                    </div>
                </div>
                <div class="d-flex p-1 bg-white justify-content-between flex-wrap">
                    <div class="pos">
                        <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        <span class="fw-medium primary-color"> {{ this.selection.selected.length }} Selected</span>
                        <u class="px-3 fw-medium pointer primary-color" (click)="clearSelection()">Clear</u>
                    </div>
                    <button class="btn btn-secondary" *ngIf="searchManager.list.length > 0"  [disabled]="selection.isEmpty()" (click)="markAcceptanceCriteria('OK')">
                        <span *ngIf="isMarking">
                            <span class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                            ></span>
                        </span>
                        Mark as Accepted
                    </button>
                </div>
                <hr>

                <div class="card" style="border: none;">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col" style="width: 3%"></th>
                                    <th scope="col" style="width: 3%">S/N</th>
                                    <th scope="col" style="width: 24%">Given That</th>
                                    <th scope="col" style="width: 24%">When</th>
                                    <th scope="col" style="width: 24%">Then</th>
                                    <th scope="col" style="width: 12%">Status</th>
                                    <th scope="col" style="width: 10%">
                                        Action
                                    </th>
                                </tr>
                                </thead>
                                <ng-container>

                                    <tbody>
                                    <ng-container *ngIf="!searchManager._working; else searching">
                                        <ng-container *ngIf="searchManager.list.length > 0; else noData">
                                            <tr class="justify-content-center"
                                                *ngFor="let acceptance of searchManager.list; let i = index">
                                                <td>
                                                    <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                                  (change)="$event ? toggle(acceptance)  : null"
                                                                  [checked]="selection.isSelected(acceptance.id)"
                                                                  [disabled]="acceptance?.checkStatus == 'OK' || acceptance?.checkStatus == 'NOT_OK'">
                                                    </mat-checkbox>
                                                </td>
                                                <td data-label="S/N" class="fw-bold">{{ i + searchManager.offset + 1 }}</td>
                                                <td data-label="Given That"> {{ helperService.cutText(acceptance.given?.criterion, 40) }}
                                                    <span *ngIf="acceptance.given?.criterion?.length >= 41" [placement]="'right'"
                                                          [tooltip]="acceptance.given?.criterion">
                                                            <span class="iconify text-black pointer"
                                                                  data-icon="material-symbols:info"> </span>
                                                        </span>
                                                </td>
                                                <td data-label="When">
                                                    {{ helperService.cutText(acceptance.when?.criterion, 40) }}
                                                       <span *ngIf="acceptance.when?.criterion?.length >= 41" [placement]="'right'"
                                                             [tooltip]="acceptance.when?.criterion">
                                                            <span class="iconify text-black pointer"
                                                                  data-icon="material-symbols:info"> </span>
                                                        </span>
                                                </td>
                                                <td data-label="Then">
                                                    {{ helperService.cutText(acceptance.then?.criterion, 40) }}
                                                       <span *ngIf="acceptance.then?.criterion?.length >= 41" [placement]="'right'"
                                                             [tooltip]="acceptance.then?.criterion">
                                                            <span class="iconify text-black pointer"
                                                                  data-icon="material-symbols:info"> </span>
                                                        </span>
                                                </td>
                                                <td data-label="Status" [ngClass]="{'text-danger': acceptance?.checkStatus == this.checkStatusEnum.NotOk,
                                                    'text-success': acceptance?.checkStatus == this.checkStatusEnum.Ok, 'text-warning':  acceptance?.checkStatus == this.checkStatusEnum.NotChecked}">
                                                        {{acceptance?.checkStatus | underscoreToSpace}}

                                                </td>
                                                <td data-label="Action">
                                                    <div class="dropdown dropstart">
                                                        <button role="button"
                                                                class="dropdown-toggle text-primary text-decoration-underline pointer"
                                                                style="background: unset !important; border: unset !important; cursor: unset;"
                                                                id="dropdownMenuLink" data-bs-toggle="dropdown"
                                                                aria-expanded="false">Action
                                                        </button>
                                                        <ul class="dropdown-menu dropdown-menu-end custom-dropdown-menu"
                                                            aria-labelledby="dropdownMenuLink">
                                                            <li style="cursor: pointer;"
                                                                *ngIf="acceptance?.checkStatus != this.checkStatusEnum.Ok">
                                                                <a class="dropdown-acceptance pointer text-success"
                                                                   (click)="markAcceptanceCriteria('OK', acceptance?.id)">
                                                                    <span class="iconify icon"
                                                                          data-icon="mdi:check-all"></span>
                                                                    {{ 'Mark as Satisfactory' }}
                                                                </a>
                                                            </li>
                                                            <li style="cursor: pointer;"
                                                                *ngIf="acceptance?.checkStatus != this.checkStatusEnum.NotOk">
                                                                <a class="dropdown-acceptance pointer text-danger"
                                                                   (click)="markAcceptanceCriteria('NOT_OK', acceptance?.id)">
                                                                    <span class="iconify icon"
                                                                          data-icon="mdi:check-all"></span>
                                                                    {{ 'Mark as Unsatisfactory' }}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #searching>
                                        <tr>
                                            <td colspan="100%">
                                                <div class="d-flex justify-content-center">
                                                    <div class="spinner-border" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>

                                    <ng-template #noData>
                                        <tr>
                                            <td class="text-center" colspan="9">NO DATA</td>
                                        </tr>
                                    </ng-template>
                                    </tbody>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row py-2"
                     [ngClass]="{ 'disabled-table': searchManager.working }">
                    <div class="col-12 d-flex justify-content-end">
                        <pagination
                                [(ngModel)]="searchManager.page"
                                [boundaryLinks]="true"
                                [itemsPerPage]="searchManager.itemsPerPage"
                                [maxSize]="maxPageSize"
                                [rotate]="true"
                                [totalItems]="searchManager.totalCount"
                                firstText="&laquo;"
                                lastText="&raquo;"
                                nextText="&rsaquo;"
                                previousText="&lsaquo;"
                        ></pagination>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
</div>