<div class="card search mt-3">
    <div class="card-body py-4" style="background-color: rgba(64,139,246,0.1);">
        <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex flex-column gap-3">
                <div class="accordion-body__item__grid-item">
                    <label class="pb-2 fw-bold fs-15" *ngIf="action">{{ action }} By</label>
                    <label class="pb-2 fw-bold fs-15" *ngIf="!action">Player Name</label>
                    <p>{{ this.user?.displayName || '-' | titlecase }}</p>
                </div>
                <div class="accordion-body__item__grid-item">
                    <label class="pb-2 fw-bold fs-15">Player Role</label>
                    <p>{{ this.user?.designation || '-' | titlecase }}</p>
                </div>
            </div>
            <div>
                <div class="accordion-body__item__grid-item d-flex flex-column align-content-center justify-content-center">
                    <label class="pb-2 fw-bold fs-15 text-center">Signature</label>
                    <div id="signature" *ngIf="this.user?.signatureId" class="bg-white">
                        <img class="figure-img img-fluid" [src]="getFileUrl(this.user?.signatureId)" alt="">
                    </div>
                    <div *ngIf="!this.user?.signatureId">
                        NO UPLOADED SIGNATURE
                    </div>
                </div>
            </div>
            <div>
                <div class="accordion-body__item__grid-item d-flex flex-column align-content-center justify-content-center">
                    <label class="pb-2 fw-bold fs-15 text-center">Headshot</label>
                    <div class="img-card" *ngIf="this.user?.displayPictureId">
                        <img [src]="getFileUrl(this.user?.displayPictureId)"
                             class="profile-card__head__left__top__profile-picture figure-img img-fluid"
                             alt=""/>
                    </div>
                    <div *ngIf="!this.user?.displayPictureId">
                        NO UPLOADED HEADSHOT
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
