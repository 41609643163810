import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertType} from "../../../pages/extranet/report-issue/report-issue.component";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    ProductionProcessComplianceControllerService,
    UpdateUATProcessDto, UserAcceptanceTestPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../services/helper.service";
import moment from "moment";
import {Utils} from "../../utils/utils";


@Component({
    selector: 'app-reschedule-uat-dialog',
    templateUrl: './reschedule-uat-dialog.component.html',
    styleUrls: ['./reschedule-uat-dialog.component.css']
})
export class RescheduleUatDialogComponent implements OnInit {

    form: FormGroup;
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    };
    showAlertMessageTrigger = false;
    @Output()
    emitDismissedClicked = new EventEmitter<any>();
    @Output()
    uatRescheduleDone = new EventEmitter<any>();
    @Input()
    userAcceptanceTestPojo: UserAcceptanceTestPojo

    _datePickerConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: 'DD-MM-YYYY HH:mm:ss',
        containerClass: 'theme-dark-blue',
        adaptivePosition: true,
        showWeekNumbers: false,
        displayOneMonthRange: true,
        returnFocusToInput: false,
        withTimepicker: true,
        keepDatepickerOpened: true
    };
    loading: boolean;

    constructor(private fb: FormBuilder,
                private helperService: HelperService,
                private processComplianceControllerService: ProductionProcessComplianceControllerService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            uatDate: ['', [Validators.required]],
            comments: ['', [Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH), Validators.maxLength(1500)]],
            accessDetails: ['', [Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH), Validators.maxLength(1500)]]
        });
    }

    minDate() {
        return new Date();
    }

    close(): void {
        this.emitDismissedClicked.emit();
    }

    rescheduleUat() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.loading = true;

        const updateUATProcessDto: UpdateUATProcessDto = {
            uatRequestId: this.userAcceptanceTestPojo?.id,
            uatName: this.userAcceptanceTestPojo?.name,
            uatDate: moment(this.form.get('uatDate').value).format('YYYY-MM-DDTHH:mm:ss'),
            comments: this.form.get('comments').value,
            accessDetails: this.form.get('accessDetails').value
        }

        this.processComplianceControllerService.updateUATRequest({updateUATProcessDto: updateUATProcessDto}).subscribe({
            next: () => {
                this.loading = false;
            },
            error: (err: unknown) => {
                this.loading = false;
                this.showAlertMessage(this.helperService.getError(err), AlertType.error);
            },
            complete: () =>{
                this.uatRescheduleDone.emit();
            }
        })
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    protected readonly AlertType = AlertType;
}
