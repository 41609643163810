<div class="placard pointer" (click)="switchPlatform()">
    <div class="d-flex column-gap-3 align-items-center">
        <div class="">
            <img class="rounded-1" [src]="logo" alt="" height="26" width="26"
                 style="padding:1px;border: 1px solid #CDCDCD;">
        </div>
        <div class="fw-bold fs-10">
            {{ helperService.cutText(currentPlatform?.name || 'Select...', 20) }} (Platform)
        </div>
        <div class="">
            <i class="iconify icon" data-icon="ep:switch"> </i>
        </div>
    </div>
</div>
