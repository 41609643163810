<div class="d-flex gap-3 align-items-center flex-wrap">
    <div *ngIf="label" class="fw-semibold mb-0 text-dull">{{ label }}</div>
    <div class="d-flex gap-2 align-items-center min-width">
        <div *ngIf="showDays" class="text-end times">
            <p class="mb-0 fw-bold text-primary">{{ startTime | stopWatch : 'days' : endTime | async  }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Day(s)</p>
        </div>
        <div class="text-end times">
            <p class="mb-0 text-muted fw-bold">{{ startTime | stopWatch : 'hours' : endTime | async }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Hr</p>
        </div>
        <div>
            <span class="fw-bold">:</span>
        </div>
        <div class=" text-end times">
            <p class="mb-0 text-muted fw-bold">{{ startTime | stopWatch : 'minutes' : endTime | async }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Min</p>
        </div>

        <div>
            <span class="fw-bold">:</span>
        </div>
        <div class=" text-end times">
            <p class="mb-0 text-muted fw-bold">{{ startTime | stopWatch : 'seconds' : endTime | async }}{{ 's' }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Sec</p>
        </div>
    </div>
</div>
