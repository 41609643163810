<!-- ------------------------------- -->

<div class="m-0 rounded rounded-3">
  <div class="d-flex justify-content-between p-3 align-items-center">
    <div class="d-flex justify-content-start align-items-center">
      <h6 class="modal-title mb-0 fs-5" id="modal-title">{{ header }}</h6>
    </div>
    <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <div>
        <svg
          fill="none"
          height="35"
          style="height: 70px; width: 80px; margin: 0px auto 20px"
          viewBox="0 0 26 25"
          width="35"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.9276"
            cy="12.4286"
            fill="white"
            r="12.1934"
            stroke="#0d6efd"
            stroke-width="0.343475"
          />
          <path
            d="M12.0068 9.26399V8.15108H12.9746V9.26399H12.0068ZM12.0068 16.0329V10.3231H12.9746V16.0329H12.0068Z"
            fill="#0d6efd"
          />
        </svg>
      </div>
      <div
        style="
          font-size: 1rem;
          font-weight: 600;
          margin: 0 auto 10px;
          max-width: 340px;
          color: #444;
        "
      >
        {{ body }}
      </div>
    </div>
  </div>
  <div class="modal-footer border-top-0">
    <button (click)="close()" class="btn btn-outline-danger me-2" type="button">
      <i class="fa fa-times mr-1"></i>Cancel
    </button>
    <button
      (click)="confirmButtonClicked()"
      [disabled]="loading"
      class="mx-2 btn btn-primary px-2 pe-3"
      type="button"
    >
      <i [ngClass]="{ 'fa-spinner fa-spin': loading, 'fa-check': !loading }" class="fa"></i>
      <span>{{ positiveButtonText }}</span>
    </button>
  </div>
</div>
