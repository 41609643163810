/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityChecklistSupportingDocumentPojo } from './activity-checklist-supporting-document-pojo';
import { NameIdPojo } from './name-id-pojo';


export interface ActivityChecklistItemStatusPojo { 
    id?: number;
    activityChecklistItemId?: number;
    note?: string;
    status?: ActivityChecklistItemStatusPojo.StatusEnum;
    createdAt?: string;
    updatedBy?: NameIdPojo;
    attachmentPojoList?: Array<ActivityChecklistSupportingDocumentPojo>;
}
export namespace ActivityChecklistItemStatusPojo {
    export type StatusEnum = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        NotStarted: 'NOT_STARTED' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
}


