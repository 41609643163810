import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";

const  material = [
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatTabsModule
]

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    material,
  ]


})
export class MaterialModule { }
