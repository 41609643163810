// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Constant {
  export enum Storage {
    SESSION = 'session',
    LOCAL = 'local'
  }

  export enum PortalAccountType {
    ADMIN = 'ADMIN',
    ORGANIZATION = 'ORGANIZATION'
  }
}
