<div *ngIf="_fileData; else noData"
        class="flex-row flex-column d-flex py-2 px-2 border-1 border-radius-4 border rounded position-relative">
    <button class="cancel-button">
        <span type="button" class="close pull-right" (click)="onCancel()">
      <span style="font-size: 30px" aria-hidden="true">&times;</span>
    </span>
    </button>
    <ng-container *ngIf="mediaType === 'IMAGE'">
        <div class="onboarding-img-preview text-center">
            <img [src]="getUrl" alt="" style="max-height: 225px; max-width: 150px" class="img-fluid" />
        </div>
    </ng-container>

    <ng-container *ngIf="mediaType === 'PDF' || mediaType === 'UNKNOWN'">
        <img style="max-height: 225px; max-width: 150px" src="/assets/img/pdf.png" />
    </ng-container>

    <ng-container *ngIf="mediaType === 'TEXT'">
        <span class="initials-signature"> {{ _fileData }} </span>
    </ng-container>
</div>
<ng-template #noData>
    <div class="onboarding-img-preview">
        <img src="../../assets/img/image-preview.svg" alt="" />
        <div>Preview</div>
    </div>
</ng-template>


