<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title>
        {{ 'Acceptance Criteria' }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                class="text-danger pointer"
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>

</div>


<mat-dialog-content class="p-4">
    <div class="row">
        <div class="col-12">
            <div *ngIf="showAlertMessageTrigger"
                 [ngClass]="{ anim: showAlertMessageTrigger }"
                 [class.alert-success]="getMessageType() === AlertType.success"
                 [class.alert-danger]="getMessageType() === AlertType.error"
                 [class.alert-primary]="getMessageType() === AlertType.primary"
                 [class.alert-info]="getMessageType() === AlertType.info"
                 class="alert mb-4 fs-14 text-center">
                {{ getErrorMessage() }}
            </div>
            <div class="content">
                <table class="table table-striped mt-3">
                    <thead>
                    <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">Given That</th>
                        <th scope="col">When</th>
                        <th scope="col">Then</th>
                        <th *ngIf="showActionColumn" scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngIf="!searchManager._working; else searching">
                        <ng-container *ngIf="searchManager.list.length > 0; else noData">
                            <tr *ngFor="let criteriaPojo of searchManager.list; let i = index">
                                <td data-label="S/N">{{ i + searchManager.offset + 1 }}</td>
                                <td data-label="Given That">{{ criteriaPojo?.given?.criterion || '-' }}</td>
                                <td data-label="When">{{ criteriaPojo?.when?.criterion || '-' }}</td>
                                <td data-label="Then">{{ criteriaPojo?.then?.criterion || '-' }}</td>
                                <td data-label="Action" *ngIf="showActionColumn"><span class="text-danger pointer"
                                          (click)="deleteAcceptanceCriteria(criteriaPojo?.id)">Delete</span></td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <ng-template #searching>
                        <tr>
                            <td colspan="100%">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template #noData>
                        <tr>
                            <td class="text-center" colspan="100%">NO DATA</td>
                        </tr>
                    </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
