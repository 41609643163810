/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { EpicPojo } from './epic-pojo';
import { UserStoryPojo } from './user-story-pojo';


export interface BusinessRequirementPojo { 
    id?: number;
    identifier?: string;
    requirementNumDisplayName?: string;
    requirement?: string;
    platformId?: number;
    version?: number;
    revisionDate?: string;
    creationDate?: string;
    definitionStatus?: BusinessRequirementPojo.DefinitionStatusEnum;
    parentRequirement?: NameIdPojo;
    description?: string;
    actor?: string;
    section?: NameIdPojo;
    hasChildren?: boolean;
    childrenRequirementNumDisplayNames?: Array<string>;
    epic?: EpicPojo;
    author?: string;
    userStories?: Array<UserStoryPojo>;
    allReqHasAcceptanceCriteria?: boolean;
}
export namespace BusinessRequirementPojo {
    export type DefinitionStatusEnum = 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    export const DefinitionStatusEnum = {
        Defined: 'DEFINED' as DefinitionStatusEnum,
        PartiallyDefined: 'PARTIALLY_DEFINED' as DefinitionStatusEnum,
        NotDefined: 'NOT_DEFINED' as DefinitionStatusEnum
    };
}


