<div [ngClass]="{'dashed-border': !this.profileImage}">
    <ngx-dropzone
            #selectFile
            (change)="onFileSelected($event)"
            [accept]="'image/png, image/jpeg, image/jpg'"
            [maxFileSize]="maxFileSizeInBytes"
            [disabled]="this.isReadOnly"
            style="width: 100%">
        <div class="img-card" *ngIf="this.profileImage">
            <img  [src]="getFileURL()" class="profile-card__head__left__top__profile-picture figure-img img-fluid"
                 alt=""/>
        </div>

        <ngx-dropzone-label *ngIf="!this.profileImage">
            <div
                 class="d-flex align-items-center flex-wrap flex-sm-nowrap gap-2 gap-sm-2 gap-md-6 p-2">
                <label class="file-select-card">
                    <span class="iconify icon" data-icon="basil:upload-outline"></span>
                    <div class="text-black" [innerHTML]="'Choose file <span class=fw-normal>or</span> drag here'"></div>
                    <div class="instructions mt-1">
                        File format must only be PNG or JPG. Max 2MB in size
                    </div>
                </label>
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone>
    <button class="btn secondary-btn" *ngIf="showImageUploadButton" (click)="selectFile._onClick()">
        <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_4825_12999)">
                <path
                        d="M8.5 2H3.83333C3.47971 2 3.14057 2.14048 2.89052 2.39052C2.64048 2.64057 2.5 2.97971 2.5 3.33333V12.6667C2.5 13.0203 2.64048 13.3594 2.89052 13.6095C3.14057 13.8595 3.47971 14 3.83333 14H13.1667C13.5203 14 13.8594 13.8595 14.1095 13.6095C14.3595 13.3594 14.5 13.0203 14.5 12.6667V8"
                        stroke="black"
                        stroke-opacity="0.6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                />
                <path
                        d="M12.7504 1.75003C13.0156 1.48481 13.3753 1.33582 13.7504 1.33582C14.1255 1.33582 14.4852 1.48481 14.7504 1.75003C15.0156 2.01525 15.1646 2.37496 15.1646 2.75003C15.1646 3.1251 15.0156 3.48481 14.7504 3.75003L8.74172 9.75936C8.58342 9.91753 8.38786 10.0333 8.17305 10.096L6.25772 10.656C6.20036 10.6728 6.13955 10.6738 6.08166 10.6589C6.02377 10.6441 5.97094 10.614 5.92869 10.5717C5.88643 10.5295 5.85631 10.4766 5.84148 10.4188C5.82665 10.3609 5.82766 10.3001 5.84439 10.2427L6.40439 8.32736C6.46741 8.11273 6.58341 7.9174 6.74172 7.75936L12.7504 1.75003Z"
                        stroke="black"
                        stroke-opacity="0.6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4825_12999">
                    <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>

        <span>{{ 'Edit Image'}}</span>
    </button>
</div>
