<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<form [formGroup]="form">
    <div class="card mb-2 search">
        <div class="card-body">
            <div class="row g-3 align-items-end">
                <div class="col-md-7">
                    <div class="row g-3 align-items-end">
                        <div *ngIf="showSectionControl" class="col-md-6">
                            <div>
                                <label for="section" class="form-label">Requirement Section</label>
                                <div>
                                    <select formControlName="sectionId" id="section" class="form-select">
                                        <option readonly [value]="''">Select Section</option>
                                        <option *ngFor="let p of sections$ | async" [value]="p.id">{{ p?.name | titlecase}}</option>
                                    </select>
                                </div>
                                <error-message
                                        [controlName]="'sectionId'"
                                        [singularError]="true"
                                        [form]="form"
                                ></error-message>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <label for="keyword" class="form-label">Keyword</label>
                                <input type="text"
                                       placeholder="Enter any Keyword"
                                       id="keyword"
                                       class="form-control"
                                       formControlName="keyword"
                                       name="keyword"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <button [disabled]="searchManager.working"
                            class="btn btn-lg float-end btn-dark px-3 d-flex align-items-center"
                            (click)="submit()">
                        <i *ngIf="!searchManager.working">
                            <i class="iconify icon me-2"
                               data-icon="iconamoon:search-thin"></i>
                        </i>
                        <app-loading *ngIf="this.searchManager.working"></app-loading>
                        Search
                    </button>
                </div>
            </div>

        </div>
    </div>
</form>

<ng-container *ngIf="!emptyListShowOnce">
    <div class="d-md-flex flex-wrap align-items-center justify-content-between">
        <div class="col-md-4">
            <app-total-records [searchManager]="searchManager"></app-total-records>
        </div>
        <div class="col-md-4x justify-content-end" *ngIf="this.searchManager?.list?.length > 10">
            <page-size
                    *ngIf="searchManager.totalCount > 0"
                    [searchManager]="searchManager"
            ></page-size>
        </div>
    </div>
    <div class="search">
        <div class="d-flex justify-content-between flex-wrap p-1 bg-white" [ngClass]="{'ms-3': !isRequirementDocumentRequest}" *ngIf="!isRequirementDocumentRequest">
            <div class="pos">
                <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
                <span class="fw-medium primary-color"> {{ this.selection.selected.length }} Selected</span>
                <u class="fw-medium pointer primary-color" (click)="clearSelection()">Clear</u>
            </div>

            <button class="btn btn-lg btn-secondary d-flex align-items-center" (click)="mapTo()" [disabled]="selection.selected.length < 1">
                <span class="iconify" data-icon="tabler:check"></span>
                <span class="ms-2">{{ callToActionText }}</span>
            </button>
        </div>
        <hr>

        <div class="card" style="border: none;">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
<!--                            *ngIf="!isRequirementDocumentRequest"-->
                            <th scope="col" *ngIf="!isRequirementDocumentRequestApproval"></th>
                            <th scope="col">SN</th>
                            <th scope="col">Requirement ID</th>
                            <th scope="col">Requirement Description</th>
                            <th scope="col" *ngIf="isRequirementDocumentRequest">User Story</th>
                            <th scope="col" *ngIf="isRequirementDocumentRequest">Author</th>
                            <th scope="col" *ngIf="isRequirementDocumentRequest ">Creation Date</th>
                            <th scope="col" *ngIf="!isRequirementDocumentRequest">Section</th>
                            <th scope="col" *ngIf="isRequirementDocumentRequestApproval">Action</th>
                        </tr>
                        </thead>
                        <ng-container *ngIf="!searchManager._working; else searching">
                            <ng-container *ngIf="searchManager.list.length > 0; else noData">
                                <tbody>
                                <tr class="justify-content-center th-1"
                                        *ngFor="let req of searchManager.list; let i = index">
<!--                                    *ngIf="!isRequirementDocumentRequest"-->
                                    <td *ngIf="!isRequirementDocumentRequestApproval">
                                        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                      (change)="$event ? selection.toggle(req.id) : null"
                                                      [checked]="selection.isSelected(req.id)">
                                        </mat-checkbox>
                                    </td>
                                    <td class="fw-bold">{{ i + searchManager.offset + 1 }}</td>
                                    <td>{{ req?.requirementNumDisplayName | uppercase }}</td>
                                    <td>
                                        {{ helperService.cutText(req.requirement, 40) }}
                                        <span *ngIf="req.requirement?.length >= 41" [placement]="'right'"
                                              [tooltip]="req.requirement">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                    </td>
                                    <td><a class="pointer" (click)="req?.userStories?.length > 0 ? viewUserStories(req?.userStories) : ''">{{req?.userStories?.length || 0}}</a>
                                        <span *ngIf="req?.allReqHasAcceptanceCriteria && isRequirementDocumentRequestApproval" class="iconify" style="color: green"
                                              data-icon="ion:reload-circle-outline"> </span>
                                    </td>
                                    <td *ngIf="showAuthor">{{req?.author || '-'}}</td>
                                    <td *ngIf="showCreationDate">{{req?.creationDate | date: 'EEEE, MMMM d, yyyy'}}</td>
                                    <td *ngIf="!isRequirementDocumentRequest">
                                        {{ helperService.cutText(req.section?.name, 40) }}
                                        <span *ngIf="req.section?.name?.length >= 41" [placement]="'right'"
                                              [tooltip]="req.section?.name">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                    </td>
                                    <td *ngIf="isRequirementDocumentRequestApproval"><a (click)="editRequirement(req)">Manage</a></td>
                                </tr>
                                </tbody>
                            </ng-container>
                        </ng-container>
                        <ng-template #searching>
                            <tr>
                                <td colspan="100%">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template #noData>
                            <tr>
                                <td class="text-center" colspan="9">NO DATA</td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="searchManager.totalCount > 10"
            class="row py-2"
            [ngClass]="{ 'disabled-table': searchManager.working }">
        <div class="col-12 d-flex justify-content-end">
            <pagination
                    [(ngModel)]="searchManager.page"
                    [boundaryLinks]="true"
                    [itemsPerPage]="searchManager.itemsPerPage"
                    [maxSize]="maxPageSize"
                    [rotate]="true"
                    [totalItems]="searchManager.totalCount"
                    firstText="&laquo;"
                    lastText="&raquo;"
                    nextText="&rsaquo;"
                    previousText="&lsaquo;"
            ></pagination>
        </div>
    </div>
</ng-container>


<empty-list
        *ngIf="searchManager.list.length == 0 && !searchManager.working && emptyListShowOnce"
        [description]="'No Business Requirement has been created or searched for'"
        [@fadeInOut]></empty-list>

