import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {HelperService} from "../../../../../../services/helper.service";
import {AlertType} from "../../../../../extranet/report-issue/report-issue.component";
import {UnsubscribeOnDestroyAdapter} from "../../../../../../services/unsubscribe-on-destroy-adapter";
import {FormHelper} from "../../../../../../models/etc/form-helper";
import {
    KeyResultControllerService,
    KeyResultDto,
    QueryResultsCadencePojo, QueryResultsKeyResultPojo,
    SearchKeyResultRequestParams
} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";
import {PaginatedSearch} from "../../../../../../shared/search/paginated-search";
import {PlatformSelectorService} from "../../../../../../services/platform-selector.service";

@Component({
    selector: 'app-create-uat-key-result',
    templateUrl: './create-uat-key-result.component.html',
    styleUrls: ['./create-uat-key-result.component.css']
})
export class CreateUatKeyResultComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

    form = this.fb.group({
        result: ['',
            Validators.compose([
                Validators.required,
                Validators.minLength(FormHelper.NOTE_FIELD_MIN_LENGTH),
                Validators.maxLength(FormHelper.NOTE_FIELD_MAX_LENGTH)
            ])],
    })

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    @Input()
    keyResults: { result: string }[] = [];

    @Output()
    creationSuccess: EventEmitter<any> = new EventEmitter<any>();

    /**
     * If this is passed, {@link keyResults} is ignored, and a db call is made to fetch Key results
     */
    @Input()
    uatRequestId: number

    platformId: number;

    initialFormData: any;

    constructor(public helperService: HelperService,
                private fb: FormBuilder,
                private keyResultService: KeyResultControllerService,
                private platformSelectorService: PlatformSelectorService) {
        super();
    }


    ngOnInit(): void {
        this.saveInitFormData();
        this.initListeners();
        this.getData(1)
    }

    initListeners(): void {
        this.platformSelectorService.currentPlatform.subscribe(value => {
            if (value) {
                this.platformId = value;
            }
        });
    }

    saveInitFormData(): void {
        this.initialFormData = this.form.getRawValue();
    }

    resetForm(): void {
        this.form.reset(this.initialFormData);
    }

    deleteKeyResult(index: number): void {
        this.keyResults.splice(index, 1);
    }

    addEntryToList(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form)
            return;
        }

        const duplicate = this.keyResults.find(res => res.result?.toLowerCase() === this.form.controls.result.value?.toLowerCase())

        if (duplicate) {
            this.showAlertMessage.emit({msg: 'Duplicate Key Results are not allowed', type: AlertType.error});
            return;
        }

        const form = this.form.getRawValue();
        const benchmark: { result: string } =
            {
                result: form.result,
            };
        this.keyResults = this.keyResults.concat(benchmark);

        this.resetForm();

    }

    saveEntry(): void {

        if (this.keyResults?.length < 1) {
            this.showAlertMessage.emit({msg: 'Add Key Results before proceeding', type: AlertType.error});
            return;
        }

        if (!this.uatRequestId) {
            this.showAlertMessage.emit({msg: 'No UAT request has been selected', type: AlertType.error});
            return;
        }

        const payload: Array<KeyResultDto> = this.keyResults.map((result): KeyResultDto => {
            return {
                checkStatus: KeyResultDto.CheckStatusEnum.Ok,
                comment: "",
                result: result.result,
                userAcceptanceTestId: this.uatRequestId,
            }
        });

        this.subscriptions.sink = this.keyResultService.createKeyResult({keyResultDto: payload})
            .subscribe({
                next: (data) => {
                },
                complete: () => {
                    this.showAlertMessage.emit({msg: 'Key Results created', type: AlertType.success});

                    setTimeout(() => {
                        this.creationSuccess.emit();
                    }, 1500)
                },
                error: (error: unknown) => {
                    this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error});
                }
            })
    }

    filter = {};
    page = 1;
    loading = false;
    perPage = 1000;
    maxPage = 4;

    getData(p = 1, filter = this.filter) {
        this.page = p;
        this.filter = filter;
        this.loading = true;

        if (!this.uatRequestId) {
            return;
        }
        const searchQueryParams: SearchKeyResultRequestParams = {
            ...PaginatedSearch.removeNullFields(this.filter),
            offset: ((p || 0) - 1) * this.perPage,
            limit: this.perPage,
            platformId: this.platformId,
            userAcceptanceId: this.uatRequestId
        };
        this.subscriptions.sink = this.keyResultService
            .searchKeyResult(
                searchQueryParams
            )
            .subscribe({
                next: (data: QueryResultsKeyResultPojo) => {
                    this.keyResults = data?.results.map((x) => {
                        return {
                            result: x.result
                        }
                    })
                },
                error: (e) => {
                    this.showAlertMessage.emit({msg: this.helperService.getError(e), type: AlertType.error});
                    this.loading = false;
                },
                complete: () => {
                    this.loading = false;
                },
            });
    }

    getEntries(): { result: string }[] {
        return this.keyResults;
    }

    protected readonly FormHelper = FormHelper;
}
