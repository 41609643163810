import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  submit = false
  showCancel = true
  showConfirm = true
  cancelText = 'Cancel'
  confirmText = 'Confirm'

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
    console.log(this.data)
    this.showCancel = this.data.cancel? this.data.cancel.show : true
    this.showConfirm= this.data.confirm? this.data.confirm.show : true
    this.cancelText = this.data.cancel? this.data.cancel.text : 'Cancel'
    this.confirmText = this.data.confirm? this.data.confirm.text : 'Confirm'
  }

  yes(){
    this.dialogRef.close(true);
  }

  no(){
    this.dialogRef.close(false);
  }

}
