<svg [ngStyle]="{'width':width,'height':height}" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8832 68.8665L0.0619301 30.4347C-0.136586 29.875 0.158112 29.2551 0.71777 29.0506C18.8893 22.515 39.3656 16.4364 56.8692 8.96792C57.6513 8.4745 58.2652 8.96197 58.6021 9.89466L80.3178 65.8695L86.2146 82.269C86.4131 82.8286 86.1244 83.4545 85.5647 83.6533L44.0108 98.5963L27.8127 104.422C27.253 104.627 26.6333 104.332 26.4288 103.772L13.8832 68.8665Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.406 56.1141L18.5045 15.8646C18.4021 15.2748 18.7994 14.7152 19.383 14.6128L66.9119 6.46426L77.7969 4.67085C78.3926 4.41204 79.3614 4.71289 79.5899 6.04303L91.3472 64.7563L94.2897 81.9322C94.392 82.516 93.9948 83.0816 93.4111 83.184L49.8894 90.6466L32.9213 93.5593C32.3376 93.6557 31.772 93.2584 31.6697 92.6746L25.406 56.1141Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.7219 44.2466V82.9434C38.7219 83.5634 39.2274 84.0749 39.8471 84.0749H57.8081H84.3977H88.2185H103.875C104.495 84.0749 105 83.5634 105 82.9434V64.7626V14.5411V14.0175H93.5678C92.4667 14.0175 90.1801 14.174 90.1801 11.6765L90.1559 0.512695H39.8469C39.2272 0.512695 38.7217 1.01821 38.7217 1.63816V11.2973V15.3837V44.2466H38.7219Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6475 22.5388C13.2093 24.7055 6.83115 26.8539 0.71777 29.0505C0.158112 29.2551 -0.136586 29.8749 0.0619301 30.4345L13.8832 68.8666L26.4288 103.772C26.6335 104.332 27.2532 104.626 27.8127 104.422L44.0108 98.5961L80.9197 85.3261L19.6475 22.5388Z" fill="#B5C4CF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.7219 11.2969L19.383 14.6128C18.7994 14.7151 18.4021 15.2748 18.5045 15.8646L19.6478 22.5386L25.4062 56.114L31.6699 92.6743C31.7722 93.2582 32.3378 93.6552 32.9215 93.559L49.8896 90.6463L80.9198 85.3262L88.2185 84.0744H84.3977L38.7219 11.2969Z" fill="#D7E7EC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M105 64.7626V14.5411V14.0175V13.843C105 13.5179 104.862 13.4638 104.362 12.9763L92.0213 1.20483C91.4376 0.651123 91.3415 0.512695 90.9984 0.512695H90.1559H39.8469C39.2272 0.512695 38.7217 1.01821 38.7217 1.63816V82.9434C38.7217 83.5634 39.2272 84.0749 39.8469 84.0749H103.875C104.495 84.0749 105 83.5634 105 82.9434V64.7626Z" fill="#EDF3F4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.7218 11.2969L34.191 12.0733V87.1017C34.191 87.7276 34.6966 88.2332 35.3163 88.2332H53.2773H63.9637L80.9197 85.3264L88.2185 84.0746H84.3977H79.6983H57.808H39.8471C39.2273 84.0746 38.7218 83.5631 38.7218 82.9432C38.7218 59.0629 38.7218 35.1768 38.7218 11.2969Z" fill="#B5C4CF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4059 56.1142L19.6476 22.5388C18.0892 23.0624 16.5367 23.5859 14.9902 24.1095L15.7064 28.2802L21.4648 61.8556L27.7346 98.4159C27.8308 98.9995 28.3964 99.3968 28.98 99.3006L45.9482 96.3879L53.9992 95.0097L78.248 86.2835L77.9593 85.8321L63.9635 88.2333L49.8896 90.6467L32.9214 93.5594C32.3378 93.6558 31.7722 93.2586 31.6698 92.6747L25.4059 56.1142Z" fill="#9AAFB7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M105 64.7626V14.5411V14.0175C105 13.4096 104.801 13.4037 104.362 12.9763L92.0212 1.20483C91.4376 0.651123 91.3414 0.512695 90.9983 0.512695H90.1558H77.6342C92.9356 20.9986 93.808 57.926 82.4539 80.9755C81.9303 82.0468 81.3828 83.0759 80.8112 84.0749H84.3974H88.2182H103.875C104.494 84.0749 105 83.5634 105 82.9434V64.7626Z" fill="#D7E7EC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.1799 11.676C90.1799 14.1735 92.4663 14.017 93.5676 14.017H105V13.8425C105 13.5174 104.862 13.4633 104.362 12.9758L92.0213 1.20435C91.4376 0.650635 91.3414 0.512207 90.9983 0.512207H90.1559L90.1799 11.676Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M104.362 12.9763L92.0213 1.20483C91.4376 0.651123 91.3414 0.512695 90.9983 0.512695H90.1559L90.1799 11.6763C90.1799 14.1738 92.4663 14.0173 93.5676 14.0173H105V13.8428C105 13.5179 104.862 13.4638 104.362 12.9763Z" fill="#B5C4CF"/>
    <path d="M94.8311 25.123H49.5285C48.6535 25.123 47.9441 24.4134 47.9441 23.5379C47.9441 22.6625 48.6535 21.9529 49.5285 21.9529H94.8311C95.7062 21.9529 96.4155 22.6625 96.4155 23.5379C96.4155 24.4134 95.7064 25.123 94.8311 25.123Z" fill="#9AAFB7"/>
    <path d="M94.8311 36.3827H49.5285C48.6535 36.3827 47.9441 35.6732 47.9441 34.7977C47.9441 33.9222 48.6535 33.2126 49.5285 33.2126H94.8311C95.7062 33.2126 96.4155 33.9222 96.4155 34.7977C96.4155 35.6732 95.7064 36.3827 94.8311 36.3827Z" fill="#9AAFB7"/>
    <path d="M94.8311 47.6425H49.5285C48.6535 47.6425 47.9441 46.9329 47.9441 46.0575C47.9441 45.182 48.6535 44.4724 49.5285 44.4724H94.8311C95.7062 44.4724 96.4155 45.182 96.4155 46.0575C96.4155 46.9329 95.7064 47.6425 94.8311 47.6425Z" fill="#9AAFB7"/>
    <path d="M94.8311 58.903H49.5285C48.6535 58.903 47.9441 58.1934 47.9441 57.318C47.9441 56.4425 48.6535 55.7329 49.5285 55.7329H94.8311C95.7062 55.7329 96.4155 56.4425 96.4155 57.318C96.4155 58.1934 95.7064 58.903 94.8311 58.903Z" fill="#9AAFB7"/>
    <path d="M94.8311 70.163H49.5285C48.6535 70.163 47.9441 69.4534 47.9441 68.578C47.9441 67.7025 48.6535 66.9929 49.5285 66.9929H94.8311C95.7062 66.9929 96.4155 67.7025 96.4155 68.578C96.4155 69.4534 95.7064 70.163 94.8311 70.163Z" fill="#9AAFB7"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="105" height="105" fill="white"/>
    </clipPath>
  </defs>
</svg>
