/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WigPojo } from './wig-pojo';
import { NameIdPojo } from './name-id-pojo';
import { NameCodeIdPojo } from './name-code-id-pojo';
import { CustomerValuePojo } from './customer-value-pojo';
import { PortalUserDetailsPojo } from './portal-user-details-pojo';
import { ProductionProcessComplianceEntryPojo } from './production-process-compliance-entry-pojo';


export interface UserAcceptanceTestPojo { 
    id?: number;
    reviewedAt?: string;
    requestId?: string;
    name?: string;
    comment?: string;
    authorizedBy?: NameIdPojo;
    requestedBy?: PortalUserDetailsPojo;
    customerValues?: Array<CustomerValuePojo>;
    complianceEntries?: Array<ProductionProcessComplianceEntryPojo>;
    identifier?: string;
    requestStatus?: UserAcceptanceTestPojo.RequestStatusEnum;
    statusUpdatedAt?: string;
    plannedTime?: string;
    wigPojo?: WigPojo;
    mappedProductionUnitIds?: Array<number>;
    canManageUAT?: boolean;
    accessDetail?: string;
    sprintPojo?: NameIdPojo;
    useCases?: Array<NameCodeIdPojo>;
    platformPojo?: NameIdPojo;
    uatComplianceId?: number;
    notifiedEmailList?: Array<string>;
}
export namespace UserAcceptanceTestPojo {
    export type RequestStatusEnum = 'PENDING' | 'FAILED' | 'PASSED' | 'NOTIFIED';
    export const RequestStatusEnum = {
        Pending: 'PENDING' as RequestStatusEnum,
        Failed: 'FAILED' as RequestStatusEnum,
        Passed: 'PASSED' as RequestStatusEnum,
        Notified: 'NOTIFIED' as RequestStatusEnum
    };
}


