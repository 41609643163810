/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCodeIdPojo } from './name-code-id-pojo';


export interface SprintUatPojo { 
    id?: number;
    name?: string;
    plannedTime?: string;
    status?: SprintUatPojo.StatusEnum;
    useCases?: Array<NameCodeIdPojo>;
}
export namespace SprintUatPojo {
    export type StatusEnum = 'PENDING' | 'FAILED' | 'PASSED' | 'NOTIFIED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Failed: 'FAILED' as StatusEnum,
        Passed: 'PASSED' as StatusEnum,
        Notified: 'NOTIFIED' as StatusEnum
    };
}


