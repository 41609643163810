/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UMLDesignItemDto } from './uml-design-item-dto';
import { AttachmentDto } from './attachment-dto';
import { BusinessRequirementRequestDto } from './business-requirement-request-dto';
import { MRPRequestDto } from './mrp-request-dto';
import { UATRequestDto } from './uat-request-dto';
import { ComplianceCommitmentUnitDto } from './compliance-commitment-unit-dto';


export interface CreateComplianceEntryLogDto { 
    productionProcessStageCode: string;
    notes?: string;
    supportingDocumentDto?: Array<AttachmentDto>;
    softwareModuleId?: number;
    platformId: number;
    affectedProductionUnitIdsAndCommitment?: { [key: string]: ComplianceCommitmentUnitDto; };
    businessRequirementRequestDto?: BusinessRequirementRequestDto;
    mrpRequestDto?: Array<MRPRequestDto>;
    uatRequestDto?: UATRequestDto;
    authorityId?: number;
    portalAccountRoleTypeId?: number;
    fileDescription?: string;
    outputStatusConstant?: CreateComplianceEntryLogDto.OutputStatusConstantEnum;
    complianceId?: number;
    productionStandardStageCodes?: Array<number>;
    useCaseId?: number;
    designType?: CreateComplianceEntryLogDto.DesignTypeEnum;
    umlDesignItems?: Array<UMLDesignItemDto>;
}
export namespace CreateComplianceEntryLogDto {
    export type OutputStatusConstantEnum = 'FAILED' | 'SUCCESSFUL' | 'PENDING' | 'REQUESTED_REQUIREMENT_APPROVAL' | 'REQUESTED_UI_APPROVAL' | 'REQUESTED_UML_APPROVAL' | 'REQUESTED_CQRS_APPROVAL' | 'APPROVED_AND_SIGNED' | 'DISAPPROVED_AND_NOT_SIGNED' | 'QA_FAILED' | 'QA_SUCCESSFUL' | 'RELEASE_FAILED' | 'RELEASE_SUCCESSFUL' | 'REQUESTED_PLAN_APPROVAL' | 'REQUESTED_QA' | 'REQUESTED_UAT' | 'UAT_FAILED' | 'UAT_SUCCESSFUL' | 'MONITORING_SUCCESSFUL' | 'BLACKBOX';
    export const OutputStatusConstantEnum = {
        Failed: 'FAILED' as OutputStatusConstantEnum,
        Successful: 'SUCCESSFUL' as OutputStatusConstantEnum,
        Pending: 'PENDING' as OutputStatusConstantEnum,
        RequestedRequirementApproval: 'REQUESTED_REQUIREMENT_APPROVAL' as OutputStatusConstantEnum,
        RequestedUiApproval: 'REQUESTED_UI_APPROVAL' as OutputStatusConstantEnum,
        RequestedUmlApproval: 'REQUESTED_UML_APPROVAL' as OutputStatusConstantEnum,
        RequestedCqrsApproval: 'REQUESTED_CQRS_APPROVAL' as OutputStatusConstantEnum,
        ApprovedAndSigned: 'APPROVED_AND_SIGNED' as OutputStatusConstantEnum,
        DisapprovedAndNotSigned: 'DISAPPROVED_AND_NOT_SIGNED' as OutputStatusConstantEnum,
        QaFailed: 'QA_FAILED' as OutputStatusConstantEnum,
        QaSuccessful: 'QA_SUCCESSFUL' as OutputStatusConstantEnum,
        ReleaseFailed: 'RELEASE_FAILED' as OutputStatusConstantEnum,
        ReleaseSuccessful: 'RELEASE_SUCCESSFUL' as OutputStatusConstantEnum,
        RequestedPlanApproval: 'REQUESTED_PLAN_APPROVAL' as OutputStatusConstantEnum,
        RequestedQa: 'REQUESTED_QA' as OutputStatusConstantEnum,
        RequestedUat: 'REQUESTED_UAT' as OutputStatusConstantEnum,
        UatFailed: 'UAT_FAILED' as OutputStatusConstantEnum,
        UatSuccessful: 'UAT_SUCCESSFUL' as OutputStatusConstantEnum,
        MonitoringSuccessful: 'MONITORING_SUCCESSFUL' as OutputStatusConstantEnum,
        Blackbox: 'BLACKBOX' as OutputStatusConstantEnum
    };
    export type DesignTypeEnum = 'UI_UX' | 'ERD';
    export const DesignTypeEnum = {
        UiUx: 'UI_UX' as DesignTypeEnum,
        Erd: 'ERD' as DesignTypeEnum
    };
}


