import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {ComplianceDisapprovalComponent} from "../../compliance-disapproval/compliance-disapproval.component";
import {HelperService} from "../../../../services/helper.service";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-compliance-disapproval-dialog',
    templateUrl: './compliance-disapproval-dialog.component.html',
    styleUrls: ['./compliance-disapproval-dialog.component.css']
})
export class ComplianceDisapprovalDialogComponent {

    @Input()
    title: string;

    subtitle: string;

    @Output()
    onConfirmClicked = new EventEmitter<void>();

    @Output()
    onDisapproval = new EventEmitter<{ productionStandardIds: number[], reason: string }>();

    @ViewChild("disapprovalComponent")
    disapprovalComponent: ComplianceDisapprovalComponent;

    productionStageCode = environment.productionStandardProcessCode;

    constructor(public dialogRef: MatDialogRef<ComplianceDisapprovalDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                protected helperService: HelperService) {

        this.title = this.data?.modal?.title
        this.subtitle = this.data?.modal?.subtitle

        const stageCode = this.data?.productionStageCode;

        if (stageCode) {
            this.productionStageCode = stageCode;
        }
    }


    disapprove(): void {

        if (this.disapprovalComponent.form.invalid) {
            this.helperService.getInvalidFormFields(this.disapprovalComponent.form);
            return;
        }

        const form = this.disapprovalComponent.form.getRawValue();
        const selected = this.disapprovalComponent.codingStandardSearchComponent.selected;
        this.onConfirmClicked.emit();
        this.onDisapproval.emit({productionStandardIds: selected, reason: form.reason});
    }

    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////

}
