<div class="wrapper"
     [ngStyle]="{
          width: config.width? (config.width+(config.padding*2))+'px': '100%',
          padding: config.padding+'px',
          height: config.height? (config.height+(config.padding*2))+'px': 'auto',
          borderRadius: config.circle? '50%':'5px',
          backgroundColor: config.background,
        }"
>
    <ng-container *ngIf="!showHtml">
       <ng-container  *ngIf="config.image" >
           <img *ngIf="isLoading" [src]="loader" class="loader"
                [ngStyle]="{
                width: config.width? config.width+'px': '100%',
                height: config.height? config.height+'px': 'auto',
                objectFit: config.contain? 'contain': config.cover? 'cover' : '',
                borderRadius: config.circle? '50%':'5px'
              }"
           />
           <img
                   [src]="config.image" [alt]="config.alt"
                   class="main-image"
                   [ngStyle]="{
                    display:isLoading || showErrorImage?'none':'block',
                    width: config.width? config.width+'px': '100%',
                    height: config.height? config.height+'px': 'auto',
                    objectFit: config.contain? 'contain': config.cover? 'cover' : '',
                    borderRadius: config.circle? '50%':'5px'
                  }"
                   (load)="showImage()" (error)="showError()"/>
           <img [src]="errorImage" class="loader"
                [ngStyle]="{
                display:isLoading || showSuccessImage?'none':'block',
                width: config.width? config.width+'px': '100%',
                height: config.height? config.height+'px': 'auto',
                objectFit: config.contain? 'contain': config.cover? 'cover' : '',
                borderRadius: config.circle? '50%':'5px'
              }"
           />
       </ng-container>
        <ng-container *ngIf="config.initials">
            <div class="initials" [style.background-color]="config.initialsBgColor">
                {{ config.initials }}
            </div>
        </ng-container>
    </ng-container>

    <div *ngIf="showHtml" class="fs-12">
        <div [innerHTML]="htmlText"></div>

    </div>

</div>

