import {EventEmitter, Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  toggleStatus =  new Subject<boolean>

  constructor() {
    // this.toggleStatus = new EventEmitter<boolean>()
  }

  changeToggleStatus(status: boolean){
    this.toggleStatus.next(status)
  }
}
