/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductionUnitUploadDto } from './production-unit-upload-dto';


export interface UploadResponseProductionUnitUploadDtoProductionUnitUploadDto { 
    errorSheetNameUrl?: string;
    sheetName?: string;
    successes?: Array<ProductionUnitUploadDto>;
    failures?: Array<{ [key: string]: object; }>;
    errors?: Array<ProductionUnitUploadDto>;
}

