import {Component, Inject, OnInit, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {
    ProcessComplianceWorkflowPojo,
    ProductionProcessComplianceControllerService,
    ProductionProcessComplianceEntryPojo
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../../services/helper.service";
import {PlatformSelectorService} from "../../../../services/platform-selector.service";

@Component({
    selector: 'app-compliance-workflow-tracker-dialog',
    templateUrl: './compliance-workflow-tracker-dialog.component.html',
    styleUrls: ['./compliance-workflow-tracker-dialog.component.css']
})
export class ComplianceWorkflowTrackerDialogComponent implements OnInit {

    @Input() 
    isProductionUnit:boolean

    title: string;

    complianceId: number

    complianceStages: Array<ProductionProcessComplianceEntryPojo>;

    processCompliancePojo: ProductionProcessComplianceEntryPojo;

    constructor(public dialogRef: MatDialogRef<ComplianceWorkflowTrackerDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private processComplianceService: ProductionProcessComplianceControllerService,
                protected helperService: HelperService,
                private platformSelector: PlatformSelectorService) {

        this.complianceId = this.data?.complianceId;
        this.processCompliancePojo = this.data?.compliancePojo;
        
        this.isProductionUnit = this.data?.isProductionUnit;

        if (!this.complianceId || !this.processCompliancePojo) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }



        this.title = this.data?.modal?.title
    }

    ngOnInit(): void {
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getAlertMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////


}
