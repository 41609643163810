/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TicketRatingDto { 
    ticketId: number;
    note?: string;
    score?: number;
    statusConstant?: TicketRatingDto.StatusConstantEnum;
}
export namespace TicketRatingDto {
    export type StatusConstantEnum = 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    export const StatusConstantEnum = {
        Logged: 'LOGGED' as StatusConstantEnum,
        Identified: 'IDENTIFIED' as StatusConstantEnum,
        Classified: 'CLASSIFIED' as StatusConstantEnum,
        Assigned: 'ASSIGNED' as StatusConstantEnum,
        Resolving: 'RESOLVING' as StatusConstantEnum,
        Cancelled: 'CANCELLED' as StatusConstantEnum,
        Resolved: 'RESOLVED' as StatusConstantEnum,
        Fulfilled: 'FULFILLED' as StatusConstantEnum,
        Closed: 'CLOSED' as StatusConstantEnum
    };
}


