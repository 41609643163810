/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { PlatformPojo } from './platform-pojo';
import { SoftwareDeploymentEnvironmentPojo } from './software-deployment-environment-pojo';


export interface SoftwareModulePojo { 
    id?: number;
    name?: string;
    identifier?: string;
    identifierDisplayName?: string;
    platform?: PlatformPojo;
    deploymentEnvironment?: SoftwareDeploymentEnvironmentPojo;
    definitionStatus?: SoftwareModulePojo.DefinitionStatusEnum;
    customerValuePojo?: NameIdPojo;
    createdAt?: string;
    description?: string;
    totalTestCases?: number;
    passedTestCases?: number;
    completionStatus?: SoftwareModulePojo.CompletionStatusEnum;
}
export namespace SoftwareModulePojo {
    export type DefinitionStatusEnum = 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    export const DefinitionStatusEnum = {
        Defined: 'DEFINED' as DefinitionStatusEnum,
        PartiallyDefined: 'PARTIALLY_DEFINED' as DefinitionStatusEnum,
        NotDefined: 'NOT_DEFINED' as DefinitionStatusEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        Completed: 'COMPLETED' as CompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CompletionStatusEnum
    };
}


