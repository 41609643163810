/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcessPojo } from './process-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface FinancialCostPojo { 
    id?: number;
    amount?: number;
    playerDailyRate?: number;
    gainLossConstant?: FinancialCostPojo.GainLossConstantEnum;
    iso4217CurrencyCode?: string;
    comment?: string;
    breachCostPercentage?: number;
    effectiveHours?: number;
    reviewStatus?: FinancialCostPojo.ReviewStatusEnum;
    processBreachCategory?: ProcessPojo;
    player?: PortalUserPojo;
    reviewedBy?: PortalUserPojo;
    effectiveDate?: string;
    identifier?: string;
    totalDeduction?: number;
    totalAddition?: number;
}
export namespace FinancialCostPojo {
    export type GainLossConstantEnum = 'GAIN' | 'LOSS';
    export const GainLossConstantEnum = {
        Gain: 'GAIN' as GainLossConstantEnum,
        Loss: 'LOSS' as GainLossConstantEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


