/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';
import { Wig } from './wig';
import { ProductionProcessComplianceEntry } from './production-process-compliance-entry';
import { Sprint } from './sprint';
import { PortalUser } from './portal-user';


export interface UserAcceptanceTest { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: UserAcceptanceTest.StatusEnum;
    name?: string;
    requestStatus?: UserAcceptanceTest.RequestStatusEnum;
    comment?: string;
    reviewedAt?: string;
    identifier?: string;
    statusUpdatedAt?: string;
    accessDetails?: string;
    plannedTime?: string;
    uatComplianceEntry?: ProductionProcessComplianceEntry;
    authorizedBy?: PortalUser;
    platform?: Platform;
    wig?: Wig;
    monitoringComplianceEntry?: ProductionProcessComplianceEntry;
    sprint?: Sprint;
}
export namespace UserAcceptanceTest {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type RequestStatusEnum = 'PENDING' | 'FAILED' | 'PASSED' | 'NOTIFIED';
    export const RequestStatusEnum = {
        Pending: 'PENDING' as RequestStatusEnum,
        Failed: 'FAILED' as RequestStatusEnum,
        Passed: 'PASSED' as RequestStatusEnum,
        Notified: 'NOTIFIED' as RequestStatusEnum
    };
}


