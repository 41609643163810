import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";
import {User} from "../../../models/user/user.model";
import {environment} from "../../../../environments/environment";
import {EntryPlayer} from "../../../../../sdk/customer-fulfillment-api-sdk";

@Component({
  selector: 'app-user-card-header',
  templateUrl: './user-card-header.component.html',
  styleUrls: ['./user-card-header.component.css']
})
export class UserCardHeaderComponent implements OnInit {

  @Input()
  isApproval= false;

  @Input()
  user: User | EntryPlayer;

  @Input()
  action = 'Requested';

  @Input()
  playerLabel = 'Requested By'

  constructor(private authenticationService: AuthenticationService) {

  }
  ngOnInit(): void {
    if (!this.user) {
    this.authenticationService.getUser().subscribe(v => {
      this.user = v;
    });
    }

  }

  getFileUrl(id: number) {
    if (id != null) {
      return environment.siteUrl + "/files/" + id;
    }
    return "./assets/img/user_avatar.png";
  }
}
