/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';


export interface CIRequestStatusUpdateDto { 
    ciRequestLifecycleStatus: CIRequestStatusUpdateDto.CiRequestLifecycleStatusEnum;
    dataDictionaryDto?: AttachmentDto;
    ciRequestId: number;
}
export namespace CIRequestStatusUpdateDto {
    export type CiRequestLifecycleStatusEnum = 'LOGGED' | 'ACTIVATED' | 'ASSIGNED' | 'RESOLVED' | 'ACCEPTED';
    export const CiRequestLifecycleStatusEnum = {
        Logged: 'LOGGED' as CiRequestLifecycleStatusEnum,
        Activated: 'ACTIVATED' as CiRequestLifecycleStatusEnum,
        Assigned: 'ASSIGNED' as CiRequestLifecycleStatusEnum,
        Resolved: 'RESOLVED' as CiRequestLifecycleStatusEnum,
        Accepted: 'ACCEPTED' as CiRequestLifecycleStatusEnum
    };
}


