<!--<div mat-dialog-title>-->
<!--  <div class="d-flex justify-content-between align-items-center w-100">-->
<!--    <div>-->
<!--&lt;!&ndash;      {{ data?.modal?.title }}&ndash;&gt;-->
<!--    </div>-->
<!--    <button mat-icon-button-->
<!--            (click)="dialogRef.close()"-->
<!--    >-->
<!--      <mat-icon-->
<!--        class="text-dark cursor-pointer"-->
<!--      >close-->
<!--      </mat-icon>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->
<!--    <div class="modal-header">
      <div class="modal-title">
        <p>View Tickets</p>
      </div>
    </div>-->

<mat-dialog-content>
  <div class="pt-5">
    <div class="modal-body">
      <p class="font-500 mb-5 text-center">
        Input email or ticket ID to view Ticket
      </p>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="form">
          <div class="form-wrapper">
            <input type="text"
                   placeholder="Enter email or Ticket ID"
                   formControlName="email"
            />
          </div>
          <error-message
            [controlName]="'email'"
            [singularError]="true"
            [form]="form">
          </error-message>
        </div>

        <div class="text-center">
          <button
            [disabled]="submitting"
            type="submit"
            class="js-btn js-btn-primary ms-3 "
          >
            <span class="me-2">Submit </span>
            <app-loading *ngIf="submitting"></app-loading>
          </button>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
