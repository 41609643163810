/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SoftwareModuleTestCaseStatusUpdateDto { 
    checkStatus: SoftwareModuleTestCaseStatusUpdateDto.CheckStatusEnum;
    softwareModuleTestCaseId: number;
    softwareModuleId: number;
    comment?: string;
}
export namespace SoftwareModuleTestCaseStatusUpdateDto {
    export type CheckStatusEnum = 'OK' | 'NOT_OK' | 'NOT_CHECKED';
    export const CheckStatusEnum = {
        Ok: 'OK' as CheckStatusEnum,
        NotOk: 'NOT_OK' as CheckStatusEnum,
        NotChecked: 'NOT_CHECKED' as CheckStatusEnum
    };
}


