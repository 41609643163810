/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MRPUatPojo } from './mrp-uat-pojo';
import { NameIdPojo } from './name-id-pojo';


export interface MRPPojo { 
    sprintId?: number;
    sprintName?: string;
    sprintStartTime?: string;
    sprintEndTime?: string;
    sprintIdentifierDisplayName?: string;
    uats?: Array<MRPUatPojo>;
    wig?: NameIdPojo;
}

