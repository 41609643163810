import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'bulk-upload-result-dialog',
    templateUrl: './bulk-upload-result-dialog.component.html',
    styleUrls: ['./bulk-upload-result-dialog.component.scss']
})
export class BulkUploadResultDialogComponent {
    continue: EventEmitter<any> = new EventEmitter<any>();

    preview = new EventEmitter<any>();

    downloadErrorSheet: EventEmitter<any> = new EventEmitter<any>();

    submit = false;

    @Input() result: any;

    showErrorMessageTrigger: boolean;

    errorMessage: string;

    submitting = false;

    showErrorMessage(error): void {
        this.errorMessage = error;
        this.showErrorMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showErrorMessageTrigger = false;
        }, 5000);
    }

    constructor(private bsModalRef: BsModalRef) {
    }

    close(): void {
        this.bsModalRef.hide();
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }
}
