import {Component} from '@angular/core';
import {HelperService} from "../../../../../../services/helper.service";
import {
    PlatformSwitcherComponent
} from "../../../../../../shared/components/dialogs/platform-switcher/platform-switcher.component";
import {PlatformSelectorService} from "../../../../../../services/platform-selector.service";
import {combineLatestWith, Observable, of, startWith, switchMap} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {PlatformControllerService, PlatformPojo} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";
import {UnsubscribeOnDestroyAdapter} from "../../../../../../services/unsubscribe-on-destroy-adapter";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-platform-switcher-button',
    templateUrl: './platform-switcher-button.component.html',
    styleUrls: ['./platform-switcher-button.component.css']
})
export class PlatformSwitcherButtonComponent extends UnsubscribeOnDestroyAdapter {

    currentPlatform: PlatformPojo
    loadingPlatform = false;

    constructor(public helperService: HelperService,
                public platformSelectorService: PlatformSelectorService,
                private productService: PlatformControllerService,
                private activatedRoute: ActivatedRoute) {

        super();

        const apiCall = this.productService.searchPlatforms({limit: 1000})
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
        this.subscriptions.sink = this.platformSelectorService.currentPlatform.pipe(
            switchMap(selected => {
                return apiCall.pipe(
                    map((result) => result.find((x): boolean => x.id == selected)))
            }),
            tap(p => {
                this.currentPlatform = p;
            })
        ).subscribe();

    }

    get logo(): any {
        if (this.currentPlatform?.logo) {
            return this.helperService.getFileUrl(this.currentPlatform?.logo?.id, true);
        } else {
            return 'assets/img/logo.png';
        }
    }

    switchPlatform(): void {
        this.platformSelectorService.switchPlatform();
    }
}
