import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {PageManager} from "./page-manager";
import {PlatformSwitcherComponent} from "../shared/components/dialogs/platform-switcher/platform-switcher.component";
import {HelperService} from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class PlatformSelectorService {

    private platform$: BehaviorSubject<number> = new BehaviorSubject<number>(this.getLastPlatform());

    constructor(private pageManager: PageManager,
                private helperService: HelperService) {
    }

    get currentPlatform(): Observable<number> {
        return this.platform$.asObservable();
    }

    set currentPlatform(platformId: number) {
        if (platformId) {
            this.platform$.next(platformId);
            this.pageManager.storeData('PLATFORM', 'CURRENT_PLATFORM_ID', platformId);

        } else {
            this.platform$.next(this.getLastPlatform());
        }

    }

    private getLastPlatform(): number | undefined {
        const lastPlatform = this.pageManager.getData('PLATFORM', 'CURRENT_PLATFORM_ID') as string;
        return lastPlatform ? parseInt(lastPlatform, 10) : undefined;
    }

    switchPlatform(): void {
        this.helperService.loadModal(PlatformSwitcherComponent, '', '',
            {},
            '1200px');
    }
}
