/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MarkAcceptanceCriteriaDto { 
    ids?: Array<number>;
    checkStatusConstant?: MarkAcceptanceCriteriaDto.CheckStatusConstantEnum;
}
export namespace MarkAcceptanceCriteriaDto {
    export type CheckStatusConstantEnum = 'OK' | 'NOT_OK' | 'NOT_CHECKED';
    export const CheckStatusConstantEnum = {
        Ok: 'OK' as CheckStatusConstantEnum,
        NotOk: 'NOT_OK' as CheckStatusConstantEnum,
        NotChecked: 'NOT_CHECKED' as CheckStatusConstantEnum
    };
}


