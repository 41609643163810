<div class="app">

  <header *ngIf="showPageHeader">
    <app-extranet-header ></app-extranet-header>
  </header>

  <div class="view">
    <router-outlet></router-outlet>
  </div>

  <footer *ngIf="showPageFooter">
  </footer>

</div>



