/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { ProcessModelGroup } from './process-model-group';
import { PortalUser } from './portal-user';


export interface ProcessModel { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: ProcessModel.StatusEnum;
    reviewStatus?: ProcessModel.ReviewStatusEnum;
    reasonForReview?: string;
    reviewedAt?: string;
    reviewer?: PortalUser;
    name?: string;
    code?: string;
    identifier?: string;
    lastUpdatedAt?: string;
    portalAccount?: PortalAccount;
    modelGroup?: ProcessModelGroup;
    lastUpdatedBy?: PortalUser;
}
export namespace ProcessModel {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


