/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TicketLifecyclePojo { 
    lifecycleStatus?: TicketLifecyclePojo.LifecycleStatusEnum;
    statusChangeTime?: string;
    actorName?: string;
    actorUsername?: string;
    name?: string;
}
export namespace TicketLifecyclePojo {
    export type LifecycleStatusEnum = 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    export const LifecycleStatusEnum = {
        Logged: 'LOGGED' as LifecycleStatusEnum,
        Identified: 'IDENTIFIED' as LifecycleStatusEnum,
        Classified: 'CLASSIFIED' as LifecycleStatusEnum,
        Assigned: 'ASSIGNED' as LifecycleStatusEnum,
        Resolving: 'RESOLVING' as LifecycleStatusEnum,
        Cancelled: 'CANCELLED' as LifecycleStatusEnum,
        Resolved: 'RESOLVED' as LifecycleStatusEnum,
        Fulfilled: 'FULFILLED' as LifecycleStatusEnum,
        Closed: 'CLOSED' as LifecycleStatusEnum
    };
}


