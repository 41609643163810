
<div class="header">
  <div class="main-header">
    <nav class="navbar navbar-expand-lg primary-bg">
      <div class="container">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>


        <li class="collapse navbar-collapse" id="navbarTogglerDemo01">
          <a class="navbar-brand fw-bold text-light" href="/"> <img
            src="assets/img/logo.svg" alt=""></a>


          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <ng-container *ngIf="!user">
              <li class="nav-list-item">
                <button (click)="login()" class="js-btn js-btn-light-outline pointer mx-2 btn-md border-2">
                  Login
                </button>
              </li>
            </ng-container>

            <ng-container *ngIf="user">
              <li class="nav-list-item">
                <button (click)="goToDashboard()" class="js-btn js-btn-light-outline pointer mx-2 btn-md border-2">
                  Dashboard
                </button>
              </li>
              <li class="nav-list-item">
                <button (click)="logout()" class="js-btn js-btn-light-outline pointer mx-2 btn-md border-2">
                  Logout
                </button>
              </li>
            </ng-container>

            <li class="nav-list-item">
              <button (click)="viewTicket()" class="js-btn js-btn-light-outline pointer mx-2 btn-md border-2"
                      type="button">
                View Tickets
              </button>
            </li>

            <li class="nav-list-item">
              <button (click)="logTicket()" class="js-btn js-btn-light-outline pointer mx-2 btn-md border-2"
                      type="button">
                Report an Issue
              </button>
            </li>

          </ul>
      </div>
    </nav>
  </div>
</div>
