/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';


export interface CIRequestUpdateDto { 
    businessRequirementIds?: Array<number>;
    attachmentDtos?: Array<AttachmentDto>;
    ciRequestId: number;
    updateType: CIRequestUpdateDto.UpdateTypeEnum;
}
export namespace CIRequestUpdateDto {
    export type UpdateTypeEnum = 'REQUIREMENT' | 'ATTACHMENT';
    export const UpdateTypeEnum = {
        Requirement: 'REQUIREMENT' as UpdateTypeEnum,
        Attachment: 'ATTACHMENT' as UpdateTypeEnum
    };
}


