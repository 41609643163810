import { Component } from '@angular/core';

@Component({
  selector: 'app-master-records',
  templateUrl: './master-records.component.html',
  styleUrls: ['./master-records.component.css']
})
export class MasterRecordsComponent {

}
