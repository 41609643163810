import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchHandler} from "../../../../../../shared/search/search-handler";
import {
    KeyResultControllerService, KeyResultDto,
    KeyResultPojo, KeyResultStatusUpdateDto, QueryResultsKeyResultPojo,
    SearchKeyResultRequestParams
} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../../../../../shared/search/search-filter-source";
import {FormBuilder, Validators} from "@angular/forms";
import {AlertType} from "../../../../../extranet/report-issue/report-issue.component";
import {SearchManager} from "../../../../../../shared/search/search-manager";
import {HelperService} from "../../../../../../services/helper.service";
import {PlatformSelectorService} from "../../../../../../services/platform-selector.service";
import {PageManager} from "../../../../../../services/page-manager";
import {
    CreateUatKeyResultDialogComponent
} from "../create-uat-key-result-dialog/create-uat-key-result-dialog.component";
import {NameValuePair} from "../../../../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../../../../shared/search/paginated-search";
import {Observable} from "rxjs";

@Component({
    selector: 'app-uat-key-result-update-status-search',
    templateUrl: './uat-key-result-update-status-search.component.html',
    styleUrls: ['./uat-key-result-update-status-search.component.css']
})
export class UatKeyResultUpdateStatusSearchComponent implements OnInit,
    SearchHandler<KeyResultPojo, SearchKeyResultRequestParams>,
    SearchFilterSource<SearchKeyResultRequestParams> {

    form = this.fb.group({
        keyword: [''],
        platformId: ['', Validators.required],
        userAcceptanceId: [undefined as number, Validators.required]
    })

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    @Input()
    uatRequestId: number

    @Input()
    showAddButton = true;

    @Input()
    showKeywordControl = true;

    maxPageSize = 5;

    searchManager!: SearchManager<KeyResultPojo, SearchKeyResultRequestParams>;

    constructor(private fb: FormBuilder,
                public helperService: HelperService,
                private platformSelectorService: PlatformSelectorService,
                private pageManager: PageManager,
                private keyResultService: KeyResultControllerService) {
        this.searchManager = new SearchManager<KeyResultPojo, SearchKeyResultRequestParams>(this, this)
    }

    ngOnInit() {
        this.updateFormData();
        this.initListeners();
    }

    updateFormData(): void {

        if (this.uatRequestId) {
            this.form.patchValue({
                userAcceptanceId: this.uatRequestId
            })
        }
    }

    initListeners(): void {
        this.platformSelectorService.currentPlatform.subscribe(value => {
            if (value) {
                this.form.patchValue({
                    platformId: value.toString()
                })
                this.submit();
            }
        })
    }

    addKeyResult() {
        this.loadKeyResultModal();
    }

    loadKeyResultModal(): void {
        this.helperService.loadModal(CreateUatKeyResultDialogComponent, 'Add Key Result', '',
            {
                uatRequestId: this.uatRequestId
            },
            '500px')
            .afterClosed()
            .subscribe((result) => {
                if (result && result == true) {
                    // this.showAlertMessage.emit({msg: 'Key Result Added!', type: AlertType.success});
                    this.submit();
                }
            })
    }

    deleteKeyResult(id: number) {
        this.keyResultService.deleteKeyResult({id: id})
            .subscribe({
                complete: () => {
                    this.showAlertMessage.emit({msg: 'Key Result Deleted successfully', type: AlertType.success})
                    this.submit();
                },
                error: (error: unknown) => {
                    this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error})
                }
            })
    }

    getFilter(): SearchKeyResultRequestParams {
        return Object.assign({...this.form.getRawValue()});
    }

    getPersistentKey(): string {
        return 'UatKeyResultSearchComponent';
    }

    getSearchDescriptor(e: SearchKeyResultRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    updateSearchQuery(): void {
        this.pageManager.storeData(this.getPersistentKey(), 'search', this.form.value);
    }

    submit(): void {
        if (!this.form?.valid) {
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    private loadSearchData(): void {
        const data = this.pageManager.getData(this.getPersistentKey(), 'search');

        const cleanData = PaginatedSearch.removeNullFields(data);

        if (cleanData) {
            this.form.patchValue(cleanData);
        }
    }

    search(page: number | undefined, filter?: SearchKeyResultRequestParams): Observable<QueryResultsKeyResultPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.keyResultService.searchKeyResult({
            ...paginatedSearch.getSearchParams(), platformId: Number(this.form.controls.platformId.value)
        });
    }

    markAsSatisfactory(keyResult: KeyResultPojo): void {

        const payload: KeyResultStatusUpdateDto = {
            checkStatus: 'OK',
            comment: keyResult?.comment,
            keyResultId: keyResult?.id,
        }
        this.makeUpdateKeyResultStatusAPICall([payload]);
    }

    markAsUnsatisfactory(keyResult: KeyResultPojo): void {

        const payload: KeyResultStatusUpdateDto = {
            checkStatus: 'NOT_OK',
            comment: keyResult?.comment,
            keyResultId: keyResult?.id,
        }
        this.makeUpdateKeyResultStatusAPICall([payload]);
    }

    makeUpdateKeyResultStatusAPICall(payload: Array<KeyResultStatusUpdateDto>) {
        this.keyResultService.updateKeyResultStatus({keyResultStatusUpdateDto: payload})
            .subscribe({
                complete: () => {
                    this.showAlertMessage.emit({msg: 'Key Result Updated', type: AlertType.success});
                    this.submit();
                },
                error: (error: unknown) => {
                    this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error})
                }
            })
    }

}
