<div class="d-flex gap-3 align-items-center flex-wrap">
    <div *ngIf="label" class="fw-semibold mb-0 text-dull">{{ label }}</div>
    <div class="d-flex gap-2 align-items-center min-width">
        <div *ngIf="showDays" class="text-end times">
            <p class="mb-0 fw-bold" [ngClass]="{'text-danger':  (time | countdown:'isNegative' | async) == 'true',
                             'text-primary': (time | countdown:'isNegative' | async) == 'false'}">{{ time | countdown : 'days'  | async }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Day(s)</p>
        </div>
        <div class="text-end times">
            <p class="mb-0 text-muted fw-bold">{{ time | countdown : 'hours' | async }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Hr</p>
        </div>
        <div>
            <span class="fw-bold">:</span>
        </div>
        <div class=" text-end times">
            <p class="mb-0 text-muted fw-bold">{{ time | countdown : 'minutes' | async }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Min</p>
        </div>

        <div>
            <span class="fw-bold">:</span>
        </div>
        <div class=" text-end times">
            <p class="mb-0 text-muted fw-bold">{{ time | countdown : 'seconds'  | async }}{{ 's' }}</p>
            <p *ngIf="showUnitLabel" class="mb-0 fs-12 opacity-75">Sec</p>
        </div>
    </div>
</div>
