<div class="primary-bg py-4">
  <div class="container py-4">
    <div class="row">
      <div class="d-flex justify-content-between">
        <div>
          <p class="text-light mb-0 small fw-normal opacity-50">
            © 2023 Copyright Byteworks Technology Solutions Ltd
          </p>
        </div>

        <div>
          <ul class="d-flex">
            <li class="opacity-50 fw-normal small mx-md-2">
              <a href="" class="text-decoration-none small text-light">
                Terms and Conditions
              </a>
            </li>

            <li class="opacity-50 fw-normal small mx-md-2">
              <a href="" class="text-decoration-none small text-light">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
