/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { NameCodePojo } from '../model/models';
import { ProductionProcessStagePojo } from '../model/models';
import { QueryResultsProductionProcessStagePojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface FetchProcessStageByProcessRequestParams {
    processId: number;
}

export interface FetchUpdatableProductionProcessStageRequestParams {
    platformId: number;
}

export interface SearchProcessStageByProcessRequestParams {
    identifier?: string;
    process?: string;
    code?: string;
    mandatoryStage?: boolean;
    attestationRequiredForReleaseCompliance?: boolean;
    supportingDocRequiredForUpdate?: boolean;
    generatesOutputFile?: boolean;
    decompositionRequiredForUpdate?: boolean;
    directUpdateSupported?: boolean;
    nextStage?: string;
    shortDisplayName?: string;
    portalAccount?: string;
    createdAt?: string;
    previousStage?: string;
    createdBy?: string;
    processId?: number;
    processCode?: string;
    name?: string;
    attestationRequiredForCICompliance?: boolean;
    stageNumber?: number;
    entryCanBeDeleted?: boolean;
    id?: number;
    autoRequestNextStageOnUpdate?: boolean;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    limit?: number;
    productionUnitTypeCodes?: Array<object>;
    keyword?: string;
    priorityItems?: Array<object>;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class ProductionProcessStageControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get All Process Stages By Process
     * Get All Process Stages By Process ID and Portal Account
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchProcessStageByProcess(requestParameters: FetchProcessStageByProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameCodePojo>>;
    public fetchProcessStageByProcess(requestParameters: FetchProcessStageByProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameCodePojo>>>;
    public fetchProcessStageByProcess(requestParameters: FetchProcessStageByProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameCodePojo>>>;
    public fetchProcessStageByProcess(requestParameters: FetchProcessStageByProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const processId = requestParameters.processId;
        if (processId === null || processId === undefined) {
            throw new Error('Required parameter processId was null or undefined when calling fetchProcessStageByProcess.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NameCodePojo>>(`${this.configuration.basePath}/production-process-stage/process/${encodeURIComponent(String(processId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Valid Production Process Stages
     * Get All Production Process Stages Valid for Use in Updating Process Compliance for a Platform
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchUpdatableProductionProcessStage(requestParameters: FetchUpdatableProductionProcessStageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ProductionProcessStagePojo>>;
    public fetchUpdatableProductionProcessStage(requestParameters: FetchUpdatableProductionProcessStageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ProductionProcessStagePojo>>>;
    public fetchUpdatableProductionProcessStage(requestParameters: FetchUpdatableProductionProcessStageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ProductionProcessStagePojo>>>;
    public fetchUpdatableProductionProcessStage(requestParameters: FetchUpdatableProductionProcessStageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling fetchUpdatableProductionProcessStage.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProductionProcessStagePojo>>(`${this.configuration.basePath}/production-process-stage/${encodeURIComponent(String(platformId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Process Stages By Process
     * Search Process Stages By Process ID and Portal Account
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchProcessStageByProcess(requestParameters: SearchProcessStageByProcessRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsProductionProcessStagePojo>;
    public searchProcessStageByProcess(requestParameters: SearchProcessStageByProcessRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsProductionProcessStagePojo>>;
    public searchProcessStageByProcess(requestParameters: SearchProcessStageByProcessRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsProductionProcessStagePojo>>;
    public searchProcessStageByProcess(requestParameters: SearchProcessStageByProcessRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const process = requestParameters.process;
        const code = requestParameters.code;
        const mandatoryStage = requestParameters.mandatoryStage;
        const attestationRequiredForReleaseCompliance = requestParameters.attestationRequiredForReleaseCompliance;
        const supportingDocRequiredForUpdate = requestParameters.supportingDocRequiredForUpdate;
        const generatesOutputFile = requestParameters.generatesOutputFile;
        const decompositionRequiredForUpdate = requestParameters.decompositionRequiredForUpdate;
        const directUpdateSupported = requestParameters.directUpdateSupported;
        const nextStage = requestParameters.nextStage;
        const shortDisplayName = requestParameters.shortDisplayName;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const previousStage = requestParameters.previousStage;
        const createdBy = requestParameters.createdBy;
        const processId = requestParameters.processId;
        const processCode = requestParameters.processCode;
        const name = requestParameters.name;
        const attestationRequiredForCICompliance = requestParameters.attestationRequiredForCICompliance;
        const stageNumber = requestParameters.stageNumber;
        const entryCanBeDeleted = requestParameters.entryCanBeDeleted;
        const id = requestParameters.id;
        const autoRequestNextStageOnUpdate = requestParameters.autoRequestNextStageOnUpdate;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const productionUnitTypeCodes = requestParameters.productionUnitTypeCodes;
        const keyword = requestParameters.keyword;
        const priorityItems = requestParameters.priorityItems;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (process !== undefined && process !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>process, 'process');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'code');
        }
        if (mandatoryStage !== undefined && mandatoryStage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mandatoryStage, 'mandatoryStage');
        }
        if (attestationRequiredForReleaseCompliance !== undefined && attestationRequiredForReleaseCompliance !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>attestationRequiredForReleaseCompliance, 'attestationRequiredForReleaseCompliance');
        }
        if (supportingDocRequiredForUpdate !== undefined && supportingDocRequiredForUpdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>supportingDocRequiredForUpdate, 'supportingDocRequiredForUpdate');
        }
        if (generatesOutputFile !== undefined && generatesOutputFile !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>generatesOutputFile, 'generatesOutputFile');
        }
        if (decompositionRequiredForUpdate !== undefined && decompositionRequiredForUpdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>decompositionRequiredForUpdate, 'decompositionRequiredForUpdate');
        }
        if (directUpdateSupported !== undefined && directUpdateSupported !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>directUpdateSupported, 'directUpdateSupported');
        }
        if (nextStage !== undefined && nextStage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nextStage, 'nextStage');
        }
        if (shortDisplayName !== undefined && shortDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shortDisplayName, 'shortDisplayName');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (previousStage !== undefined && previousStage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>previousStage, 'previousStage');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (processId !== undefined && processId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processId, 'processId');
        }
        if (processCode !== undefined && processCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processCode, 'processCode');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (attestationRequiredForCICompliance !== undefined && attestationRequiredForCICompliance !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>attestationRequiredForCICompliance, 'attestationRequiredForCICompliance');
        }
        if (stageNumber !== undefined && stageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stageNumber, 'stageNumber');
        }
        if (entryCanBeDeleted !== undefined && entryCanBeDeleted !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>entryCanBeDeleted, 'entryCanBeDeleted');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (autoRequestNextStageOnUpdate !== undefined && autoRequestNextStageOnUpdate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>autoRequestNextStageOnUpdate, 'autoRequestNextStageOnUpdate');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (productionUnitTypeCodes) {
            productionUnitTypeCodes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'productionUnitTypeCodes');
            })
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsProductionProcessStagePojo>(`${this.configuration.basePath}/production-process-stage`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
