<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title>
        {{ title }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>
</div>

<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getAlertMessage() }}
</div>

<mat-dialog-content class="p-4">
    <ng-container *ngIf="!isProductionUnit">
        <div class="mb-4">
            <label for="">Production Unit</label>
            <select name="" id="" class="form-control form-select">
                <option value="">Select Production Unit</option>
                <option value="">Create Production Unit- UIC</option>
                <option value="">Manage Production Unit -API</option>
                <option value="">com.bw.cfs.services.SoftwareProductionUn...</option>
            </select>
        </div>
        
        <div class="divider"></div>

    </ng-container>

    <div class="card mb-4">
        <div class="card-body" style="background-color: rgba(24, 29, 142, 0.06) !important;">
            <div class="container">
                <div class="row">
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">PU Code</div>
                        <div class="fs-12">PUC-2423</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Module</div>
                        <div class="fs-12">Master Record</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Reset Count</div>
                        <div class="fs-12">-</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Production Unit Name</div>
                        <div class="fs-12">Create Production Unit</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Production Unit Type</div>
                        <div class="fs-12">UI Component</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-compliance-workflow-tracker [processCompliancePojo]="processCompliancePojo"></app-compliance-workflow-tracker>
</mat-dialog-content>

<mat-dialog-actions>
</mat-dialog-actions>
