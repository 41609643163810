/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlayerBreachStatisticsPojo } from './player-breach-statistics-pojo';
import { PlayerProcessStatisticsPojo } from './player-process-statistics-pojo';
import { PlayerProductionUnitStatisticsPojo } from './player-production-unit-statistics-pojo';
import { PlayerBlockerStatisticsPojo } from './player-blocker-statistics-pojo';
import { PlayerPerformanceStatisticsPojo } from './player-performance-statistics-pojo';
import { PlayerCadenceStatisticsPojo } from './player-cadence-statistics-pojo';
import { PlayerProductivityStatisticsPojo } from './player-productivity-statistics-pojo';
import { PlayerTicketStatisticsPojo } from './player-ticket-statistics-pojo';
import { PlayerCommitmentStatisticsPojo } from './player-commitment-statistics-pojo';
import { PlayerDelegationStatisticsPojo } from './player-delegation-statistics-pojo';
import { PlayerGEMBAAttendanceStatisticsPojo } from './player-gemba-attendance-statistics-pojo';


export interface PlayerStatisticsPojo { 
    id?: number;
    playerBlockerStatistics?: PlayerBlockerStatisticsPojo;
    playerBreachStatistics?: PlayerBreachStatisticsPojo;
    playerCommitmentStatistics?: PlayerCommitmentStatisticsPojo;
    playerGEMBAAttendanceStatisticsPojo?: PlayerGEMBAAttendanceStatisticsPojo;
    playerProductionUnitStatistics?: PlayerProductionUnitStatisticsPojo;
    playerTicketStatistics?: PlayerTicketStatisticsPojo;
    playerDelegationStatistics?: PlayerDelegationStatisticsPojo;
    playerCadenceStatistics?: PlayerCadenceStatisticsPojo;
    playerProductivityStatistics?: PlayerProductivityStatisticsPojo;
    playerProcessStatistics?: PlayerProcessStatisticsPojo;
    playerPerformanceStatistics?: PlayerPerformanceStatisticsPojo;
}

