import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {takeUntil, timer} from "rxjs";

@Directive({
    selector: '[appProgressFill]',
    exportAs: 'appProgressFill'
})
export class ProgressFillDirective implements OnChanges {

    @Input()
    fillDuration: number;

    @Input()
    startFill: boolean;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['startFill'] && (changes['startFill'].currentValue != changes['startFill'].previousValue)) {
            // this.startProgressFill();
        }
    }

    private startProgressFill() {
        const button = this.el.nativeElement;
        timer(0, 100).pipe(
            takeUntil(timer(this.fillDuration))
        )
            .subscribe(elapsed => {
                const progress = (elapsed * 100) / (this.fillDuration / 100);
                this.renderer.setStyle(button, 'background', `linear-gradient(to right, #000 0%, #000 ${progress}%, #ccc ${progress}%)`);
            })
            .add(
                () =>
                    this.renderer.setStyle(button, 'background', '')
            );
    }

    triggerFiller(): void {
        this.startProgressFill();
    }
}
