<div class="modal-content">
  <div class="modal-header rounded-0">
    <h1 class="modal-title fs-5 text-dark" id="messageModalLabel">{{title}}
      <span data-icon="icon-park-outline:message-unread" style="color: #fff;" width="25" height="25"></span>
    </h1>
    <button type="button" class="btn-close" (click)="dismissModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
      <div class="mb-3">
          <div id="sender" role="alert">
              <main class="wrap mb-3">
                  <div class="mt-2">
                      <div class="ms-2 ">
                          <p class="col-auto bg-white rounded-3" [innerText]="this.notes || ''"></p>
                      </div>
                  </div>
              </main>
          </div>
      </div>
  </div>
</div>

