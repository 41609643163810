/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BusinessRequirementCustomerValueMapperDto } from '../model/models';
import { BusinessRequirementDto } from '../model/models';
import { BusinessRequirementPojo } from '../model/models';
import { BusinessRequirementSoftwareModuleMapperDto } from '../model/models';
import { QueryResultsBusinessRequirementDocumentPojo } from '../model/models';
import { QueryResultsBusinessRequirementPojo } from '../model/models';
import { QueryResultsSectionGroupRequirementPojo } from '../model/models';
import { VersionPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateBusinessRequirementRequestParams {
    businessRequirementDto: BusinessRequirementDto;
}

export interface DeleteBusinessRequirementRequestParams {
    id: number;
}

export interface DeleteBusinessRequirementToCustomerValueMappingRequestParams {
    businessRequirementCustomerValueMapperDto: Array<BusinessRequirementCustomerValueMapperDto>;
}

export interface DeleteBusinessRequirementToSoftwareModuleMappingRequestParams {
    businessRequirementSoftwareModuleMapperDto: Array<BusinessRequirementSoftwareModuleMapperDto>;
}

export interface DownloadBrdRequestParams {
    platformId: number;
}

export interface GetAllBusinessRequirementRequestParams {
    platformId: number;
}

export interface MapBusinessRequirementToCustomerValueRequestParams {
    platformId: number;
    businessRequirementCustomerValueMapperDto: Array<BusinessRequirementCustomerValueMapperDto>;
}

export interface MapBusinessRequirementToSoftwareModuleRequestParams {
    platformId: number;
    businessRequirementSoftwareModuleMapperDto: Array<BusinessRequirementSoftwareModuleMapperDto>;
}

export interface SearchBusinessRequirementRequestParams {
    platformId: number;
    identifier?: string;
    lastUpdatedBy?: string;
    lastUpdatedAt?: string;
    revisionDate?: string;
    reviewedAt?: string;
    epic?: string;
    requirement?: string;
    reviewer?: string;
    version?: number;
    platform?: string;
    reasonForReview?: string;
    actor?: string;
    createdAt?: string;
    parentRequirement?: string;
    createdBy?: string;
    requirementNumDisplayName?: string;
    reviewStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    id?: number;
    businessRequirementSection?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    definitionStatus?: 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    createdByName?: string;
    customerValueId?: number;
    offset?: number;
    sectionId?: number;
    createdAfter?: string;
    priorityItems?: Array<object>;
    complianceEntryId?: number;
    shouldShowAll?: boolean;
    limit?: number;
    exclude?: Array<object>;
    createdBefore?: string;
    moduleId?: number;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchBusinessRequirementDocumentRequestParams {
    platformId: number;
    fileName?: string;
    approvedBy?: string;
    approvedAt?: string;
    version?: number;
    platform?: string;
    createdAt?: string;
    versionNumberDisplayName?: string;
    file?: string;
    createdBy?: string;
    requirementApprovalComplianceEntry?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    limit?: number;
    keyword?: string;
    priorityItems?: Array<object>;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchBusinessRequirementSectionGroupRequestParams {
    platformId: number;
    identifier?: string;
    lastUpdatedBy?: string;
    lastUpdatedAt?: string;
    revisionDate?: string;
    reviewedAt?: string;
    epic?: string;
    requirement?: string;
    reviewer?: string;
    version?: number;
    platform?: string;
    reasonForReview?: string;
    actor?: string;
    createdAt?: string;
    parentRequirement?: string;
    createdBy?: string;
    requirementNumDisplayName?: string;
    reviewStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    id?: number;
    businessRequirementSection?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    definitionStatus?: 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    createdByName?: string;
    customerValueId?: number;
    offset?: number;
    sectionId?: number;
    createdAfter?: string;
    priorityItems?: Array<object>;
    complianceEntryId?: number;
    shouldShowAll?: boolean;
    limit?: number;
    exclude?: Array<object>;
    createdBefore?: string;
    moduleId?: number;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface UpdateBusinessRequirementRequestParams {
    businessRequirementDto: BusinessRequirementDto;
}


@Injectable({
  providedIn: 'root'
})
export class BusinessRequirementControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBusinessRequirement(requestParameters: CreateBusinessRequirementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<BusinessRequirementPojo>;
    public createBusinessRequirement(requestParameters: CreateBusinessRequirementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<BusinessRequirementPojo>>;
    public createBusinessRequirement(requestParameters: CreateBusinessRequirementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<BusinessRequirementPojo>>;
    public createBusinessRequirement(requestParameters: CreateBusinessRequirementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const businessRequirementDto = requestParameters.businessRequirementDto;
        if (businessRequirementDto === null || businessRequirementDto === undefined) {
            throw new Error('Required parameter businessRequirementDto was null or undefined when calling createBusinessRequirement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<BusinessRequirementPojo>(`${this.configuration.basePath}/business-requirements`,
            businessRequirementDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBusinessRequirement(requestParameters: DeleteBusinessRequirementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteBusinessRequirement(requestParameters: DeleteBusinessRequirementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteBusinessRequirement(requestParameters: DeleteBusinessRequirementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteBusinessRequirement(requestParameters: DeleteBusinessRequirementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBusinessRequirement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/business-requirements/${encodeURIComponent(String(id))}/delete`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBusinessRequirementToCustomerValueMapping(requestParameters: DeleteBusinessRequirementToCustomerValueMappingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteBusinessRequirementToCustomerValueMapping(requestParameters: DeleteBusinessRequirementToCustomerValueMappingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteBusinessRequirementToCustomerValueMapping(requestParameters: DeleteBusinessRequirementToCustomerValueMappingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteBusinessRequirementToCustomerValueMapping(requestParameters: DeleteBusinessRequirementToCustomerValueMappingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const businessRequirementCustomerValueMapperDto = requestParameters.businessRequirementCustomerValueMapperDto;
        if (businessRequirementCustomerValueMapperDto === null || businessRequirementCustomerValueMapperDto === undefined) {
            throw new Error('Required parameter businessRequirementCustomerValueMapperDto was null or undefined when calling deleteBusinessRequirementToCustomerValueMapping.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/business-requirements/c5-mapping/remove`,
            businessRequirementCustomerValueMapperDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBusinessRequirementToSoftwareModuleMapping(requestParameters: DeleteBusinessRequirementToSoftwareModuleMappingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteBusinessRequirementToSoftwareModuleMapping(requestParameters: DeleteBusinessRequirementToSoftwareModuleMappingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteBusinessRequirementToSoftwareModuleMapping(requestParameters: DeleteBusinessRequirementToSoftwareModuleMappingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteBusinessRequirementToSoftwareModuleMapping(requestParameters: DeleteBusinessRequirementToSoftwareModuleMappingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const businessRequirementSoftwareModuleMapperDto = requestParameters.businessRequirementSoftwareModuleMapperDto;
        if (businessRequirementSoftwareModuleMapperDto === null || businessRequirementSoftwareModuleMapperDto === undefined) {
            throw new Error('Required parameter businessRequirementSoftwareModuleMapperDto was null or undefined when calling deleteBusinessRequirementToSoftwareModuleMapping.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/business-requirements/module-mapping/remove`,
            businessRequirementSoftwareModuleMapperDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadBrd(requestParameters: DownloadBrdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public downloadBrd(requestParameters: DownloadBrdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public downloadBrd(requestParameters: DownloadBrdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public downloadBrd(requestParameters: DownloadBrdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling downloadBrd.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/business-requirements/${encodeURIComponent(String(platformId))}/download-brd`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBusinessRequirement(requestParameters: GetAllBusinessRequirementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<BusinessRequirementPojo>>;
    public getAllBusinessRequirement(requestParameters: GetAllBusinessRequirementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<BusinessRequirementPojo>>>;
    public getAllBusinessRequirement(requestParameters: GetAllBusinessRequirementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<BusinessRequirementPojo>>>;
    public getAllBusinessRequirement(requestParameters: GetAllBusinessRequirementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling getAllBusinessRequirement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<BusinessRequirementPojo>>(`${this.configuration.basePath}/business-requirements/${encodeURIComponent(String(platformId))}/get-all`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBusinessRequirementDocumentByPlatform(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<VersionPojo>>;
    public getBusinessRequirementDocumentByPlatform(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<VersionPojo>>>;
    public getBusinessRequirementDocumentByPlatform(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<VersionPojo>>>;
    public getBusinessRequirementDocumentByPlatform(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<VersionPojo>>(`${this.configuration.basePath}/business-requirements/get-by-platform`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapBusinessRequirementToCustomerValue(requestParameters: MapBusinessRequirementToCustomerValueRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public mapBusinessRequirementToCustomerValue(requestParameters: MapBusinessRequirementToCustomerValueRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public mapBusinessRequirementToCustomerValue(requestParameters: MapBusinessRequirementToCustomerValueRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public mapBusinessRequirementToCustomerValue(requestParameters: MapBusinessRequirementToCustomerValueRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling mapBusinessRequirementToCustomerValue.');
        }
        const businessRequirementCustomerValueMapperDto = requestParameters.businessRequirementCustomerValueMapperDto;
        if (businessRequirementCustomerValueMapperDto === null || businessRequirementCustomerValueMapperDto === undefined) {
            throw new Error('Required parameter businessRequirementCustomerValueMapperDto was null or undefined when calling mapBusinessRequirementToCustomerValue.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/business-requirements/${encodeURIComponent(String(platformId))}/map-customer-value`,
            businessRequirementCustomerValueMapperDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapBusinessRequirementToSoftwareModule(requestParameters: MapBusinessRequirementToSoftwareModuleRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public mapBusinessRequirementToSoftwareModule(requestParameters: MapBusinessRequirementToSoftwareModuleRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public mapBusinessRequirementToSoftwareModule(requestParameters: MapBusinessRequirementToSoftwareModuleRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public mapBusinessRequirementToSoftwareModule(requestParameters: MapBusinessRequirementToSoftwareModuleRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling mapBusinessRequirementToSoftwareModule.');
        }
        const businessRequirementSoftwareModuleMapperDto = requestParameters.businessRequirementSoftwareModuleMapperDto;
        if (businessRequirementSoftwareModuleMapperDto === null || businessRequirementSoftwareModuleMapperDto === undefined) {
            throw new Error('Required parameter businessRequirementSoftwareModuleMapperDto was null or undefined when calling mapBusinessRequirementToSoftwareModule.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/business-requirements/${encodeURIComponent(String(platformId))}/map-module`,
            businessRequirementSoftwareModuleMapperDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Business Requirement
     * Filter Business Requirement
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBusinessRequirement(requestParameters: SearchBusinessRequirementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsBusinessRequirementPojo>;
    public searchBusinessRequirement(requestParameters: SearchBusinessRequirementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsBusinessRequirementPojo>>;
    public searchBusinessRequirement(requestParameters: SearchBusinessRequirementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsBusinessRequirementPojo>>;
    public searchBusinessRequirement(requestParameters: SearchBusinessRequirementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling searchBusinessRequirement.');
        }
        const identifier = requestParameters.identifier;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const revisionDate = requestParameters.revisionDate;
        const reviewedAt = requestParameters.reviewedAt;
        const epic = requestParameters.epic;
        const requirement = requestParameters.requirement;
        const reviewer = requestParameters.reviewer;
        const version = requestParameters.version;
        const platform = requestParameters.platform;
        const reasonForReview = requestParameters.reasonForReview;
        const actor = requestParameters.actor;
        const createdAt = requestParameters.createdAt;
        const parentRequirement = requestParameters.parentRequirement;
        const createdBy = requestParameters.createdBy;
        const requirementNumDisplayName = requestParameters.requirementNumDisplayName;
        const reviewStatus = requestParameters.reviewStatus;
        const id = requestParameters.id;
        const businessRequirementSection = requestParameters.businessRequirementSection;
        const status = requestParameters.status;
        const definitionStatus = requestParameters.definitionStatus;
        const templateType = requestParameters.templateType;
        const createdByName = requestParameters.createdByName;
        const customerValueId = requestParameters.customerValueId;
        const offset = requestParameters.offset;
        const sectionId = requestParameters.sectionId;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const complianceEntryId = requestParameters.complianceEntryId;
        const shouldShowAll = requestParameters.shouldShowAll;
        const limit = requestParameters.limit;
        const exclude = requestParameters.exclude;
        const createdBefore = requestParameters.createdBefore;
        const moduleId = requestParameters.moduleId;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (revisionDate !== undefined && revisionDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>revisionDate, 'revisionDate');
        }
        if (reviewedAt !== undefined && reviewedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedAt, 'reviewedAt');
        }
        if (epic !== undefined && epic !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>epic, 'epic');
        }
        if (requirement !== undefined && requirement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requirement, 'requirement');
        }
        if (reviewer !== undefined && reviewer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewer, 'reviewer');
        }
        if (version !== undefined && version !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>version, 'version');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (reasonForReview !== undefined && reasonForReview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForReview, 'reasonForReview');
        }
        if (actor !== undefined && actor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actor, 'actor');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (parentRequirement !== undefined && parentRequirement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>parentRequirement, 'parentRequirement');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (requirementNumDisplayName !== undefined && requirementNumDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requirementNumDisplayName, 'requirementNumDisplayName');
        }
        if (reviewStatus !== undefined && reviewStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewStatus, 'reviewStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (businessRequirementSection !== undefined && businessRequirementSection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>businessRequirementSection, 'businessRequirementSection');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (definitionStatus !== undefined && definitionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>definitionStatus, 'definitionStatus');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (createdByName !== undefined && createdByName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdByName, 'createdByName');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (sectionId !== undefined && sectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sectionId, 'sectionId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (complianceEntryId !== undefined && complianceEntryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>complianceEntryId, 'complianceEntryId');
        }
        if (shouldShowAll !== undefined && shouldShowAll !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shouldShowAll, 'shouldShowAll');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'exclude');
            })
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (moduleId !== undefined && moduleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>moduleId, 'moduleId');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsBusinessRequirementPojo>(`${this.configuration.basePath}/business-requirements/${encodeURIComponent(String(platformId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Business Requirement Document
     * Filter Business Requirement Document
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBusinessRequirementDocument(requestParameters: SearchBusinessRequirementDocumentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsBusinessRequirementDocumentPojo>;
    public searchBusinessRequirementDocument(requestParameters: SearchBusinessRequirementDocumentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsBusinessRequirementDocumentPojo>>;
    public searchBusinessRequirementDocument(requestParameters: SearchBusinessRequirementDocumentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsBusinessRequirementDocumentPojo>>;
    public searchBusinessRequirementDocument(requestParameters: SearchBusinessRequirementDocumentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling searchBusinessRequirementDocument.');
        }
        const fileName = requestParameters.fileName;
        const approvedBy = requestParameters.approvedBy;
        const approvedAt = requestParameters.approvedAt;
        const version = requestParameters.version;
        const platform = requestParameters.platform;
        const createdAt = requestParameters.createdAt;
        const versionNumberDisplayName = requestParameters.versionNumberDisplayName;
        const file = requestParameters.file;
        const createdBy = requestParameters.createdBy;
        const requirementApprovalComplianceEntry = requestParameters.requirementApprovalComplianceEntry;
        const id = requestParameters.id;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const keyword = requestParameters.keyword;
        const priorityItems = requestParameters.priorityItems;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fileName !== undefined && fileName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fileName, 'fileName');
        }
        if (approvedBy !== undefined && approvedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvedBy, 'approvedBy');
        }
        if (approvedAt !== undefined && approvedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvedAt, 'approvedAt');
        }
        if (version !== undefined && version !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>version, 'version');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (versionNumberDisplayName !== undefined && versionNumberDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>versionNumberDisplayName, 'versionNumberDisplayName');
        }
        if (file !== undefined && file !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>file, 'file');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (requirementApprovalComplianceEntry !== undefined && requirementApprovalComplianceEntry !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requirementApprovalComplianceEntry, 'requirementApprovalComplianceEntry');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsBusinessRequirementDocumentPojo>(`${this.configuration.basePath}/business-requirements/document/${encodeURIComponent(String(platformId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Business Requirement with section grouping response
     * Search Business Requirement with section grouping response
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBusinessRequirementSectionGroup(requestParameters: SearchBusinessRequirementSectionGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsSectionGroupRequirementPojo>;
    public searchBusinessRequirementSectionGroup(requestParameters: SearchBusinessRequirementSectionGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsSectionGroupRequirementPojo>>;
    public searchBusinessRequirementSectionGroup(requestParameters: SearchBusinessRequirementSectionGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsSectionGroupRequirementPojo>>;
    public searchBusinessRequirementSectionGroup(requestParameters: SearchBusinessRequirementSectionGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling searchBusinessRequirementSectionGroup.');
        }
        const identifier = requestParameters.identifier;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const revisionDate = requestParameters.revisionDate;
        const reviewedAt = requestParameters.reviewedAt;
        const epic = requestParameters.epic;
        const requirement = requestParameters.requirement;
        const reviewer = requestParameters.reviewer;
        const version = requestParameters.version;
        const platform = requestParameters.platform;
        const reasonForReview = requestParameters.reasonForReview;
        const actor = requestParameters.actor;
        const createdAt = requestParameters.createdAt;
        const parentRequirement = requestParameters.parentRequirement;
        const createdBy = requestParameters.createdBy;
        const requirementNumDisplayName = requestParameters.requirementNumDisplayName;
        const reviewStatus = requestParameters.reviewStatus;
        const id = requestParameters.id;
        const businessRequirementSection = requestParameters.businessRequirementSection;
        const status = requestParameters.status;
        const definitionStatus = requestParameters.definitionStatus;
        const templateType = requestParameters.templateType;
        const createdByName = requestParameters.createdByName;
        const customerValueId = requestParameters.customerValueId;
        const offset = requestParameters.offset;
        const sectionId = requestParameters.sectionId;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const complianceEntryId = requestParameters.complianceEntryId;
        const shouldShowAll = requestParameters.shouldShowAll;
        const limit = requestParameters.limit;
        const exclude = requestParameters.exclude;
        const createdBefore = requestParameters.createdBefore;
        const moduleId = requestParameters.moduleId;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (revisionDate !== undefined && revisionDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>revisionDate, 'revisionDate');
        }
        if (reviewedAt !== undefined && reviewedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedAt, 'reviewedAt');
        }
        if (epic !== undefined && epic !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>epic, 'epic');
        }
        if (requirement !== undefined && requirement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requirement, 'requirement');
        }
        if (reviewer !== undefined && reviewer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewer, 'reviewer');
        }
        if (version !== undefined && version !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>version, 'version');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (reasonForReview !== undefined && reasonForReview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForReview, 'reasonForReview');
        }
        if (actor !== undefined && actor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actor, 'actor');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (parentRequirement !== undefined && parentRequirement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>parentRequirement, 'parentRequirement');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (requirementNumDisplayName !== undefined && requirementNumDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requirementNumDisplayName, 'requirementNumDisplayName');
        }
        if (reviewStatus !== undefined && reviewStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewStatus, 'reviewStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (businessRequirementSection !== undefined && businessRequirementSection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>businessRequirementSection, 'businessRequirementSection');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (definitionStatus !== undefined && definitionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>definitionStatus, 'definitionStatus');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (createdByName !== undefined && createdByName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdByName, 'createdByName');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (sectionId !== undefined && sectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sectionId, 'sectionId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (complianceEntryId !== undefined && complianceEntryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>complianceEntryId, 'complianceEntryId');
        }
        if (shouldShowAll !== undefined && shouldShowAll !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shouldShowAll, 'shouldShowAll');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'exclude');
            })
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (moduleId !== undefined && moduleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>moduleId, 'moduleId');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsSectionGroupRequirementPojo>(`${this.configuration.basePath}/business-requirements/search/section-grouping/${encodeURIComponent(String(platformId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBusinessRequirement(requestParameters: UpdateBusinessRequirementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<BusinessRequirementPojo>;
    public updateBusinessRequirement(requestParameters: UpdateBusinessRequirementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<BusinessRequirementPojo>>;
    public updateBusinessRequirement(requestParameters: UpdateBusinessRequirementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<BusinessRequirementPojo>>;
    public updateBusinessRequirement(requestParameters: UpdateBusinessRequirementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const businessRequirementDto = requestParameters.businessRequirementDto;
        if (businessRequirementDto === null || businessRequirementDto === undefined) {
            throw new Error('Required parameter businessRequirementDto was null or undefined when calling updateBusinessRequirement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<BusinessRequirementPojo>(`${this.configuration.basePath}/business-requirements/edit`,
            businessRequirementDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
