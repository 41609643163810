import { Component, EventEmitter, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// eslint-disable-next-line import/no-unresolved,@typescript-eslint/ban-ts-comment
// eslint-disable-next-line import/no-unresolved
import { WebcamImage } from 'ngx-webcam';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-face-capture-dialog',
  templateUrl: './face-capture-dialog.component.html',
  styleUrls: ['./face-capture-dialog.component.css']
})
export class FaceCaptureDialogComponent {
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  protected readonly close = close;

  @Output() imageResult: EventEmitter<string> = new EventEmitter<string>();

  heading: any;
  errorMessage = null;
  sysImage = '';

  constructor(private bsModalRef: BsModalRef) {}

  public getSnapshot(): void {
    this.trigger.next(void 0);
    this.imageResult.emit(this.webcamImage?.imageAsDataUrl);
  }

  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
