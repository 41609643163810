/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { BwBinaryData } from './bw-binary-data';
import { PortalUser } from './portal-user';


export interface Platform { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: Platform.StatusEnum;
    name?: string;
    code?: string;
    description?: string;
    url?: string;
    portalAccount?: PortalAccount;
    logo?: BwBinaryData;
}
export namespace Platform {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


