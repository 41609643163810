<header>
  <nav class="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <div class="pointer" routerLink="/" style="cursor: pointer">
          <h3 class="fw-bold mb-0">
            <span
              class="iconify"
              data-icon="material-symbols:image-outline"
            ></span>

            <span class="fs-5 ms-2">LOGO</span>
          </h3>
        </div>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <button
            type="button"
            class="btn me-4 btn-outline-light bg-transparent border-0 position-relative"
          >
            <span
              class="iconify text-light fs-3"
              data-icon="basil:notification-solid"
            ></span>
            <span
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-1"
              style="margin-left: -10px; margin-top: 5px"
            >
              99+
              <span class="visually-hidden">unread messages</span>
            </span>
          </button>
        </ul>
        <div class="d-flex">
          <div class="dropdown">
            <a
              class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown "
              aria-expanded="false"
            >
              <img
                src="https://github.com/mdo.png"
                alt=""
                width="32"
                height="32"
                class="rounded-circle me-2"
              />
              <div>
                <p class="fw-bold text-light mb-0">Stanley Agbo</p>
                <p class="mb-0 small text-light fw-light">
                  sagbo@byteworks.com.ng
                </p>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
              <li><a class="dropdown-item" href="#">Settings</a></li>
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Sign out</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>

<div class="container-fluid">
  <div class="row">
    <div
      class="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary"
    >
      <div
        class="offcanvas-lg offcanvas-end bg-body-tertiary"
        tabindex="-1"
        id="sidebarMenu"
        aria-labelledby="sidebarMenuLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 overflow-y-auto">
          <div class="d-flex flex-column flex-shrink-0 p-3 text-bg-dark">
            <a
              href="/"
              class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <svg class="bi pe-none me-2" width="40" height="32">
                <use xlink:href="#bootstrap"></use>
              </svg>
              <span class="fs-4">Sidebar</span>
            </a>
            <hr />
            <ul class="nav nav-pills flex-column mb-auto">
              <li class="nav-item my-2">
                <a href="#" class="nav-link active text-white">
                  <span
                    class="iconify bi pe-none me-2 fs-4"
                    data-icon="basil:apps-outline"
                  ></span>
                  Dashboard
                </a>
              </li>
              <li class="nav-item my-2">
                <a href="#" class="nav-link text-white">
                  <span
                    class="iconify bi pe-none me-2 fs-4"
                    data-icon="basil:invoice-outline"
                  ></span>
                  Audit Trail
                </a>
              </li>
              <li class="nav-item my-2">
                <a class="nav-link text-white" routerLink="/users">
                  <span
                    class="iconify bi pe-none me-2 fs-4"
                    data-icon="basil:contacts-outline"
                  ></span>
                  Users
                </a>
              </li>
            </ul>

            <div class="dropdown">
              <a
                href=""
                class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown "
                aria-expanded="false"
              >
                <img
                  src="https://github.com/mdo.png"
                  alt=""
                  width="32"
                  height="32"
                  class="rounded-circle me-2"
                />
                <div>
                  <p class="fw-bold text-light mb-0">Stanley Agbo</p>
                  <p class="mb-0 small text-light fw-light">
                    sagbo@byteworks.com.ng
                  </p>
                </div>
              </a>
              <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                <li><a class="dropdown-item" href="#">Settings</a></li>
                <li><a class="dropdown-item">Profile</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#">Sign out</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
        >
          <h1 class="h4">Dashboard</h1>
        </div>

        <div class="row gap-4">
          <div class="row row-cols-3">
            <div class="col">
              <div class="card p-4">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="small mb-0">Option A</p>
                  <div class="rounded-circle p-2" style="background: #e7f6ff">
                    <span
                      class="iconify fs-4 text-primary"
                      data-icon="system-uicons:browser-alt"
                    ></span>
                  </div>
                </div>

                <div class="my-3">
                  <h2 class="fw-bold">234,879,965</h2>
                </div>

                <div class="text-end">
                  <p class="fw-light small">
                    <span class="text-danger fw-normal">
                      <span
                        class="iconify"
                        data-icon="formkit:arrowdown"
                      ></span>
                      20.2%</span
                    >
                    from last month
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card p-4">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="small mb-0">Option B</p>
                  <div class="rounded-circle p-2" style="background: #e7f6ff">
                    <span
                      class="iconify fs-4 text-primary"
                      data-icon="system-uicons:browser-alt"
                    ></span>
                  </div>
                </div>

                <div class="my-3">
                  <h2 class="fw-bold">234,879,965</h2>
                </div>

                <div class="text-end">
                  <p class="fw-light small">
                    <span class="text-danger fw-normal">
                      <span
                        class="iconify"
                        data-icon="formkit:arrowdown"
                      ></span>
                      20.2%</span
                    >
                    from last month
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card p-4">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="small mb-0">Option C</p>
                  <div class="rounded-circle p-2" style="background: #e7f6ff">
                    <span
                      class="iconify fs-4 text-primary"
                      data-icon="system-uicons:browser-alt"
                    ></span>
                  </div>
                </div>

                <div class="my-3">
                  <h2 class="fw-bold">234,879,965</h2>
                </div>

                <div class="text-end">
                  <p class="fw-light small">
                    <span class="text-success fw-normal">
                      <span class="iconify" data-icon="formkit:arrowup"></span>
                      20.2%</span
                    >
                    from last month
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-cols-2 mb-4">
            <div class="col-8">
              <div class="card p-4">
                <p class="fw-bold text-muted">Some Analytics</p>

                <div class="my-4 text-center">
                  <img src="/assets/img/chart1.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card p-3">
                <p class="fw-bold text-muted">Some More Analytics</p>

                <div class="my-4 text-center">
                  <img
                    src="/assets/img/chart2.png"
                    width="200"
                    class="img-fluid"
                    alt=""
                  />
                </div>

                <div>
                  <div class="d-flex my-2">
                    <div class="p-1 px-2 me-1 bg-success"></div>
                    <p class="small text-muted mb-0">Total positive records</p>
                  </div>
                  <div class="d-flex my-2">
                    <div class="p-1 px-2 me-1 bg-danger"></div>
                    <p class="small text-muted mb-0">Total Negative Records</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          <h1 class="h5 mb-0">Recent Stuff</h1>

          <p class="mb-0">view all</p>
        </div>

        <div class="my-4 mb-5 card p-3">
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col" class="small">SN</th>
                  <th scope="col" class="small">Name</th>
                  <th scope="col" class="small">Description</th>
                  <th scope="col" class="small">Status</th>
                  <th scope="col" class="small">Timestamp</th>
                  <th scope="col" class="small">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" class="small">01</th>
                  <td class="small text-muted">Water Chillers</td>
                  <td class="small text-muted">Hugo Stores</td>
                  <td class="small text-muted">Pending Approval</td>
                  <td class="small text-muted">02/03/2023</td>
                  <td class="small text-primary fw-normal">View Details</td>
                </tr>
                <tr>
                  <th scope="row" class="small">01</th>
                  <td class="small text-muted">Water Chillers</td>
                  <td class="small text-muted">Hugo Stores</td>
                  <td class="small text-muted">Pending Approval</td>
                  <td class="small text-muted">02/03/2023</td>
                  <td class="small text-primary fw-normal">View Details</td>
                </tr>
                <tr>
                  <th scope="row" class="small">01</th>
                  <td class="small text-muted">Water Chillers</td>
                  <td class="small text-muted">Hugo Stores</td>
                  <td class="small text-muted">Pending Approval</td>
                  <td class="small text-muted">02/03/2023</td>
                  <td class="small text-primary fw-normal">View Details</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
