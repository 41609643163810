/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CIRequestChecklistPojo { 
    id?: number;
    ciRequestId?: number;
    item?: string;
    statusConstant?: CIRequestChecklistPojo.StatusConstantEnum;
}
export namespace CIRequestChecklistPojo {
    export type StatusConstantEnum = 'DONE' | 'NOT_DONE';
    export const StatusConstantEnum = {
        Done: 'DONE' as StatusConstantEnum,
        NotDone: 'NOT_DONE' as StatusConstantEnum
    };
}


