/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { SprintUatPojo } from './sprint-uat-pojo';


export interface SprintPojo { 
    id?: number;
    name?: string;
    identifierDisplayName?: string;
    startDate?: string;
    endDate?: string;
    uats?: Array<SprintUatPojo>;
    wig?: NameIdPojo;
}

