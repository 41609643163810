/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UMLDesignItemDto { 
    diagramType: UMLDesignItemDto.DiagramTypeEnum;
    itemName: string;
}
export namespace UMLDesignItemDto {
    export type DiagramTypeEnum = 'CLASS' | 'COMPONENT' | 'DEPLOYMENT' | 'OBJECT' | 'PACKAGE' | 'PROFILE' | 'COMPOSITE_STRUCTURE' | 'USE_CASE' | 'ACTIVITY' | 'STATE_MACHINE' | 'SEQUENCE' | 'COMMUNICATION' | 'INTERACTION_OVERVIEW' | 'TIMING';
    export const DiagramTypeEnum = {
        Class: 'CLASS' as DiagramTypeEnum,
        Component: 'COMPONENT' as DiagramTypeEnum,
        Deployment: 'DEPLOYMENT' as DiagramTypeEnum,
        Object: 'OBJECT' as DiagramTypeEnum,
        Package: 'PACKAGE' as DiagramTypeEnum,
        Profile: 'PROFILE' as DiagramTypeEnum,
        CompositeStructure: 'COMPOSITE_STRUCTURE' as DiagramTypeEnum,
        UseCase: 'USE_CASE' as DiagramTypeEnum,
        Activity: 'ACTIVITY' as DiagramTypeEnum,
        StateMachine: 'STATE_MACHINE' as DiagramTypeEnum,
        Sequence: 'SEQUENCE' as DiagramTypeEnum,
        Communication: 'COMMUNICATION' as DiagramTypeEnum,
        InteractionOverview: 'INTERACTION_OVERVIEW' as DiagramTypeEnum,
        Timing: 'TIMING' as DiagramTypeEnum
    };
}


