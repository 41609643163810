import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Platform, PlatformControllerService} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-platform-selection-dialog',
  templateUrl: './platform-selection-dialog.component.html',
  styleUrls: ['./platform-selection-dialog.component.css']
})
export class PlatformSelectionDialogComponent implements OnInit {

  @Output()
  selectedPlatform: EventEmitter<Platform> = new EventEmitter<Platform>();

  platforms: Platform[];

  form: FormGroup

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private platformController: PlatformControllerService) {
  }

  ngOnInit() {
    this.getPlatforms();
    this.initForm()
  }

  getPlatforms(): void {
    this.platformController.getAllPlatforms().subscribe({
      next: (v) => {
        this.platforms = v;
      }
    });
  }

  onDone() {
    if (this.form.invalid) return;
    this.selectedPlatform.emit(this.form.get('platform').value);
    this.close()
  }

  close() {
    this.modalRef.hide();
  }

  private initForm() {
    this.form = this.fb.group({
      platform: ['', Validators.required]
    });

  }
}
