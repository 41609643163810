<div class="modal-header">
    <div class="pull-left">
        <h5 class="modal-title fs-14" id="modal-title">{{ title }}</h5>
    </div>
    <span (click)="close()" class="close pull-right" type="button">
             <span aria-hidden="true" style="font-size: 30px">&times;</span>
         </span>
</div>

<div
        *ngIf="showAlertMessageTrigger"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-info]="getMessageType() === AlertType.info"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-success]="getMessageType() === AlertType.success"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        class="alert mb-4 fs-14 text-center"
>
    {{ getAlertMessage() }}
</div>

<div class="modal-body p-4">
    <div class="card mb-4">
        <div class="card-body" style="background-color: rgba(24, 29, 142, 0.06) !important;">
            <div class="container">
                <div class="row">
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">PU Code</div>
                        <div class="fs-12">{{ productionUnit.code }}</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Module</div>
                        <div class="fs-12">{{ productionUnit?.softwareModule?.name }}</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Reset Count</div>
                        <div class="fs-12"> {{ productionUnit?.resetData?.length }}</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Production Unit Name</div>
                        <div class="fs-12">{{ productionUnit.name }}t</div>
                    </div>
                    <div class="col-4 mb-3">
                        <div class="fs-14 fw-bold">Production Unit Type</div>
                        <div class="fs-12">{{ productionUnit?.softwareUnitType?.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion" id="accordionPanelsStayOpenExample">
        <ng-container *ngIf="(complianceStages$ | async) else stagesLoader">
            <ng-container *ngFor="let item of complianceStages; index as i;">
                <div [ngStyle]="{'border-left': getLeftBorderStyle(item)}"
                     class="accordion-item">
                    <ng-container *ngIf="item?.passCount > 0 || item?.failCount > 0; else processInfo">
                        <h2 class="accordion-header" style="margin-bottom: 0;">
                            <button
                                    [attr.aria-controls]="'panelsStayOpen-collapse'+i"
                                    [attr.data-bs-target]="'#panelsStayOpen-collapse'+i"
                                    aria-expanded="false"
                                    class="accordion-button custom-accordion-button collapsed"
                                    data-bs-toggle="collapse"
                                    type="button">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <i [attr.data-icon]="getIcon(item)"
                                               [ngStyle]="{'color': logged(item)? 'green' : '#9C9C9C'}"
                                               class="pointer iconify icon fs-3"></i>
                                        </div>
                                        <div>
                                            {{ item?.processName }}
                                        </div>
                                    </div>
                                    <ng-container *ngIf="item.failCount > 0;">
                                        <div class="red-loop-count ms-2">
                                            <span class="iconify" data-icon="bx:reset"
                                                  style="color: #D62B2B; height: 18px; width: 18px;">
                                            </span>
                                            <span class="red-loop-count__name"> {{ item?.failCount + ' Red loop' }}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </button>
                        </h2>

                    </ng-container>
                    <ng-template #processInfo>
                        <div class="d-flex align-items-center py-2">
                            <div class="ms-2 me-3">
                                <i [attr.data-icon]="getIcon(item)"
                                   [ngStyle]="{'color': logged(item)? 'green' : '#9C9C9C'}"
                                   class="pointer iconify icon fs-3"></i>
                            </div>
                            <div>
                                {{ item?.processName }}
                            </div>
                        </div>
                    </ng-template>
                    <div *ngIf="item?.failCount > 0 || item?.passCount > 0" [id]="'panelsStayOpen-collapse' +i"
                         class="accordion-collapse collapse">
                        <div [ngStyle]="{ 'background': getBackground(item) }"
                             class="accordion-body">

                            <div class="d-flex justify-content-between align-items-center"
                                 style="background-color: white; padding: 9px 11px; border-radius: 5px;">
                                <div class="d-flex justify-content-between align-items-center gap-2">
                                   <span [ngClass]="{ 'd-none':  item?.processCode == 'RESET' }">
                                        <select #stageSelection class="count-dropdown">
                                        <option *ngIf="item?.passCount > 0" value="passed">Passed</option>
                                        <option *ngIf="item?.failCount > 0" value="failed">Failed & Red Loop</option>
                                    </select>
                                   </span>
                                </div>
                                <p style="margin: 0;">
                                    Total: {{ stageSelection.value === 'passed' ? item?.passCount : item.failCount }}</p>
                            </div>
                            <div class="d-flex flex-column gap-2 mt-2">
                                <ng-container *ngIf="stageSelection.value === 'passed'">
                                    <ng-container *ngFor="let entry of item.passedEntries; let i = index">
                                        <div class="card border-0 mt-3"
                                             style="background-color: transparent !important; border: 0.8px solid #09742D !important">
                                            <div class="card-body">
                                                <div class="d-flex flex-column justify-content-between">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">{{  item?.processCode!='RESET' ? 'Pass' : 'Reset' }} Count</div>
                                                                <div>#{{ i + 1 }}</div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">Date & Time</div>
                                                                <div>{{ entry.time | datetime }}</div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">Responsible Player</div>
                                                                <div>{{ entry.responsible?.name || '-' }}</div>
                                                            </div>
                                                            <div class="col-6 mb-3"  *ngIf="item?.processCode!='RESET'">
                                                                <div class="fw-semibold">Checker</div>
                                                                <div>{{ entry.checker?.name || '-' }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <!-- Failed Entries -->
                                <ng-container *ngIf="stageSelection.value === 'failed'">
                                    <ng-container *ngFor="let entry of item.failedEntries; let i = index">
                                        <div class="card border-0 mt-3"
                                             style="background-color: transparent !important; border: 2px solid rgba(214, 43, 43, 0.21) !important">
                                            <div class="card-body">
                                                <div class="d-flex flex-column justify-content-between">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">Fail Count</div>
                                                                <div>#{{ i + 1 }}</div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">Date & Time</div>
                                                                <div>{{ entry.time | datetime }}</div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">Responsible Player</div>
                                                                <div>{{ entry.responsible?.name || '-' }}</div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="fw-semibold">Checker</div>
                                                                <div>{{ entry.checker?.name || '-' }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #stagesLoader>
    <p>Loading Stages...</p>
</ng-template>


<!--
<div class="d-flex flex-column gap-2 mt-2">
    <ng-container *ngIf="passedAndFailedSelect !== 'failed'">
        <div class="card border-0 mt-3"
             style="background-color: transparent !important; border: 0.8px solid #09742D !important">
            <div class="card-body">
                <div class=" d-flex flex-column justify-content-between">
                    <div class="container">
                        <div class="row">
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Pass Count
                                </div>
                                <div>
                                    #1
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Date & Time
                                </div>
                                <div>
                                    2024-08-10, 2:34PM
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Responsible Player
                                </div>
                                <div>
                                    Jonathan Salawu
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Checker
                                </div>
                                <div>
                                    Damilola Ajayi
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="passedAndFailedSelect === 'failed'">
        <div class="card border-0 mt-3"
             style="background-color: transparent !important; border: 0.8px solid rgba(214, 43, 43, 0.21) !important">
            <div class="card-body">
                <div class=" d-flex flex-column justify-content-between">
                    <div class="container">
                        <div class="row">
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Red Loop Count
                                </div>
                                <div>
                                    #1
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Date & Time
                                </div>
                                <div>
                                    2024-08-10, 2:34PM
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="fw-semibold">
                                    Note
                                </div>
                                <div>
                                    The view is missing some information on the business
                                    requirement
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Responsible Player
                                </div>
                                <div>
                                    Jonathan Salawu
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="fw-semibold">
                                    Checker
                                </div>
                                <div>
                                    Damilola Ajayi
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="fw-semibold">
                                    Checked Standard
                                </div>
                                <div>
                                    STD/TECH/PU/API/001
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>-->
