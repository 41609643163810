<div class="my-4 d-flex justify-content-between align-items-center">
    <div class="pos">

        <div class="wrapper" (click)="previous()"
             [ngStyle]="{
                  width: config.width? (config.width+(config.padding*2))+'px': '100%',
                  padding: config.padding+'px',
                  height: config.height? (config.height+(config.padding*2))+'px': 'auto',
                  borderRadius: config.circle? '50%':'5px',
                  backgroundColor: config.background,
                  border: '0.6px solid var(--secondary-color, #0064F1)',
                  cursor: 'pointer',
             }"
             [ngClass]="{
                disabled: _disablePrevious,
             }"
        >
            <i class="iconify icon" style="color: #0064F1;" data-icon="grommet-icons:link-previous"></i>
        </div>

        <div *ngIf="showNextPreviousText" class="text">
            Previous
        </div>
    </div>

    <div class="pos">
        <div *ngIf="showNextPreviousText" class="text">
            Next
        </div>

        <div class="wrapper" (click)="next()"
             [ngStyle]="{
                  width: config.width? (config.width+(config.padding*2))+'px': '100%',
                  padding: config.padding+'px',
                  height: config.height? (config.height+(config.padding*2))+'px': 'auto',
                  borderRadius: config.circle? '50%':'5px',
                  backgroundColor: config.background,
                  border: '0.6px solid var(--secondary-color, #0064F1)',
                  cursor: 'pointer',
            }"
             [ngClass]="{
                disabled: _disableNext,
             }"

        >
            <i class="iconify icon" style="color: #0064F1;" data-icon="grommet-icons:link-next"></i>
        </div>
    </div>
</div>
