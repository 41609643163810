/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { Platform } from './platform';
import { CustomerOrganisation } from './customer-organisation';
import { PortalUser } from './portal-user';


export interface Wig { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: Wig.StatusEnum;
    name?: string;
    startDate?: string;
    dueAt?: string;
    description?: string;
    externalSystemIssueUrl?: string;
    completedAt?: string;
    completed?: boolean;
    portalAccount?: PortalAccount;
    parentWig?: Wig;
    customerOrganisation?: CustomerOrganisation;
    platform?: Platform;
}
export namespace Wig {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


