import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent {
  @Input() title = 'Success';
  @Input() message = 'Action was successful';

  lottieIconPath = 'assets/lottie/success.json';
  @Output() closeEvent = new EventEmitter<void>();

  constructor(private modalRef: BsModalRef) {}

  close(): void {

    this.closeEvent.emit();
    this.modalRef.hide();
  }
}
