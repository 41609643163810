<div class="accordion" id="accordionPanelsStayOpenExample">
    <ng-container *ngFor="let item of complianceStages; index as i;">
        <div class="accordion-item"
             [ngStyle]="{'border-left': lastLogged == i? '5px solid #DB8F11': logged(i)? '5px solid green' : '5px solid #9C9C9C'}">
            <h2 class="accordion-header" style="margin-bottom: 0;">
                <button class="accordion-button custom-accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#panelsStayOpen-collapse'+i" aria-expanded="false"
                        [attr.aria-controls]="'panelsStayOpen-collapse'+i">
                    <div class="me-3">
                        <i class="pointer iconify icon fs-3" [attr.data-icon]="getIcon(i)"
                           [ngStyle]="{'color':lastLogged == i? '#DB8F11': logged(i)? 'green' : '#9C9C9C'}"></i>
                    </div>
                    <div>
                        {{ item?.productionProcessStageName }}
                    </div>

                    <div class="red-loop-count">
                        <span class="iconify" data-icon="bx:reset" style="color: #D62B2B; height: 18px; width: 18px;"></span>
                        2 <span class="red-loop-count__name">Red loop</span>
                    </div>
                </button>
            </h2>
            <div [id]="'panelsStayOpen-collapse' +i" class="accordion-collapse collapse">
                <div *ngIf="item?.productionProcessStageCode != processCompliancePojo?.productionProcessStageCode"
                     class="accordion-body" style="background: rgba(58, 222, 114, 0.10);">
                    
                    <div class="d-flex justify-content-between align-items-center" style="background-color: white; padding: 9px 11px; border-radius: 5px;">
                        <div class="d-flex justify-content-between align-items-center gap-2">
                            <select class=" count-dropdown" for="" (click)="passedAndFailedSelectChange($event)">
                                <option value="passed">Passed</option>
                                <option value="failed">Failed & Red Loop</option>
                            </select>
                        </div>
                        <p style="margin: 0;">Total: 2</p>
                    </div>
                    <div class="d-flex flex-column gap-2 mt-2">
                        <ng-container *ngIf="passedAndFailedSelect !== 'failed'">
                            <div class="card border-0 mt-3" style="background-color: transparent !important; border: 0.8px solid #09742D !important">
                                <div class="card-body">
                                    <div class=" d-flex flex-column justify-content-between">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Pass Count
                                                    </div>
                                                    <div>
                                                        #1
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Date & Time
                                                    </div>
                                                    <div>
                                                        2024-08-10, 2:34PM
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Responsible Player
                                                    </div>
                                                    <div>
                                                        Jonathan Salawu
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Checker
                                                    </div>
                                                    <div>
                                                        Damilola Ajayi
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="passedAndFailedSelect === 'failed'">
                            <div class="card border-0 mt-3" style="background-color: transparent !important; border: 0.8px solid rgba(214, 43, 43, 0.21) !important">
                                <div class="card-body">
                                    <div class=" d-flex flex-column justify-content-between">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Red Loop Count
                                                    </div>
                                                    <div>
                                                        #1
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Date & Time
                                                    </div>
                                                    <div>
                                                        2024-08-10, 2:34PM
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="fw-semibold">
                                                        Note
                                                    </div>
                                                    <div>
                                                        The view is missing some information on the business requirement
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Responsible Player
                                                    </div>
                                                    <div>
                                                        Jonathan Salawu
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Checker
                                                    </div>
                                                    <div>
                                                        Damilola Ajayi
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="fw-semibold">
                                                        Checked Standard
                                                    </div>
                                                    <div>
                                                        STD/TECH/PU/API/001
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="item?.productionProcessStageCode == processCompliancePojo?.productionProcessStageCode"
                     class="accordion-body" style="background: rgba(219, 143, 17, 0.10);">
                    <div class="d-flex justify-content-between align-items-center" style="background-color: white; padding: 9px 11px; border-radius: 5px;">
                        <div class="d-flex justify-content-between align-items-center gap-2">
                            <select class=" count-dropdown" for="" (click)="passedAndFailedSelectChange($event)">
                                <option value="passed">Passed</option>
                                <option value="failed">Failed & Red Loop</option>
                            </select>
                        </div>
                        <p style="margin: 0;">Total: 2</p>
                    </div>
                    <div class="d-flex flex-column gap-2 mt-2">
                        <ng-container *ngIf="passedAndFailedSelect !== 'failed'">
                            <div class="card border-0 mt-3" style="background-color: transparent !important; border: 0.8px solid #09742D !important">
                                <div class="card-body">
                                    <div class=" d-flex flex-column justify-content-between">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Pass Count
                                                    </div>
                                                    <div>
                                                        #1
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Date & Time
                                                    </div>
                                                    <div>
                                                        2024-08-10, 2:34PM
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Responsible Player
                                                    </div>
                                                    <div>
                                                        Jonathan Salawu
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Checker
                                                    </div>
                                                    <div>
                                                        Damilola Ajayi
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="passedAndFailedSelect === 'failed'">
                            <div class="card border-0 mt-3" style="background-color: transparent !important; border: 0.8px solid rgba(214, 43, 43, 0.21) !important">
                                <div class="card-body">
                                    <div class=" d-flex flex-column justify-content-between">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Red Loop Count
                                                    </div>
                                                    <div>
                                                        #1
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Date & Time
                                                    </div>
                                                    <div>
                                                        2024-08-10, 2:34PM
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="fw-semibold">
                                                        Note
                                                    </div>
                                                    <div>
                                                        The view is missing some information on the business requirement
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Responsible Player
                                                    </div>
                                                    <div>
                                                        Jonathan Salawu
                                                    </div>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <div class="fw-semibold">
                                                        Checker
                                                    </div>
                                                    <div>
                                                        Damilola Ajayi
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="fw-semibold">
                                                        Checked Standard
                                                    </div>
                                                    <div>
                                                        STD/TECH/PU/API/001
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>