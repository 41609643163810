import {Component, Inject} from '@angular/core';
import {UnsubscribeOnDestroyAdapter} from "../../../../services/unsubscribe-on-destroy-adapter";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HelperService} from "../../../../services/helper.service";
import {
    BusinessRequirementSoftwareModuleMapperDto,
    CiRequestControllerService,
    CIRequestUpdateDto, HPUToProductionUnitMapperDto,
    ProductionProcessComplianceControllerService,
    ProductionUnitControllerService,
    UATToProductionUnitMapperDto
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';

@Component({
    selector: 'app-production-unit-selector-dialog',
    templateUrl: './production-unit-selector-dialog.component.html',
    styleUrls: ['./production-unit-selector-dialog.component.css']
})

export class ProductionUnitSelectorDialogComponent extends UnsubscribeOnDestroyAdapter {

    platformId: number;

    uatRequestId: number;

    hardwareProductionUnitId: number;

    selectedIds: Array<number>;

    title: string;

    showModuleControl = false;

    /**
     * List of components calling this modal
     * <li>ForecastUATProductionUnitComponent</li>
     * <li>HardwareProductionUnitComponent</li>
     *
     * Handle all Ids properly
     *
     * @param dialogRef
     * @param data
     * @param helperService
     * @param processComplianceService
     */
    constructor(public dialogRef: MatDialogRef<ProductionUnitSelectorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public helperService: HelperService,
                private processComplianceService: ProductionProcessComplianceControllerService,
                private productionUnitService: ProductionUnitControllerService) {

        super();

        this.platformId = this.data?.platformId;
        this.selectedIds = this.data?.selectedIds;
        this.uatRequestId = this.data?.uatRequestId;
        this.hardwareProductionUnitId = this.data?.hardwareProductionUnitId;

        if (this.hardwareProductionUnitId) {
            this.showModuleControl = true;
        }

        if (!this.platformId) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)

        } else if (!this.uatRequestId && !this.hardwareProductionUnitId) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)

        } else if (this.uatRequestId && this.hardwareProductionUnitId) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }

        this.title = this.data?.modal?.title
    }

    get platform(): number {
        return this.platformId;
    }

    set platform(platformId: number) {
        if (platformId) {
            this.platformId = platformId;
        }
    }

    set selection($event: Array<number>) {
        if ($event && $event.length > 0) {
            this.manageMapTo($event);
        } else {
            this.showAlertMessage('Select at least one production unit to map', AlertType.error);
        }
    }

    get selection(): Array<number> {
        return this.selectedIds;
    }

    manageMapTo(selectedIds: Array<number>): void {

        if (this.uatRequestId) {
            this.mapToUAT(selectedIds)

        } else if (this.hardwareProductionUnitId) {
            this.mapToHPU(selectedIds);

        } else {
            this.showAlertMessage('No mapping destination selected', AlertType.error);
        }
    }

    mapToUAT(selectedIds: Array<number>): void {
        const payload: Array<UATToProductionUnitMapperDto> =
            selectedIds.map((id: number): UATToProductionUnitMapperDto => {
                return {
                    userAcceptanceTestId: this.uatRequestId,
                    productionUnitId: id
                }
            })

        this.subscriptions.sink = this.processComplianceService.mapUATToProductionUnit({
            uATToProductionUnitMapperDto: payload,
            platformId: this.platformId
        })
            .subscribe({
                next: (data) => {
                },
                complete: () => {
                    this.dialogRef.close(true);
                },
                error: (error: unknown) => {
                    this.showAlertMessage(this.helperService.getError(error), AlertType.error);
                }
            })
    }

    mapToHPU(selectedIds: Array<number>): void {
        const payload: Array<HPUToProductionUnitMapperDto> =
            selectedIds.map((id: number): HPUToProductionUnitMapperDto => {
                return {
                    hardwareProductionUnitId: this.hardwareProductionUnitId,
                    productionUnitId: id
                }
            })

        this.subscriptions.sink = this.productionUnitService.mapHPUToProductionUnit({
            hPUToProductionUnitMapperDto: payload,
            platformId: this.platformId
        })
            .subscribe({
                next: (data) => {
                },
                complete: () => {
                    this.dialogRef.close(true);
                },
                error: (error: unknown) => {
                    this.showAlertMessage(this.helperService.getError(error), AlertType.error);
                }
            })
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////
}
