/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlayerCertificationPojo } from './player-certification-pojo';
import { NameCodePojo } from './name-code-pojo';
import { PlayerProductivityStatisticsPojo } from './player-productivity-statistics-pojo';
import { PlayerSkillPojo } from './player-skill-pojo';


export interface ProfilePojo { 
    profileImageId?: number;
    signatureId?: number;
    designationName?: string;
    designationCode?: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    countryPojo?: NameCodePojo;
    statePojo?: NameCodePojo;
    streetAddress?: string;
    nextOfKinFirstName?: string;
    nextOfKinLastName?: string;
    nextOfKinEmailAddress?: string;
    nextOfKinPhoneNumber?: string;
    playerSkillPojo?: Array<PlayerSkillPojo>;
    playerCertificationPojo?: Array<PlayerCertificationPojo>;
    playerProductivityStatisticsPojo?: PlayerProductivityStatisticsPojo;
}

