/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';


export interface CriterionChecker { 
    type: CriterionChecker.TypeEnum;
    criterion: string;
    andValues?: Array<NameIdPojo>;
}
export namespace CriterionChecker {
    export type TypeEnum = 'GIVEN' | 'WHEN' | 'THEN';
    export const TypeEnum = {
        Given: 'GIVEN' as TypeEnum,
        When: 'WHEN' as TypeEnum,
        Then: 'THEN' as TypeEnum
    };
}


