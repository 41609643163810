/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LagDto } from './lag-dto';
import { UserStoryDto } from './user-story-dto';


export interface CustomerValueDto { 
    id?: number;
    identifierDisplayName?: string;
    description?: string;
    platformId?: number;
    dueAt?: string;
    type?: CustomerValueDto.TypeEnum;
    wigId?: number;
    lagName?: string;
    lagMeasureIds?: Array<number>;
    userStories?: Array<UserStoryDto>;
    lagDto?: LagDto;
}
export namespace CustomerValueDto {
    export type TypeEnum = 'PERFORMANCE' | 'PRODUCTIVITY';
    export const TypeEnum = {
        Performance: 'PERFORMANCE' as TypeEnum,
        Productivity: 'PRODUCTIVITY' as TypeEnum
    };
}


