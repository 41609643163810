import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PortalUserPojo} from "../../../../../../sdk/customer-fulfillment-api-sdk";

@Component({
    selector: 'app-view-player-dialog',
    templateUrl: './view-player-dialog.component.html',
    styleUrls: ['./view-player-dialog.component.css']
})
export class ViewPlayerDialogComponent {

    title: string;

    players: Array<PortalUserPojo> = new Array<PortalUserPojo>();

    constructor(public dialogRef: MatDialogRef<ViewPlayerDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,) {

        this.title = this.data?.modal?.title

        this.players = this.data?.players

        if (!this.players || this.players.length == 0) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }
    }
}
