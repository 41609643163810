/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadMeasureCommitmentPojo } from './lead-measure-commitment-pojo';
import { ScoreboardUpdatePojo } from './scoreboard-update-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface LeadMeasureCommitmentGroupingPojo { 
    player?: PortalUserPojo;
    totalUnreleasedCommitments?: number;
    scoreboardUpdatePojoList?: Array<ScoreboardUpdatePojo>;
    leadMeasureCommitmentPojoList?: Array<LeadMeasureCommitmentPojo>;
}

