import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {FormBuilder, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {
    BusinessRequirementControllerService,
    BusinessRequirementSectionControllerService,
    BusinessRequirementSectionPojo,
    DeploymentEnvironmentControllerService,
    ModuleControllerService,
    NameCodePojo,
    NameIdPojo,
    PlatformControllerService,
    PlatformPojo,
    QueryResultsSoftwareModulePojo,
    SearchBusinessRequirementRequestParams,
    SearchSoftwareModuleRequestParams,
    SoftwareModulePojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {SearchManager} from "../../search/search-manager";
import {distinctUntilChanged, Observable, of} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";
import {HelperService} from "../../../services/helper.service";
import {catchError, map} from "rxjs/operators";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../search/paginated-search";
import {SearchHandler} from "../../search/search-handler";
import {SearchFilterSource} from "../../search/search-filter-source";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-module-selector',
    templateUrl: './module-selector.component.html',
    styleUrls: ['./module-selector.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0}),
                animate('300ms ease-out', style({opacity: 1})),
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({opacity: 0})),
            ]),
        ])
    ]
})
export class ModuleSelectorComponent implements OnInit,
    SearchHandler<SoftwareModulePojo, SearchSoftwareModuleRequestParams>,
    SearchFilterSource<SearchSoftwareModuleRequestParams> {

    form = this.fb.group({
        platformId: ['', Validators.required],
        sectionId: [''],
        keyword: [''],
        customerValueId: [''],
        deploymentEnvCode: [''],
        moduleId: [''],
        moduleName: [''],
        createdAfter: [''],
        createdBefore: ['']
    });

    displayedColumns = ['select', 'sn', 'id', 'name', 'description'];

    dataSource: MatTableDataSource<SoftwareModulePojo>;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    searchManager!: SearchManager<SoftwareModulePojo, SearchSoftwareModuleRequestParams>;

    maxPageSize = 5;

    platforms$: Observable<Array<PlatformPojo>>

    environments$: Observable<Array<NameCodePojo>>

    sections$: Observable<Array<BusinessRequirementSectionPojo>>

    platformId: number;

    emptyListShowOnce = true;

    initialSelection = [];

    allowMultiSelect = true;

    selection = new SelectionModel<number>(this.allowMultiSelect, this.initialSelection);

    @Input()
    callToActionText = 'Map';

    @Input()
    showPlatformControl = false;

    private minDate: any;

    isTemplateChange = false;

    isInitialization = true;

    @Output()
    selectedIds: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    //Inject data
    //data.platformId

    constructor(public helperService: HelperService,
                private fb: FormBuilder,
                private businessReqService: BusinessRequirementControllerService,
                private productService: PlatformControllerService,
                private sectionService: BusinessRequirementSectionControllerService,
                private moduleService: ModuleControllerService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private deploymentEnvironmentControllerService: DeploymentEnvironmentControllerService) {

        this.searchManager = new SearchManager<SoftwareModulePojo, SearchSoftwareModuleRequestParams>(this, this)

        this.searchManager.queryResult
            .subscribe((result) => {
                this.setDataSource();
                this.emptyListShowOnce = false;
            })

    }

    @Input()
    set platform(platformId: number) {

        if (platformId) {
            this.platformId = platformId;
            this.form.controls.platformId.setValue(platformId.toString());

            this.fetchSections();
        }
    }

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    ngOnInit() {
        this.fetchProducts();
        this.fetchDeploymentEnvironments();
        this.initFormListeners();
        this.searchManager.reloadAndShowFirstPage();
    }


    fetchProducts() {
        this.platforms$ = this.productService.searchPlatforms({limit: 1000})
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }


    initFormListeners(): void {

        this.form.controls.createdAfter.valueChanges.subscribe((v) => {
            this.minDate = v;
            if (this.form.controls.createdBefore.value) {
                if (this.form.controls.createdBefore.value < v) {
                    this.form.controls.createdBefore.setValue(null);
                }
            }
        });
    }

    fetchDeploymentEnvironments(): void {
        this.environments$ = this.deploymentEnvironmentControllerService.fetchDeploymentEnvironment()
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    this.showAlertMessage(this.helperService.getError(error), AlertType.error);
                    return of( [])
                }),
            );
    }

    fetchSections() {
        this.sections$ = this.sectionService.searchBusinessRequirementSection({
            platformId: this.platformId,
            limit: 1000
        })
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource(this.searchManager.list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    mapTo(): void {
        this.selectedIds.emit(this.selection.selected);
    }

    now(): Date {
        return new Date();
    }

    getMinDate(): any {
        return this.minDate;
    }

    getFilter(): SearchSoftwareModuleRequestParams {
        const form = this.form.getRawValue();
        const data = Object.assign(form);
        data.platformId = Number(form.platformId)
        return data;

    }

    getPersistentKey(): string {
        return ModuleSelectorComponent.name;
    }

    getSearchDescriptor(e: SearchSoftwareModuleRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchSoftwareModuleRequestParams): Observable<QueryResultsSoftwareModulePojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        return this.moduleService.searchSoftwareModule(filter);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row.id));
    }


    clearSelection() {
        this.selection.clear();
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////
}
