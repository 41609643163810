import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.css']
})
export class TimerComponent {


    /**
     * Count down timer
     */


    @Input()
    time: string;

    @Input()
    label = '';

    @Input()
    showUnitLabel = false;

    @Input()
    showDays = false;
}
