import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.css']
})
export class FullPageLoaderComponent {
  @Input() opacity = 1;

  @Input() customText: string | undefined | null;
}
