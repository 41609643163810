/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AssignPlayerToSoftwareProductionUnitDto } from '../model/models';
import { LeadMeasureCommitmentDto } from '../model/models';
import { LeadMeasureCommitmentPojo } from '../model/models';
import { QueryResultsCommitmentByPlayerPojo } from '../model/models';
import { QueryResultsLeadMeasureCommitmentGroupingPojo } from '../model/models';
import { QueryResultsLeadMeasureCommitmentPojo } from '../model/models';
import { UpdateLeadMeasureCommitmentDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AssignPlayerToSoftwareProductionUnitRequestParams {
    assignPlayerToSoftwareProductionUnitDto: AssignPlayerToSoftwareProductionUnitDto;
}

export interface CreateLeadMeasureCommitmentRequestParams {
    leadMeasureCommitmentDto: LeadMeasureCommitmentDto;
}

export interface DeleteCommitmentRequestParams {
    id: number;
}

export interface GetLeadMeasureCommitmentRequestParams {
    id: number;
}

export interface RemovePlayerFromSoftwareProductionUnitRequestParams {
    assignPlayerToSoftwareProductionUnitDto: AssignPlayerToSoftwareProductionUnitDto;
}

export interface SearchCommitmentByPlayerRequestParams {
    id: number;
    benchmarkValue?: number;
    createdAt?: string;
    selectedCustomerValue?: string;
    startTime?: string;
    effortType?: 'NEW' | 'UPDATE';
    recommitment?: boolean;
    checkerNote?: string;
    leadMeasure?: string;
    day?: string;
    originalLeadMeasureCommitment?: string;
    benchmarkPreAcceptanceValue?: number;
    player?: string;
    completedAt?: string;
    currentPreAcceptanceValue?: number;
    cancelledAt?: string;
    affectedExecutionDomainEntity?: string;
    reasonForCancellation?: string;
    initialPreAcceptanceValue?: number;
    checkerAttestation?: boolean;
    dueAt?: string;
    createdBy?: string;
    cadenceEvent?: string;
    cancelled?: boolean;
    comment?: string;
    completionStatus?: 'COMPLETED' | 'NOT_COMPLETED' | 'PRODUCTION_AUTHORIZATION' | 'RELEASED' | 'EXECUTED';
    category?: 'WIG' | 'WHIRLWIND';
    currentValue?: number;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    softwareModuleId?: number;
    playerUserId?: string;
    priorityItems?: Array<object>;
    leadMeasures?: Array<object>;
    completedAtEndDate?: string;
    cancelledAtStartDate?: string;
    isUnReleasedCommitment?: boolean;
    dateOfCommitment?: string;
    softwareProductionUnitId?: number;
    limit?: number;
    cancelledAtEndDate?: string;
    keyword?: string;
    leadMeasureId?: number;
    orderColumn?: string;
    dateOfCommitmentTo?: string;
    completedAtStartDate?: string;
    commitmentToday?: boolean;
    playerId?: number;
    order?: 'ASC' | 'DESC';
    dateOfCommitmentFrom?: string;
    customerValueId?: number;
    offset?: number;
    platformId?: number;
    executedDomainEntityType?: 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'LEAD_MEASURE_COMMITMENT' | 'TICKET' | 'PROCESS_STAGE';
    playerNameLike?: string;
}

export interface SearchCommitmentPlayerGroupingRequestParams {
    benchmarkValue?: number;
    createdAt?: string;
    selectedCustomerValue?: string;
    startTime?: string;
    effortType?: 'NEW' | 'UPDATE';
    recommitment?: boolean;
    id?: number;
    checkerNote?: string;
    leadMeasure?: string;
    day?: string;
    originalLeadMeasureCommitment?: string;
    benchmarkPreAcceptanceValue?: number;
    player?: string;
    completedAt?: string;
    currentPreAcceptanceValue?: number;
    cancelledAt?: string;
    affectedExecutionDomainEntity?: string;
    reasonForCancellation?: string;
    initialPreAcceptanceValue?: number;
    checkerAttestation?: boolean;
    dueAt?: string;
    createdBy?: string;
    cadenceEvent?: string;
    cancelled?: boolean;
    comment?: string;
    completionStatus?: 'COMPLETED' | 'NOT_COMPLETED' | 'PRODUCTION_AUTHORIZATION' | 'RELEASED' | 'EXECUTED';
    category?: 'WIG' | 'WHIRLWIND';
    currentValue?: number;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    softwareModuleId?: number;
    playerUserId?: string;
    priorityItems?: Array<object>;
    leadMeasures?: Array<object>;
    completedAtEndDate?: string;
    cancelledAtStartDate?: string;
    isUnReleasedCommitment?: boolean;
    dateOfCommitment?: string;
    softwareProductionUnitId?: number;
    limit?: number;
    cancelledAtEndDate?: string;
    keyword?: string;
    leadMeasureId?: number;
    orderColumn?: string;
    dateOfCommitmentTo?: string;
    completedAtStartDate?: string;
    commitmentToday?: boolean;
    playerId?: number;
    order?: 'ASC' | 'DESC';
    dateOfCommitmentFrom?: string;
    customerValueId?: number;
    offset?: number;
    platformId?: number;
    executedDomainEntityType?: 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'LEAD_MEASURE_COMMITMENT' | 'TICKET' | 'PROCESS_STAGE';
    playerNameLike?: string;
}

export interface SearchLeadMeasureCommitmentRequestParams {
    benchmarkValue?: number;
    createdAt?: string;
    selectedCustomerValue?: string;
    startTime?: string;
    effortType?: 'NEW' | 'UPDATE';
    recommitment?: boolean;
    id?: number;
    checkerNote?: string;
    leadMeasure?: string;
    day?: string;
    originalLeadMeasureCommitment?: string;
    benchmarkPreAcceptanceValue?: number;
    player?: string;
    completedAt?: string;
    currentPreAcceptanceValue?: number;
    cancelledAt?: string;
    affectedExecutionDomainEntity?: string;
    reasonForCancellation?: string;
    initialPreAcceptanceValue?: number;
    checkerAttestation?: boolean;
    dueAt?: string;
    createdBy?: string;
    cadenceEvent?: string;
    cancelled?: boolean;
    comment?: string;
    completionStatus?: 'COMPLETED' | 'NOT_COMPLETED' | 'PRODUCTION_AUTHORIZATION' | 'RELEASED' | 'EXECUTED';
    category?: 'WIG' | 'WHIRLWIND';
    currentValue?: number;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    softwareModuleId?: number;
    playerUserId?: string;
    priorityItems?: Array<object>;
    leadMeasures?: Array<object>;
    completedAtEndDate?: string;
    cancelledAtStartDate?: string;
    isUnReleasedCommitment?: boolean;
    dateOfCommitment?: string;
    softwareProductionUnitId?: number;
    limit?: number;
    cancelledAtEndDate?: string;
    keyword?: string;
    leadMeasureId?: number;
    orderColumn?: string;
    dateOfCommitmentTo?: string;
    completedAtStartDate?: string;
    commitmentToday?: boolean;
    playerId?: number;
    order?: 'ASC' | 'DESC';
    dateOfCommitmentFrom?: string;
    customerValueId?: number;
    offset?: number;
    platformId?: number;
    executedDomainEntityType?: 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'LEAD_MEASURE_COMMITMENT' | 'TICKET' | 'PROCESS_STAGE';
    playerNameLike?: string;
}

export interface UpdateCommitmentRequestParams {
    id: number;
    updateLeadMeasureCommitmentDto: UpdateLeadMeasureCommitmentDto;
}

export interface UpdateCommitmentStatusRequestParams {
    id: number;
    status: 'COMPLETED' | 'NOT_COMPLETED' | 'PRODUCTION_AUTHORIZATION' | 'RELEASED' | 'EXECUTED';
    note: string;
}


@Injectable({
  providedIn: 'root'
})
export class ExecutionControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignPlayerToSoftwareProductionUnit(requestParameters: AssignPlayerToSoftwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public assignPlayerToSoftwareProductionUnit(requestParameters: AssignPlayerToSoftwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public assignPlayerToSoftwareProductionUnit(requestParameters: AssignPlayerToSoftwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public assignPlayerToSoftwareProductionUnit(requestParameters: AssignPlayerToSoftwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const assignPlayerToSoftwareProductionUnitDto = requestParameters.assignPlayerToSoftwareProductionUnitDto;
        if (assignPlayerToSoftwareProductionUnitDto === null || assignPlayerToSoftwareProductionUnitDto === undefined) {
            throw new Error('Required parameter assignPlayerToSoftwareProductionUnitDto was null or undefined when calling assignPlayerToSoftwareProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/execution/assign-player-to-software-production-unit`,
            assignPlayerToSoftwareProductionUnitDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLeadMeasureCommitment(requestParameters: CreateLeadMeasureCommitmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<LeadMeasureCommitmentPojo>;
    public createLeadMeasureCommitment(requestParameters: CreateLeadMeasureCommitmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<LeadMeasureCommitmentPojo>>;
    public createLeadMeasureCommitment(requestParameters: CreateLeadMeasureCommitmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<LeadMeasureCommitmentPojo>>;
    public createLeadMeasureCommitment(requestParameters: CreateLeadMeasureCommitmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const leadMeasureCommitmentDto = requestParameters.leadMeasureCommitmentDto;
        if (leadMeasureCommitmentDto === null || leadMeasureCommitmentDto === undefined) {
            throw new Error('Required parameter leadMeasureCommitmentDto was null or undefined when calling createLeadMeasureCommitment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<LeadMeasureCommitmentPojo>(`${this.configuration.basePath}/execution/lead-measure-commitment/create`,
            leadMeasureCommitmentDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCommitment(requestParameters: DeleteCommitmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteCommitment(requestParameters: DeleteCommitmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteCommitment(requestParameters: DeleteCommitmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteCommitment(requestParameters: DeleteCommitmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCommitment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<object>(`${this.configuration.basePath}/execution/${encodeURIComponent(String(id))}/delete-commitment`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLeadMeasureCommitment(requestParameters: GetLeadMeasureCommitmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<LeadMeasureCommitmentPojo>;
    public getLeadMeasureCommitment(requestParameters: GetLeadMeasureCommitmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<LeadMeasureCommitmentPojo>>;
    public getLeadMeasureCommitment(requestParameters: GetLeadMeasureCommitmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<LeadMeasureCommitmentPojo>>;
    public getLeadMeasureCommitment(requestParameters: GetLeadMeasureCommitmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getLeadMeasureCommitment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<LeadMeasureCommitmentPojo>(`${this.configuration.basePath}/execution/lead-measure-commitment/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePlayerFromSoftwareProductionUnit(requestParameters: RemovePlayerFromSoftwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public removePlayerFromSoftwareProductionUnit(requestParameters: RemovePlayerFromSoftwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public removePlayerFromSoftwareProductionUnit(requestParameters: RemovePlayerFromSoftwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public removePlayerFromSoftwareProductionUnit(requestParameters: RemovePlayerFromSoftwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const assignPlayerToSoftwareProductionUnitDto = requestParameters.assignPlayerToSoftwareProductionUnitDto;
        if (assignPlayerToSoftwareProductionUnitDto === null || assignPlayerToSoftwareProductionUnitDto === undefined) {
            throw new Error('Required parameter assignPlayerToSoftwareProductionUnitDto was null or undefined when calling removePlayerFromSoftwareProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/execution/remove-player-from-software-production-unit`,
            assignPlayerToSoftwareProductionUnitDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCommitmentByPlayer(requestParameters: SearchCommitmentByPlayerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsCommitmentByPlayerPojo>;
    public searchCommitmentByPlayer(requestParameters: SearchCommitmentByPlayerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsCommitmentByPlayerPojo>>;
    public searchCommitmentByPlayer(requestParameters: SearchCommitmentByPlayerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsCommitmentByPlayerPojo>>;
    public searchCommitmentByPlayer(requestParameters: SearchCommitmentByPlayerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling searchCommitmentByPlayer.');
        }
        const benchmarkValue = requestParameters.benchmarkValue;
        const createdAt = requestParameters.createdAt;
        const selectedCustomerValue = requestParameters.selectedCustomerValue;
        const startTime = requestParameters.startTime;
        const effortType = requestParameters.effortType;
        const recommitment = requestParameters.recommitment;
        const checkerNote = requestParameters.checkerNote;
        const leadMeasure = requestParameters.leadMeasure;
        const day = requestParameters.day;
        const originalLeadMeasureCommitment = requestParameters.originalLeadMeasureCommitment;
        const benchmarkPreAcceptanceValue = requestParameters.benchmarkPreAcceptanceValue;
        const player = requestParameters.player;
        const completedAt = requestParameters.completedAt;
        const currentPreAcceptanceValue = requestParameters.currentPreAcceptanceValue;
        const cancelledAt = requestParameters.cancelledAt;
        const affectedExecutionDomainEntity = requestParameters.affectedExecutionDomainEntity;
        const reasonForCancellation = requestParameters.reasonForCancellation;
        const initialPreAcceptanceValue = requestParameters.initialPreAcceptanceValue;
        const checkerAttestation = requestParameters.checkerAttestation;
        const dueAt = requestParameters.dueAt;
        const createdBy = requestParameters.createdBy;
        const cadenceEvent = requestParameters.cadenceEvent;
        const cancelled = requestParameters.cancelled;
        const comment = requestParameters.comment;
        const completionStatus = requestParameters.completionStatus;
        const category = requestParameters.category;
        const currentValue = requestParameters.currentValue;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const softwareModuleId = requestParameters.softwareModuleId;
        const playerUserId = requestParameters.playerUserId;
        const priorityItems = requestParameters.priorityItems;
        const leadMeasures = requestParameters.leadMeasures;
        const completedAtEndDate = requestParameters.completedAtEndDate;
        const cancelledAtStartDate = requestParameters.cancelledAtStartDate;
        const isUnReleasedCommitment = requestParameters.isUnReleasedCommitment;
        const dateOfCommitment = requestParameters.dateOfCommitment;
        const softwareProductionUnitId = requestParameters.softwareProductionUnitId;
        const limit = requestParameters.limit;
        const cancelledAtEndDate = requestParameters.cancelledAtEndDate;
        const keyword = requestParameters.keyword;
        const leadMeasureId = requestParameters.leadMeasureId;
        const orderColumn = requestParameters.orderColumn;
        const dateOfCommitmentTo = requestParameters.dateOfCommitmentTo;
        const completedAtStartDate = requestParameters.completedAtStartDate;
        const commitmentToday = requestParameters.commitmentToday;
        const playerId = requestParameters.playerId;
        const order = requestParameters.order;
        const dateOfCommitmentFrom = requestParameters.dateOfCommitmentFrom;
        const customerValueId = requestParameters.customerValueId;
        const offset = requestParameters.offset;
        const platformId = requestParameters.platformId;
        const executedDomainEntityType = requestParameters.executedDomainEntityType;
        const playerNameLike = requestParameters.playerNameLike;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (benchmarkValue !== undefined && benchmarkValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>benchmarkValue, 'benchmarkValue');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (selectedCustomerValue !== undefined && selectedCustomerValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>selectedCustomerValue, 'selectedCustomerValue');
        }
        if (startTime !== undefined && startTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startTime, 'startTime');
        }
        if (effortType !== undefined && effortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>effortType, 'effortType');
        }
        if (recommitment !== undefined && recommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recommitment, 'recommitment');
        }
        if (checkerNote !== undefined && checkerNote !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>checkerNote, 'checkerNote');
        }
        if (leadMeasure !== undefined && leadMeasure !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasure, 'leadMeasure');
        }
        if (day !== undefined && day !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day, 'day');
        }
        if (originalLeadMeasureCommitment !== undefined && originalLeadMeasureCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>originalLeadMeasureCommitment, 'originalLeadMeasureCommitment');
        }
        if (benchmarkPreAcceptanceValue !== undefined && benchmarkPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>benchmarkPreAcceptanceValue, 'benchmarkPreAcceptanceValue');
        }
        if (player !== undefined && player !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>player, 'player');
        }
        if (completedAt !== undefined && completedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAt, 'completedAt');
        }
        if (currentPreAcceptanceValue !== undefined && currentPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentPreAcceptanceValue, 'currentPreAcceptanceValue');
        }
        if (cancelledAt !== undefined && cancelledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAt, 'cancelledAt');
        }
        if (affectedExecutionDomainEntity !== undefined && affectedExecutionDomainEntity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedExecutionDomainEntity, 'affectedExecutionDomainEntity');
        }
        if (reasonForCancellation !== undefined && reasonForCancellation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForCancellation, 'reasonForCancellation');
        }
        if (initialPreAcceptanceValue !== undefined && initialPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>initialPreAcceptanceValue, 'initialPreAcceptanceValue');
        }
        if (checkerAttestation !== undefined && checkerAttestation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>checkerAttestation, 'checkerAttestation');
        }
        if (dueAt !== undefined && dueAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueAt, 'dueAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (cadenceEvent !== undefined && cadenceEvent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cadenceEvent, 'cadenceEvent');
        }
        if (cancelled !== undefined && cancelled !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelled, 'cancelled');
        }
        if (comment !== undefined && comment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>comment, 'comment');
        }
        if (completionStatus !== undefined && completionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionStatus, 'completionStatus');
        }
        if (category !== undefined && category !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>category, 'category');
        }
        if (currentValue !== undefined && currentValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentValue, 'currentValue');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (softwareModuleId !== undefined && softwareModuleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareModuleId, 'softwareModuleId');
        }
        if (playerUserId !== undefined && playerUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerUserId, 'playerUserId');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (leadMeasures) {
            leadMeasures.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'leadMeasures');
            })
        }
        if (completedAtEndDate !== undefined && completedAtEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAtEndDate, 'completedAtEndDate');
        }
        if (cancelledAtStartDate !== undefined && cancelledAtStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAtStartDate, 'cancelledAtStartDate');
        }
        if (isUnReleasedCommitment !== undefined && isUnReleasedCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isUnReleasedCommitment, 'isUnReleasedCommitment');
        }
        if (dateOfCommitment !== undefined && dateOfCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitment, 'dateOfCommitment');
        }
        if (softwareProductionUnitId !== undefined && softwareProductionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareProductionUnitId, 'softwareProductionUnitId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cancelledAtEndDate !== undefined && cancelledAtEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAtEndDate, 'cancelledAtEndDate');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (leadMeasureId !== undefined && leadMeasureId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasureId, 'leadMeasureId');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (dateOfCommitmentTo !== undefined && dateOfCommitmentTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitmentTo, 'dateOfCommitmentTo');
        }
        if (completedAtStartDate !== undefined && completedAtStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAtStartDate, 'completedAtStartDate');
        }
        if (commitmentToday !== undefined && commitmentToday !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>commitmentToday, 'commitmentToday');
        }
        if (playerId !== undefined && playerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerId, 'playerId');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }
        if (dateOfCommitmentFrom !== undefined && dateOfCommitmentFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitmentFrom, 'dateOfCommitmentFrom');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (executedDomainEntityType !== undefined && executedDomainEntityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>executedDomainEntityType, 'executedDomainEntityType');
        }
        if (playerNameLike !== undefined && playerNameLike !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerNameLike, 'playerNameLike');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsCommitmentByPlayerPojo>(`${this.configuration.basePath}/execution/lead-measure-commitment/by-player/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCommitmentPlayerGrouping(requestParameters: SearchCommitmentPlayerGroupingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsLeadMeasureCommitmentGroupingPojo>;
    public searchCommitmentPlayerGrouping(requestParameters: SearchCommitmentPlayerGroupingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsLeadMeasureCommitmentGroupingPojo>>;
    public searchCommitmentPlayerGrouping(requestParameters: SearchCommitmentPlayerGroupingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsLeadMeasureCommitmentGroupingPojo>>;
    public searchCommitmentPlayerGrouping(requestParameters: SearchCommitmentPlayerGroupingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const benchmarkValue = requestParameters.benchmarkValue;
        const createdAt = requestParameters.createdAt;
        const selectedCustomerValue = requestParameters.selectedCustomerValue;
        const startTime = requestParameters.startTime;
        const effortType = requestParameters.effortType;
        const recommitment = requestParameters.recommitment;
        const id = requestParameters.id;
        const checkerNote = requestParameters.checkerNote;
        const leadMeasure = requestParameters.leadMeasure;
        const day = requestParameters.day;
        const originalLeadMeasureCommitment = requestParameters.originalLeadMeasureCommitment;
        const benchmarkPreAcceptanceValue = requestParameters.benchmarkPreAcceptanceValue;
        const player = requestParameters.player;
        const completedAt = requestParameters.completedAt;
        const currentPreAcceptanceValue = requestParameters.currentPreAcceptanceValue;
        const cancelledAt = requestParameters.cancelledAt;
        const affectedExecutionDomainEntity = requestParameters.affectedExecutionDomainEntity;
        const reasonForCancellation = requestParameters.reasonForCancellation;
        const initialPreAcceptanceValue = requestParameters.initialPreAcceptanceValue;
        const checkerAttestation = requestParameters.checkerAttestation;
        const dueAt = requestParameters.dueAt;
        const createdBy = requestParameters.createdBy;
        const cadenceEvent = requestParameters.cadenceEvent;
        const cancelled = requestParameters.cancelled;
        const comment = requestParameters.comment;
        const completionStatus = requestParameters.completionStatus;
        const category = requestParameters.category;
        const currentValue = requestParameters.currentValue;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const softwareModuleId = requestParameters.softwareModuleId;
        const playerUserId = requestParameters.playerUserId;
        const priorityItems = requestParameters.priorityItems;
        const leadMeasures = requestParameters.leadMeasures;
        const completedAtEndDate = requestParameters.completedAtEndDate;
        const cancelledAtStartDate = requestParameters.cancelledAtStartDate;
        const isUnReleasedCommitment = requestParameters.isUnReleasedCommitment;
        const dateOfCommitment = requestParameters.dateOfCommitment;
        const softwareProductionUnitId = requestParameters.softwareProductionUnitId;
        const limit = requestParameters.limit;
        const cancelledAtEndDate = requestParameters.cancelledAtEndDate;
        const keyword = requestParameters.keyword;
        const leadMeasureId = requestParameters.leadMeasureId;
        const orderColumn = requestParameters.orderColumn;
        const dateOfCommitmentTo = requestParameters.dateOfCommitmentTo;
        const completedAtStartDate = requestParameters.completedAtStartDate;
        const commitmentToday = requestParameters.commitmentToday;
        const playerId = requestParameters.playerId;
        const order = requestParameters.order;
        const dateOfCommitmentFrom = requestParameters.dateOfCommitmentFrom;
        const customerValueId = requestParameters.customerValueId;
        const offset = requestParameters.offset;
        const platformId = requestParameters.platformId;
        const executedDomainEntityType = requestParameters.executedDomainEntityType;
        const playerNameLike = requestParameters.playerNameLike;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (benchmarkValue !== undefined && benchmarkValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>benchmarkValue, 'benchmarkValue');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (selectedCustomerValue !== undefined && selectedCustomerValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>selectedCustomerValue, 'selectedCustomerValue');
        }
        if (startTime !== undefined && startTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startTime, 'startTime');
        }
        if (effortType !== undefined && effortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>effortType, 'effortType');
        }
        if (recommitment !== undefined && recommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recommitment, 'recommitment');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (checkerNote !== undefined && checkerNote !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>checkerNote, 'checkerNote');
        }
        if (leadMeasure !== undefined && leadMeasure !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasure, 'leadMeasure');
        }
        if (day !== undefined && day !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day, 'day');
        }
        if (originalLeadMeasureCommitment !== undefined && originalLeadMeasureCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>originalLeadMeasureCommitment, 'originalLeadMeasureCommitment');
        }
        if (benchmarkPreAcceptanceValue !== undefined && benchmarkPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>benchmarkPreAcceptanceValue, 'benchmarkPreAcceptanceValue');
        }
        if (player !== undefined && player !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>player, 'player');
        }
        if (completedAt !== undefined && completedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAt, 'completedAt');
        }
        if (currentPreAcceptanceValue !== undefined && currentPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentPreAcceptanceValue, 'currentPreAcceptanceValue');
        }
        if (cancelledAt !== undefined && cancelledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAt, 'cancelledAt');
        }
        if (affectedExecutionDomainEntity !== undefined && affectedExecutionDomainEntity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedExecutionDomainEntity, 'affectedExecutionDomainEntity');
        }
        if (reasonForCancellation !== undefined && reasonForCancellation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForCancellation, 'reasonForCancellation');
        }
        if (initialPreAcceptanceValue !== undefined && initialPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>initialPreAcceptanceValue, 'initialPreAcceptanceValue');
        }
        if (checkerAttestation !== undefined && checkerAttestation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>checkerAttestation, 'checkerAttestation');
        }
        if (dueAt !== undefined && dueAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueAt, 'dueAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (cadenceEvent !== undefined && cadenceEvent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cadenceEvent, 'cadenceEvent');
        }
        if (cancelled !== undefined && cancelled !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelled, 'cancelled');
        }
        if (comment !== undefined && comment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>comment, 'comment');
        }
        if (completionStatus !== undefined && completionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionStatus, 'completionStatus');
        }
        if (category !== undefined && category !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>category, 'category');
        }
        if (currentValue !== undefined && currentValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentValue, 'currentValue');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (softwareModuleId !== undefined && softwareModuleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareModuleId, 'softwareModuleId');
        }
        if (playerUserId !== undefined && playerUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerUserId, 'playerUserId');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (leadMeasures) {
            leadMeasures.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'leadMeasures');
            })
        }
        if (completedAtEndDate !== undefined && completedAtEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAtEndDate, 'completedAtEndDate');
        }
        if (cancelledAtStartDate !== undefined && cancelledAtStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAtStartDate, 'cancelledAtStartDate');
        }
        if (isUnReleasedCommitment !== undefined && isUnReleasedCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isUnReleasedCommitment, 'isUnReleasedCommitment');
        }
        if (dateOfCommitment !== undefined && dateOfCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitment, 'dateOfCommitment');
        }
        if (softwareProductionUnitId !== undefined && softwareProductionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareProductionUnitId, 'softwareProductionUnitId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cancelledAtEndDate !== undefined && cancelledAtEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAtEndDate, 'cancelledAtEndDate');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (leadMeasureId !== undefined && leadMeasureId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasureId, 'leadMeasureId');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (dateOfCommitmentTo !== undefined && dateOfCommitmentTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitmentTo, 'dateOfCommitmentTo');
        }
        if (completedAtStartDate !== undefined && completedAtStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAtStartDate, 'completedAtStartDate');
        }
        if (commitmentToday !== undefined && commitmentToday !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>commitmentToday, 'commitmentToday');
        }
        if (playerId !== undefined && playerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerId, 'playerId');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }
        if (dateOfCommitmentFrom !== undefined && dateOfCommitmentFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitmentFrom, 'dateOfCommitmentFrom');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (executedDomainEntityType !== undefined && executedDomainEntityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>executedDomainEntityType, 'executedDomainEntityType');
        }
        if (playerNameLike !== undefined && playerNameLike !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerNameLike, 'playerNameLike');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsLeadMeasureCommitmentGroupingPojo>(`${this.configuration.basePath}/execution/lead-measure-commitment/player-grouping`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLeadMeasureCommitment(requestParameters: SearchLeadMeasureCommitmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsLeadMeasureCommitmentPojo>;
    public searchLeadMeasureCommitment(requestParameters: SearchLeadMeasureCommitmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsLeadMeasureCommitmentPojo>>;
    public searchLeadMeasureCommitment(requestParameters: SearchLeadMeasureCommitmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsLeadMeasureCommitmentPojo>>;
    public searchLeadMeasureCommitment(requestParameters: SearchLeadMeasureCommitmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const benchmarkValue = requestParameters.benchmarkValue;
        const createdAt = requestParameters.createdAt;
        const selectedCustomerValue = requestParameters.selectedCustomerValue;
        const startTime = requestParameters.startTime;
        const effortType = requestParameters.effortType;
        const recommitment = requestParameters.recommitment;
        const id = requestParameters.id;
        const checkerNote = requestParameters.checkerNote;
        const leadMeasure = requestParameters.leadMeasure;
        const day = requestParameters.day;
        const originalLeadMeasureCommitment = requestParameters.originalLeadMeasureCommitment;
        const benchmarkPreAcceptanceValue = requestParameters.benchmarkPreAcceptanceValue;
        const player = requestParameters.player;
        const completedAt = requestParameters.completedAt;
        const currentPreAcceptanceValue = requestParameters.currentPreAcceptanceValue;
        const cancelledAt = requestParameters.cancelledAt;
        const affectedExecutionDomainEntity = requestParameters.affectedExecutionDomainEntity;
        const reasonForCancellation = requestParameters.reasonForCancellation;
        const initialPreAcceptanceValue = requestParameters.initialPreAcceptanceValue;
        const checkerAttestation = requestParameters.checkerAttestation;
        const dueAt = requestParameters.dueAt;
        const createdBy = requestParameters.createdBy;
        const cadenceEvent = requestParameters.cadenceEvent;
        const cancelled = requestParameters.cancelled;
        const comment = requestParameters.comment;
        const completionStatus = requestParameters.completionStatus;
        const category = requestParameters.category;
        const currentValue = requestParameters.currentValue;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const softwareModuleId = requestParameters.softwareModuleId;
        const playerUserId = requestParameters.playerUserId;
        const priorityItems = requestParameters.priorityItems;
        const leadMeasures = requestParameters.leadMeasures;
        const completedAtEndDate = requestParameters.completedAtEndDate;
        const cancelledAtStartDate = requestParameters.cancelledAtStartDate;
        const isUnReleasedCommitment = requestParameters.isUnReleasedCommitment;
        const dateOfCommitment = requestParameters.dateOfCommitment;
        const softwareProductionUnitId = requestParameters.softwareProductionUnitId;
        const limit = requestParameters.limit;
        const cancelledAtEndDate = requestParameters.cancelledAtEndDate;
        const keyword = requestParameters.keyword;
        const leadMeasureId = requestParameters.leadMeasureId;
        const orderColumn = requestParameters.orderColumn;
        const dateOfCommitmentTo = requestParameters.dateOfCommitmentTo;
        const completedAtStartDate = requestParameters.completedAtStartDate;
        const commitmentToday = requestParameters.commitmentToday;
        const playerId = requestParameters.playerId;
        const order = requestParameters.order;
        const dateOfCommitmentFrom = requestParameters.dateOfCommitmentFrom;
        const customerValueId = requestParameters.customerValueId;
        const offset = requestParameters.offset;
        const platformId = requestParameters.platformId;
        const executedDomainEntityType = requestParameters.executedDomainEntityType;
        const playerNameLike = requestParameters.playerNameLike;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (benchmarkValue !== undefined && benchmarkValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>benchmarkValue, 'benchmarkValue');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (selectedCustomerValue !== undefined && selectedCustomerValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>selectedCustomerValue, 'selectedCustomerValue');
        }
        if (startTime !== undefined && startTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startTime, 'startTime');
        }
        if (effortType !== undefined && effortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>effortType, 'effortType');
        }
        if (recommitment !== undefined && recommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recommitment, 'recommitment');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (checkerNote !== undefined && checkerNote !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>checkerNote, 'checkerNote');
        }
        if (leadMeasure !== undefined && leadMeasure !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasure, 'leadMeasure');
        }
        if (day !== undefined && day !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day, 'day');
        }
        if (originalLeadMeasureCommitment !== undefined && originalLeadMeasureCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>originalLeadMeasureCommitment, 'originalLeadMeasureCommitment');
        }
        if (benchmarkPreAcceptanceValue !== undefined && benchmarkPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>benchmarkPreAcceptanceValue, 'benchmarkPreAcceptanceValue');
        }
        if (player !== undefined && player !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>player, 'player');
        }
        if (completedAt !== undefined && completedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAt, 'completedAt');
        }
        if (currentPreAcceptanceValue !== undefined && currentPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentPreAcceptanceValue, 'currentPreAcceptanceValue');
        }
        if (cancelledAt !== undefined && cancelledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAt, 'cancelledAt');
        }
        if (affectedExecutionDomainEntity !== undefined && affectedExecutionDomainEntity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedExecutionDomainEntity, 'affectedExecutionDomainEntity');
        }
        if (reasonForCancellation !== undefined && reasonForCancellation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForCancellation, 'reasonForCancellation');
        }
        if (initialPreAcceptanceValue !== undefined && initialPreAcceptanceValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>initialPreAcceptanceValue, 'initialPreAcceptanceValue');
        }
        if (checkerAttestation !== undefined && checkerAttestation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>checkerAttestation, 'checkerAttestation');
        }
        if (dueAt !== undefined && dueAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueAt, 'dueAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (cadenceEvent !== undefined && cadenceEvent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cadenceEvent, 'cadenceEvent');
        }
        if (cancelled !== undefined && cancelled !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelled, 'cancelled');
        }
        if (comment !== undefined && comment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>comment, 'comment');
        }
        if (completionStatus !== undefined && completionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionStatus, 'completionStatus');
        }
        if (category !== undefined && category !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>category, 'category');
        }
        if (currentValue !== undefined && currentValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentValue, 'currentValue');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (softwareModuleId !== undefined && softwareModuleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareModuleId, 'softwareModuleId');
        }
        if (playerUserId !== undefined && playerUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerUserId, 'playerUserId');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (leadMeasures) {
            leadMeasures.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'leadMeasures');
            })
        }
        if (completedAtEndDate !== undefined && completedAtEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAtEndDate, 'completedAtEndDate');
        }
        if (cancelledAtStartDate !== undefined && cancelledAtStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAtStartDate, 'cancelledAtStartDate');
        }
        if (isUnReleasedCommitment !== undefined && isUnReleasedCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isUnReleasedCommitment, 'isUnReleasedCommitment');
        }
        if (dateOfCommitment !== undefined && dateOfCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitment, 'dateOfCommitment');
        }
        if (softwareProductionUnitId !== undefined && softwareProductionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareProductionUnitId, 'softwareProductionUnitId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cancelledAtEndDate !== undefined && cancelledAtEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cancelledAtEndDate, 'cancelledAtEndDate');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (leadMeasureId !== undefined && leadMeasureId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasureId, 'leadMeasureId');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (dateOfCommitmentTo !== undefined && dateOfCommitmentTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitmentTo, 'dateOfCommitmentTo');
        }
        if (completedAtStartDate !== undefined && completedAtStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAtStartDate, 'completedAtStartDate');
        }
        if (commitmentToday !== undefined && commitmentToday !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>commitmentToday, 'commitmentToday');
        }
        if (playerId !== undefined && playerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerId, 'playerId');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }
        if (dateOfCommitmentFrom !== undefined && dateOfCommitmentFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfCommitmentFrom, 'dateOfCommitmentFrom');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (executedDomainEntityType !== undefined && executedDomainEntityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>executedDomainEntityType, 'executedDomainEntityType');
        }
        if (playerNameLike !== undefined && playerNameLike !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerNameLike, 'playerNameLike');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsLeadMeasureCommitmentPojo>(`${this.configuration.basePath}/execution/lead-measure-commitment`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCommitment(requestParameters: UpdateCommitmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<LeadMeasureCommitmentPojo>;
    public updateCommitment(requestParameters: UpdateCommitmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<LeadMeasureCommitmentPojo>>;
    public updateCommitment(requestParameters: UpdateCommitmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<LeadMeasureCommitmentPojo>>;
    public updateCommitment(requestParameters: UpdateCommitmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCommitment.');
        }
        const updateLeadMeasureCommitmentDto = requestParameters.updateLeadMeasureCommitmentDto;
        if (updateLeadMeasureCommitmentDto === null || updateLeadMeasureCommitmentDto === undefined) {
            throw new Error('Required parameter updateLeadMeasureCommitmentDto was null or undefined when calling updateCommitment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<LeadMeasureCommitmentPojo>(`${this.configuration.basePath}/execution/${encodeURIComponent(String(id))}/update-commitment`,
            updateLeadMeasureCommitmentDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCommitmentStatus(requestParameters: UpdateCommitmentStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateCommitmentStatus(requestParameters: UpdateCommitmentStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateCommitmentStatus(requestParameters: UpdateCommitmentStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateCommitmentStatus(requestParameters: UpdateCommitmentStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCommitmentStatus.');
        }
        const status = requestParameters.status;
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateCommitmentStatus.');
        }
        const note = requestParameters.note;
        if (note === null || note === undefined) {
            throw new Error('Required parameter note was null or undefined when calling updateCommitmentStatus.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (note !== undefined && note !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>note, 'note');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/execution/${encodeURIComponent(String(id))}/update-commitment-status`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
