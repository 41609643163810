/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountTypeRole } from './portal-account-type-role';


export interface LeadMeasurePojo { 
    id?: number;
    createdAt?: string;
    currentValue?: number;
    desiredDirection?: LeadMeasurePojo.DesiredDirectionEnum;
    status?: LeadMeasurePojo.StatusEnum;
    roles?: Array<PortalAccountTypeRole>;
    name?: string;
    createdByEmail?: string;
    description?: string;
    code?: string;
}
export namespace LeadMeasurePojo {
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        Up: 'UP' as DesiredDirectionEnum,
        Down: 'DOWN' as DesiredDirectionEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


