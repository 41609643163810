import {Component, Input} from '@angular/core';
import {CustomerValuePojo} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";

@Component({
  selector: 'app-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.css']
})
export class ModuleCardComponent {

  @Input()
  c5: CustomerValuePojo
}
