import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploadComponent
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {
  value: any | undefined;
  private onChange!: (file: any) => void;
  private onTouched: (() => void) | undefined;
  @Input()
  onChangeEmitter!: EventEmitter<File | string | undefined>;
  @Output() onErrorEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClickEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() maxFileSizeInKB = 2560;
  maxFileSizeInBytes!: number;
  @Input() cropperMaxWidth = 300;
  @Input() cropperMaxHeight = 300;
  @Input() cropperAspectRatio = 1;
  @Input() maintainAspectRatio = true;
  @Input() cropImage = true;
  @Input() profileImage = false;
  @Input()
  isReadOnly = false;
  @Input()
  showImageUploadButton = false;
  allowedFileTypes: string[] = ['image/png, image/jpeg, image/jpg'];
  isInvalidSize = false;
  isInvalidType = false;
  @Input()
  imageId: number;


  constructor(
    private modal: BsModalService,
    private translationService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.translationService.onLangChange.subscribe((value) => {
      this.reEmitErrorMessage();
    });
  }

  ngOnInit(): void {
    this.maxFileSizeInBytes = this.maxFileSizeInKB * 1024;
  }

  touched = false;

  disabled = false;


  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      if (this.onTouched) {
        this.onTouched();
      }
      this.touched = true;
    }
  }

  getReadableFileSize(size: number): string {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return Number((size / Math.pow(1024, i)).toFixed(2)) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  setValue(value: any): void {
    this.value = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  getErrorMessage(type: ErrorMessageType): string {
    return new TranslatePipe(this.translationService, this.changeDetectorRef).transform(type, {
      size: this.getReadableFileSize(this.maxFileSizeInBytes)
    });
  }

  emitErrorMessage(type: ErrorMessageType): void {
    const errorMessage: string = this.getErrorMessage(type);
    console.log('Error message ', errorMessage);
    this.onErrorEmitter.emit(errorMessage);
  }

  reEmitErrorMessage(): void {
    if (this.isInvalidType) {
      this.emitErrorMessage(ErrorMessageType.FILE_TYPE);
    } else if (this.isInvalidSize) {
      this.emitErrorMessage(ErrorMessageType.SIZE);
    }
  }

  onFileSelected($event: NgxDropzoneChangeEvent): void {
    this.markAsTouched();
    console.log("Event file ", $event.addedFiles)
    if ($event.rejectedFiles && $event.rejectedFiles.length) {
      if ($event.rejectedFiles[0].size > this.maxFileSizeInBytes) {
        this.emitErrorMessage(ErrorMessageType.SIZE);
        this.isInvalidSize = true;
        return;
      }

      if (!this.allowedFileTypes.includes($event.rejectedFiles[0].type)) {
        this.emitErrorMessage(ErrorMessageType.FILE_TYPE);
        this.isInvalidType = true;
        return;
      }
    }

    this.isInvalidSize = false;
    this.isInvalidType = false;
    const f = $event.addedFiles[0];
    // let hasError = false;
    // if (
    //   this.minimumImageHeight != null ||
    //   this.maximumImageHeight != null ||
    //   this.minimumImageWidth != null ||
    //   this.maximumImageWidth != null
    // ) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(f);
    //   reader.onload = (): void => {
    //     const img = new Image();
    //     img.src = reader.result as string;
    //     img.onload = (): void => {
    //       const height = img.naturalHeight;
    //       const width = img.naturalWidth;
    //       if (this.minimumImageWidth != null) {
    //         hasError = width < this.minimumImageWidth;
    //         this.onErrorEmitter.emit(`Image width cannot be less than ${this.minimumImageWidth}`);
    //         return;
    //       }
    //       if (this.maximumImageHeight != null) {
    //         hasError = height > this.maximumImageHeight;
    //         this.onErrorEmitter.emit(`Image height cannot be more than ${this.maximumImageHeight}`);
    //         return;
    //       }
    //       if (this.minimumImageHeight != null) {
    //         hasError = height < this.minimumImageHeight;
    //         this.onErrorEmitter.emit(`Image height cannot be less than ${this.minimumImageHeight}`);
    //         return;
    //       }
    //       if (this.maximumImageWidth != null) {
    //         hasError = width > this.maximumImageWidth;
    //         this.onErrorEmitter.emit(`Image width cannot be more than ${this.maximumImageWidth}`);
    //         return;
    //       }
    //     };
    //   };
    // }
    // if (hasError) {
    //   return;
    // }
    if (this.cropImage) {
      const ref = this.modal.show(ImageCropperComponent, {
        initialState: {
          imageFile: f,
          maxHeight: this.cropperMaxHeight,
          maxWidth: this.cropperMaxWidth,
          aspectRatio: this.cropperAspectRatio,
          maintainAspectRatio: this.maintainAspectRatio
        }
      });
      ref?.content?.imageCropped.subscribe((v) => {
        if (this.onChangeEmitter) {
          this.onChangeEmitter.emit(v);
        }
        this.setValue(v);
      });
    } else {
      // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      this.getBase64(f)
        .then((value1) => {
          if (this.onChangeEmitter) {
            this.onChangeEmitter.emit(value1 as any);
          }
          this.setValue(value1);
          return null;
        })
        .catch((reason) => console.log(reason));
    }

    // ref?.content?.imageCroppedAsBlob.subscribe((v) => {
    //   const file = new File([v], f.name, { lastModified: f.lastModified });
    //   this.setValue(file);
    // });
  }

  getBase64(file: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result ||'');
      reader.onerror = (error): void => reject(error);
    });
  }


  getFileURL() {
    if (this.imageId != null) {
      return environment.siteUrl + "/files/" + this.imageId;
    }
    return "./assets/img/user_avatar.png";
  }

}

export enum ErrorMessageType {
  SIZE = 'form.error.fileSize',
  FILE_TYPE = 'form.error.imageOnly'
}
