/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { BwBinaryData } from './bw-binary-data';


export interface PortalUser { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: PortalUser.StatusEnum;
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    userId?: string;
    generatedPassword?: string;
    username?: string;
    dateOfBirth?: string;
    displayName?: string;
    gender?: PortalUser.GenderEnum;
    setupComplete?: boolean;
    emailVerified?: boolean;
    preferredName?: string;
    userVerified?: boolean;
    image?: BwBinaryData;
    address?: Address;
    signature?: BwBinaryData;
}
export namespace PortalUser {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


