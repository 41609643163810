<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title class="fs-16">
        {{ title }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>

</div>
<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getAlertMessage() }}
</div>

<mat-dialog-content>
    <app-create-uat-key-result
        #createUatKeyResultComponent
        [keyResults]="keyResults"
        [uatRequestId]="uatRequestId"
        (showAlertMessage)="showAlertMessage($event.msg, $event.type)"
        (creationSuccess)="creationSuccess()"
    ></app-create-uat-key-result>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex justify-content-around w-100">
        <div class="m-3">
            <button class="btn btn-lg btn-dark" (click)="manageEntry()">
                Done
            </button>
        </div>
    </div>

</mat-dialog-actions>
