import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';

@Directive({
  selector: '[authLink]'
})
export class AuthLinkDirective implements OnInit, OnDestroy {
  // @HostBinding('rel')
  // rel = '';

  // @HostBinding('target')
  @Input()
  @HostBinding('href')
  href?: string;
  // target = '_self';
  private subscriptions = new Subscription();

  constructor(private elRef: ElementRef, private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.authenticationService.getToken()?.subscribe((value) => {
        const url = new URL(
          `${(this.href?.startsWith('http') ? '' : window.location.origin) + this.href}`
        );
        url.searchParams.delete('access_token');
        if (value) {
          url.searchParams.set('access_token', value);
        }
        this.href = url.href.replace(window.location.origin, '');
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
