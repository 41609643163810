/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountType?: MembershipPojo.AccountTypeEnum;
    roles?: Array<string>;
}
export namespace MembershipPojo {
    export type AccountTypeEnum = 'SYSTEM' | 'ORGANIZATION' | 'USER';
    export const AccountTypeEnum = {
        System: 'SYSTEM' as AccountTypeEnum,
        Organization: 'ORGANIZATION' as AccountTypeEnum,
        User: 'USER' as AccountTypeEnum
    };
}


