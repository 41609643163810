<div class="">
    <div class="card rounded-4 border-0">
        <div class="card-body px-4 py-4">
            <div *ngIf="showAlertMessageTrigger"
                 [ngClass]="{ anim: showAlertMessageTrigger }"
                 [class.alert-success]="getMessageType() === AlertType.success"
                 [class.alert-danger]="getMessageType() === AlertType.error"
                 [class.alert-primary]="getMessageType() === AlertType.primary"
                 [class.alert-info]="getMessageType() === AlertType.info"
                 class="alert mb-4 fs-14 text-center"
            >
                {{ getAlertMessage() }}
            </div>
            <ng-container *ngIf="!searchManager._working; else searching">
                <ng-container *ngIf="searchManager.list.length > 0; else noData">
                    <ng-container *ngFor="let item of searchManager.list; let k = index">
                        <ng-container *ngIf="item?.businessRequirementPojo?.length>0">
                            <div>
                                <div class="text-md-center fw-medium  text-sm-center fs-5">
                                    Section: {{ item.sectionPojo?.name }}
                                </div>
                            </div>
                            <div class="table-responsive  my-3">
                                <table class="table table-striped">
                                    <thead>
                                    <tr class="fw-medium">
                                        <th scope="col" style="width:10%">S/N</th>
                                        <th scope="col" style="width:20%">Requirement ID</th>
                                        <th scope="col" style="width:40%">Requirement Description</th>
                                        <th scope="col" style="width:20%">Parent</th>
                                        <th *ngIf="!isProduction" scope="col" style="width:20%">Actor</th>
                                        <th *ngIf="!isProduction" scope="col" style="width:10%">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-content-center th-1"
                                        *ngFor="let ff of item?.businessRequirementPojo; let i = index">
                                        <td data-label="S/N">{{ i + searchManager.offset + 1 }}</td>
                                        <td data-label="Requirement ID">{{ ff.requirementNumDisplayName | uppercase }}</td>
                                        <td data-label="Requirement Description" class="id">
                                            {{ helperService.cutText(ff?.requirement, 40) }}
                                            <span *ngIf="ff?.requirement?.length >= 41" [placement]="'right'"
                                                  [tooltip]="ff?.requirement">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                        </td>
                                        <td data-label="Parent">{{ ff.parentRequirement?.name || '-' }}</td>
                                        <td *ngIf="!isProduction" data-label="Actor">{{ ff.actor || '-' }}</td>
                                        <td *ngIf="!isProduction" data-label="Action" (click)="deleteMapping(ff.id)">
                                            <img class="pointer" src="assets/img/openmoji_delete.png"
                                                 style="max-width: 20px;"></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="searchManager.totalCount > 0 && !searchManager.working" class="row py-2">
            <div class="col-12 d-flex justify-content-end">
                <pagination
                        [(ngModel)]="searchManager.page"
                        [boundaryLinks]="true"
                        [itemsPerPage]="searchManager.itemsPerPage"
                        [maxSize]="maxPageSize"
                        [rotate]="true"
                        [totalItems]="searchManager.totalCount"
                        firstText="&laquo;"
                        lastText="&raquo;"
                        nextText="&rsaquo;"
                        previousText="&lsaquo;"
                ></pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #searching>
    <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>

<ng-template #noData>
    <div class="mt-3 text-center" style="margin: auto; max-width: 900px">
        <div class="fs-18 text-md-center">No Requirement Linked</div>
    </div>
</ng-template>
