/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PULifecycleEntryPojo } from './pu-lifecycle-entry-pojo';


export interface ProductionUnitLifecycleTracker { 
    processCode?: string;
    processName?: string;
    stageNumber?: number;
    currentState?: ProductionUnitLifecycleTracker.CurrentStateEnum;
    failCount?: number;
    passCount?: number;
    currentStatus?: ProductionUnitLifecycleTracker.CurrentStatusEnum;
    passedEntries?: Array<PULifecycleEntryPojo>;
    failedEntries?: Array<PULifecycleEntryPojo>;
}
export namespace ProductionUnitLifecycleTracker {
    export type CurrentStateEnum = 'FAILED' | 'SUCCESSFUL' | 'PENDING' | 'REQUESTED_REQUIREMENT_APPROVAL' | 'REQUESTED_UI_APPROVAL' | 'REQUESTED_UML_APPROVAL' | 'REQUESTED_CQRS_APPROVAL' | 'APPROVED_AND_SIGNED' | 'DISAPPROVED_AND_NOT_SIGNED' | 'QA_FAILED' | 'QA_SUCCESSFUL' | 'RELEASE_FAILED' | 'RELEASE_SUCCESSFUL' | 'REQUESTED_PLAN_APPROVAL' | 'REQUESTED_QA' | 'REQUESTED_UAT' | 'UAT_FAILED' | 'UAT_SUCCESSFUL' | 'MONITORING_SUCCESSFUL' | 'BLACKBOX';
    export const CurrentStateEnum = {
        Failed: 'FAILED' as CurrentStateEnum,
        Successful: 'SUCCESSFUL' as CurrentStateEnum,
        Pending: 'PENDING' as CurrentStateEnum,
        RequestedRequirementApproval: 'REQUESTED_REQUIREMENT_APPROVAL' as CurrentStateEnum,
        RequestedUiApproval: 'REQUESTED_UI_APPROVAL' as CurrentStateEnum,
        RequestedUmlApproval: 'REQUESTED_UML_APPROVAL' as CurrentStateEnum,
        RequestedCqrsApproval: 'REQUESTED_CQRS_APPROVAL' as CurrentStateEnum,
        ApprovedAndSigned: 'APPROVED_AND_SIGNED' as CurrentStateEnum,
        DisapprovedAndNotSigned: 'DISAPPROVED_AND_NOT_SIGNED' as CurrentStateEnum,
        QaFailed: 'QA_FAILED' as CurrentStateEnum,
        QaSuccessful: 'QA_SUCCESSFUL' as CurrentStateEnum,
        ReleaseFailed: 'RELEASE_FAILED' as CurrentStateEnum,
        ReleaseSuccessful: 'RELEASE_SUCCESSFUL' as CurrentStateEnum,
        RequestedPlanApproval: 'REQUESTED_PLAN_APPROVAL' as CurrentStateEnum,
        RequestedQa: 'REQUESTED_QA' as CurrentStateEnum,
        RequestedUat: 'REQUESTED_UAT' as CurrentStateEnum,
        UatFailed: 'UAT_FAILED' as CurrentStateEnum,
        UatSuccessful: 'UAT_SUCCESSFUL' as CurrentStateEnum,
        MonitoringSuccessful: 'MONITORING_SUCCESSFUL' as CurrentStateEnum,
        Blackbox: 'BLACKBOX' as CurrentStateEnum
    };
    export type CurrentStatusEnum = 'pass' | 'fail';
    export const CurrentStatusEnum = {
        Pass: 'pass' as CurrentStatusEnum,
        Fail: 'fail' as CurrentStatusEnum
    };
}


