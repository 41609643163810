import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../models/user/user.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserAccount } from '../../../models/account/user-account.model';

@Component({
  selector: 'app-account-switcher',
  templateUrl: './account-switcher.component.html',
  styleUrls: ['./account-switcher.component.css']
})
export class AccountSwitcherComponent implements OnInit {
  @Input()
  user: User | undefined;
  account: UserAccount | undefined;

  showAccountCodeHelp = false;

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  get intials(): string {
    return [this.user?.firstName, this.user?.lastName]
      .filter((value) => !!value)
      .map((value) => value?.substring(0, 1).toUpperCase())
      .join('');
  }

  getUserFullName(): string {
    return [this.user?.firstName, this.user?.lastName].filter((value) => !!value).join(', ');
  }

  logout(): void {
    this.authenticationService.logout(`${window.location.origin}`).subscribe();
  }

  ngOnInit(): void {
    this.authenticationService.getAccount().subscribe((value) => {
      if (value) {
        this.account = value;
      }
    });
  }

  get otherAccounts(): UserAccount[] | undefined {
    return this.user?.accounts.filter(
      (value) =>
        value.accountCode != this.account?.accountCode &&
        value.accountType != 'ORGANIZATION' &&
        value.accountType != 'USER'
    );
  }

  getRoles(account: UserAccount): string {
    return account?.roles?.filter((value) => !!value)?.join(', ') || '';
  }

  toggleHelp($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.showAccountCodeHelp = !this.showAccountCodeHelp;
  }

  getFirstLetter(account: UserAccount): string {
    return account.accountName.substring(0, 1).toUpperCase();
  }

  switch(account: UserAccount): void {
    this.authenticationService.setCurrentAccount(account);
    this.router.navigateByUrl('/dashboard');
  }
}
