<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<div class="card mb-5 search">
    <div class="card-body">
        <form [formGroup]="form" (submit)="submit()">
            <div class="row g-3" [ngClass]="{'mb-4': isUat}">
                <div class="d-flex" [ngClass]="!isUat?'justify-content-end':'justify-content-between'">
                    <div class="col-md-6 fs-16 fw-bold" *ngIf="isUat">
                        <label for="keyword" class="form-label"></label>
                        <div> Affected User Story</div>
                    </div>
                    <div class="col-md-6">
                        <label for="keyword" class="form-label">Keyword</label>
                        <input type="text"
                               placeholder="Enter any Keyword"
                               id="keyword"
                               class="form-control"
                               [formControlName]="'keyword'"
                               name="keyword"
                        />
                    </div>
                </div>
            </div>
        </form>

        <div class="row">
            <ng-container *ngIf="!emptyListShowOnce">
                <div *ngIf="searchManager.totalCount > 0 " class="row"
                     [ngClass]="{'disabled-table': searchManager.working}">
                    <div class="d-md-flex align-items-center justify-content-between">
                        <div class="col-md-6">
                            <app-total-records [searchManager]="searchManager"></app-total-records>
                        </div>
                        <div class="col-md-6 d-flex justify-content-end">
                            <page-size
                                    *ngIf="searchManager.totalCount > 0"
                                    [searchManager]="searchManager"
                            ></page-size>
                        </div>
                    </div>
                </div>
                <div class="d-flex p-1 bg-white" *ngIf="showSelect">
                    <div class="pos">
                        <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        <span class="fw-medium primary-color"> {{ this.selection.selected.length }} Selected</span>
                        <u class="px-3 fw-medium pointer primary-color" (click)="clearSelection()">Clear</u>
                    </div>
                </div>
                <hr class="mt-2">

                <div class="card" style="border: none;">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th *ngIf="showSelect" scope="col" style="width: 5%"></th>
                                    <th scope="col" style="width: 5%">S/N</th>
                                    <th scope="col" style="width: 10%">User Type</th>
                                    <th scope="col" style="width: 30%">I Want To (Action)</th>
                                    <th scope="col" style="width: 30%">So That I Can (Value)</th>
                                    <th scope="col" style="width: 5%" *ngIf="this.shouldShowCriteria">Criteria
                                        Count
                                    </th>
                                    <th scope="col" style="width: 15%"
                                        *ngIf="this.shouldShowCreateCriteria || this.showManageCriteria">
                                        Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngIf="!searchManager._working; else searching">
                                    <ng-container *ngIf="searchManager.list.length > 0; else noData">
                                        <tr class="justify-content-center"
                                            *ngFor="let story of searchManager.list; let i = index">
                                            <td *ngIf="showSelect">
                                                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                              (change)="$event ? toggle(story)  : null"
                                                              [checked]="selection.isSelected(story.id)">
                                                </mat-checkbox>
                                            </td>
                                            <td data-label="S/N" class="fw-bold">{{ i + searchManager.offset + 1 }}</td>
                                            <td data-label="User Type">{{ story?.actor }}</td>
                                            <td data-label="I Want To (Action)">
                                                {{ helperService.cutText(story.action, 40) }}
                                                <span *ngIf="story.action?.length >= 41" [placement]="'right'"
                                                      [tooltip]="story.action">
                                                            <span class="iconify text-black pointer"
                                                                  data-icon="material-symbols:info"> </span>
                                                        </span>
                                            </td>
                                            <td data-label="Criteria Count">
                                                {{ helperService.cutText(story.value, 40) }}
                                                <span *ngIf="story.value.length >= 41" [placement]="'right'"
                                                      [tooltip]="story.value">
                                                            <span class="iconify text-black pointer"
                                                                  data-icon="material-symbols:info"> </span>
                                                        </span>
                                            </td>
                                            <td data-label="So That I Can (Value)" *ngIf="this.shouldShowCriteria">
                                                <a class="text-primary text-decoration-underline"
                                                   [ngClass]="{'text-decoration-none': story?.criteriaCount == 0}"
                                                   (click)="viewCriteriaList(story?.id)">{{ story?.criteriaCount || 0 }}</a>
                                                <span class="iconify text-success pointer"
                                                      *ngIf="showCountCheckMark(story)"
                                                      data-icon="ic:baseline-check-box"> </span>
                                            </td>
                                            <td data-label="Action" *ngIf="this.shouldShowCreateCriteria">
                                                <a (click)="createCriteria(story)" class="text-primary pointer"><span
                                                        class="iconify text-primary pointer"
                                                        data-icon="material-symbols:add"> </span> Add
                                                    Criteria</a>
                                            </td>
                                            <td data-label="Action" *ngIf="this.showManageCriteria">
                                                <a (click)="openCriteriaModal(story)"
                                                   class="text-primary pointer"> Accept Criteria</a>
                                            </td>
                                        </tr>

                                    </ng-container>
                                </ng-container>
                                <ng-template #searching>
                                    <tr>
                                        <td colspan="100%">
                                            <div class="d-flex justify-content-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>

                                <ng-template #noData>
                                    <tr>
                                        <td class="text-center" colspan="9">NO DATA</td>
                                    </tr>
                                </ng-template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row py-2"
                     [ngClass]="{ 'disabled-table': searchManager.working }">
                    <div class="col-12 d-flex justify-content-end">
                        <pagination
                                [(ngModel)]="searchManager.page"
                                [boundaryLinks]="true"
                                [itemsPerPage]="searchManager.itemsPerPage"
                                [maxSize]="maxPageSize"
                                [rotate]="true"
                                [totalItems]="searchManager.totalCount"
                                firstText="&laquo;"
                                lastText="&raquo;"
                                nextText="&rsaquo;"
                                previousText="&lsaquo;"
                        ></pagination>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
</div>


<empty-list
        *ngIf="searchManager.list.length == 0 && !searchManager.working && emptyListShowOnce"
        [description]="'No User Story has been created or searched for'"
        [@fadeInOut]></empty-list>

