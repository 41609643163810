import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { SearchManager } from '../../search/search-manager';

@Component({
  selector: 'filter-preview',
  templateUrl: './filter-preview.component.html',
  styleUrls: ['./filter-preview.component.css']
})
export class FilterPreviewComponent {
  @Input() searchManager!: SearchManager<any, any>;

  @Input()
  nameTransformer: ((name: string) => string | undefined) | undefined;
  @Input()
  valueTransformer: ((value: string) => string | undefined) | undefined;
  @Input()
  shouldBeVisible: ((name: string) => boolean | undefined) | undefined;

  getDescriptions(): NameValuePair[] {
    if (
      !this.searchManager ||
      !this.searchManager.filterSource ||
      !(this.searchManager.filterSource as any)['getSearchDescriptor'] ||
      !this.searchManager.filter
    ) {
      return [];
    }

    return (this.searchManager.filterSource as any)['getSearchDescriptor'](
      this.searchManager.filter
    );
  }

  getName(name: any): string {
    let transformedName = null;
    if (this.nameTransformer) {
      transformedName = this.nameTransformer(name);
    }
    return transformedName || name;
  }

  getValue(value: any): string {
    // let transformedName =null;
    // if(this.valueTransformer){
    //   transformedName=this.valueTransformer(value);
    // }
    try {
      value.toISOString();
    } catch (e) {
      return value;
    }
    return moment(new Date(value)).format('DD-MM-YYYY');
  }

  visible(name: string): boolean {
    const transformedName = null;
    if (this.shouldBeVisible) {
      return <boolean>this.shouldBeVisible(name);
    } else {

      return true;
    }
  }
}

export class NameValuePair {
  public name: any;
  public value: any;
  public isSelected: any;

  constructor(name: any, value: any) {
    this.name = name;
    this.value = value;
  }
}
