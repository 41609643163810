<div>


  <header class="py-5">
    <div class="container">
      <div class="row py-5 d-flex align-items-center">
        <div class="col-xl-6 col-12">
          <div class="">
            <p class="small mb-0">
              <span class="text-primary">_ 20k </span> people love this product
            </p>

            <h1 class="mt-4 fw-bold fs-1" style="line-height: 58px">
              Design Template for <br />
              BAGS
            </h1>

            <p class="col-md-10 small text-muted col-12 mt-4">
              This application synthesizes the main features of the newest
              Byteworks products. This is just a placeholder.
            </p>

            <button class="js-btn js-btn-primary mt-5 px-5 p-3" routerLink="sign-up">
              Sign up to Application Now!
            </button>
          </div>
        </div>

        <div class="col-xl-6 col-12">
          <img src="/assets/img/headerImg.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </header>
</div>
