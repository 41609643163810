import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SearchHandler} from "../../search/search-handler";
import {
    AcceptanceCriteriaPojo,
    QueryResultsUserStoryPojo,
    SearchUserStoryRequestParams,
    UserStoryControllerService,
    UserStoryPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../search/search-filter-source";
import {FormBuilder} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {SearchManager} from "../../search/search-manager";
import {distinctUntilChanged, Observable} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";
import {HelperService} from "../../../services/helper.service";
import {PlatformSelectorService} from "../../../services/platform-selector.service";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {CriteriaCountModalComponent} from "../criteria-count-modal/criteria-count-modal.component";
import {
    CreateAcceptanceCriteriaDialogComponent
} from "../../../pages/intranet/process/components/create-acceptance-criteria-dialog/create-acceptance-criteria-dialog.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {
    UserStoryDialogComponent
} from "../../../pages/intranet/master-records/customer-value/user-story-dialog/user-story-dialog.component";

@Component({
    selector: 'app-user-story-selector',
    templateUrl: './user-story-selector.component.html',
    styleUrls: ['./user-story-selector.component.css']
})
export class UserStorySelectorComponent implements OnInit, OnChanges,
    SearchHandler<UserStoryPojo, SearchUserStoryRequestParams>,
    SearchFilterSource<SearchUserStoryRequestParams> {

    @Input() callToActionText = 'Map Requirement';
    @Input() shouldShowCriteria!: boolean;
    @Input() isUat!: boolean;
    @Input() shouldShowCreateCriteria!: boolean;
    @Input() showActionColumn = false;
    @Input() showCriteriaActionColumn = true;
    @Input() showManageCriteria: boolean;
    @Input() showSelect = true;
    @Input() useCaseId: number;
    @Input() useCaseIds: number[];
    @Input() businessRequirementId: number;
    @Input() module: number;
    @Input() exclude: number[];
    @Input() priorityItems: number[] = [];

    @Output() selectedIds: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    form = this.fb.group({
        action: [''],
        actor: [''],
        value: [''],
        customerValueId: [''],
        createdBefore: [''],
        createdAfter: [''],
        keyword: [''],
        useCaseId: [],
        priorityItems: []
    });

    dataSource: MatTableDataSource<UserStoryPojo>;

    searchManager!: SearchManager<UserStoryPojo, SearchUserStoryRequestParams>;
    maxPageSize = 5;
    platformId: number;

    emptyListShowOnce = true;
    initialSelection = [];
    allowMultiSelect = true;
    selection = new SelectionModel<number>(this.allowMultiSelect, this.initialSelection);

    constructor(public helperService: HelperService,
                private fb: FormBuilder,
                private userStoryControllerService: UserStoryControllerService,
                private platformSelectorService: PlatformSelectorService,
                private modalService: BsModalService) {

        this.searchManager = new SearchManager<UserStoryPojo, SearchUserStoryRequestParams>(this, this);

        this.searchManager.queryResult
            .subscribe((result) => {
                this.setDataSource();
                this.emptyListShowOnce = false;
            });

        this.platformSelectorService.currentPlatform.subscribe(x => {
            if (!x) {
                this.platformSelectorService.switchPlatform();
            } else {
                // this.currentPlatformId = x;
            }
        });
    }


    @Input()
    set selectionModel(ids: Array<number>) {
        this.selection.clear();
        if (ids && ids.length > 0) {
            ids.forEach(id => this.selection.select(id));
        }
        this.form.controls.priorityItems.patchValue(ids);
    }


    @Output()
    selectionModelChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    ngOnInit() {
        if(!this.businessRequirementId && !this.useCaseId){
            this.submit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['useCaseId'] && changes['useCaseId'].currentValue) {
            this.useCaseId = changes['useCaseId'].currentValue;
            this.submit();
        }
        if(changes['businessRequirementId'] && changes['businessRequirementId'].currentValue){
            this.businessRequirementId = changes['businessRequirementId'].currentValue;
            this.submit();
        }
    }



    setDataSource() {
        this.dataSource = new MatTableDataSource(this.searchManager.list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    emit(): void {
        this.selectedIds.emit(this.selection.selected);
        this.selectionModelChange.emit(this.selection.selected);
    }

    getFilter(): SearchUserStoryRequestParams {
        const form = this.form.getRawValue();
        return Object.assign(form);

    }

    getPersistentKey(): string {
        return 'UserStorySelectorComponent';
    }

    getSearchDescriptor(e: SearchUserStoryRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchUserStoryRequestParams): Observable<QueryResultsUserStoryPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;

        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage;
        if(this.useCaseId){
            filter.useCaseIds = [this.useCaseId as any];
        }
        if(this.useCaseIds?.length > 0){
            filter.useCaseIds = this.useCaseIds as any;
        }
        if(this.businessRequirementId){
            filter.businessRequirementId = this.businessRequirementId;
        }

        return this.userStoryControllerService.searchUserStory(filter);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row.id));
        this.selectedIds.emit(this.selection.selected);
    }

    viewCriteriaList(id: number) {
        this.helperService.loadModal(CriteriaCountModalComponent, 'Criteria', '',
            {
                userStoryId: id,
                showActionColumn: true
            },
            '1200px')
            .afterClosed()
            .subscribe((value) => {
                this.searchManager.reloadAndShowFirstPage();
            });
    }

    clearSelection() {
        this.selection.clear();
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;


    toggle(story: UserStoryPojo) {
        this.selection.toggle(story.id);
        this.selectedIds.emit(this.selection.selected);
    }

    createCriteria(userStory: UserStoryPojo) {
        const bsModal = this.modalService.show(CreateAcceptanceCriteriaDialogComponent, {
            initialState: {
                userStory
            },
            backdrop: 'static',
            class: 'modal-lg modal-dialog-centered',
            keyboard: false
        });
        bsModal.content.addedCriteriaEvent.subscribe(() => {
            this.searchManager.reloadAndShowFirstPage();
        });

    }

    openCriteriaModal(story: UserStoryPojo) {
        this.helperService.loadModal(UserStoryDialogComponent, 'Manage User Story', '',
            {
                userStories: [story],
                shouldShowCriteriaSearch: true
            },
            '950px')
            .afterClosed()
            .subscribe(()=>{
                this.submit();
            })
    }

    showCountCheckMark(story: UserStoryPojo) {
        if (story?.countOfAcceptedCriteria === 0 && story?.criteriaCount === 0) {
            return false;
        }

        return (story?.criteriaCount == story?.countOfAcceptedCriteria);
    }
}
