import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent {
  @Input() title = 'Error';
  @Input() message = 'An error occured';

  lottieIconPath = 'assets/lottie/error.json';
  @Output() closeEvent = new EventEmitter<void>();

  constructor(private modalRef: BsModalRef) {}

  close(): void {
    this.closeEvent.emit();
    this.modalRef.hide();
  }
}
