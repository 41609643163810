/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCodePojo } from './name-code-pojo';
import { MembershipPojo } from './membership-pojo';
import { PortalAccountMemberRole } from './portal-account-member-role';


export interface PortalUserPojo { 
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    displayName?: string;
    preferredName?: string;
    status?: PortalUserPojo.StatusEnum;
    id?: number;
    memberships?: Array<MembershipPojo>;
    createdAt?: string;
    roles?: Array<PortalAccountMemberRole>;
    nin?: string;
    userId?: string;
    signatureId?: number;
    signatureBase64?: string;
    designation?: string;
    profilePhotoId?: number;
    designationInCurrentAccount?: NameCodePojo;
    designationId?: number;
    departmentId?: number;
    costPerDay?: number;
    iso4217CurrencyCode?: string;
    userIsAbsentToday?: boolean;
    absenceReason?: string;
    cadenceReportOnlyUserIsInCadence?: boolean;
}
export namespace PortalUserPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


