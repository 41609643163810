import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationPojo} from "../../../../../../../../sdk/customer-fulfillment-api-sdk";
import {NotificationService} from "../../../../../../services/notification.service";

@Component({
    selector: 'app-notification-details-modal',
    templateUrl: './notification-details-modal.component.html',
    styleUrls: ['./notification-details-modal.component.css']
})
export class NotificationDetailsModalComponent implements OnInit {
    @Input()
    header: string;

    @Input()
    message: string;

    @Input()
    notificationDate: string;

    @Input()
    notification: NotificationPojo

    permissions: { added: string[]; removed: string[] };

    constructor(private bsModalRef: BsModalRef,
                private notificationService: NotificationService,) {
    }

    ngOnInit(): void {
        this.notificationService.markNotificationAsRead(this.notification?.trackerId);

        if (this.isPrivilegeNotification) {
            this.permissions = this.parsePrivilegesMessage(this.notification?.message);
        }
    }

    close() {
        this.bsModalRef.hide();
    }

    getMessageLine1(): string {

        if (this.isPrivilegeNotification) {
            return "Your privileges have been updated. Below are the updates made:"
        }

        return this.notification?.message;
    }

    get isPrivilegeNotification(): boolean {
        return this.notification?.notificationType == NotificationPojo.TypeEnum.PrivilegesUpdated
    }

    parsePrivilegesMessage(message: string): { added: string[], removed: string[] } {
        let result = {
            added: [],
            removed: []
        };

        // Regex to find the 'ADDED' and 'REMOVED' sections
        const addedRegex = /ADDED:\s*\[([^\]]+)]/;
        const removedRegex = /REMOVED:\s*\[([^\]]+)]/;

        // Extract the 'ADDED' part
        const addedMatch = message.match(addedRegex);
        if (addedMatch) {
            result.added = addedMatch[1].split(',').map(priv => priv.trim());
        }

        // Extract the 'REMOVED' part
        const removedMatch = message.match(removedRegex);
        if (removedMatch) {
            result.removed = removedMatch[1].split(',').map(priv => priv.trim());
        }

        return result;
    }
}
