import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.css']
})
export class AlertMessageComponent {

  _message: string | undefined;
  _messageType: "primary" | "success" | "danger" | "warning" | "info" = "primary";

  @Input()
  set messageType(type: "primary" | "success" | "danger" | "warning" | "info") {
    this._messageType = type;
  }

  @Input()
  set messageTypeThroughEnum(type: MessageTypeConstant) {
    this._messageType = type;
  }


  get messageType() {
    return this._messageType;
  }

  @Input()
  set message(message: string | undefined) {
    this._message = message;
  }

  get message() {
    return this._message!;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log(changes)
  // }

}

export enum MessageTypeConstant {
  PRIMARY = "primary",
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
  INFO = "info"
}
