import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomValidators } from '../../../custom-validators';

@Component({
  selector: 'app-disapproval-dialog',
  templateUrl: './disapproval-dialog.component.html',
  styleUrls: ['./disapproval-dialog.component.css']
})
export class DisapprovalDialogComponent implements OnInit {
  form!: FormGroup;
  @Output()
  onConfirmClicked = new EventEmitter<any>();
  @Input()
  loading = false;
  @Input()
  reason = "Are you sure you want to disapprove this request?";

  @Input()
  positiveButtonText = 'Disapprove';
  @Input()
  header = '';
  @Input()
  subheading = '';

  @Input() onCancel = new EventEmitter<boolean>();

  constructor(private modalRef: BsModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      reasonForDisapproval: [
        '',
        [Validators.required, CustomValidators.minLength(3), Validators.maxLength(2000)]
      ]
    });
  }

  onDone(): void {
    this.onConfirmClicked.emit(this.form.value);
  }

  close(): void {
    this.onCancel.emit(true);
    this.modalRef.hide();
  }

  get reasonControl(): AbstractControl | null{
    return this.getFormControl('reasonForDisapproval');
  }

  getFormControl(name: string): AbstractControl | null{
    return this.form.get(name);
  }
}
