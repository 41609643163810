/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DashboardStatisticsPojo { 
    totalTicketsAllTime?: number;
    fulfilledTicketsAllTime?: number;
    fulfillmentScoreDisplayAllTime?: string;
    totalTicketsYTD?: number;
    fulfilledTicketsYTD?: number;
    fulfillmentScoreDisplayYTD?: string;
    totalCustomerSignOffYTD?: number;
    totalNumberOfSignedCustomerSignOffs?: number;
    totalCustomerSignOffAllTime?: number;
}

