import {Component, Inject, ViewChild} from '@angular/core';
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CreateUatKeyResultComponent} from "../create-uat-key-result/create-uat-key-result.component";

@Component({
    selector: 'app-create-uat-key-result-dialog',
    templateUrl: './create-uat-key-result-dialog.component.html',
    styleUrls: ['./create-uat-key-result-dialog.component.css']
})
export class CreateUatKeyResultDialogComponent {

    title: string;

    keyResults: { result: string }[] = [];

    uatRequestId: number;

    /**
     * sets whether to call the save api for the key results in this component
     */
    persistHere = false;

    @ViewChild('createUatKeyResultComponent')
    createUatKeyResultComponent: CreateUatKeyResultComponent;

    constructor(public dialogRef: MatDialogRef<CreateUatKeyResultDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.title = this.data?.modal?.title

        this.dialogRef.disableClose = true;

        const keyResults = this.data?.keyResults;

        if (keyResults && keyResults?.length > 0) {
            this.keyResults = keyResults;
        }

        if (this.data?.uatRequestId) {
            this.uatRequestId = this.data?.uatRequestId;
            this.persistHere = true;
        }
    }

    saveEntry(): void {
        this.createUatKeyResultComponent.saveEntry();
    }

    getEntry(): void {
        const entries = this.createUatKeyResultComponent.getEntries();
        this.dialogRef.close(entries);
    }

    manageEntry(): void {

        if (this.uatRequestId) {
            this.saveEntry();

        } else {
            this.getEntry();
        }
    }

    creationSuccess(): void {
        this.dialogRef.close(true);
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getAlertMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////
}
