/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';


export interface ActivityChecklistItemStatusUpdateDto { 
    ids?: Array<number>;
    attachmentDtoList?: Array<AttachmentDto>;
    note?: string;
    status?: ActivityChecklistItemStatusUpdateDto.StatusEnum;
}
export namespace ActivityChecklistItemStatusUpdateDto {
    export type StatusEnum = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        NotStarted: 'NOT_STARTED' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
}


