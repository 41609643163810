import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-idle-confirm-dialog',
  templateUrl: './idle-confirm-dialog.component.html',
  styleUrls: ['./idle-confirm-dialog.component.css']
})
export class IdleConfirmDialogComponent {
  @Input() header = 'Confirmation';
  @Input() body = 'Are you sure you want to perform this action?';
  @Input() positiveButtonText = 'Proceed';
  @Input() negativeButtonText = 'Cancel';
  @Input() dismissOnConfirm = true;
  @Input() showCancel = true;
  @Input() showLoadingOnConfirmClick = true;
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onConfirmClicked: EventEmitter<any> = new EventEmitter();
  @Input() loading = false;

  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    if (this.showLoadingOnConfirmClick) {
      this.loading = true;
    }
    this.onConfirmClicked.emit();
    if (this.dismissOnConfirm) {
      this.modalRef.hide();
    }
  }
}
