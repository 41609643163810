<div class="d-flex justify-content-between border-bottom">
    <div mat-dialog-title>
        {{ title }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>
</div>

<mat-dialog-content class="p-4">
    <app-view-player [players]="players"/>
</mat-dialog-content>
