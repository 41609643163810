/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadMeasureCommitmentPojo } from './lead-measure-commitment-pojo';
import { SoftwareModulePojo } from './software-module-pojo';
import { TicketDetailPojo } from './ticket-detail-pojo';
import { CustomerValuePojo } from './customer-value-pojo';
import { DelegationPojo } from './delegation-pojo';
import { SoftwareProductionUnitPojo } from './software-production-unit-pojo';


export interface ExecutionEntityPojo { 
    type?: ExecutionEntityPojo.TypeEnum;
    productionUnitPojo?: SoftwareProductionUnitPojo;
    customerValuePojo?: CustomerValuePojo;
    softwareModulePojo?: SoftwareModulePojo;
    leadMeasureCommitmentPojo?: LeadMeasureCommitmentPojo;
    delegationPojo?: DelegationPojo;
    ticketDetailPojo?: TicketDetailPojo;
}
export namespace ExecutionEntityPojo {
    export type TypeEnum = 'SOFTWARE_PRODUCTION_UNIT' | 'CUSTOMER_VALUE' | 'SOFTWARE_MODULE' | 'TICKET' | 'DELEGATION' | 'LEAD_MEASURE_COMMITMENT' | 'PROCESS_STAGE';
    export const TypeEnum = {
        SoftwareProductionUnit: 'SOFTWARE_PRODUCTION_UNIT' as TypeEnum,
        CustomerValue: 'CUSTOMER_VALUE' as TypeEnum,
        SoftwareModule: 'SOFTWARE_MODULE' as TypeEnum,
        Ticket: 'TICKET' as TypeEnum,
        Delegation: 'DELEGATION' as TypeEnum,
        LeadMeasureCommitment: 'LEAD_MEASURE_COMMITMENT' as TypeEnum,
        ProcessStage: 'PROCESS_STAGE' as TypeEnum
    };
}


