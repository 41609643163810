import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-svg',
  templateUrl: './file-svg.component.html',
  styleUrls: ['./file-svg.component.css']
})
export class FileSvgComponent {
  @Input() width = 100;
  @Input() height = 100;
}
