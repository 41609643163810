/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';
import { SoftwareDeploymentEnvironment } from './software-deployment-environment';
import { PortalUser } from './portal-user';


export interface SoftwareModule { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: SoftwareModule.StatusEnum;
    name?: string;
    identifierDisplayName?: string;
    identifier?: string;
    description?: string;
    currentCompletionStatus?: SoftwareModule.CurrentCompletionStatusEnum;
    currentCompletionTime?: string;
    platform?: Platform;
    deploymentEnvironment?: SoftwareDeploymentEnvironment;
}
export namespace SoftwareModule {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type CurrentCompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED';
    export const CurrentCompletionStatusEnum = {
        Completed: 'COMPLETED' as CurrentCompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CurrentCompletionStatusEnum
    };
}


