<div class="dash-content p-0 manage-ticket">
    <div class="container">
        <div class="row flex-wrap-reverse ticket-title">
            <div class="col-md-8 pt-md-5 d-flex flex-wrap-reverse gap-md-5" style="border-right: 1px solid #888888;">
                <div class="d-flex align-items-center gap-2">
                    <h1 class="fs-26 mb-2 mb-md-0">Ticket {{ ticket?.identifier }}</h1>
                    <i *ngIf="this.details?.issueCategoryName == 'BUG (CODE)' || this.details?.issueCategoryName == 'BUG (DATA)'"
                       class="iconify iconify-icon mb-2 mb-md-0" data-icon="tabler:bug-filled"
                       style="color: red; height: 24px; width: 24px;"></i>
                </div>
                <button (click)="goToTicketDetail(ticketId)" class="btn btn-secondary mb-2 mb-md-0">
                    <i class="iconify iconify-icon" data-icon="carbon:ai-governance-lifecycle"
                       style="width: 18px; height: 18px;"></i>
                    View lifecycle status
                </button>
            </div>
            <div class="col-md-4 mb-2 mb-md-0 pt-md-5 pt-4">
                <div class="d-flex align-items-center mb-3">
                    <div class="d-flex align-items-center ms-auto pointer" [routerLink]="'/dashboard/tickets'">
                        <div class="p-1 px-2 me-2" style="background: #d8e0ff; border-radius: 4px">
                            <span class="iconify" data-icon="basil:caret-left-solid"></span>
                        </div>
                        <p class="mb-0 fs-14">Go back</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row vh-100">
            <div class="col-md-8 px-3 py-2" style="border-right: 1px solid #888888;">
                <div class="py-md-5">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="card customer-details">
                                <div class="card-body">
                                    <h4 class="fw-bolder text-center">
                                        Customer Details
                                    </h4>
                                    <table style="border-collapse: collapse;">
                                        <tr>
                                            <td>
                                                <div>
                                                    <i class="iconify iconify-icon" data-icon="wpf:name"></i>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <p class="fw-bolder">{{ customer?.name }}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <i class="iconify iconify-icon" data-icon="ic:round-phone"></i>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <p>{{ customer?.phoneNumber || '-' }}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <i class="iconify iconify-icon" data-icon="ic:round-email"></i>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <p>{{ customer?.email }}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="card ticket-details">
                                <div class="card-body">
                                    <h4 class="fw-bolder text-center">
                                        Ticket Details
                                    </h4>
                                    <table>
                                        <tr>
                                            <td class="fw-700">Ticket ID:</td>
                                            <td>
                                                <p class="fw-bolder">
                                                    {{ ticket?.identifier }}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-700">Status:</td>
                                            <td>
                                                <p>
                                                    {{ status }}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-700">Priority:</td>
                                            <td>
                                                <p>
                                                    {{ ticket?.issuePriority | underscoreToSpace }}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-700">Date Logged:</td>
                                            <td>
                                                <p>
                                                    {{ ticket?.createdAt | datetime }}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-700">Category:</td>
                                            <td>
                                                <p>
                                                    {{ details?.issueCategoryName }}
                                                    <i *ngIf="this.details?.issueCategoryName == 'BUG (CODE)' || this.details?.issueCategoryName == 'BUG (DATA)'"
                                                       class="iconify iconify-icon" data-icon="tabler:bug-filled"
                                                       style="color: red;"></i>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-700">Assigned To:</td>
                                            <td>
                                                <p class="fw-bolder">
                                                    {{
                                                        details?.assignedTo
                                                            ? details?.assignedTo?.name
                                                            : "Unassigned"
                                                    }}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-md-5 mt-3 ticket-description">
                        <div class="card-body" style="padding: 0 !important;">
                            <div style="padding: 1rem;">
                                <h4 class="px-3" style="width: fit-content;">
                                    Ticket Description
                                </h4>
                                <p style="min-height: 300px;">
                                    {{details?.description || '-'}}
                                </p>
                            </div>
                            <div style="padding: 0.8rem 1rem;border-radius: 8px; border: 0.5px solid rgba(24, 38, 100, 0.10);"
                                 *ngIf="this.ticket?.attachments?.length > 0">
                                <div class="row">
                                    <p>Attachments</p>
                                    <div class="col-md-9">
                                        <div class="d-flex gap-2 flex-wrap">
                                            <div class="attachment" *ngFor="let ff of this.ticket?.attachments"
                                                 [ngStyle]="{'background-image': 'url(' + getFileUrl(ff, false) + ')'}">
                                                <div class="attachment-download" (click)="downloadAttachment(ff)">
                                                    <i class="iconify iconify-icon"
                                                       data-icon="material-symbols-light:download"
                                                       style="height: 22px; width: 20px;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 d-flex justify-content-end align-items-center">
                                        <button class="d-flex gap-2 justify-content-center align-items-center download-all pointer"
                                                (click)="downloadImages()">
                                            <i class="iconify iconify-icon" data-icon="material-symbols-light:download"
                                               style="height: 24px; width: 22px;"></i>
                                            Download All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-2">
                <div class="py-md-5">
                    <div
                            *ngIf="showAlertMessageTrigger"
                            [ngClass]="{ anim: showAlertMessageTrigger }"
                            [class.alert-success]="getMessageType() === AlertType.success"
                            [class.alert-danger]="getMessageType() === AlertType.error"
                            [class.alert-primary]="getMessageType() === AlertType.primary"
                            [class.alert-info]="getMessageType() === AlertType.info"
                            class="alert my-5 text-center" role="alert"
                    >
                        {{ getAlertMessage() }}
                    </div>
                    <div class="card bg-body">
                        <div class="card-body">
                            <h2>
                                Update Ticket
                            </h2>
                            <div class="card">
                                <div class="card-body">
                                    <div>
                                        <form [formGroup]="assignForm" class="mb-3">
                                            <label class="form-label">
                                                {{
                                                    ticket?.assignee ? "Re-assign Ticket to " : "Assign Ticket to"
                                                }}
                                            </label>
                                            <div class="custom-select">
                                                <select
                                                        class="form-control"
                                                        formControlName="assign"
                                                >
                                                    <option [value]="''">-- Select Individual --</option>
                                                    <option [value]="r.id" *ngFor="let r of representatives">
                                                        {{ r.name | titlecase }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="d-flex justify-content-end align-items-end mb-3 mt-4 p-0">
                                                <button [disabled]="this.isAssignment" (click)="assign()"
                                                        class="btn btn-primary">
                                                    Assign Ticket
                                                </button>
                                            </div>
                                        </form>
                                        <hr style="border-style: dashed;">
                                        <form [formGroup]="editForm">
                                            <div class="mb-3">
                                                <label class="form-label">Status</label>
                                                <div class="input-group">
                                                    <select
                                                            class="form-control"
                                                            formControlName="status"
                                                    >
                                                        <option [value]="''">-- Select Status --</option>
                                                        <option
                                                                [value]="status.valueOf()"
                                                                *ngFor="let status of ticketStatus"
                                                        >
                                                            {{ status | underscoreToSpace }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <error-message
                                                        [form]="editForm"
                                                        [controlName]="'status'"
                                                ></error-message>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Category</label>
                                                <div class="input-group">
                                                    <select class="form-control"
                                                            formControlName="category">
                                                        <option [value]="''">-- Select Category --</option>
                                                        <option [value]="c.code" *ngFor="let c of ticketCategories">
                                                            {{ c.name | titlecase }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <error-message
                                                        [form]="editForm"
                                                        [controlName]="'category'"
                                                ></error-message>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Ticket Type</label>
                                                <div class="input-group">
                                                    <select class="form-control"
                                                            formControlName="type">
                                                        <option [value]="''">-- Select Type --</option>
                                                        <option [value]="type" *ngFor="let type of ticketTypes">
                                                            {{ type | underscoreToSpace }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <error-message
                                                        [form]="editForm"
                                                        [controlName]="'type'"
                                                ></error-message>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Service</label>
                                                <div class="input-group">
                                                    <select
                                                            id="status"
                                                            class="form-control"
                                                            formControlName="service"
                                                    >
                                                        <option [value]="''">-- Select Service --</option>
                                                        <option [value]="s.code" *ngFor="let s of service">
                                                            {{ s.name | underscoreToSpace }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <error-message
                                                        [form]="editForm"
                                                        [controlName]="'service'"
                                                ></error-message>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Sub Service</label>
                                                <div class="input-group">
                                                    <select
                                                            class="form-control"
                                                            formControlName="subService"
                                                    >
                                                        <option [value]="''">-- Select Sub Service --</option>
                                                        <option [value]="sub.code" *ngFor="let sub of subServices">
                                                            {{ sub.name | underscoreToSpace }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <error-message
                                                        [form]="editForm"
                                                        [controlName]="'subService'"
                                                ></error-message>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Subject</label>
                                                <div class="input-group">
                                                    <input
                                                            class="form-control"
                                                            formControlName="subject"
                                                    />
                                                </div>
                                                <error-message
                                                        [form]="editForm"
                                                        [controlName]="'subject'"
                                                ></error-message>
                                            </div>

                                            <div class="d-flex justify-content-end align-items-end mb-3 mt-4 p-0">
                                                <button [disabled]="isUpdate" (click)="update()"
                                                        class="btn btn-primary">
                                                    Update Ticket
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-2" style="border-radius: 5px;
                background: #cdcccc; height: 350px; overflow-y: auto; border: 1px solid #b1b1b1" *ngIf="details?.ticketRatingPojoList?.length > 0">
                    <div class="card bg-body mt-2" *ngFor="let ff of details?.ticketRatingPojoList">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Rating</h5>
                                    <div class="input-group d-flex gap-2">
                                        <span class="rating-star"
                                              style="cursor: pointer;"
                                              *ngFor="let star of starArray; let i = index">
                                            <span class="star"
                                                  [ngStyle]="{'color': (i < ff?.score || 0) ? '#FFD300' : 'gray'}">
                                                     &#9733;
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-sm-2 date-fulfilled">
                                    <h5>Date Fulfilled</h5>
                                    <p>{{ ff?.createdAt | datetime }}</p>
                                </div>
                            </div>
                            <div class="note">
                                <h5>Note</h5>
                                <p> {{ helperService.cutText(ff?.ratingNotes, 200) }}
                                    <span *ngIf="ff?.ratingNotes?.length >= 201" [placement]="'right'"
                                          [tooltip]="ff?.ratingNotes">
                                                    <span class="iconify text-black pointer"
                                                          data-icon="material-symbols:info"> </span>
                                                </span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
