<div class="modal-content modal-scroll">
    <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Add Acceptance Criteria</h1>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body" #modalContent>
        <div class="card bg-light-subtle mb-3">
            <div class="card-body ">
                <div class="mb-3">
                    <div class="fs-16 fw-bold">User Story</div>
                </div>
                <div class=" mb-2">
                    <div class="me-3">
                        <div class="fs-14 fw-bold">As A (Type Of User)</div>
                        <div class="fs-12">{{ userStory?.actor }}</div>
                    </div>
                    <div class="me-3">
                        <div class="fs-14 fw-bold">I Want To (Action)</div>
                        <div class="fs-12">{{ userStory?.action }}</div>
                    </div>
                    <div class="me-3">
                        <div class="fs-14 fw-bold">So That (Value)</div>
                        <div class="fs-12">{{ userStory?.value }}</div>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="form">
            <!-- Given That Section -->
            <div
                    *ngIf="showAlertMessageTrigger"
                    [ngClass]="{ anim: showAlertMessageTrigger }"
                    [class.alert-success]="getMessageType() === AlertType.success"
                    [class.alert-danger]="getMessageType() === AlertType.error"
                    [class.alert-primary]="getMessageType() === AlertType.primary"
                    [class.alert-info]="getMessageType() === AlertType.info"
                    class="alert mb-4 fs-14 text-center"
            >
                {{ getAlertMessage() }}
            </div>
            <div>
                <div class="col-12 mb-7">
                    <label class="required form-label">Given That</label>
                    <textarea
                            [formControlName]="'given'"
                            class="form-control"
                            placeholder="Enter details"
                            rows="3"></textarea>
                    <character-limit
                            [controlName]="'given'"
                            [form]="form"
                            [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                    ></character-limit>
                    <error-message
                            [controlName]="'given'"
                            [singularError]="true"
                            [form]="form"
                    ></error-message>
                </div>
                <hr class="mb-4 mt-5" style="border-radius: 2px; border: 0.4px dashed #212438;"/>
                <div class="col-12 mt-3">
                    <label class="form-label">And</label>
                    <textarea
                            [formControlName]="'andGiven'"
                            class="form-control"
                            placeholder="Enter details"
                            rows="3"></textarea>
                    <character-limit
                            [controlName]="'andGiven'"
                            [form]="form"
                            [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                    ></character-limit>
                    <error-message
                            [controlName]="'andGiven'"
                            [singularError]="true"
                            [form]="form"
                    ></error-message>
                </div>
                <div class="text-end mt-5">
                    <button class="btn btn-outline-dark col-2"
                            [disabled]="!canAdd('given')"
                            (click)="addCriteria('given'); false;">
                        <span class="iconify" data-icon="octicon:plus-24"></span>
                        Add
                    </button>
                </div>
                <!-- Given That Table -->
                <table class="table table-striped mt-3" *ngIf="this.getItems('given')?.length > 0">
                    <thead>
                    <tr>
                        <th scope="col">SN</th>
                        <th scope="col">And</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <ng-container *ngIf="true">
                        <tbody>
                        <tr *ngFor="let item of getItems('given'); let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ helperService.cutText(item?.name, 50) }}
                                <span *ngIf="item?.name?.length >= 51" [placement]="'bottom'"
                                      [tooltip]="item?.name">
                                    <span class="iconify text-black pointer" data-icon="material-symbols:info">
                                    </span>
                                </span>
                            </td>
                            <td><span class="text-danger pointer" (click)="removeItems('given', i)">Delete</span></td>
                        </tr>
                        </tbody>
                    </ng-container>
                </table>
            </div>

            <!-- When Section -->
            <div class="mt-5">
                <div class="col-12 mb-7">
                    <label class="required form-label">When</label>
                    <textarea
                            [formControlName]="'when'"
                            class="form-control"
                            placeholder="Enter details"
                            rows="3"></textarea>
                    <character-limit
                            [controlName]="'when'"
                            [form]="form"
                            [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                    ></character-limit>
                    <error-message
                            [controlName]="'when'"
                            [singularError]="true"
                            [form]="form"
                    ></error-message>
                </div>
                <hr class="mb-4 mt-5" style="border-radius: 2px; border: 0.4px dashed #212438;"/>
                <div class="col-12 mt-3">
                    <label class="form-label">And</label>
                    <textarea
                            [formControlName]="'andWhen'"
                            class="form-control"
                            placeholder="Enter details"
                            rows="3"></textarea>
                    <character-limit
                            [controlName]="'andWhen'"
                            [form]="form"
                            [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                    ></character-limit>
                    <error-message
                            [controlName]="'andWhen'"
                            [singularError]="true"
                            [form]="form"
                    ></error-message>
                </div>
                <div class="text-end mt-5">
                    <button class="btn btn-outline-dark col-2"
                            [disabled]="!canAdd('when')"
                            (click)="addCriteria('when'); false;">
                        <span class="iconify" data-icon="octicon:plus-24"></span>
                        Add
                    </button>
                </div>
                <!-- When Table -->
                <table class="table table-striped mt-3" *ngIf="this.getItems('when')?.length > 0">
                    <thead>
                    <tr>
                        <th scope="col">SN</th>
                        <th scope="col">And</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <ng-container *ngIf="true">
                        <tbody>
                        <tr *ngFor="let item of getItems('when'); let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ helperService.cutText(item?.name, 50) }}
                                <span *ngIf="item?.name?.length >= 51" [placement]="'bottom'"
                                      [tooltip]="item?.name">
                                    <span class="iconify text-black pointer" data-icon="material-symbols:info">
                                    </span>
                                </span>
                            </td>
                            <td><span class="text-danger pointer" (click)="removeItems('when', i)">Delete</span></td>
                        </tr>
                        </tbody>
                    </ng-container>
                </table>
            </div>

            <!-- Then Section -->
            <div class="mt-5">
                <div class="col-12 mb-7">
                    <label class="required form-label">Then</label>
                    <textarea
                            [formControlName]="'then'"
                            class="form-control"
                            placeholder="Enter details"
                            rows="3"></textarea>
                    <character-limit
                            [controlName]="'then'"
                            [form]="form"
                            [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                    ></character-limit>
                    <error-message
                            [controlName]="'then'"
                            [singularError]="true"
                            [form]="form"
                    ></error-message>
                </div>
                <hr class="mb-4 mt-5" style="border-radius: 2px; border: 0.4px dashed #212438;"/>
                <div class="col-12 mt-3">
                    <label class="form-label">And</label>
                    <textarea
                            [formControlName]="'andThen'"
                            class="form-control"
                            placeholder="Enter details"
                            rows="3"></textarea>
                    <character-limit
                            [controlName]="'andThen'"
                            [form]="form"
                            [limit]="FormHelper.NOTE_FIELD_MAX_LENGTH"
                    ></character-limit>
                    <error-message
                            [controlName]="'andThen'"
                            [singularError]="true"
                            [form]="form"
                    ></error-message>
                </div>
                <div class="text-end mt-5">
                    <button class="btn btn-outline-dark col-2"
                            [disabled]="!canAdd('then')"
                            (click)="addCriteria('then'); false;">
                        <span class="iconify" data-icon="octicon:plus-24"></span>
                        Add
                    </button>
                </div>
                <!-- Then Table -->
                <table class="table table-striped mt-3" *ngIf="this.getItems('then')?.length > 0">
                    <thead>
                    <tr>
                        <th scope="col">SN</th>
                        <th scope="col">And</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <ng-container *ngIf="true">
                        <tbody>
                        <tr *ngFor="let item of getItems('then'); let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ helperService.cutText(item?.name, 50) }}
                                <span *ngIf="item?.name?.length >= 51" [placement]="'bottom'"
                                      [tooltip]="item?.name">
                                    <span class="iconify text-black pointer" data-icon="material-symbols:info">
                                    </span>
                                </span>
                            </td>
                            <td><span class="text-danger pointer" (click)="removeItems('then', i)">Delete</span></td>
                        </tr>
                        </tbody>
                    </ng-container>
                </table>
            </div>

            <!-- Add Acceptance Criteria Button -->
            <div class="text-end mt-5">
                <button class="btn btn-secondary"
                        (click)="create()"
                        [disabled]="submitting">
                    <span class="iconify" data-icon="octicon:plus-24"></span>
                    Add Acceptance Criteria
                </button>
            </div>
        </form>

        <!-- Added Acceptance Criteria Section -->
        <div class="mt-5">
            <div class="fw-bold fs-18" style="color: #182664">Added Acceptance Criteria</div>
            <hr style="color: #182664"/>
            <table class="table table-striped mt-3">
                <thead>
                <tr>
                    <th scope="col">SN</th>
                    <th scope="col">Given That</th>
                    <th scope="col">When</th>
                    <th scope="col">Then</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <ng-container *ngIf="true">
                    <tbody>
                    <tr *ngFor="let criteria of addedCriteria; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ helperService.cutText(criteria?.given?.criterion, 50) }}
                            <span *ngIf="criteria?.given?.criterion?.length >= 51" [placement]="'bottom'"
                                  [tooltip]="criteria?.given?.criterion">
                                    <span class="iconify text-black pointer" data-icon="material-symbols:info">
                                    </span>
                                </span>
                        </td>
                        <td>{{ helperService.cutText(criteria?.when?.criterion, 50) }}
                            <span *ngIf="criteria?.when?.criterion?.length >= 51" [placement]="'bottom'"
                                  [tooltip]="criteria?.when?.criterion">
                                    <span class="iconify text-black pointer" data-icon="material-symbols:info">
                                    </span>
                                </span>
                        </td>
                        <td>{{ helperService.cutText(criteria?.then?.criterion, 50) }}
                            <span *ngIf="criteria?.then?.criterion?.length >= 51" [placement]="'bottom'"
                                  [tooltip]="criteria?.then?.criterion">
                                    <span class="iconify text-black pointer" data-icon="material-symbols:info">
                                    </span>
                                </span>
                        </td>
                        <td><span class="text-danger pointer" (click)="removeCriteria(criteria)">Delete</span></td>
                    </tr>
                    </tbody>
                </ng-container>
            </table>
            <div class="text-end mt-5">
                <button class="btn btn-dark" (click)="finish()">
                    <span class="iconify" data-icon="lets-icons:done-round"></span>
                    Done
                </button>
            </div>
        </div>
    </div>
</div>
