/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PortalAccountDto { 
    name: string;
    type: PortalAccountDto.TypeEnum;
    email?: string;
    code?: string;
    phoneNumber?: string;
    altPhoneNumber?: string;
    streetName?: string;
    houseNumber?: string;
    zipCode?: string;
}
export namespace PortalAccountDto {
    export type TypeEnum = 'SYSTEM' | 'ORGANIZATION' | 'USER';
    export const TypeEnum = {
        System: 'SYSTEM' as TypeEnum,
        Organization: 'ORGANIZATION' as TypeEnum,
        User: 'USER' as TypeEnum
    };
}


