<div class="class">
  <div class="d-flex justify-content-between align-items-center">
    <div *ngIf="!showErrorMessageTrigger && title" class="d-flex align-items-center mb-3">
      <i class="iconify" data-icon="material-symbols:check-circle-rounded"></i>
      <p class="ms-2 mb-0 fw-bold">{{ title }}</p>
    </div>
    <button *ngIf="showBackButton" class="btn btn-light" (click)="goBack(); (false)">
      <span class="iconify icon" data-icon="basil:arrow-left-solid"></span> Go back
    </button>
  </div>
  <div class="row column-gap-5">
    <div class="col-md-12 col-lg-7 order-2 order-lg-1">
      <div
        *ngIf="showErrorMessageTrigger"
        [ngClass]="{ anim: showErrorMessageTrigger }"
        class="d-custom-none alert alert-danger my-4 fs-14"
      >
        {{ getErrorMessage() | titlecase }}
      </div>
      <div class="bg wrapper">
        <form [formGroup]="form">
          <div class="form-group">
            <label>Choose Excel Sheet</label>
            <input
              #fileInput
              type="file"
              class="wrap form-control"
              (change)="onFileSelected($event)"
              [formControlName]="'file'"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              class="wrap form-control"
            />
          </div>
        </form>
        <div class="upload-box">
          <ng-container *ngIf="items.length === 0">
            <div class="pos">
              <ng-lottie
                containerClass="mx-auto"
                [options]="{ path: path, autoplay: true, loop: false }"
                width="100px"
                height="100px"
              ></ng-lottie>
              <p class="fw-medium">No Excel sheet uploaded</p>
              <p class="pointer" (click)="clickFileInput()">Upload Sheet</p>
            </div>
          </ng-container>
          <ng-container *ngIf="items.length > 0">
            <excel-list-preview
              [items]="items"
              [headerRowNumber]="headerRowNumber"
              [columnsLength]="columnsLength">
            </excel-list-preview>
          </ng-container>
        </div>
      </div>
      <div *ngIf="currentStep === Step.FILE_SELECTED" class="btn-wrapper">
        <button class="btn btn-outline-primary" (click)="clearUpload()">Clear Upload</button>
      </div>
    </div>
    <div class="col-md-12 col-lg-4 order-1 order-lg-2">
      <div class="text-end">
        <button [disabled]="downloadingTemplate" class="btn all-unset" (click)="downloadExcelTemplate()">
          <i class="iconify" data-icon="vscode-icons:file-type-excel2"></i>
          Download Excel template
          <app-loading color="#000" *ngIf="downloadingTemplate"></app-loading>
          <i *ngIf="!downloadingTemplate">
            <i class="iconify" data-icon="solar:download-square-line-duotone"></i>
          </i>
        </button>
      </div>
      <div class="bg wrapper">
        <div class="tips">
          <div class="d-flex align-items-center mb-3">
            <i class="iconify" data-icon="bi:exclamation-circle-fill"></i>
            <p class="ms-2 mb-0 fw-bold">Helpful Tips</p>
          </div>
          <p class="mb-4">{{ instructionOverview }}</p>
          <ol>
            <li>
              Download the excel template above or
              <a (click)="downloadExcelTemplate()" class="text-primary pointer">here</a>
            </li>
            <li>Fill accurate information</li>
            <li>Upload the filled out template below and continue</li>
          </ol>

        </div>
      </div>
      <div class="end">
        <button *ngIf="showUploadButton" [disabled]="submitting" class="btn btn-primary" (click)="uploadBatch(true)">
          <span *ngIf="!submitting">
            <i class="iconify" data-icon="system-uicons:upload"></i>
          </span>
          <app-loading color="#fff" *ngIf="submitting"></app-loading>
          Upload
        </button>
      </div>
    </div>
  </div>
</div>
