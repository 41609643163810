/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';


export interface NotificationPojo { 
    trackerId?: number;
    title?: string;
    message?: string;
    sentAt?: string;
    sentBy?: string;
    status?: NotificationPojo.StatusEnum;
    platform?: NameIdPojo;
    notificationType?: string;
    type?: NotificationPojo.TypeEnum;
}
export namespace NotificationPojo {
    export type StatusEnum = 'PENDING' | 'SENT' | 'READ';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Sent: 'SENT' as StatusEnum,
        Read: 'READ' as StatusEnum
    };
    export type TypeEnum = 'PROCESS_REVIEW_REQUEST' | 'BREACH_NOTICE' | 'PROCESS_FAILURE' | 'PRIVILEGES_UPDATED';
    export const TypeEnum = {
        ProcessReviewRequest: 'PROCESS_REVIEW_REQUEST' as TypeEnum,
        BreachNotice: 'BREACH_NOTICE' as TypeEnum,
        ProcessFailure: 'PROCESS_FAILURE' as TypeEnum,
        PrivilegesUpdated: 'PRIVILEGES_UPDATED' as TypeEnum
    };
}


