import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    CiRequestControllerService, CIRequestUpdateDto
} from "../../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../../services/helper.service";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {UnsubscribeOnDestroyAdapter} from "../../../../services/unsubscribe-on-destroy-adapter";

@Component({
    selector: 'app-requirement-selector-dialog',
    templateUrl: './requirement-selector-dialog.component.html',
    styleUrls: ['./requirement-selector-dialog.component.css']
})
export class RequirementSelectorDialogComponent extends UnsubscribeOnDestroyAdapter {


    platformId: number;

    ciRequestId: number;

    selectedIds: Array<number>;

    title: string;

    constructor(public dialogRef: MatDialogRef<RequirementSelectorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public helperService: HelperService,
                private ciService: CiRequestControllerService) {

        super();

        this.platformId = this.data?.platformId;
        this.selectedIds = this.data?.selectedIds;
        this.ciRequestId = this.data?.ciRequestId;

        if (!this.platformId || !this.ciRequestId) {
            setTimeout(() => {
                    this.dialogRef.close()
                },
                1000)
        }

        this.title = this.data?.modal?.title
    }

    get platform(): number {
        return this.platformId;
    }

    set platform(platformId: number) {
        if (platformId) {
            this.platformId = platformId;
        }
    }

    set selection($event: Array<number>) {
        if ($event && $event.length > 0) {
            this.mapTo($event);
        } else {
            this.showAlertMessage('Select at least one requirement to map', AlertType.error);
        }
    }

    get selection(): Array<number> {
        return this.selectedIds;
    }


    mapTo(selectedIds: Array<number>): void {
        const payload: CIRequestUpdateDto = {
            updateType: "REQUIREMENT",
            businessRequirementIds: selectedIds,
            ciRequestId: this.ciRequestId
        }

        this.subscriptions.sink = this.ciService.updateCIRequest({cIRequestUpdateDto: payload})
            .subscribe({
                next: (data) => {
                },
                complete: () => {
                    this.showAlertMessage('Requirement Specification Successfully Updated!', AlertType.success);
                    setTimeout(() => {
                        this.dialogRef.close(true);
                    }, 1000)
                },
                error: (error: unknown) => {
                    this.showAlertMessage(this.helperService.getError(error), AlertType.error);
                }
            })
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////
}
