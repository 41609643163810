import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";

@Pipe({
  name: 'timeFromLocalTime'
})
export class TimeFromLocalTimePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    const m = moment(value,'H:mm:ss');
    return m.isValid() ? m.format('h:mm A') : null;
  }

}
