/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadMeasureCommitmentPojo } from './lead-measure-commitment-pojo';
import { ResetData } from './reset-data';
import { StandardQualityChecklistPojo } from './standard-quality-checklist-pojo';
import { NameCodePojo } from './name-code-pojo';
import { NameIdPojo } from './name-id-pojo';
import { PlayerPojo } from './player-pojo';
import { BusinessRequirementPojo } from './business-requirement-pojo';


export interface SoftwareProductionUnitPojo { 
    id?: number;
    name?: string;
    softwareModule?: NameIdPojo;
    softwareUnitType?: NameCodePojo;
    platformPojo?: NameIdPojo;
    createdAt?: string;
    standardQualityChecklists?: Array<StandardQualityChecklistPojo>;
    assignee?: NameIdPojo;
    leadMeasureCommitmentPojo?: LeadMeasureCommitmentPojo;
    completionStatus?: SoftwareProductionUnitPojo.CompletionStatusEnum;
    dueAt?: string;
    score?: number;
    player?: PlayerPojo;
    definitionStatus?: SoftwareProductionUnitPojo.DefinitionStatusEnum;
    mappedRequirements?: Array<BusinessRequirementPojo>;
    currentPlannedCICompletionDate?: string;
    currentPlannedAcceptanceTime?: string;
    code?: string;
    customerValueForForecast?: NameIdPojo;
    useCaseIds?: Array<number>;
    resetData?: Array<ResetData>;
    currentState?: SoftwareProductionUnitPojo.CurrentStateEnum;
}
export namespace SoftwareProductionUnitPojo {
    export type CompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        Completed: 'COMPLETED' as CompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CompletionStatusEnum
    };
    export type DefinitionStatusEnum = 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    export const DefinitionStatusEnum = {
        Defined: 'DEFINED' as DefinitionStatusEnum,
        PartiallyDefined: 'PARTIALLY_DEFINED' as DefinitionStatusEnum,
        NotDefined: 'NOT_DEFINED' as DefinitionStatusEnum
    };
    export type CurrentStateEnum = 'ASSIGNMENT' | 'RELEASE_PLANNING' | 'RELEASE_PLANNING_FAIL' | 'DESIGN' | 'DESIGN_FAIL' | 'IMPLEMENTATION' | 'CODE_REVIEW' | 'CODE_REVIEW_FAIL' | 'FUNCTIONAL_TESTING' | 'FUNCTIONAL_TESTING_FAIL' | 'RELEASE' | 'RELEASE_FAIL' | 'USER_ACCEPTANCE_TESTING' | 'USER_ACCEPTANCE_TESTING_FAIL' | 'BETA_SUCCESS' | 'RESET';
    export const CurrentStateEnum = {
        Assignment: 'ASSIGNMENT' as CurrentStateEnum,
        ReleasePlanning: 'RELEASE_PLANNING' as CurrentStateEnum,
        ReleasePlanningFail: 'RELEASE_PLANNING_FAIL' as CurrentStateEnum,
        Design: 'DESIGN' as CurrentStateEnum,
        DesignFail: 'DESIGN_FAIL' as CurrentStateEnum,
        Implementation: 'IMPLEMENTATION' as CurrentStateEnum,
        CodeReview: 'CODE_REVIEW' as CurrentStateEnum,
        CodeReviewFail: 'CODE_REVIEW_FAIL' as CurrentStateEnum,
        FunctionalTesting: 'FUNCTIONAL_TESTING' as CurrentStateEnum,
        FunctionalTestingFail: 'FUNCTIONAL_TESTING_FAIL' as CurrentStateEnum,
        Release: 'RELEASE' as CurrentStateEnum,
        ReleaseFail: 'RELEASE_FAIL' as CurrentStateEnum,
        UserAcceptanceTesting: 'USER_ACCEPTANCE_TESTING' as CurrentStateEnum,
        UserAcceptanceTestingFail: 'USER_ACCEPTANCE_TESTING_FAIL' as CurrentStateEnum,
        BetaSuccess: 'BETA_SUCCESS' as CurrentStateEnum,
        Reset: 'RESET' as CurrentStateEnum
    };
}


