import {Component, Input, OnInit} from '@angular/core';
import {SearchHandler} from "../../search/search-handler";
import {
  BusinessRequirementControllerService,
  SectionGroupRequirementPojo,
  BusinessRequirementSoftwareModuleMapperDto,
  SearchBusinessRequirementSectionGroupRequestParams, BusinessRequirementCustomerValueMapperDto,
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchFilterSource} from "../../search/search-filter-source";
import {SearchManager} from "../../search/search-manager";
import {Observable} from "rxjs";
import {QueryResults} from "../../search/query-results";
import {PaginatedSearch} from "../../search/paginated-search";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import { AlertType } from 'src/app/pages/extranet/report-issue/report-issue.component';
import {HelperService} from "../../../services/helper.service";
import {ConfirmDialogComponent} from "../dialogs/confirm-dialog/confirm-dialog.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-mapped-requirements',
  templateUrl: './mapped-requirements.component.html',
  styleUrls: ['./mapped-requirements.component.css']
})
export class MappedRequirementsComponent implements OnInit, SearchHandler<SectionGroupRequirementPojo, SearchBusinessRequirementSectionGroupRequestParams>,
    SearchFilterSource<SearchBusinessRequirementSectionGroupRequestParams>{
  searchManager!: SearchManager<SectionGroupRequirementPojo, SearchBusinessRequirementSectionGroupRequestParams>;
  _form: FormGroup;
  maxPageSize = 5;
  @Input()
  platformId: number;

  @Input()
  moduleId: number;

  @Input()
  customerValueId: number;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              public helperService: HelperService,
      private businessRequirementControllerService: BusinessRequirementControllerService) {
    this.searchManager = new SearchManager<SectionGroupRequirementPojo, SearchBusinessRequirementSectionGroupRequestParams>(this, this);
  }
  ngOnInit() {
    this._form = this.fb.group({
      platformId: [this.platformId || ''],
      moduleId: [this.moduleId || ''],
      customerValueId: [this.customerValueId || '']
    });

    this.searchManager.reloadAndShowFirstPage();
  }

  getFilter(): SearchBusinessRequirementSectionGroupRequestParams {
    return {...this._form.value};
  }

  search(
      page: number | undefined,
      filter?: SearchBusinessRequirementSectionGroupRequestParams
  ): Observable<QueryResults<SectionGroupRequirementPojo>> {
    console.log(filter)

    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
    const paginatedSearch = PaginatedSearch.createCleanFilter(
        filter,
        this.searchManager.itemsPerPage,
        offset
    );
    return this.businessRequirementControllerService.searchBusinessRequirementSectionGroup({platformId: this.platformId,
      ...paginatedSearch.getSearchParams()
    });
  }

  getPersistentKey(): string {
    return MappedRequirementsComponent.name;
  }

  getSearchDescriptor(filter: SearchBusinessRequirementSectionGroupRequestParams): NameValuePair[] {
    const descriptions: NameValuePair[] = [];

    Object.keys(filter).forEach((key) => {
      if (!(filter as any)[key]) {
        return;
      }
      const val = (filter as any)[key];
      descriptions.push(new NameValuePair(val, key));
    });
    return descriptions;
  }

  deleteModuleRequirement(businessReqId: number) {
    const businessRequirementSoftwareModuleMapperDto : BusinessRequirementSoftwareModuleMapperDto = {
      businessRequirmentId: businessReqId,
      softwareModuleId: this.moduleId
    }
    this.businessRequirementControllerService.deleteBusinessRequirementToSoftwareModuleMapping({businessRequirementSoftwareModuleMapperDto: [businessRequirementSoftwareModuleMapperDto]}).subscribe({
      next: (v) => {
        this.showAlertMessage('Module requirement unlinked Successfully.', AlertType.success);
        this.searchManager.reloadAndShowFirstPage();
      }, error: (err: unknown) => {
        this.showAlertMessage(this.helperService.getError(err), AlertType.error);
      }
    })
  }

  deleteModuleReason(id) : void {
    const bsModal = this.modalService.show(ConfirmDialogComponent, {
      initialState: {
        body: 'Are you sure you want to remove the module mapping to this requirement?'
      },
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered',
      keyboard: false
    });
    bsModal.content.confirm.subscribe((reason) => {
      this.deleteModuleRequirement(id);
      bsModal.hide();
    })
  }

  deleteCustomerValueRequirement(businessReqId: number) {
    const businessRequirementCustomerValueMapperDto : BusinessRequirementCustomerValueMapperDto = {
      businessRequirmentId: businessReqId,
      customerValueId: this.customerValueId
    }
    this.businessRequirementControllerService.deleteBusinessRequirementToCustomerValueMapping({businessRequirementCustomerValueMapperDto: [businessRequirementCustomerValueMapperDto]}).subscribe({
      next: (v) => {
        this.showAlertMessage('Customer value requirement unlinked Successfully.', AlertType.success);
        this.searchManager.reloadAndShowFirstPage();
      }, error: (err: unknown) => {
        this.showAlertMessage(this.helperService.getError(err), AlertType.error);
      }
    })
  }

  deleteCustomerValueReason(id) : void {
    const bsModal = this.modalService.show(ConfirmDialogComponent, {
      initialState: {
        body: 'Are you sure you want to remove the customer value mapping to this requirement?'
      },
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered',
      keyboard: false
    });
    bsModal.content.confirm.subscribe((reason) => {
      this.deleteCustomerValueRequirement(id);
      bsModal.hide();
    })
  }

  deleteMapping(id: number): void {
    if (this.moduleId) {
      this.deleteModuleReason(id)
    } else {
      this.deleteCustomerValueReason(id)
    }
  }


  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  alertMessage: {
    msg: string;
    type: AlertType;
  } = {
    msg: '',
    type: AlertType.primary
  };
  showAlertMessageTrigger = false;

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  get isProduction(): boolean {
    return environment.production
  }

  public readonly AlertType = AlertType;

}
