/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SoftwareServerPojo { 
    id?: number;
    name?: string;
    type?: SoftwareServerPojo.TypeEnum;
    version?: string;
    hardwareDeviceId?: number;
    hardwareProductionUnitId?: number;
    environmentUrl?: string;
}
export namespace SoftwareServerPojo {
    export type TypeEnum = 'APPLICATION_SERVER' | 'DATABASE_SERVER' | 'WEB_SERVER' | 'CACHE_SERVER' | 'EVENT_STORE_BROKER';
    export const TypeEnum = {
        ApplicationServer: 'APPLICATION_SERVER' as TypeEnum,
        DatabaseServer: 'DATABASE_SERVER' as TypeEnum,
        WebServer: 'WEB_SERVER' as TypeEnum,
        CacheServer: 'CACHE_SERVER' as TypeEnum,
        EventStoreBroker: 'EVENT_STORE_BROKER' as TypeEnum
    };
}


