/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BlockerStatusUpdateDto { 
    resolutionStatus: BlockerStatusUpdateDto.ResolutionStatusEnum;
    blockerId: number;
}
export namespace BlockerStatusUpdateDto {
    export type ResolutionStatusEnum = 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    export const ResolutionStatusEnum = {
        Pending: 'PENDING' as ResolutionStatusEnum,
        InProgress: 'IN_PROGRESS' as ResolutionStatusEnum,
        Resolved: 'RESOLVED' as ResolutionStatusEnum,
        Cancelled: 'CANCELLED' as ResolutionStatusEnum
    };
}


