/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HardwareProductionUnitUploadDto { 
    dataCenter: string;
    rack: string;
    rackModel: string;
    hardwareDeviceName: string;
    hardwareDeviceIp: string;
    hardwareDeviceDNSAddressName: string;
    hardwareDeviceOS: string;
    hardwareDeviceOSVersion: string;
    hardwareDeviceSerialNumber?: string;
    vmName: string;
    vmIp: string;
    vmConfigurationFileType: string;
    vmConfigurationFileName: string;
    vmOS: string;
    vmOSVersion: string;
    softwareServerType: string;
    softwareServerName: string;
    softwareServerVersion: string;
    softwareServerUrl?: string;
    purpose: string;
    sourceCodeRepositoryURL?: string;
    environmentType: string;
    vmRAMInGB: number;
    vmDiskInGB: number;
    vmCPUCoreCount: number;
    row?: number;
    message?: string;
    serialNumber?: number;
}

