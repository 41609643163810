/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface BusinessType { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    type?: BusinessType.TypeEnum;
    displayName?: string;
    displayLabel?: string;
}
export namespace BusinessType {
    export type TypeEnum = 'SOLE_PROPRIETORSHIP' | 'INCORPORATED_TRUSTEE' | 'LLC' | 'GOVERNMENT' | 'PARTNERSHIP' | 'CORPORATION';
    export const TypeEnum = {
        SoleProprietorship: 'SOLE_PROPRIETORSHIP' as TypeEnum,
        IncorporatedTrustee: 'INCORPORATED_TRUSTEE' as TypeEnum,
        Llc: 'LLC' as TypeEnum,
        Government: 'GOVERNMENT' as TypeEnum,
        Partnership: 'PARTNERSHIP' as TypeEnum,
        Corporation: 'CORPORATION' as TypeEnum
    };
}


