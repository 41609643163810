<div class="d-flex justify-content-between border-bottomx">
    <div mat-dialog-title class="fs-16">
        {{ title }}
    </div>
    <div mat-dialog-close>
        <button mat-icon-button
                aria-label="Close">
            <span class="iconify" data-icon="clarity:close-line"></span>
        </button>
    </div>
</div>

<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>


<mat-dialog-content class="mb-4">
    <div class="">

        <p class="fw-bold fs-18 text-center">
            Select or Create a Platform to Switch to
        </p>

        <div class="row mt-5">

            <div class="col-md-2">

                <div class="pos">
                    <img (click)="addPlatform()" class="pos pointer" width="61" height="61" src="assets/img/add.svg" alt="">
                    <p class="text-center">
                        Add Platform
                    </p>
                </div>

            </div>

            <div class="col-md-10">
                <div class="row row-gap-5">

                    <ng-container *ngFor="let p of platforms$ | async">

                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <app-platform-card
                                    [platformPojo]="p"
                                    [(platformModel)]="platform"
                                    (deletePlatform)="deletePlatform($event)">

                            </app-platform-card>
                        </div>

                    </ng-container>

                </div>

            </div>

        </div>
    </div>
</mat-dialog-content>

