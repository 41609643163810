/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductionProcessStagePojo { 
    id?: number;
    identifier?: string;
    stageNumber?: number;
    code?: string;
    name?: string;
    shortDisplayName?: string;
    supportingDocumentRequiredForUpdate?: boolean;
    mandatory?: boolean;
    decompositionRequiredForUpdate?: boolean;
    entryCanBeDeleted?: boolean;
    generatesOutputFile?: boolean;
    directUpdateSupported?: boolean;
}

