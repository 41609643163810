import {Component} from '@angular/core';
import {NavigationService} from "../../services/navigation.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-extranet-layout',
  templateUrl: './extranet-layout.component.html',
  styleUrls: ['./extranet-layout.component.scss']
})
export class ExtranetLayoutComponent {

  showPageHeader = true
  showPageFooter = true
  showAuthHeader = false

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe((data: any) => {
      this.showPageHeader = data.showPageHeader;
      this.showPageFooter = data.showPageFooter;
      this.showAuthHeader = data.showAuthHeader;
    })
  }

}
