import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {CountryISO, PhoneNumberFormat, SearchCountryField,} from 'ngx-intl-tel-input';
import {distinctUntilChanged} from "rxjs";
import {
  IssueCategoryControllerService, IssueCategoryDto,
  Platform,
  PlatformControllerService,
  Ticket,
  TicketControllerService,
  TicketDto
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {HelperService} from "../../../services/helper.service";
import {Router} from "@angular/router";
import IssuePriorityEnum = Ticket.IssuePriorityEnum;
import {DomSanitizer} from "@angular/platform-browser";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {
  SearchTicketModalComponent
} from "../../../shared/components/dialogs/search-ticket-modal/search-ticket-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {Constant} from "../../../models/etc/constant";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
    selector: 'app-report-issue',
    templateUrl: './report-issue.component.html',
    styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent implements OnInit {
    //form
    form = this.fb.group({
        name: [
            '',
            Validators.compose([
                Validators.required,
                Validators.maxLength(65),
                Validators.minLength(2),
              Validators.required
            ])
        ],
        email: [
            '',
            Validators.compose([
                Validators.required,
                Validators.maxLength(100),
                Validators.email,
                Validators.minLength(2)
            ])
        ],
        phoneNumber: [
            ''
        ],
        product: [
            '',
            Validators.compose([
                Validators.required
            ])
        ],
        issueCategory: [
          '',
          Validators.compose([
            Validators.required
          ])
        ],
        priority: [
            'NORMAL',
            Validators.compose([
                Validators.required
            ])
        ],
        description: [
            '',
            Validators.compose([
                Validators.required,
                Validators.maxLength(2000)
            ])
        ],
        document: ['']
    });

    //phone
    selectedCountryISO: any = CountryISO['Nigeria'];
    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;
    submitting = false;

    value = '';
    remainingText = 1000;

    //html resource
    issuePriority: TicketDto.IssuePriorityEnum[] =
        Object.values(TicketDto.IssuePriorityEnum)
    line1 = 'Dear Customer, please input the necessary details to raise an issue below. We will review your report and take necessary action promptly.';
    line2 = 'Kindly input the necessary details to create an issue below.<br />We will review ' +
        'your report and take necessary action promptly.';
    // public  files: AttachmentDto[] = [];
    public files: any[] = [];
    token: string|undefined;
    @ViewChild("recaptcha") recaptcha: ElementRef | undefined;
    recaptchaToken: string|undefined
    recaptchaTouched = false;


    //upload
    base64Doc: any;
    @Input()
    maxNumberofDocForUplaod = 5

    @Input()
    maxDocSize: number = 5 * 1024 * 1024

    //error handling
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    products: Platform[] = []
    issueCategories: IssueCategoryDto[] = []

    @Input() controlName = 'description'
    @Input() limit = 2000;
    @Input() position = "float-end";
    @Input() isNativeElement = false;
    @Input() elementRef: any;
    showWarning = false;
    initialCount = 0;
    isCaptchaResolved = false;

    constructor(
        private fb: FormBuilder,
        private ticketService: TicketControllerService,
        private helperService: HelperService,
        private router: Router,
        private productService: PlatformControllerService,
        private issueCategoryService: IssueCategoryControllerService,
        private sanitizer: DomSanitizer,
        private bsModal: BsModalService,
        private authenticationService: AuthenticationService
        // private recaptchaV3Service: ReCaptchaV3Service
    ) {
    }

    create(): void {
      if (this.form.invalid) {
        this.helperService.getInvalidFormFields(this.form)
        window.scroll(0, 0)
        this.showAlertMessage(
          "Please fill all required fields before proceeding",
          AlertType.error);
        return
      }
      if (this.isRecaptchaInvalid || !this.recaptchaToken) {
        this.showAlertMessage(
          "Please check the recaptcha box",
          AlertType.error);
        return
      }
      this.submitting = true;
      this.ticketService
        .createTicket({ticketDto: this.getTicketDto()})
        .subscribe({
          next: (value: Ticket) => {
            sessionStorage.setItem(Constant.CURRENT_CUSTOMER_NAME, this.getTicketDto().customerName);
            sessionStorage.setItem(Constant.CURRENT_CUSTOMER_EMAIL, this.getTicketDto().customerEmail);
            sessionStorage.setItem(Constant.CURRENT_CUSTOMER_PHONE_NUMBER, this.getTicketDto().customerPhoneNumber);
            this.showAlertMessage(
              'You have Issued A report, You will be routed to the Ticket view',
              AlertType.success)
            setTimeout(() => {
              this.router.navigate(['view-issue/', value.id], {state: {ticket: value}})
            }, 3000)
          },
          complete: () => {
            this.submitting = false;
          },
          error: (error: unknown) => {
            this.submitting = false;
            this.showAlertMessage(this.helperService.getError(error), AlertType.error)
          }
        })
    }

    private getTicketDto(): TicketDto {
        const form = this.form.getRawValue();
      console.log("category : ",form.issueCategory)
        const phone: any = form?.phoneNumber;
        form.phoneNumber = phone?.e164Number
        if (this.base64Doc) {
            form.document = this.base64Doc
        }

        return {
            customerEmail: form.email!,
            customerName: form.name!,
            customerPhoneNumber: form?.phoneNumber!,
            description: form.description!,
            issuePriority: form.priority as IssuePriorityEnum,
            lifecycleStatus: TicketDto.LifecycleStatusEnum.Logged,
            platformId: +form.product!,
            issueCategoryId: Number(form.issueCategory),
            document: this.files
        };
    }

    ngOnInit(): void {
        this.initListeners();
        this.fetchProducts();
        this.fetchIssueCategories();
        this.initCustomer();

      if (this.elementRef && this.isNativeElement) {
        this.validateLength(this.elementRef.value, this.elementRef.value.slice(0, this.limit));
        this.elementRef.addEventListener('keyup', this.listener.bind(this));
      } else if (!this.isNativeElement) {
        const val = this.form?.get(this.controlName)?.value;
        if (val) {
          this.validateLength(val, val.slice(0, this.limit));
        }
        this.form?.get(this.controlName)?.valueChanges.subscribe((val: string) => {
          this.validateLength(val, val?.slice(0, this.limit));
        });
      }
    }

    private initCustomer(){

        this.authenticationService.getUser().subscribe((user) => {

            if (user) {
                this.form.patchValue({
                    name: user.displayName || '',
                    email: user.email || '',
                    phoneNumber: user.phone || ''
                });
            }
        });
    }






    private initListeners() {
        this.form.controls.document
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.base64Doc = value;
            });

        this.form.get('description')?.valueChanges.subscribe((value: any) => {
            this.value = value;
            this.remainingText = 2000 - this.value.length;
        });

    }

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;


    cancel(): void {
        window.location.reload();
    }

    fetchProducts() {
        this.productService.getAllPlatforms()
            .subscribe({
                next: (data) => {
                    this.products = data
                },
                complete: () => {
                },
                error: () => {
                }
            })
    }

  fetchIssueCategories() {
    this.issueCategoryService.getAllIssueCategories()
      .subscribe({
        next: (data) => {
          this.issueCategories = data
        },
        complete: () => {
        },
        error: () => {
        }
      })
  }


    onSelect(event: { addedFiles: any; }) {
        this.prepareFilesList(event.addedFiles)
    }

    onRemove(event: any) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }


    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            if (this.files.length === this.maxNumberofDocForUplaod) {
                this.showAlertMessage(
                    `You can upload a maximum of ${this.maxNumberofDocForUplaod} documents`,
                    AlertType.error)
                return
            }

            if (item.size > this.maxDocSize) {
                this.showAlertMessage(
                    `Maximum document size is ${this.maxDocSize / (1024 * 1024)}MB`,
                    AlertType.error)
                continue;
            }
            const totalSize = 0
            // this.files.map(e => {
            //     totalSize = e.size + totalSize
            // })
            if ((item.size + totalSize) > this.maxDocSize) {
                this.showAlertMessage(
                    `Maximum size of all documents must not be greater than
                    ${this.maxDocSize / (1024 * 1024)}MB`,
                    AlertType.error)
                return
            }
          if (this.getFileType(item) == 'pdf') {
            if (item) {
              const reader = new FileReader();
              reader.readAsDataURL(item);
              reader.onload = (): void => {
                const base64String = reader?.result?.toString().split(',')[1];
                const docs = this.files;
                docs.push({
                  base64Data: base64String,
                  type: "pdf",
                  fileName: this.getFileName(item)
                });
                this.files = [...docs];
                console.log(this.files);
              };
            }
            return;
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(item);
            reader.onload = (): void => {
              const base64 = reader.result as string;
              const docs = this.files;
              docs.push({
                base64Data: base64,
                type: "image",
                fileName: this.getFileName(item)
              });
              this.files = [...docs];
              console.log(this.files);
            };
          }
        }
    }

  getFileType(file: File): any {
    return file?.name?.split('.')?.pop();
  }

  getFileName(file: File): string {
    return file.name;
  }



    resolved(captchaResponse: string) {
        // Here, you can send the `captchaResponse` to your server for verification.
        this.recaptchaToken = captchaResponse;
        this.recaptchaTouched = true;
        // this.recaptchaV3Service.execute('importantAction')
        //     .subscribe((token: string) => {
        //         console.debug(`Token [${token}] generated`);
        //     });
    }


    public send(form: FormGroupDirective): void {
        // if (form.invalid) {
        //     for (const control of Object.keys(form.controls)) {
        //         form.controls[control].markAsTouched();
        //     }
        //     return;
        // }
        //
        // this.recaptchaV3Service.execute('importantAction')
        //     .subscribe((token: string) => {
        //         console.debug(`Token [${token}] generated`);
        //     });
    }

    get isRecaptchaInvalid() {
        return (!this.recaptchaToken && this.recaptchaTouched);
    }
  listener(e: any): void {
    const val: string = e.target.value;
    this.validateLength(val, val.slice(0, this.limit));
  }

  validateLength(oldValue: string, newValue = ''): void {
    if (oldValue.length > this.limit) {
      if (this.isNativeElement) {
        this.elementRef.value = newValue;
      } else {
        this.form.get(this.controlName)?.setValue(newValue);
      }
      this.showWarning = true;
      this.initialCount = this.limit;
    } else {
      this.showWarning = false;
      this.initialCount = oldValue.length;
    }
  }

  delete(file: any): void {
      this.files.splice(this.files.indexOf(file), 1);
  }

}

export enum AlertType {
    success
    ,
    error
    ,
    info
    ,
    primary
}

export interface AttachmentDto {
    base64Data?: string;
    type?: AttachmentDto.TypeEnum;
    fileName?: string;
    plainData?: string;
}

export namespace AttachmentDto {
    export type TypeEnum = 'pdf' | 'image' | 'svg' | 'audio';
    export const TypeEnum = {
        PDF: 'pdf' as TypeEnum,
        IMAGE: 'image' as TypeEnum,
        SVG: 'svg' as TypeEnum,
        AUDIO: 'audio' as TypeEnum
    };
}
