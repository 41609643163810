<div class="m-0">
    <div class="modal-header">
        <h2 class="modal-title fs-5 mb-0" id="notificationTitle">{{ notification?.title }}</h2>
        <div class="d-flex gap-md-4 gap-2 justify-content-between align-items-center">
            <p class="mb-0">
                {{ notification?.sentAt | datetime }}
            </p>
            <div (click)="close()" type="button"
                 style="border: 0.5px solid rgba(15, 15, 15, 0.30); border-radius: 100%;">
        <span class="close fs-5">
          <span class="iconify iconify-icon" data-icon="iconoir:cancel"
                style="height: 24px; width: 24px; color: red;"></span>
        </span>
            </div>
        </div>
    </div>

    <div class="modal-body mb-3">
        <p style="font-size: 1rem;" id="message">
            {{ getMessageLine1() }}
        </p>
        <div *ngIf="isPrivilegeNotification" class="info-card">
            <h4 class="mb-2">
                Added:
            </h4>
            <ul *ngIf="permissions?.added?.length > 0" class="mb-3">
                <li *ngFor="let p of permissions.added">{{ p | underscoreToSpace }}</li>
            </ul>
            <p *ngIf="permissions?.added?.length == 0">No privileges added</p>
            <h4 class="mb-2">
                Removed:
            </h4>
            <ul *ngIf="permissions?.removed?.length > 0" class="mb-3">
                <li *ngFor="let p of permissions.removed">{{ p | underscoreToSpace }}</li>
            </ul>
            <p *ngIf="permissions?.removed?.length == 0">No privileges removed</p>
        </div>
    </div>
</div>