<div class="d-flex align-items-center py-5 vh-100 bg-body-tertiary">
  <div class="container">
    <div class="row">
      <main class="form-signin w-100 m-auto">
        <form>
          <div class="text-center my-5">
            <div class="pointer" routerLink="/" style="cursor: pointer">
              <h3 class="fw-bold my-5 mb-0">
                <span
                  class="iconify"
                  data-icon="material-symbols:image-outline"
                ></span>

                <span class="fs-5 ms-2">LOGO</span>
              </h3>
            </div>
          </div>
          <div class="text-center mb-5">
            <h1 class="h5 fw-bold mb-0">Welcome back!</h1>
            <p class="mb-0 small fw-normal text-muted">
              Don’t have an account?
              <span class="fw-bold text-primary">Sign up</span>
            </p>
          </div>

          <div class="form-floating">
            <input
              type="email"
              class="form-control"
              id="floatingEmail"
              placeholder="Email address"
            />
            <label for="floatingEmail">Email address</label>
          </div>
          <div class="form-floating">
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
            />
            <label for="floatingPassword">Password</label>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <div class="form-check text-start my-5">
              <input
                class="form-check-input"
                type="checkbox"
                value="remember-me"
                id="flexCheckDefault"
                checked
              />
              <label
                class="form-check-label small text-muted"
                for="flexCheckDefault"
              >
                Remember me
              </label>
            </div>

            <p class="mb-0 small text-primary fw-semibold">Forgot password?</p>
          </div>
          <div>
            <button
              class="btn btn-primary w-100 py-2 my-3"
              routerLink="/dashboard"
            >
              Sign In
            </button>
          </div>
          <p class="mt-5 mb-3 text-body-secondary small text-muted text-center">
            © 2017–2023
          </p>
        </form>
      </main>
    </div>
  </div>
</div>
