import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
    BusinessRequirementSectionControllerService,
    DeploymentEnvironmentControllerService,
    ModuleControllerService,
    NameCodePojo,
    PlatformControllerService,
    PlatformPojo, ProductionUnitControllerService,
    QueryResultsSoftwareProductionUnitPojo,
    SearchSoftwareProductionUnitRequestParams,
    SoftwareModulePojo,
    SoftwareProductionUnitPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {SearchManager} from "../../search/search-manager";
import {distinctUntilChanged, Observable, of} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";
import {HelperService} from "../../../services/helper.service";
import {catchError, map} from "rxjs/operators";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../search/paginated-search";
import {SearchHandler} from "../../search/search-handler";
import {SearchFilterSource} from "../../search/search-filter-source";
import {AlertType} from 'src/app/pages/extranet/report-issue/report-issue.component';
import {PlatformSelectorService} from "../../../services/platform-selector.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-production-unit-selector',
    templateUrl: './production-unit-selector.component.html',
    styleUrls: ['./production-unit-selector.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0}),
                animate('300ms ease-out', style({opacity: 1})),
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({opacity: 0})),
            ]),
        ])
    ]
})
export class ProductionUnitSelectorComponent implements OnInit,
    SearchHandler<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>,
    SearchFilterSource<SearchSoftwareProductionUnitRequestParams> {

    form = this.fb.group({
        platformId: ['', Validators.required],
        keyword: [''],
        unitType: [''],
        moduleId: ['', Validators.required],
        moduleName: [''],
        createdAfter: [''],
        createdBefore: [''],
        useCaseId: [],
        useCaseIds: []
    });

    searchManager!: SearchManager<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>;

    maxPageSize = 5;

    platforms$: Observable<Array<PlatformPojo>>

    environments$: Observable<Array<NameCodePojo>>

    modules$: Observable<Array<SoftwareModulePojo>>

    platformId: number;

    emptyListShowOnce = true;

    initialSelection = [];

    allowMultiSelect = true;

    selection = new SelectionModel<number>(this.allowMultiSelect, this.initialSelection);

    @Input()
    callToActionText = 'Map';

    @Input()
    showPlatformControl = false;

    private minDate: any;


    @Output()
    selectedIds: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    @Input()
    showModuleControl = false;

    @Input()
    showSelect = true;

    @Input()
    showReleaseDate = false;

    @Input()
    showTitle = false;


    @Input()
    set useCaseId(useCaseId: number) {
        this.form.controls.useCaseId.setValue(useCaseId);
    }

    @Input()
    set useCaseIds(useCaseIds: number[]) {
        this.form.controls.useCaseIds.setValue(useCaseIds);
    }

    @Input()
    showInlineKeyword = false;

    constructor(public helperService: HelperService,
                private fb: FormBuilder,
                private productService: PlatformControllerService,
                private sectionService: BusinessRequirementSectionControllerService,
                private productionUnitService: ProductionUnitControllerService,
                private deploymentEnvironmentControllerService: DeploymentEnvironmentControllerService,
                private platformSelectorService: PlatformSelectorService,
                private moduleService: ModuleControllerService) {

        this.searchManager = new SearchManager<SoftwareProductionUnitPojo, SearchSoftwareProductionUnitRequestParams>(this, this)
        this.emptyListShowOnce = false;
    }

    @Input()
    set platform(platformId: number) {

        if (platformId) {
            this.platformId = platformId;
            this.form.controls.platformId.setValue(platformId.toString());

            this.fetchModule();
        }
    }

    @Output()
    platformChange: EventEmitter<number> = new EventEmitter<number>();


    @Input()
    set selectionModel(ids: Array<number>) {

        if (ids && ids.length > 0) {
            ids.forEach(id => this.selection.select(id))
        }
    }

    @Output()
    selectionModelChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>

    submit(): void {
        if (this.form.invalid) {
            this.helperService.getInvalidFormFields(this.form);
            return;
        }
        if (this.form.controls.moduleId.hasValidator(Validators.required)) {
            if (isNaN(Number(this.form.controls.moduleId.value))) {
                return;
            }
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    ngOnInit() {
        this.fetchProducts();
        this.fetchDeploymentEnvironments();
        this.initFormListeners();
        this.updateFormValidators();
    }


    fetchProducts() {
        this.platforms$ = this.productService.searchPlatforms({limit: 1000})
            .pipe(
                catchError(() => {
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }

    updateFormValidators() {
        if (!this.showModuleControl) {
            this.form.controls.moduleId.removeValidators(Validators.required);
            this.form.controls.moduleId.updateValueAndValidity();
        }
        this.submit();
    }


    initFormListeners(): void {

        this.form.controls.useCaseId.valueChanges.subscribe(() => {
            this.submit();
        });

        this.form.controls.createdAfter.valueChanges.subscribe((v) => {
            this.minDate = v;
            if (this.form.controls.createdBefore.value) {
                if (this.form.controls.createdBefore.value < v) {
                    this.form.controls.createdBefore.setValue(null);
                }
            }
        });

        this.platformSelectorService.currentPlatform.pipe(distinctUntilChanged()).subscribe(value => {
            if (value) {
                this.form.patchValue({
                    platformId: value.toString()
                })
                this.submit();
            }
        })

/*        this.form.controls.moduleId.valueChanges.subscribe((v) => {
            if (v) {
                this.submit();
            }
        })*/
    }

    fetchDeploymentEnvironments(): void {
        this.environments$ = this.deploymentEnvironmentControllerService.fetchDeploymentEnvironment()
            .pipe(
                catchError((err) => {
                    this.showAlertMessage(this.helperService.getError(err), AlertType.error);
                    return of([])
                }),
            );
    }

    fetchModule() {
        this.modules$ = this.moduleService.searchSoftwareModule({
            platformId: this.platformId,
            limit: 1000,
            isDropDown: true
        })
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []})
                }),
                map((result) => result.results),
            )
    }

    mapTo(): void {
        this.selectedIds.emit(this.selection.selected);
        this.selectionModelChange.emit(this.selection.selected);
    }

    now(): Date {
        return new Date();
    }

    getMinDate(): any {
        return this.minDate;
    }

    getFilter(): SearchSoftwareProductionUnitRequestParams {
        const form = this.form.getRawValue();
        const data = Object.assign(form);
        data.platformId = Number(form.platformId)
        return data;

    }

    getPersistentKey(): string {
        return 'ProductionUnitSelectorComponent';
    }

    getSearchDescriptor(e: SearchSoftwareProductionUnitRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            const val = (e as any)[key];
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    search(page: number | undefined, filter?: SearchSoftwareProductionUnitRequestParams): Observable<QueryResultsSoftwareProductionUnitPojo> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        filter.offset = offset;
        filter.limit = this.searchManager.itemsPerPage
        const paginatedSearch = PaginatedSearch.createCleanFilter(
            filter,
            this.searchManager.itemsPerPage,
            offset
        );
        const moduleId = this.form.controls.moduleId?.value ? Number(this.form.controls.moduleId?.value) : undefined;
        return this.productionUnitService.searchSoftwareProductionUnit({
            ...paginatedSearch.getSearchParams(), moduleId: moduleId
        });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.searchManager.list.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.searchManager.list.forEach(row => this.selection.select(row.id));
    }


    clearSelection() {
        this.selection.clear();
    }


    /////////////////////
    //////ALERT
    /////////////////////
    private alertMessage: { msg: string; type: AlertType } = {
        msg: '',
        type: AlertType.primary
    }
    showAlertMessageTrigger = false;

    showAlertMessage(msg: any, type: AlertType): void {
        this.alertMessage.msg = msg;
        this.alertMessage.type = type;
        this.showAlertMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showAlertMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.alertMessage.msg;
    }

    getMessageType(): AlertType {
        return this.alertMessage.type;
    }

    protected readonly AlertType = AlertType;

    /////////////////////
    //////END ALERT
    /////////////////////

}
