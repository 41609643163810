import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ProcessComplianceWorkflowPojo, ProductionProcessComplianceControllerService,
    ProductionProcessComplianceEntryPojo
} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {of, switchMap} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {HelperService} from "../../../services/helper.service";
import {PlatformSelectorService} from "../../../services/platform-selector.service";
import {AlertType} from "../../../pages/extranet/report-issue/report-issue.component";

@Component({
    selector: 'app-compliance-workflow-tracker',
    templateUrl: './compliance-workflow-tracker.component.html',
    styleUrls: ['./compliance-workflow-tracker.component.scss']
})
export class ComplianceWorkflowTrackerComponent implements OnInit {

    complianceStages: Array<ProductionProcessComplianceEntryPojo> = [];

    workflow: ProcessComplianceWorkflowPojo[];

    @Input()
    processCompliancePojo: ProductionProcessComplianceEntryPojo;

    title: string;

    tabHeaders: string[] = []

    tabIcons: string[] = [
        'weui:done2-outlined',
        'carbon:in-progress',
        'tabler:progress',
        'icons8:cancel'
    ];

    passedAndFailedSelect:string;

    @Output()
    alert = new EventEmitter<{ msg: string; type: AlertType }>();

    constructor(private processComplianceService: ProductionProcessComplianceControllerService,
                protected helperService: HelperService,
                private platformSelector: PlatformSelectorService) {
    }

    getIcon(index: number): string {

        const lastLoggedIndex = this.lastLogged;

        if (lastLoggedIndex == index) {
            return this.tabIcons[1];
        }

        if (this.logged(index)) {
            return this.tabIcons[0]
        }

        return this.tabIcons[2]
    }

    logged(index: number): boolean {
        return this.complianceStages[index]?.isProcessLogged
    }

    get lastLogged(): number {
        const index = this.complianceStages.slice().reverse().findIndex(x => x.productionProcessStageCode == this.processCompliancePojo?.productionProcessStageCode)

        if (index != -1) {
            return this.complianceStages.length - index - 1;
        }
        return -1;
    }

    ngOnInit(): void {
        this.fetchComplianceLifecycle();
        this.fetchComplianceWorkflowHistory();
    }

    fetchComplianceWorkflowHistory(): void {
        this.processComplianceService.getProductionProcessComplianceWorkflowHistory({id: this.processCompliancePojo?.id})
            .subscribe({
                next: (result) => {
                    this.workflow = result;
                    console.log('history ', result)
                },
                error: (error: unknown) => {
                    this.alert.emit({msg: this.helperService.getError(error), type: AlertType.error});
                }
            })
    }

    fetchComplianceLifecycle(): void {
        this.platformSelector.currentPlatform.pipe(
            switchMap(id => {
                    if (id) {
                        return this.processComplianceService.fetchProcessComplianceLifecycle({platformId: id})
                            .pipe(
                                catchError(error => {
                                    console.error('Error occurred:', error);
                                    return of([])
                                })
                            )
                    } else {
                        this.platformSelector.switchPlatform();
                        return of([]);
                    }
                }
            ),
            tap((result: ProductionProcessComplianceEntryPojo[]) => {
                this.complianceStages = result;
            }),
            map((result: ProductionProcessComplianceEntryPojo[]) => result.map(x => x.productionProcessStageName)),
            tap(headers => {
                this.tabHeaders = headers;
            })
        )
            .subscribe();

    }

    passedAndFailedSelectChange(event: Event):void {
        this.passedAndFailedSelect = (event.target as HTMLSelectElement).value;
        return;
    }
}
