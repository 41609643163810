/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubService } from './sub-service';
import { PortalAccount } from './portal-account';
import { IssueCategory } from './issue-category';
import { Platform } from './platform';
import { Customer } from './customer';
import { TicketSubCategory } from './ticket-sub-category';
import { Service } from './service';
import { TicketType } from './ticket-type';
import { BwBinaryData } from './bw-binary-data';
import { TicketCategory } from './ticket-category';
import { PortalUser } from './portal-user';


export interface Ticket { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: Ticket.StatusEnum;
    identifier?: string;
    lifecycleStatus?: Ticket.LifecycleStatusEnum;
    issuePriority?: Ticket.IssuePriorityEnum;
    description?: string;
    subject?: string;
    assignedAt?: string;
    resolvedAt?: string;
    cancelledAt?: string;
    fulfilledAt?: string;
    closedAt?: string;
    statusUpdateTokenSalt?: string;
    resolutionStartedAt?: string;
    qrCode?: BwBinaryData;
    ticketCategory?: TicketCategory;
    customer?: Customer;
    platform?: Platform;
    ticketSubCategory?: TicketSubCategory;
    service?: Service;
    portalAccount?: PortalAccount;
    subService?: SubService;
    ticketType?: TicketType;
    assignedBy?: PortalUser;
    assignedTo?: PortalUser;
    resolvedBy?: PortalUser;
    cancelledBy?: PortalUser;
    fulfilledBy?: PortalUser;
    closedBy?: PortalUser;
    issueCategory?: IssueCategory;
}
export namespace Ticket {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type LifecycleStatusEnum = 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    export const LifecycleStatusEnum = {
        Logged: 'LOGGED' as LifecycleStatusEnum,
        Identified: 'IDENTIFIED' as LifecycleStatusEnum,
        Classified: 'CLASSIFIED' as LifecycleStatusEnum,
        Assigned: 'ASSIGNED' as LifecycleStatusEnum,
        Resolving: 'RESOLVING' as LifecycleStatusEnum,
        Cancelled: 'CANCELLED' as LifecycleStatusEnum,
        Resolved: 'RESOLVED' as LifecycleStatusEnum,
        Fulfilled: 'FULFILLED' as LifecycleStatusEnum,
        Closed: 'CLOSED' as LifecycleStatusEnum
    };
    export type IssuePriorityEnum = 'CRITICAL' | 'URGENT' | 'HIGH' | 'NORMAL' | 'LOW';
    export const IssuePriorityEnum = {
        Critical: 'CRITICAL' as IssuePriorityEnum,
        Urgent: 'URGENT' as IssuePriorityEnum,
        High: 'HIGH' as IssuePriorityEnum,
        Normal: 'NORMAL' as IssuePriorityEnum,
        Low: 'LOW' as IssuePriorityEnum
    };
}


