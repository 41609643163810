/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TicketClassificationDto { 
    serviceCode?: string;
    subServiceCode?: string;
    ticketCategoryCode?: string;
    typeConstant?: TicketClassificationDto.TypeConstantEnum;
    subject?: string;
    ticketId?: number;
    status?: TicketClassificationDto.StatusEnum;
}
export namespace TicketClassificationDto {
    export type TypeConstantEnum = 'INCIDENT' | 'SERVICE_REQUEST';
    export const TypeConstantEnum = {
        Incident: 'INCIDENT' as TypeConstantEnum,
        ServiceRequest: 'SERVICE_REQUEST' as TypeConstantEnum
    };
    export type StatusEnum = 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    export const StatusEnum = {
        Logged: 'LOGGED' as StatusEnum,
        Identified: 'IDENTIFIED' as StatusEnum,
        Classified: 'CLASSIFIED' as StatusEnum,
        Assigned: 'ASSIGNED' as StatusEnum,
        Resolving: 'RESOLVING' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Resolved: 'RESOLVED' as StatusEnum,
        Fulfilled: 'FULFILLED' as StatusEnum,
        Closed: 'CLOSED' as StatusEnum
    };
}


