/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityChecklistItemPojo } from './activity-checklist-item-pojo';


export interface ActivityMilestonePojo { 
    id?: number;
    milestone?: string;
    startDate?: string;
    dueDate?: string;
    mileStoneStatus?: ActivityMilestonePojo.MileStoneStatusEnum;
    activityChecklistItems?: Array<ActivityChecklistItemPojo>;
}
export namespace ActivityMilestonePojo {
    export type MileStoneStatusEnum = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    export const MileStoneStatusEnum = {
        NotStarted: 'NOT_STARTED' as MileStoneStatusEnum,
        InProgress: 'IN_PROGRESS' as MileStoneStatusEnum,
        Done: 'DONE' as MileStoneStatusEnum
    };
}


