import {Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {
  AcceptanceCriteriaControllerService, AcceptanceCriteriaDto, AcceptanceCriteriaPojo, CriterionChecker,
  NameIdPojo,
  UserStoryPojo
} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import CheckStatusEnum = AcceptanceCriteriaDto.CheckStatusEnum;
import {HelperService} from "../../../../../services/helper.service";
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";
import {FormHelper} from "../../../../../models/etc/form-helper";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-create-acceptance-criteria-dialog',
  templateUrl: './create-acceptance-criteria-dialog.component.html',
  styleUrls: ['./create-acceptance-criteria-dialog.component.css']
})
export class CreateAcceptanceCriteriaDialogComponent {

  @ViewChild('modalContent') modalContent: ElementRef;

  @Input()
  userStory: UserStoryPojo;

  @Output()
  addedCriteriaEvent: EventEmitter<AcceptanceCriteriaPojo[]> = new EventEmitter<AcceptanceCriteriaPojo[]>();

  addedCriteria: AcceptanceCriteriaPojo[] = [];

  andThen: NameIdPojo[] = [];
  andWhen: NameIdPojo[] = [];
  andGiven: NameIdPojo[] = [];

  criterionValidator = Validators.compose([
    this.helperService.noWhitespaceValidator,
    Validators.required,
    Validators.pattern(FormHelper.ALPHANUMERIC_WITH_HYPHEN_HASH_DOT_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE_COMMA_AMPERSAND_BRACES_COLON),
    Validators.minLength(FormHelper.NOTE_FIELD_MIN_LENGTH),
    Validators.maxLength(FormHelper.NOTE_FIELD_MAX_LENGTH)
  ]);

  criterionAdderValidator = Validators.compose([
    this.helperService.noWhitespaceValidator,
    Validators.pattern(FormHelper.ALPHANUMERIC_WITH_HYPHEN_HASH_DOT_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE_COMMA_AMPERSAND_BRACES_COLON)
  ]);


  form = this.fb.group({
    checkStatus: [CheckStatusEnum.NotChecked],
    comment: [''],
    userStoryId: [],
    acceptanceCriteriaId: [],
    given: ['', this.criterionValidator],
    then: ['', this.criterionValidator],
    when: ['', this.criterionValidator],
    andGiven: ['', this.criterionAdderValidator],
    andWhen: ['', this.criterionAdderValidator],
    andThen: ['', this.criterionAdderValidator]
  });

  submitting = false;


  constructor(private fb: FormBuilder,
              private renderer: Renderer2,
              public modalRef: BsModalRef,
              public helperService: HelperService,
              private controller: AcceptanceCriteriaControllerService) {
  }

  create(): void {
    if (this.form.invalid) {
      this.helperService.getInvalidFormFields(this.form)
      this.showAlertMessage(
          "Please fill all required fields before proceeding",
          AlertType.error);
      return;
    }

    /*
    if (this.andGiven.length === 0 || this.andWhen.length === 0 || this.andThen.length === 0) {
        this.showAlertMessage(
            'Please add at least one criterion for each of the given, when and then fields',
            AlertType.error);
        return;
    }
    */

    if (this.form.controls?.andGiven?.value || this.form.controls?.andWhen.value || this.form.controls?.andThen.value) {
        this.showAlertMessage(
            'Please add the "And" criterion you have entered before proceeding',
            AlertType.error);
        return;
    }

    this.submitting = true;

    this.controller
        .createAcceptanceCriteria({acceptanceCriteriaDto: this.getDto(), userStoryId: this.userStory.id})
        .subscribe({
          next: (value: AcceptanceCriteriaPojo) => {
            this.showAlertMessage(
                'You have created an acceptance criteria successfully',
                AlertType.success);
            this.addedCriteria.push(value);
            this.andThen = [];
            this.andWhen = [];
            this.andGiven = [];
            this.form.reset();
          },
          complete: () => {
            this.submitting = false;
          },
          error: (error: unknown) => {
            this.submitting = false;
            this.showAlertMessage(this.helperService.getError(error), AlertType.error)
          }
        })
  }

  private getDto(): AcceptanceCriteriaDto {
    const form = this.form.getRawValue();

    return {
      checkStatus: form.checkStatus,
      comment: form.comment,
      given: this.createCriterion('given'),
      then: this.createCriterion('then'),
      when: this.createCriterion('when')
    }
  }

  createCriterion(type: 'given' | 'when' | 'then'): CriterionChecker {
    switch (type) {
      case 'given':
        return {
          type: 'GIVEN', criterion: this.form.controls.given.value, andValues: this.andGiven
        }
      case 'when':
        return {
          type: 'WHEN', criterion: this.form.controls.when.value, andValues: this.andWhen
        }
      case 'then':
        return {
          type: 'THEN', criterion: this.form.controls.then.value, andValues: this.andThen
        }
    }
  }

  getItems(type: 'when' | 'then' | 'given'): NameIdPojo[] {
    switch (type) {
      case "then":
        return this.andThen;
      case "given":
        return this.andGiven;
      case "when":
        return this.andWhen;
    }
  }

  removeItems(type: 'when' | 'then' | 'given', i: number) {
    switch (type) {
      case 'when':
        this.andWhen.splice(i, 1);
        break;
      case "then":
        this.andThen.splice(i, 1);
        break;
      case "given":
        this.andGiven.splice(i, 1);
    }
  }

  addCriteria(type: 'when' | 'then' | 'given') {
    const controlName = `and${this.capitalizeFirstLetter(type)}`;
    const control = this.form.get(controlName);

    if (control?.value) {
      this[`and${this.capitalizeFirstLetter(type)}`].push({ id: 0, name: control.value });
      control.patchValue('');
      control.markAsPristine();
    }
  }

  canAdd(type: 'when' | 'then' | 'given'): boolean {
    return !!this.form.get(`and${this.capitalizeFirstLetter(type)}`).value;
  }

  capitalizeFirstLetter(word: string): string {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  removeCriteria(i: AcceptanceCriteriaPojo) {
    this.controller.deleteAcceptanceCriteria({id: i.id}).subscribe({
      next: () => {
        this.addedCriteria.splice(this.addedCriteria.indexOf(i), 1);
      }, error: (err: unknown) => {
        this.showAlertMessage(this.helperService.getError(err), AlertType.error);
      }
    });
  }

  finish(): void {
    this.addedCriteriaEvent.emit(this.addedCriteria);
    this.close();
  }

  close(): void {
    this.modalRef.hide();
  }



  //////////////////////
  /////////ALERT////////
  //////////////////////

  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    this.scrollToTop();
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  scrollToTop() {
    if (this.modalContent && this.modalContent.nativeElement) {
      const element = this.modalContent.nativeElement;
      element.style.display = 'none';
      element.offsetHeight;
      element.style.display = 'block';
      element.scrollTop = 0;
    }
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly FormHelper = FormHelper;
  protected readonly AlertType = AlertType;
}
