import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-note-viewer',
  templateUrl: './note-viewer.component.html',
  styleUrls: ['./note-viewer.component.css']
})
export class NoteViewerComponent implements OnInit {

  @Input()
  notes: string;
  @Input()
  title: string;


  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  dismissModal() {
    this.bsModalRef.hide();
  }

}
