/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MRPRequestDto } from './mrp-request-dto';


export interface UpdateMRPComplianceEntryLogDto { 
    productionProcessStageCode: string;
    notes?: string;
    softwareModuleId?: number;
    platformId: number;
    mrpRequestDto: MRPRequestDto;
    outputStatusConstant?: UpdateMRPComplianceEntryLogDto.OutputStatusConstantEnum;
    complianceId?: number;
}
export namespace UpdateMRPComplianceEntryLogDto {
    export type OutputStatusConstantEnum = 'FAILED' | 'SUCCESSFUL' | 'PENDING' | 'REQUESTED_REQUIREMENT_APPROVAL' | 'REQUESTED_UI_APPROVAL' | 'REQUESTED_UML_APPROVAL' | 'REQUESTED_CQRS_APPROVAL' | 'APPROVED_AND_SIGNED' | 'DISAPPROVED_AND_NOT_SIGNED' | 'QA_FAILED' | 'QA_SUCCESSFUL' | 'RELEASE_FAILED' | 'RELEASE_SUCCESSFUL' | 'REQUESTED_PLAN_APPROVAL' | 'REQUESTED_QA' | 'REQUESTED_UAT' | 'UAT_FAILED' | 'UAT_SUCCESSFUL' | 'MONITORING_SUCCESSFUL' | 'BLACKBOX';
    export const OutputStatusConstantEnum = {
        Failed: 'FAILED' as OutputStatusConstantEnum,
        Successful: 'SUCCESSFUL' as OutputStatusConstantEnum,
        Pending: 'PENDING' as OutputStatusConstantEnum,
        RequestedRequirementApproval: 'REQUESTED_REQUIREMENT_APPROVAL' as OutputStatusConstantEnum,
        RequestedUiApproval: 'REQUESTED_UI_APPROVAL' as OutputStatusConstantEnum,
        RequestedUmlApproval: 'REQUESTED_UML_APPROVAL' as OutputStatusConstantEnum,
        RequestedCqrsApproval: 'REQUESTED_CQRS_APPROVAL' as OutputStatusConstantEnum,
        ApprovedAndSigned: 'APPROVED_AND_SIGNED' as OutputStatusConstantEnum,
        DisapprovedAndNotSigned: 'DISAPPROVED_AND_NOT_SIGNED' as OutputStatusConstantEnum,
        QaFailed: 'QA_FAILED' as OutputStatusConstantEnum,
        QaSuccessful: 'QA_SUCCESSFUL' as OutputStatusConstantEnum,
        ReleaseFailed: 'RELEASE_FAILED' as OutputStatusConstantEnum,
        ReleaseSuccessful: 'RELEASE_SUCCESSFUL' as OutputStatusConstantEnum,
        RequestedPlanApproval: 'REQUESTED_PLAN_APPROVAL' as OutputStatusConstantEnum,
        RequestedQa: 'REQUESTED_QA' as OutputStatusConstantEnum,
        RequestedUat: 'REQUESTED_UAT' as OutputStatusConstantEnum,
        UatFailed: 'UAT_FAILED' as OutputStatusConstantEnum,
        UatSuccessful: 'UAT_SUCCESSFUL' as OutputStatusConstantEnum,
        MonitoringSuccessful: 'MONITORING_SUCCESSFUL' as OutputStatusConstantEnum,
        Blackbox: 'BLACKBOX' as OutputStatusConstantEnum
    };
}


