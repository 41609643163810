import {Component, Input} from '@angular/core';
import {UserStoryPojo} from "../../../../../sdk/customer-fulfillment-api-sdk";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-user-story-modal',
  templateUrl: './user-story-modal.component.html',
  styleUrls: ['./user-story-modal.component.css']
})
export class UserStoryModal {

  currentPage = 1;
  itemsPerPage = 3;
  totalPages = 0;

  @Input()
  userStories: Array<UserStoryPojo>;

  constructor(private modalRef: BsModalRef) {
  }

  ngOnInit() {

    if (this.userStories)
    this.totalPages = Math.ceil(this.userStories.length / this.itemsPerPage);
  }

  getPaginatedUserStories(userStories: any[]): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return userStories.slice(startIndex, startIndex + this.itemsPerPage);
  }

  changePage(page: number): void {
    this.currentPage = page;
  }

  close() {
    this.modalRef.hide();
  }
}
