<div *ngIf="showAddButton" class=" text-end">
    <button (click)="addKeyResult()" class="btn btn-lg btn-dark">
        Add Key Result
    </button>
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
    <div *ngIf="searchManager.totalCount > 0 || true" class="row"
         [ngClass]="{'disabled-table': searchManager.working}">
        <div class="d-md-flex flex-wrap align-items-center justify-content-between">
            <div class="col-md-2x">
                <app-total-records [searchManager]="searchManager"></app-total-records>
            </div>
            <div *ngIf="showKeywordControl" class="col-md-6x">

                <input type="text" formControlName="keyword" id="keyword"
                       class="form-control"
                       placeholder="Search Request Keyword">

            </div>
            <div class="col-md-4x justify-content-end">
                <page-size
                        *ngIf="searchManager.totalCount > 0 || true"
                        [searchManager]="searchManager"
                ></page-size>
            </div>
        </div>
    </div>
</form>

<div class="card search" style="border: 0.2px solid #ececec;">
    <div class="card-body">
        <div class="table-responsivex">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">SN</th>
                    <th scope="col">Key Results</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>

                <ng-container *ngIf="!searchManager._working; else stillLoading">
                    <ng-container *ngIf="searchManager.list.length > 0; else NoDataToDisplay">
                        <tbody>
                        <tr
                                class="justify-content-center"
                                *ngFor="let entry of searchManager.list; let i = index">
                            <td>{{ i + searchManager.offset + 1 }}</td>
                            <td>{{ entry?.result }}</td>
                            <td>
                                <ng-container *ngIf="entry?.checkStatus == 'OK'">
                                    <div class="text-success">
                                        {{ 'Satisfactory' }}
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="entry?.checkStatus == 'NOT_CHECKED'">
                                    <div class="yellow">
                                        {{ 'Not Checked' }}
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="entry?.checkStatus == 'NOT_OK'">
                                    <div class="text-danger">
                                        {{ 'Unsatisfactory' }}
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <div class="dropdown">
                                    <a role="button" class="dropdown-toggle"
                                       id="dropdownMenuLink" data-bs-toggle="dropdown"
                                       aria-expanded="false">Action</a>
                                    <ul class="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuLink">
                                        <li (click)="markAsSatisfactory(entry)" style="cursor: pointer">
                                            <a class="dropdown-item pointer">
                                                <i class="iconify icon text-success"
                                                   data-icon="mdi:check-all"></i>
                                                Mark as satisfactory </a>
                                        </li>
                                        <li (click)="markAsUnsatisfactory(entry)" style="cursor: pointer">
                                            <a class="dropdown-item pointer">
                                                <i class="iconify icon text-success"
                                                   data-icon="mdi:check-all"></i>
                                                Mark as Unsatisfactory </a>
                                        </li>

                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </ng-container>
                </ng-container>
            </table>
        </div>
    </div>
</div>

<div
        *ngIf="searchManager.totalCount > 10"
        class="row py-2 mt-4"
        [ngClass]="{ 'disabled-table': searchManager.working }"
>
    <div class="col-12 d-flex justify-content-end">
        <pagination
                [(ngModel)]="searchManager.page"
                [boundaryLinks]="true"
                [itemsPerPage]="searchManager.itemsPerPage"
                [maxSize]="maxPageSize"
                [rotate]="true"
                [totalItems]="searchManager.totalCount"
                firstText="&laquo;"
                lastText="&raquo;"
                nextText="&rsaquo;"
                previousText="&lsaquo;"
        ></pagination>
    </div>
</div>

<ng-template #stillLoading>
    <tr>
        <td colspan="100%">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #NoDataToDisplay>
    <tr>
        <td class="text-center" colspan="100%">NO DATA</td>
    </tr>
</ng-template>
