<div class="m-0">
  <div class="modal-header">
    <h5 class="modal-title fs-20 mb-0">Create Business Requirement</h5>
    <button type="button" class="btn-close" (click)="close()" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
      <div>
        <form [formGroup]="form" id="form">
          <div>
            <label for="platform" class="required form-label">Select the platform the business requirement is for</label>
            <select class="form-control form-select" formControlName="platform" id="platform">
              <option [value]="''">Select platform</option>
              <option *ngFor="let p of platforms" [value]="p.id">
                {{ p.name | uppercase }}
              </option>
            </select>
            <error-message
                    [controlName]="'platform'"
                    [singularError]="true"
                    [form]="form"
            ></error-message>
          </div>
        </form>
      </div>
  </div>
  <div class="modal-footer">
    <button (click)="close()" class="btn btn-outline-secondary px-3 mt-1 mr-2" type="button">
      <i class="fa fa-times mr-1"></i>Cancel
    </button>
    <button
      (click)="onDone()"
      class="btn btn-primary px-3 mt-1"
      type="button"
      [disabled]="form.invalid || null"
    >
      Proceed
    </button>
  </div>
</div>
