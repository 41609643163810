import {Component, EventEmitter, Input, Output} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.css']
})
export class FilePreviewComponent {
  image: ParsedImage;

  _fileData: File | string;
  @Output()
  cancelImage = new EventEmitter<any>();

  @Input()
  set fileData(file: string | File) {
    this._fileData = file;
    this.inferMediaType(file as string);
  }

  mediaType: 'IMAGE' | 'PDF' | 'UNKNOWN' | 'TEXT' = 'IMAGE';
  isBase64 = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

  constructor(private _sanitizer: DomSanitizer) {}

  inferMediaType(data: string): void {
    if (data == null) {
      return;
    }
    data = data.trim();

    if (data == null || data.length < 1) {
      return;
    }

    if (data.startsWith('data:')) {
      const mediaTypeMatch = data.match(/^data:([^;,]+)[;,]/);
      const mediaType = mediaTypeMatch ? mediaTypeMatch[1] : '';
      if (mediaType.startsWith('image/')) {
        this.mediaType = 'IMAGE';
      } else if (mediaType === 'application/pdf') {
        this.mediaType = 'PDF';
      } else {
        this.mediaType = 'TEXT';
      }
    } else if (this.isBase64.test(data)) {
      if (data.startsWith('/9j/') || data.startsWith('iVB')) {
        this.mediaType = 'IMAGE';
      } else if (data.startsWith('JVBERi0x')) {
        this.mediaType = 'PDF';
      } else {
        this.mediaType = 'TEXT';
      }
    } else {
      this.mediaType = 'TEXT';
    }
  }

  get getUrl(): any {
    if (typeof this._fileData == 'string' && this.isBase64.test(this._fileData)) {
      const passportBlob = this.b64toBlob(this._fileData, 'image/png');
      const url = URL.createObjectURL(passportBlob);
      return this._sanitizer.bypassSecurityTrustUrl(url);
    } else {
      return this._fileData;
    }
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onCancel() {
    this.cancelImage.emit();
  }
}

export interface ParsedImage {
  url: any;
  base64: string;
}
