import {Component} from '@angular/core';
import {User} from "../../models/user/user.model";
import {Subject} from "rxjs";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BsModalService} from "ngx-bootstrap/modal";
import {HelperService} from "../../services/helper.service";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-extranet-header',
  templateUrl: './extranet-header.component.html',
  styleUrls: ['./extranet-header.component.scss']
})
export class ExtranetHeaderComponent {

  user: User | undefined;
  toggled = false;
  private unsubscribeAll = new Subject<any>();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.activatedroute.data.subscribe((data: any) => {
      if (data.showNav && window.innerWidth > 1024) {
        setTimeout(() => {
          this.navigationService.changeToggleStatus(true);
        });
      }
    });

    this.navigationService.toggleStatus.asObservable().subscribe((status) => {
      this.toggled = status;
    });

  }

  login(): void {
    this.authenticationService.loginAndNavigateToDashboard();
  }

  logout(): void {
    this.authenticationService.logout(`${window.location.origin}`).subscribe();
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }


  viewTicket() {
    // this.helperService.loadModal(SearchTicketModalComponent, 'View Ticket', '', {}, '500px')
    this.router.navigate(['/view-issue']);
  }

  logTicket() {
    this.router.navigate(['']);
  }

  ngOnInit(): void {
    this.authenticationService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
}
