/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateUATProcessStageOutputStatusDto { 
    userAcceptanceTestId: number;
    productionProcessStageCode: string;
    notes?: string;
    outputStatus: UpdateUATProcessStageOutputStatusDto.OutputStatusEnum;
    uatRequestStatus: UpdateUATProcessStageOutputStatusDto.UatRequestStatusEnum;
}
export namespace UpdateUATProcessStageOutputStatusDto {
    export type OutputStatusEnum = 'FAILED' | 'SUCCESSFUL' | 'PENDING' | 'REQUESTED_REQUIREMENT_APPROVAL' | 'REQUESTED_UI_APPROVAL' | 'REQUESTED_UML_APPROVAL' | 'REQUESTED_CQRS_APPROVAL' | 'APPROVED_AND_SIGNED' | 'DISAPPROVED_AND_NOT_SIGNED' | 'QA_FAILED' | 'QA_SUCCESSFUL' | 'RELEASE_FAILED' | 'RELEASE_SUCCESSFUL' | 'REQUESTED_PLAN_APPROVAL' | 'REQUESTED_QA' | 'REQUESTED_UAT' | 'UAT_FAILED' | 'UAT_SUCCESSFUL' | 'MONITORING_SUCCESSFUL' | 'BLACKBOX';
    export const OutputStatusEnum = {
        Failed: 'FAILED' as OutputStatusEnum,
        Successful: 'SUCCESSFUL' as OutputStatusEnum,
        Pending: 'PENDING' as OutputStatusEnum,
        RequestedRequirementApproval: 'REQUESTED_REQUIREMENT_APPROVAL' as OutputStatusEnum,
        RequestedUiApproval: 'REQUESTED_UI_APPROVAL' as OutputStatusEnum,
        RequestedUmlApproval: 'REQUESTED_UML_APPROVAL' as OutputStatusEnum,
        RequestedCqrsApproval: 'REQUESTED_CQRS_APPROVAL' as OutputStatusEnum,
        ApprovedAndSigned: 'APPROVED_AND_SIGNED' as OutputStatusEnum,
        DisapprovedAndNotSigned: 'DISAPPROVED_AND_NOT_SIGNED' as OutputStatusEnum,
        QaFailed: 'QA_FAILED' as OutputStatusEnum,
        QaSuccessful: 'QA_SUCCESSFUL' as OutputStatusEnum,
        ReleaseFailed: 'RELEASE_FAILED' as OutputStatusEnum,
        ReleaseSuccessful: 'RELEASE_SUCCESSFUL' as OutputStatusEnum,
        RequestedPlanApproval: 'REQUESTED_PLAN_APPROVAL' as OutputStatusEnum,
        RequestedQa: 'REQUESTED_QA' as OutputStatusEnum,
        RequestedUat: 'REQUESTED_UAT' as OutputStatusEnum,
        UatFailed: 'UAT_FAILED' as OutputStatusEnum,
        UatSuccessful: 'UAT_SUCCESSFUL' as OutputStatusEnum,
        MonitoringSuccessful: 'MONITORING_SUCCESSFUL' as OutputStatusEnum,
        Blackbox: 'BLACKBOX' as OutputStatusEnum
    };
    export type UatRequestStatusEnum = 'PENDING' | 'FAILED' | 'PASSED' | 'NOTIFIED';
    export const UatRequestStatusEnum = {
        Pending: 'PENDING' as UatRequestStatusEnum,
        Failed: 'FAILED' as UatRequestStatusEnum,
        Passed: 'PASSED' as UatRequestStatusEnum,
        Notified: 'NOTIFIED' as UatRequestStatusEnum
    };
}


