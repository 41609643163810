/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductionReleaseDto { 
    id?: number;
    identifierDisplayName?: string;
    name: string;
    platformId: number;
    deploymentEnvironmentId: number;
    dueAt: string;
    completedAt?: string;
    lifecycleStatus?: ProductionReleaseDto.LifecycleStatusEnum;
    deployedByPortalUserId?: number;
}
export namespace ProductionReleaseDto {
    export type LifecycleStatusEnum = 'PREPARING' | 'ACTIVATED' | 'RELEASED' | 'ROLLED_BACK' | 'ACCEPTED';
    export const LifecycleStatusEnum = {
        Preparing: 'PREPARING' as LifecycleStatusEnum,
        Activated: 'ACTIVATED' as LifecycleStatusEnum,
        Released: 'RELEASED' as LifecycleStatusEnum,
        RolledBack: 'ROLLED_BACK' as LifecycleStatusEnum,
        Accepted: 'ACCEPTED' as LifecycleStatusEnum
    };
}


