import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'character-limit',
  templateUrl: './character-limit.component.html',
  standalone: true,
  styleUrls: ['./character-limit.component.css'],
  imports: [CommonModule]
})
export class CharacterLimitComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() limit = 20;
  @Input() position: string = Position.RIGHT;
  @Input() isNativeElement = false;
  @Input() elementRef;
  showWarning = false;
  initialCount = 0;

  ngOnInit(): void {
    if (this.elementRef && this.isNativeElement) {
      this.validateLength(this.elementRef.value, this.elementRef.value.slice(0, this.limit));
      this.elementRef.addEventListener('keyup', this.listener.bind(this));
    } else if (!this.isNativeElement) {
      const val = this.form.get(this.controlName)?.value;
      if (val) {
        this.validateLength(val, val.slice(0, this.limit));
      }
      this.form.get(this.controlName)?.valueChanges.subscribe((val: string) => {
        this.validateLength(val, val?.slice(0, this.limit));
      });
    }
  }

  listener(e): void {
    const val: string = e.target.value;
    this.validateLength(val, val.slice(0, this.limit));
  }

  validateLength(oldValue: string, newValue = ''): void {
    if (oldValue?.length > this.limit) {
      if (this.isNativeElement) {
        this.elementRef.value = newValue;
      } else {
        this.form.get(this.controlName).setValue(newValue);
      }
      this.showWarning = true;
      this.initialCount = this.limit;
    } else {
      this.showWarning = false;
      this.initialCount = oldValue?.length;
    }
  }
}

enum Position {
  RIGHT = 'float-end',
  LEFT = 'float-start'
}
