<div class="m-0">
  <div class="modal-header">
    <div class="pull-left">
        <h2 class="modal-title fs-5" id="exampleModalLabel">{{header}}</h2>
        <div *ngIf="subheading">{{subheading}}</div>
    </div>
    <span type="button" class="close pull-right fs-5" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <div>
      <div></div>
      <div>
        <form [formGroup]="form" id="form">
          <div class="col-sm-12  fs-5 mb-4 text-center">
            <span>{{reason}}</span>
          </div>
          <div class="form-group col-sm-12">
            <label for="r" class="required">Reason</label>
            <textarea
              id="r"
              class="form-control"
              [maxLength]="2000"
              formControlName="reasonForDisapproval"
              placeholder="Give a reason"
              [rows]="8"
            ></textarea>
            <ng-container *ngIf="reasonControl?.touched">
              <small *ngIf="reasonControl?.hasError('required')"> Reason is required </small>
              <small
                *ngIf="reasonControl?.hasError('minlength') && !reasonControl?.hasError('required')"
              >
                Reason must be at least 3 characters
              </small>
              <small
                *ngIf="reasonControl?.hasError('maxlength') && !reasonControl?.hasError('required')"
              >
                Reason cannot be more than 2000 characters
              </small>
            </ng-container>

          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <div class="text-end">
    <button
      (click)="onDone(); (false)"
      class="btn btn-dark mt-1"
      type="button"
      [disabled]="form.invalid || null"
    >
        <i class="iconify icon" data-icon="iconoir:cancel"></i>
      <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': loading }"></i> {{positiveButtonText}}
    </button>
      </div>
  </div>
</div>
