/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketRatingPojo } from './ticket-rating-pojo';
import { NameCodePojo } from './name-code-pojo';
import { NameIdPojo } from './name-id-pojo';


export interface TicketDetailPojo { 
    identifier?: string;
    name?: string;
    id?: number;
    lifecycleStatus?: TicketDetailPojo.LifecycleStatusEnum;
    issuePriority?: TicketDetailPojo.IssuePriorityEnum;
    description?: string;
    subject?: string;
    assignedTo?: NameIdPojo;
    type?: TicketDetailPojo.TypeEnum;
    ticketCategory?: NameCodePojo;
    ticketSubCategory?: NameCodePojo;
    service?: NameCodePojo;
    subService?: NameCodePojo;
    platformName?: string;
    issueCategoryName?: string;
    createdAt?: string;
    customerPojo?: NameIdPojo;
    rating?: number;
    ticketRatingPojoList?: Array<TicketRatingPojo>;
}
export namespace TicketDetailPojo {
    export type LifecycleStatusEnum = 'LOGGED' | 'IDENTIFIED' | 'CLASSIFIED' | 'ASSIGNED' | 'RESOLVING' | 'CANCELLED' | 'RESOLVED' | 'FULFILLED' | 'CLOSED';
    export const LifecycleStatusEnum = {
        Logged: 'LOGGED' as LifecycleStatusEnum,
        Identified: 'IDENTIFIED' as LifecycleStatusEnum,
        Classified: 'CLASSIFIED' as LifecycleStatusEnum,
        Assigned: 'ASSIGNED' as LifecycleStatusEnum,
        Resolving: 'RESOLVING' as LifecycleStatusEnum,
        Cancelled: 'CANCELLED' as LifecycleStatusEnum,
        Resolved: 'RESOLVED' as LifecycleStatusEnum,
        Fulfilled: 'FULFILLED' as LifecycleStatusEnum,
        Closed: 'CLOSED' as LifecycleStatusEnum
    };
    export type IssuePriorityEnum = 'CRITICAL' | 'URGENT' | 'HIGH' | 'NORMAL' | 'LOW';
    export const IssuePriorityEnum = {
        Critical: 'CRITICAL' as IssuePriorityEnum,
        Urgent: 'URGENT' as IssuePriorityEnum,
        High: 'HIGH' as IssuePriorityEnum,
        Normal: 'NORMAL' as IssuePriorityEnum,
        Low: 'LOW' as IssuePriorityEnum
    };
    export type TypeEnum = 'INCIDENT' | 'SERVICE_REQUEST';
    export const TypeEnum = {
        Incident: 'INCIDENT' as TypeEnum,
        ServiceRequest: 'SERVICE_REQUEST' as TypeEnum
    };
}


