import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-stop-watch',
    templateUrl: './stop-watch.component.html',
    styleUrls: ['./stop-watch.component.css']
})
export class StopWatchComponent {

    /**
     * Stop watch
     */

    @Input()
    startTime: string;

    @Input()
    endTime: string;

    @Input()
    label = '';

    @Input()
    showUnitLabel = false;

    @Input()
    showDays = false;
}
