import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({

  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  @Input() header = 'Confirmation';
  @Input() body = 'Are you sure?';
  @Input() positiveButtonText = 'Proceed';
  @Input() negativeButtonText = 'Cancel';
  @Input() dismissOnConfirm = true;
  @Input() showLoadingOnConfirmClick = true;
  @Output()
  confirm = new EventEmitter();
  cancel = new EventEmitter();
  @Input() loading = false;

  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
    this.cancel.emit();
  }


  confirmButtonClicked(): void {
    if (this.showLoadingOnConfirmClick) {
      this.loading = true;
    }
    this.confirm.emit();
    if (this.dismissOnConfirm) {
      this.modalRef.hide();
    }

  }
}
