<div class="platform">

    <div class="top-right" style=" ">

        <img *ngIf="isSelected" src="assets/img/mark_primary.svg" alt="M">

        <a *ngIf="false" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
           aria-expanded="false"><span class="iconify icon " data-icon="ri:more-line"></span></a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
            <li *ngIf="false">
                <a class="dropdown-item pointer" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Edit
                </a>
            </li>
            <li (click)="delete(platformPojo?.id)">
                <a class="dropdown-item pointer">Delete
                </a>
            </li>
        </ul>
    </div>
    <div class="pos">

        <div class="d-flex justify-content-center" style="background: white; width: 80px; border-radius: 7px">
            <img style="border-radius: 5px; padding:7px; object-fit: contain; width: 70px; height: 50px" [src]="logo"
                 alt="">
        </div>
        <p class="fs-12">{{ platformPojo.name }}</p>
        <button class="btn btn-rounded fs-12" (click)="selectPlatform(platformPojo.id)">
            Select Platform
        </button>

    </div>

</div>
