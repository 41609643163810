<div class="m-0 rounded rounded-3">
    <br>
    <div class="d-flex justify-content-between p-3 align-items-center">
        <div class="d-flex justify-content-start align-items-center">
            <h6 class="modal-title mb-0 fs-5" id="modal-title">Reschedule UAT</h6>
        </div>
        <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <div
                    *ngIf="showAlertMessageTrigger"
                    [ngClass]="{ anim: showAlertMessageTrigger }"
                    [class.alert-success]="getMessageType() === AlertType.success"
                    [class.alert-danger]="getMessageType() === AlertType.error"
                    [class.alert-primary]="getMessageType() === AlertType.primary"
                    [class.alert-info]="getMessageType() === AlertType.info"
                    class="alert mb-4 fs-14 text-center"
            >
                {{ getErrorMessage() }}
            </div>

            <div class="mb-5">
                <div>
                    <label class="form-label required">Date</label>
                    <input type="text"
                           class="form-control"
                           formControlName="uatDate"
                           placeholder="DD-MM-YYYY HH:mm:ss"
                           #dp1="bsDatepicker"
                           [bsConfig]="_datePickerConfig"
                           [minDate]="minDate()"
                           bsDatepicker
                    />
                    <error-message [form]="form"
                                   [controlName]="'uatDate'"></error-message>
                </div>
            </div>
            <div class="mb-4">
                <label class="form-label">Comments</label><br>
                <textarea id="textarea" formControlName="comments" name="textarea"
                          class="form-control" placeholder="Enter Comments" rows="4"
                          cols="50"></textarea>
                <error-message [form]="form" [controlName]="'comments'"></error-message>
            </div>
            <div class="mb-4">
                <label class="form-label">Access Details</label><br>
                <textarea id="accessDetails" formControlName="accessDetails" name="textarea"
                          class="form-control" placeholder="Enter Access Details" rows="4"
                          cols="50"></textarea>
                <error-message [form]="form" [controlName]="'accessDetails'"></error-message>
            </div>
        </form>
    </div>
    <div class="modal-footer border-top-0">
        <div class="text-end">
            <button (click)="rescheduleUat()"
                    [disabled]="loading"
                    class="mx-2 btn btn-primary px-2 pe-3"
                    type="button">
                <span>Reschedule UAT</span>
            </button>
        </div>
    </div>
</div>
