import {Component, OnInit} from '@angular/core';
import {ActivationStart, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {UserIdleService} from "angular-user-idle";
import {IdleConfirmDialogComponent} from "./shared/components/idle-confirm-dialog/idle-confirm-dialog.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationService} from "./services/authentication.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'cfs-frontend';
    loading: boolean;
    isExtranet: boolean;

    constructor(private router: Router,
                private userIdle: UserIdleService,
                private bsModalService: BsModalService,
                private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.router.events.subscribe((data) => {
            if (data instanceof ActivationStart) {
                if (data) {
                    this.isExtranet = data.snapshot.data['isExtranet'];
                }
            } else if (data instanceof NavigationStart) {
                this.loading = true;
            } else if (data instanceof NavigationEnd) {
                this.loading = false;
            }
        });

        if (!this.isExtranet) {
            this.checkIdleness();
        }
    }


    checkIdleness(): void {
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe((count) => console.log(count));

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
            this.logoutDialog();
            this.userIdle.stopWatching();
        });
    }

    logoutDialog(): void {
        const bsModalRef = this.bsModalService.show(IdleConfirmDialogComponent, {
            initialState: {
                dismissOnConfirm: false,
                body: 'Oops looks like you have been idle for a while, please refresh',
                positiveButtonText: 'Login Again',
                showCancel: false
            },

            class: 'modal-sm modal-dialog-centered',
            backdrop: 'static',
            keyboard: false,
            animated: true
        });
        bsModalRef?.content?.onConfirmClicked.subscribe((v) => {
                this.authenticationService.logout(window.location.origin);
                bsModalRef.hide();
            })
            .add(() => {
                window.location.reload();
            });
    }
}
