import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as XLSX from 'xlsx';
import {SuccessDialogComponent} from '../success-dialog/success-dialog.component';

type AOA = any[][];

@Component({
    selector: 'upload-excel-file',
    templateUrl: './upload-excel-file.component.html',
    styleUrls: ['./upload-excel-file.component.scss']
})
export class UploadExcelFileComponent {
    @Input()
    headerRowNumber = 1;

    @Input()
    columnsLength = 9;

    @Input()
    title = 'Kindly upload the details of the recipients';

    @Input()
    instructionOverview = 'Follow the instructions to upload multiple recipients at once';

    @Input()
    showBackButton: boolean;

    @Input()
    showUploadButton = true;

    @Output()
    upload = new EventEmitter<{ data: any; publish: boolean }>();

    @Output()
    downloadTemplate = new EventEmitter<boolean>();

    @Output()
    goBackClicked = new EventEmitter<any>();

    @ViewChild('fileInput')
    fileInput: ElementRef;

    path = 'assets/lottie/no_data.json';

    errorMessages = '';
    fileUploaded = false;
    allowedFileTypes: string[] = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
    ];
    data: AOA = [
        [1, 2],
        [3, 4]
    ];
    file!: File;
    showErrorMessageTrigger = false;
    errMessage: string;
    totalCount: number;
    errorSheetNameUrl: string;
    uploadResponse: any;
    form = this.fb.group({
        file: []
    });
    items = [];
    canPublish = false;
    submitting = false;
    downloadingTemplate = false;
    currentStep = Step.NONE;


    constructor(private bsModalService: BsModalService,
                private fb: FormBuilder) {
    }

    downloadExcelTemplate(): void {
        this.downloadTemplate.emit(true);
    }

    onFileSelected(evt: any): void {
        this.errMessage = '';
        this.showErrorMessageTrigger = false;

        this.data = [
            [1, 2],
            [3, 4]
        ];
        const file = evt.target.files[0];

        if (file.size > 1024 * 1000) {
            this.errMessage = '';
            this.showErrorMessage('File size is too large, must not be greater than 1MB');
            this.clearUpload();
            return;
        }

        if (file) {
            const fileType = evt.target.files[0].type;
            const fileName = evt.target.files[0].name;
            if (this.allowedFileTypes.indexOf(fileType) == -1) {
                this.errMessage = '';
                this.showErrorMessage('Invalid file format, can only accept .xls or .xlsx files!');
                this.clearUpload();
                return;
            }
            this.file = evt.target.files[0];
            this.currentStep = Step.FILE_SELECTED;
            this.fileUploaded = true;
        }

        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        this.items = [];

        reader.onload = (e: any): void => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>XLSX.utils.sheet_to_json(ws, {header: 1});
            this.data.map((res) => {
                this.items.push(res);
            });
        };
        reader.readAsBinaryString(target.files[0]);
    }

    get Step(): typeof Step {
        return Step;
    }

    uploadBatch(publish = false): void {
        if (this.file == null) {
            this.errMessage = '';
            this.showErrorMessage('You must attach the file to proceed');
            return;
        }
        if (this.file.size > 1024 * 1000) {
            this.errMessage = '';
            this.showErrorMessage('File size is too large, must not be greater than 1MB');
            return;
        }
        this.submitting = true;
        this.upload.emit({data: this.file, publish: publish});
    }

    clearUpload(): void {
        this.data = [];
        this.file = null;
        this.form.reset();
        this.items = [];
        this.fileUploaded = false;
        this.currentStep = Step.NONE;
    }


    goBack(): void {
        this.goBackClicked.emit();
    }

    openSuccessModal(): void {
        setTimeout(() => {
            const ref2 = this.bsModalService.show(SuccessDialogComponent, {
                initialState: {
                    title: '',
                    message: 'You have successfully added products to your list of inventory'
                },
                class: 'modal-dialog modal-dialog-centered modal-md'
            });
        }, 200);
    }

    showErrorMessage(error: any): void {
        this.errMessage = error;
        this.showErrorMessageTrigger = true;
        window.scroll(0, 0);
        setTimeout(() => {
            this.showErrorMessageTrigger = false;
        }, 10000);
    }

    getErrorMessage(): string {
        return this.errMessage;
    }

    clickFileInput(): void {
        this.fileInput.nativeElement.click();
    }
}

export enum Step {
    NONE = 'NONE',
    FILE_SELECTED = 'FILE_SELECTED',
    FILE_PROCESSED = 'FILE_PROCESSED',
    FILE_PUBLISHED = 'FILE_PUBLISHED'
}
