<div class="account-switcher ms-2 my-1">
  <a class="nav-link btn btn-primary btn-sm text-white" type="button" data-bs-toggle="dropdown">
    <span class="iconify fs-14" data-icon="ci:user"></span>
    {{ getUserFullName() }}
  </a>
  <ul class="dropdown-menu dropdown-menu-right py-4">
    <div class="info">
      <div class="image">
        <span class="">{{ intials }}</span>
      </div>
      <div class="name mt-2">{{ getUserFullName() }}</div>
      <div class="type mt-1 px-4" style="max-width: 24rem">
        <div class="fs-15">{{ account?.accountName || '' }}</div>
        <p class="fs-12 opacity-75">

          {{ account?.accountCode }}
<!--          <a [tooltip]="accountCodeInfo" class="fs-10 pointer text-decoration-underline"-->
<!--          >What is this?</a-->
<!--          >-->
        </p>
        <ng-template #accountCodeInfo>
          <div class="my-2 mt-3">
            <p class="mb-0 small fw-normal">
              This is your Expira account code. This code helps vendors/customers find you on
              expira.
            </p>
          </div>
        </ng-template>
        <div class="fw-normal fs-13">{{ getRoles(account!) }}</div>
      </div>

      <!-- <div class="account_buttons border-0">
              <button class="btn_1">View Account Profile</button>
          </div> -->
    </div>
    <div (click)="switch(account)" *ngFor="let account of otherAccounts" class="dropdown-item pointer">
      <div class="d-flex align-items-center">
        <div class="image">
          <span class="">{{ getFirstLetter(account) }}</span>
        </div>
        <div class="details ms-2">
          <div class="name">{{ account.accountName.toUpperCase() }}</div>
          <div class="type">{{account.accountType|titlecase}}</div>
        </div>
      </div>
    </div>
    <div class="account_buttons">
      <button (click)="logout()" class="btn_1 logout_btn" type="button">
        <span class="fa fa-power-off"></span> Logout
      </button>
    </div>
  </ul>
</div>
