/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UatStatus { 
    uatName?: string;
    isUatMissed?: boolean;
    requestStatus?: UatStatus.RequestStatusEnum;
    uatCode?: string;
    date?: string;
    isRescheduled?: boolean;
    historyId?: number;
}
export namespace UatStatus {
    export type RequestStatusEnum = 'PENDING' | 'FAILED' | 'PASSED' | 'NOTIFIED';
    export const RequestStatusEnum = {
        Pending: 'PENDING' as RequestStatusEnum,
        Failed: 'FAILED' as RequestStatusEnum,
        Passed: 'PASSED' as RequestStatusEnum,
        Notified: 'NOTIFIED' as RequestStatusEnum
    };
}


