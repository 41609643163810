<div class="d-flex align-items-center search-param-card" *ngIf="getDescriptions().length">
    <p class="mb-0 small fw-bold">Search Parameters:</p>
    <div class="container">
      <div class="row g-3">
        <div class="d-flex flex-wrap">
            <span class="badge rounded-pill bg-pill-search px-4 p-2 mx-1" *ngFor="let item of getDescriptions()">
              <span class="fs-12" *ngIf="visible(item.name)">{{getName(item.name)| underscoreToSpace | titlecase}}: {{getValue(item.value)|underscoreToSpace}}</span>
            </span>
        </div>
      </div>
    </div>
  </div>
