/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WigPojo } from './wig-pojo';
import { CustomerValueStatisticsPojo } from './customer-value-statistics-pojo';
import { SoftwareModuleStatisticsPojo } from './software-module-statistics-pojo';


export interface WigStatisticsPojo { 
    decompositionScore?: number;
    wig?: WigPojo;
    customerValues?: Array<CustomerValueStatisticsPojo>;
    unmappedModules?: Array<SoftwareModuleStatisticsPojo>;
}

