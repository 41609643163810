/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ActivityMilestoneDto } from '../model/models';
import { ActivityMilestonePojo } from '../model/models';
import { PerformanceMilestoneScoreboardPojo } from '../model/models';
import { QueryResultsActivityMilestonePojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateActivityMilestoneRequestParams {
    activityMilestoneDto: ActivityMilestoneDto;
}

export interface GetPerformanceMilestoneScoreboardDataRequestParams {
    id: number;
}

export interface SearchActivityMilestoneRequestParams {
    customerValue?: string;
    createdAt?: string;
    customerValueId?: number;
    milestone?: string;
    createdBy?: string;
    milestoneStatus?: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    id?: number;
    dueTime?: string;
    startDate?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    dueDate?: string;
    limit?: number;
    keyword?: string;
    priorityItems?: Array<object>;
    activityChecklistStatus?: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class ActivityMilestoneControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createActivityMilestone(requestParameters: CreateActivityMilestoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ActivityMilestonePojo>;
    public createActivityMilestone(requestParameters: CreateActivityMilestoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ActivityMilestonePojo>>;
    public createActivityMilestone(requestParameters: CreateActivityMilestoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ActivityMilestonePojo>>;
    public createActivityMilestone(requestParameters: CreateActivityMilestoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const activityMilestoneDto = requestParameters.activityMilestoneDto;
        if (activityMilestoneDto === null || activityMilestoneDto === undefined) {
            throw new Error('Required parameter activityMilestoneDto was null or undefined when calling createActivityMilestone.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ActivityMilestonePojo>(`${this.configuration.basePath}/activity-milestones/create`,
            activityMilestoneDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Data for Performance Lag Milestone Scoreboard
     * Fetch Data for Performance Lag Milestone Scoreboard
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPerformanceMilestoneScoreboardData(requestParameters: GetPerformanceMilestoneScoreboardDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PerformanceMilestoneScoreboardPojo>;
    public getPerformanceMilestoneScoreboardData(requestParameters: GetPerformanceMilestoneScoreboardDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PerformanceMilestoneScoreboardPojo>>;
    public getPerformanceMilestoneScoreboardData(requestParameters: GetPerformanceMilestoneScoreboardDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PerformanceMilestoneScoreboardPojo>>;
    public getPerformanceMilestoneScoreboardData(requestParameters: GetPerformanceMilestoneScoreboardDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPerformanceMilestoneScoreboardData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PerformanceMilestoneScoreboardPojo>(`${this.configuration.basePath}/activity-milestones/${encodeURIComponent(String(id))}/scoreboard-data`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Activity Milestone 
     * Search Activity Milestone
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActivityMilestone(requestParameters: SearchActivityMilestoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsActivityMilestonePojo>;
    public searchActivityMilestone(requestParameters: SearchActivityMilestoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsActivityMilestonePojo>>;
    public searchActivityMilestone(requestParameters: SearchActivityMilestoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsActivityMilestonePojo>>;
    public searchActivityMilestone(requestParameters: SearchActivityMilestoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const customerValue = requestParameters.customerValue;
        const createdAt = requestParameters.createdAt;
        const customerValueId = requestParameters.customerValueId;
        const milestone = requestParameters.milestone;
        const createdBy = requestParameters.createdBy;
        const milestoneStatus = requestParameters.milestoneStatus;
        const id = requestParameters.id;
        const dueTime = requestParameters.dueTime;
        const startDate = requestParameters.startDate;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const dueDate = requestParameters.dueDate;
        const limit = requestParameters.limit;
        const keyword = requestParameters.keyword;
        const priorityItems = requestParameters.priorityItems;
        const activityChecklistStatus = requestParameters.activityChecklistStatus;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (customerValue !== undefined && customerValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValue, 'customerValue');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (milestone !== undefined && milestone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>milestone, 'milestone');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (milestoneStatus !== undefined && milestoneStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>milestoneStatus, 'milestoneStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (dueTime !== undefined && dueTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueTime, 'dueTime');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (dueDate !== undefined && dueDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueDate, 'dueDate');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (activityChecklistStatus !== undefined && activityChecklistStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activityChecklistStatus, 'activityChecklistStatus');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsActivityMilestonePojo>(`${this.configuration.basePath}/activity-milestones/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
