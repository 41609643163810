/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DelegationStatusUpdateDto { 
    valueStatus: DelegationStatusUpdateDto.ValueStatusEnum;
    delegationId: number;
    comment?: string;
    newDelegateEmail?: string;
    reDelegationReasonId?: number;
}
export namespace DelegationStatusUpdateDto {
    export type ValueStatusEnum = 'VALUE_CAPTURED' | 'VALUE_NOT_CAPTURED' | 'PENDING' | 'RE_DELEGATED';
    export const ValueStatusEnum = {
        ValueCaptured: 'VALUE_CAPTURED' as ValueStatusEnum,
        ValueNotCaptured: 'VALUE_NOT_CAPTURED' as ValueStatusEnum,
        Pending: 'PENDING' as ValueStatusEnum,
        ReDelegated: 'RE_DELEGATED' as ValueStatusEnum
    };
}


